// import type { QueryClient } from '@tanstack/query-core'
import { BroadcastChannel } from 'broadcast-channel'

export function broadcastQueryClient({ queryClient, broadcastChannel = 'tanstack-query' }) {
    let transaction = false
    const tx = (cb) => {
        transaction = true
        cb()
        transaction = false
    }

    const channel = new BroadcastChannel(broadcastChannel, {
        webWorkerSupport: false,
    })

    channel.postMessage({
        type: 'request-cache',
    })

    const queryCache = queryClient.getQueryCache()

    queryClient.getQueryCache().subscribe((queryEvent) => {
        if (transaction) {
            return
        }
        const { query } = queryEvent ?? {}
        const { queryHash, queryKey, state } = query ?? {}
        if (queryEvent.type === 'updated' && queryEvent.action.type === 'success') {
            channel.postMessage({
                type: 'updated',
                queryHash,
                queryKey,
                state,
            })
        }

        if (queryEvent.type === 'removed') {
            channel.postMessage({
                type: 'removed',
                queryHash,
                queryKey,
            })
        }
    })

    channel.onmessage = (action) => {
        if (!action?.type) {
            return
        }

        tx(() => {
            const { type } = action

            if (type === 'updated') {
                const { queryHash, queryKey, state } = action

                const query = queryCache.get(queryHash)

                if (query) {
                    query.setState(state)
                    return
                }

                queryCache.build(
                    queryClient,
                    {
                        queryKey,
                        queryHash,
                    },
                    state
                )
            } else if (type === 'removed') {
                const { queryHash } = action

                const query = queryCache.get(queryHash)

                if (query) {
                    queryCache.remove(query)
                }
            } else if (type === 'request-cache') {
                let cacheData = queryCache.queries.map((query) => ({
                    queryHash: query.queryHash,
                    queryKey: query.queryKey,
                    state: query.state,
                }))

                channel.postMessage({
                    type: 'send-cache',
                    cache: cacheData,
                })
            } else if (type === 'send-cache') {
                const { cache } = action

                cache?.forEach((query) => {
                    queryCache.build(
                        queryClient,
                        {
                            queryKey: query.queryKey,
                            queryHash: query.queryHash,
                        },
                        query.state
                    )
                })
            }
        })
    }
}
