import classnames from 'classnames'
import { Tooltip, currencyFormatter } from 'components/utils'
import { Badge, Form } from 'front'
import { useModalState } from 'hooks'
import dayjs from 'lib/dayjs'
import { useState } from 'react'
import DeletePayableModal from './modals/DeletePayableModal'
import EditPayableModal from './modals/EditPayableModal'
import LinkTaskModal from './modals/LinkTaskModal'

export const PayableRow = ({ row, fileInfo, taskVariant, taskId }) => {
    const notEditable =
        row?.deadline_input === 'NA' ||
        row?.deadline_status === 'NA' ||
        row?.deadline_status === 'IGNORED'

    const ignored = row?.deadline_status == 'IGNORED'
    const [showLineItems, setShowLineItems] = useState(false)

    return (
        <>
            <tr className='align-middle'>
                <InvoiceNumberColumn
                    notEditable={notEditable}
                    row={row}
                    fileInfo={fileInfo}
                    taskVariant={taskVariant}
                    showLineItems={showLineItems}
                    setShowLineItems={setShowLineItems}
                />
                <AttorneyColumn row={row} />
                <AmountColumn
                    row={row}
                    showLineItems={showLineItems}
                    taskVariant={taskVariant}
                    taskId={taskId}
                />
                <StatusColumn row={row} />
                <InvoiceDateColumn row={row} />
                {!taskVariant && <CreatedDateColumn row={row} />}
                <MenuButtonsColumn row={row} showLineItems={showLineItems} />
            </tr>
            {showLineItems &&
                row?.line_items
                    ?.filter(({ is_deleted }) => !is_deleted)
                    ?.map(({ task_id, item_fee_amount, created }) => (
                        <tr className='align-middle' key={`lineItem-${task_id}`}>
                            <td colSpan={2}>
                                <small className='text-muted d-block'>
                                    {
                                        fileInfo?.tasks?.find((task) => task?.id === task_id)
                                            ?._task_code_label
                                    }
                                </small>
                            </td>
                            <td colSpan={3}>
                                <small className='text-muted d-block'>
                                    {currencyFormatter(item_fee_amount)}
                                </small>
                            </td>
                            <td colSpan={2}>
                                <small className='text-muted d-block'>
                                    {dayjs(created).format('MM/DD/YYYY')}
                                </small>
                            </td>
                        </tr>
                    ))}
        </>
    )
}

function InvoiceNumberColumn({
    notEditable,
    row,
    fileInfo,
    taskVariant,
    showLineItems,
    setShowLineItems,
}) {
    const linkPayableModal = useModalState(false)

    return (
        <>
            <td className='no-wrap'>
                <div className='d-flex align-items-center'>
                    <div>
                        <div
                            className={classnames({
                                'text-dark fw-bold': !notEditable && !row?.completed_date,
                                'text-muted-2': notEditable,
                            })}
                        >
                            {row?.invoice_number}
                        </div>
                        {!taskVariant && (
                            <div>
                                {row?.line_items?.length == 1 && row?.line_items[0]?.task_id && (
                                    <small className='text-muted'>
                                        {
                                            fileInfo?.tasks?.find(
                                                (task) => task?.id === row?.line_items[0]?.task_id
                                            )?._task_code_label
                                        }
                                    </small>
                                )}
                                {row?.line_items?.length > 1 && (
                                    <>
                                        <small
                                            className='text-primary cursor-pointer fw-bold'
                                            onClick={() => setShowLineItems(!showLineItems)}
                                        >
                                            Line Items
                                            <i
                                                className={classnames(
                                                    'fas fa-chevron-up fa-sm transition ms-2',
                                                    {
                                                        'fa-rotate-180': showLineItems,
                                                    }
                                                )}
                                            />
                                        </small>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                    {row?.tooltip?.length > 0 && (
                        <Tooltip content={row?.tooltip}>
                            <div className='fal fa-info-circle text-muted ms-auto' />
                        </Tooltip>
                    )}
                </div>
            </td>
            <LinkTaskModal
                show={linkPayableModal.isOpen}
                onHide={linkPayableModal.hide}
                payable={row}
            />
        </>
    )
}

function AttorneyColumn({ row }) {
    return <td className=''>{row?.vendor_number}</td>
}

function AmountColumn({ row, taskVariant, taskId }) {
    return (
        <td className=''>
            {currencyFormatter(
                !taskVariant
                    ? row?.fee_amount
                    : row?.line_items?.find(({ task_id }) => task_id == taskId)?.item_fee_amount
            )}
        </td>
    )
}

function StatusColumn({ row }) {
    let variant = 'primary',
        soft = false,
        spinner = false

    switch (row?.status) {
        case 'Batch Created':
            variant = 'success'
            soft = true
            break
        case 'Report Started':
            variant = 'info'
            spinner = true
            break
        case 'Report Printed':
            variant = 'success'
            soft = true
            break
        case 'Labels Started':
            variant = 'info'
            spinner = true
            break
        case 'Labels Printed':
            variant = 'success'
            soft = true
            break
        case 'Export Started':
            variant = 'info'
            spinner = true
            break
        case 'Export Complete':
            variant = 'success'
            break
        case 'Error':
            variant = 'danger'
            break
        default:
            variant: 'primary'
    }

    return (
        <td className='text-center'>
            <Badge
                pill
                bg={variant}
                soft={soft}
                icon={spinner ? 'fal fa-spinner-third fa-spin' : null}
            >
                {row?.status ?? 'NEW'}
            </Badge>
        </td>
    )
}

function InvoiceDateColumn({ row }) {
    return <td className=''>{dayjs(row?.invoice_date).format('MM/DD/YYYY')}</td>
}

function CreatedDateColumn({ row }) {
    return <td className=''>{dayjs(row?.created).format('MM/DD/YYYY')}</td>
}

function MenuButtonsColumn({ row }) {
    const deletePayableModal = useModalState(false)
    const editPayableModal = useModalState(false)

    return (
        <>
            <td className='text-right'>
                {/* {row?.status_id < 9 && ( */}
                <>
                    <Tooltip
                        delay={{
                            show: 450,
                            hide: 0,
                        }}
                        content='Edit'
                    >
                        <Form.Button
                            ghost
                            circle
                            variant={'secondary'}
                            onClick={editPayableModal.show}
                            icon={'fal fa-edit'}
                        />
                    </Tooltip>
                    <Tooltip
                        delay={{
                            show: 450,
                            hide: 0,
                        }}
                        content='Delete'
                    >
                        <Form.Button
                            ghost
                            circle
                            variant='danger'
                            onClick={deletePayableModal.show}
                            icon={'fal fa-xmark'}
                        />
                    </Tooltip>
                </>
                {/* )} */}
            </td>
            <DeletePayableModal
                key={deletePayableModal.key}
                show={deletePayableModal.isOpen}
                onHide={deletePayableModal.hide}
                payable={row}
            />
            <EditPayableModal
                key={editPayableModal.key}
                show={editPayableModal.isOpen}
                onHide={editPayableModal.hide}
                payable={row}
            />
        </>
    )
}
