import React, { useState, useEffect, useContext } from 'react'
export const MessageContext = React.createContext({})
import { PortalContext } from '../../GlobalState'
import axios from '../../axios'
import isEmpty from '../../utils/isEmpty'
import JsonCompare from '../../utils/JsonCompare'
import {useIsAuthenticated} from "@azure/msal-react";
import {useCookies} from "react-cookie";
export const CancelToken = axios.CancelToken
export const ROOT_URL = '/messages/1'

export default function GlobalState(props) {
    const { socket, employee } = useContext(PortalContext)
    const [isLoading, setIsLoading] = useState(false)
    const [selected, setSelected] = useState(false)
    const [messages, setMessages] = useState([])
    const [unseen, setUnseen] = useState(0)
    const [contacts, setContacts] = useState([])
    const [incomingMessage, setIncomingMessage] = useState({})
    const [incomingAttachment, setIncomingAttachment] = useState({})

    const isAuthenticated = useIsAuthenticated();
    const [cookies, setCookie, removeCookie] = useCookies(['employee']);

    useEffect(() => {
        const employee = cookies['employee'];
        setIsLoading(true)
        if (!!isAuthenticated && employee) {
            axios.get(`${ROOT_URL}/json`).then(({data}) => {
                setIsLoading(false)
                updateMessages(data)
                setContacts(data.contacts)
                data?.messages?.length > 0 && setSelected(data?.messages[0].id)
            })
        }

        // return socket.removeListener('updateMessages', updateSingleMessage);
    }, [isAuthenticated, cookies])

    useEffect(() => {
        if (socket?.on) {
            // console.log('socket',socket)
            socket.on('updateMessages', changeIncomingMessage)
            socket.on('addAttachment', changeIncomingAttachment)
        }
    }, [socket])

    useEffect(() => {
        // console.log('incoming message',incomingMessage)
        if (!isEmpty(incomingMessage)) {
            updateSingleMessage(incomingMessage.messages[0])
            setUnseen(unseen + incomingMessage.unseen)
        }
    }, [incomingMessage])

    useEffect(() => {
        // console.log('incoming attachment',incomingAttachment)
        if (!isEmpty(incomingAttachment)) {
            addAttachment(incomingAttachment)
        }
    }, [incomingAttachment])

    function changeIncomingMessage(message) {
        console.log('incoming', message)
        JsonCompare(incomingMessage, message) && setIncomingMessage(message)
    }

    function changeIncomingAttachment(attachment) {
        // console.log('attachment',attachment)
        JsonCompare(incomingAttachment, attachment) && setIncomingAttachment(attachment)
    }

    function updateSingleMessage(message) {
        let added = 0
        let newMessages = [...messages]
        newMessages = newMessages.map((m) => {
            if (m.id === message.id) {
                added++
                return { ...m, viewed: 0, messages: [...m.messages, ...message.messages] }
            } else {
                return { ...m }
            }
        })

        if (added === 0) {
            newMessages.unshift({ ...message })
        }
        setMessages(newMessages)
    }

    function addAttachment(attachment) {
        setMessages(
            messages.map((group) => {
                if (group.id === attachment.message_group_id) {
                    return {
                        ...group,
                        messages: group.messages.map((m) => {
                            if (m.id === attachment.message_id) {
                                return { ...m, attachments: [...m.attachments, { ...attachment }] }
                            } else {
                                return { ...m }
                            }
                        }),
                    }
                } else {
                    return { ...group }
                }
            })
        )
    }

    function updateMessages(data) {
        setMessages(data.messages)
        setUnseen(data.unseen)
    }

    function selectMessageGroup(message) {
        setSelected(message.id)
        message.viewed == 0 && updateViewed(message.id, employee?.cnt_id)
    }

    function findSelected(id) {
        return messages.find((message) => message.id === id)
    }

    function sendNewMessage(message, files, message_group) {
        let attachments = []
        const formData = new FormData()
        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            formData.append('files[' + i + ']', file)
            attachments[i] = { name: file.name, type: file.type, size: file.size }
        }
        formData.append(
            'message',
            JSON.stringify({
                sender: employee?.cnt_id,
                message,
                attachments,
                ...message_group,
            })
        )
        setIsLoading(true)
        axios
            .post(`${ROOT_URL}/json`, formData)
            .then(({ data }) => {
                setIsLoading(false)
                updateMessages(data)
                selectMessageGroup(data.messages[0])
            })
            .catch(() => {
                setIsLoading(false)
            })
    }

    function updateViewed(message_group_id, cnt_id) {
        axios.patch(`${ROOT_URL}/json`, { message_group_id, cnt_id }).then(({ data }) => {
            updateMessages(data)
        })
    }

    function hideMessageGroup(message_group_id) {
        axios
            .post(`${ROOT_URL}/hide/json`, { cnt_id: employee?.cnt_id, message_group_id })
            .then(({ data }) => {
                updateMessages(data)
                selectMessageGroup(data.messages[0])
            })
    }

    function downloadFile(id, callback) {
        axios.get(`${ROOT_URL}/download/${id}/json`).then(({ data }) => {
            const linkSource = `data:${data.type};base64, ${data.file}`
            const downloadLink = document.createElement('a')
            document.body.appendChild(downloadLink)

            downloadLink.href = linkSource
            downloadLink.target = '_self'
            downloadLink.download = data.name
            downloadLink.click()

            callback()
        })
    }

    return (
        <MessageContext.Provider
            value={{
                isLoading,
                messages,
                selected,
                selectMessageGroup,
                setSelected,
                findSelected,
                unseen,
                sendNewMessage,
                contacts,
                updateViewed,
                hideMessageGroup,
                downloadFile,
            }}
        >
            {props.children}
        </MessageContext.Provider>
    )
}
