import { Button, Form } from 'front'
import { sortBy } from 'lodash-es'
import { useContext, useEffect, useRef, useState } from 'react'
import { ButtonGroup, Modal } from 'react-bootstrap'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import { useCreateNote, useDeleteNote, useUpdateNote } from 'tracker/api/note.api'
import EmployeeDropdown from '../../../../utils/EmployeeDropdown'
import { Context } from '../../Context/GlobalState'
import NoteTagList from './NoteTagList'

export default function AddEditNoteModal(props) {
    const { dropdowns } = useContext(Context)
    let fileInfo = props?.fileInfo
    const { fileInfo: fileInfoContext } = useContext(ProjectContext)
    if (!fileInfo) {
        fileInfo = fileInfoContext
    }
    const { dynamic_tags: tags } = fileInfo || { dynamic_tags: [] }
    const [noteText, setNoteText] = useState(props?.text ?? '')
    const [newNoteEmp, setNewNoteEmp] = useState(props?.assigned_emp_id ?? false)
    const [newNoteConfirmed, setNewNoteConfirmed] = useState(props?.need_confirmed ?? false)
    const [newNotePinned, setNewNotePinned] = useState(props?.is_pinned ?? false)
    const [tagsSelected, setTagsSelected] = useState(
        typeof props?.tags === 'object' ? Object.values(props?.tags) : props?.tags ?? []
    )
    const newNoteRef = useRef(null)
    const { mutate: addNote, isLoading: saving } = useCreateNote(fileInfo?.project_id, {
        onSuccess,
    })
    const { mutate: updateNote, isLoading: updateSaving } = useUpdateNote(fileInfo?.project_id, {
        onSuccess,
    })
    const { mutate: deleteNote, isLoading: deleteNoteSaving } = useDeleteNote(
        fileInfo?.project_id,
        {
            onSuccess,
        }
    )
    const [showDelete, setShowDelete] = useState(false)

    function onSuccess() {
        setTagsSelected([])
        setNewNoteEmp(false)
        cancelNewNote()
    }

    const noteToClient = tagsSelected?.find((tag) => tag.value === 'note2client')

    useEffect(() => {
        if (props?.show) {
            newNoteRef?.current?.focus()
            setNoteText(props?.text ?? '')
            setNewNoteEmp(props?.assigned_emp_id ?? false)
            setNewNoteConfirmed(props?.need_confirmed || !!props?.assigned_emp_id)
            setNewNotePinned(props?.is_pinned ?? false)
            if (props?.tags) {
                setTagsSelected(
                    typeof props?.tags === 'object' ? Object.values(props?.tags) : props?.tags ?? []
                )
            } else if (props?.associate && props?.associate?._id > 0) {
                setTagsSelected([tags?.find((tag) => tag?.associate?.id == props.associate?._id)])
            } else if (props?.task) {
                setTagsSelected([tags?.find((tag) => tag?.task?.id == props.task?.id)])
            }
        }
    }, [props?.show])

    useEffect(() => {
        setNewNoteEmp(props?.assigned_emp_id ?? false)
    }, [props?.assigned_emp_id])

    // useEffect(() => {
    //     addCustomTag && newCustomTagTextRef?.current?.focus()
    // }, [addCustomTag])

    function changeTagSelected(tag) {
        const inList = tagsSelected?.find((t) => t.value === tag.value)
        if (inList) {
            setTagsSelected((current) => current?.filter((t) => t.value !== tag.value))
        } else {
            setTagsSelected((current) => [...current, tag])
        }
    }

    function saveNote() {
        const data = {
            text: noteText,
            tags: tagsSelected?.map((t) => t.value),
            assigned: newNoteEmp,
            need_confirmed: newNoteConfirmed,
            is_pinned: newNotePinned,
            confirmed: !!props?.confirmed,
            note_to_client: noteToClient,
        }
        if (props?.saveFn) {
            props?.saveFn({ ...data, id: props?.id })
        } else {
            props?.edit ? updateNote({ ...data, id: props?.id }) : addNote(data)
        }

        if (props?.onSave) props?.onSave(data)
    }

    function cancelNewNote() {
        setNoteText('')
        setTagsSelected([])
        newNoteRef?.current?.focus()
        props?.cancel?.()
    }

    return (
        <Modal
            show={props.show}
            style={{ zIndex: 1400 }}
            backdropClassName='backdrop-over-offcanvas'
            onHide={cancelNewNote}
            backdrop='static'
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {props?.title ?? (props?.id > 0 ? 'Edit note' : 'Add note')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={'row'} style={{ marginBottom: '1.25rem' }}>
                    <div className={'col-12'}>
                        <Form.Label>Employee</Form.Label>
                        <EmployeeDropdown
                            employees={sortBy(dropdowns?.employee, ['FirstName', 'LastName'])}
                            onChange={(emp_id) => {
                                setNewNoteEmp(emp_id)
                                if (emp_id) setNewNoteConfirmed(true)
                                else setNewNoteConfirmed(false)
                            }}
                            selected={newNoteEmp}
                            disabled={props?.tagsOnly}
                            allEmployees
                        />
                    </div>
                    <div className={'col-12 mt-3'}>
                        <Form.Label>Tags</Form.Label>
                        <div>
                            {tags?.length > 0 && (
                                <NoteTagList
                                    tags={tags}
                                    tagsSelected={tagsSelected}
                                    changeTagSelected={changeTagSelected}
                                    setTagsSelected={setTagsSelected}
                                />
                            )}
                        </div>
                    </div>
                    <div className='d-flex gap-2 my-2'>
                        <Form.Check
                            label='Needs Confirmed'
                            checked={newNoteConfirmed}
                            onChange={() => {
                                setNewNoteConfirmed(!newNoteConfirmed)
                            }}
                            disable={props?.tagsOnly || !!newNoteEmp}
                            ignoreGlobalDisable={true}
                        />
                        <Form.Check
                            label='Pinned'
                            checked={newNotePinned}
                            onChange={() => {
                                setNewNotePinned(!newNotePinned)
                            }}
                            ignoreGlobalDisable={true}
                        />
                        <Form.Check
                            label='Send Note to Client'
                            checked={noteToClient}
                            onChange={() => {
                                setTagsSelected((current) => {
                                    if (noteToClient) {
                                        return current?.filter((t) => t.value !== 'note2client')
                                    } else {
                                        return [
                                            ...current,
                                            { value: 'note2client', label: 'note2client' },
                                        ]
                                    }
                                })
                            }}
                            ignoreGlobalDisable={true}
                        />
                    </div>
                    {/* {tagsSelected?.length > 0 && (
                        <TagsList tags={tagsSelected} setTagsSelected={setTagsSelected} />
                    )} */}
                </div>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <Form.Control
                            as='textarea'
                            ref={newNoteRef}
                            rows={10}
                            value={noteText}
                            onChange={({ target }) => {
                                setNoteText(target.value)
                            }}
                            disable={props?.tagsOnly}
                            ignoreGlobalDisable={true}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <div>
                    {props?.is_editable && props?.edit && (
                        <div>
                            {!showDelete ? (
                                <Button
                                    variant='danger'
                                    className='w-100'
                                    ghost
                                    onClick={() => {
                                        setShowDelete(true)
                                    }}
                                >
                                    Delete Note
                                </Button>
                            ) : (
                                <ButtonGroup>
                                    <Button
                                        variant='white'
                                        className='ms-1'
                                        onClick={() => {
                                            setShowDelete(false)
                                        }}
                                        disabled={deleteNoteSaving}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        variant='danger'
                                        onClick={() => {
                                            deleteNote(props?.id)
                                        }}
                                        loading={deleteNoteSaving}
                                        disabled={deleteNoteSaving}
                                    >
                                        Confirm Delete
                                    </Button>
                                </ButtonGroup>
                            )}
                        </div>
                    )}
                </div>
                <div>
                    <Button ghost variant='secondary' onClick={cancelNewNote} className='me-2'>
                        Cancel
                    </Button>
                    <Button
                        variant={'primary'}
                        onClick={saveNote}
                        loading={saving || updateSaving || props?.loading}
                        disabled={!fileInfo?.project_id || saving || updateSaving || !noteText}
                        ignoreGlobalDisable={true}
                    >
                        Save
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
