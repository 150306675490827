import { useContext, useState } from 'react'
import { Badge, ListGroup } from '../../../../front'
import { Tooltip } from '../../../../utils'
import classnames from 'classnames'
import Link from 'next/link'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import { PortalContext } from '@/components/GlobalState'
import { useRef } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import dayjs from '@/lib/dayjs'
import { TaskListItem, TaskStatusBadge, getTaskStatusColor } from '../project/tasks/Tasks'
import { toTitleCase } from 'utils'

export default function CurrentTaskBar({}) {
    const { fileInfo } = useContext(ProjectContext)
    const { employee } = useContext(PortalContext)

    const [isHovered, setIsHovered] = useState(false)

    const tasks = fileInfo?.tasks?.filter((t) => t.status != 'DELETED' && t.status != 'COMPLETED')
    const tasksAssigned = tasks
        ?.filter((task) => task.current_employee_id == employee.EmpID)
        ?.sort((a, b) => a.is_service - b.is_service)
    const tasksNotAssigned = tasks?.filter((task) => task.current_employee_id != employee.EmpID)

    const orderedTasks = tasks?.sort((a, b) => a.is_service - b.is_service)

    const currentTask = tasksAssigned?.length > 0 ? tasksAssigned?.[0] : orderedTasks?.[0]

    if (!currentTask) return null
    return (
        <div
            className={classnames(
                'w-full py-2 ps-3 pe-10 d-flex justify-content-between border-bottom',
                {
                    'text-primary bg-soft-primary': tasksAssigned?.length > 0,
                    'bg-soft-secondary': tasksAssigned?.length == 0,
                }
            )}
        >
            <Link
                href={`/nml/tracker/file/tasks?project_id=${fileInfo?.project_id}&task_id=${currentTask?.id}`}
            >
                <div
                    className='flex-grow-1 cursor-pointer'
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <small className={classnames({ 'text-decoration-underline': isHovered })}>
                        <Badge
                            bg={tasksAssigned?.length > 0 ? 'primary' : 'secondary'}
                            soft
                            className='me-1'
                        >
                            {currentTask?.service_level}
                        </Badge>
                        <span className='fw-bold me-1'>
                            ({currentTask?.task_code}) {currentTask?._task_code_label}
                        </span>
                        <span className='fst-italic'>{currentTask?.employee_name}</span>
                        <i
                            className='fas fa-arrow-right ms-2 fa-xs'
                            style={{
                                paddingLeft: isHovered ? 3 : 0,
                                transition: 'padding-left 0.2s',
                            }}
                        />
                    </small>
                </div>
            </Link>
            <MoreTasksPopover
                tasks={tasks}
                tasksAssigned={tasksAssigned}
                tasksNotAssigned={tasksNotAssigned}
            />
        </div>
    )
}

function MoreTasksPopover({ tasks, tasksAssigned, tasksNotAssigned }) {
    const { fileInfo } = useContext(ProjectContext)

    const [show, setShow] = useState(false)

    const handleOnMouseEnter = () => {
        setShow(true)
    }
    const handleOnMouseLeave = () => {
        setShow(false)
    }
    const ref = useRef(null)

    return (
        <OverlayTrigger
            placement='bottom'
            show={show}
            container={ref}
            overlay={
                <Popover
                    id={`current-active-tasks-popover`}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                    style={{ maxWidth: '50%', width: 500 }}
                >
                    <Popover.Header className='d-flex justify-content-between fs-6 fw-bold py-1 px-3 border-bottom border-1'>
                        <div>
                            <small>
                                Your tasks
                                <Badge bg='secondary' soft className='ms-2'>
                                    {tasksAssigned.length ?? 0}
                                </Badge>
                            </small>
                        </div>
                    </Popover.Header>
                    <Popover.Body className='p-3'>
                        <ListGroup startBordered noBorders>
                            {tasksAssigned?.map((task, i) => (
                                <TaskListItem task={task} key={i} />
                            ))}
                        </ListGroup>
                    </Popover.Body>
                    <Popover.Header className='d-flex justify-content-between fs-6 fw-bold py-1 px-3 border-bottom border-top border-1 rounded-0'>
                        <div>
                            <small>
                                Other tasks
                                <Badge bg='secondary' soft className='ms-2'>
                                    {tasksNotAssigned.length ?? 0}
                                </Badge>
                            </small>
                        </div>
                    </Popover.Header>
                    <Popover.Body className='p-3'>
                        <ListGroup startBordered noBorders>
                            {tasksNotAssigned?.map((task, i) => (
                                <TaskListItem task={task} key={i} />
                            ))}
                        </ListGroup>
                    </Popover.Body>
                </Popover>
            }
        >
            <div
                ref={ref}
                className={classnames('d-inline-block cursor-default')}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
            >
                <small>{tasks?.length} More</small>
            </div>
        </OverlayTrigger>
    )
}
