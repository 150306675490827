import { Form } from 'front'
import { useContext } from 'react'
import { ProjectContext } from 'tracker/Context/ProjectContext'

export default function LinkTaskForm({ value, onChange, excludeIds = [], ...props }) {
    const { fileInfo } = useContext(ProjectContext)
    const options = [
        { id: null, _task_code_label: 'None' },
        ...fileInfo?.tasks?.filter((task) => !excludeIds?.find((id) => id == task?.id)),
    ]
    const selectedValue = options?.find((o) => o?.id == value)

    return (
        <Form.AdvancedSelect
            onChange={({ value }) => onChange(value)}
            options={options?.map((o) => taskOption(o))}
            value={taskOption(selectedValue)}
            placeholder={`Select a task to link`}
            {...props}
        />
    )
}

function taskOption(task) {
    return {
        value: task?.id,
        label: task?._task_code_label,
    }
}
