import classnames from 'classnames'
import { currencyFormatter } from 'components/utils'
import dayjs from 'dayjs'
import { Card, Form, ListGroup } from 'front'
import _ from 'lodash'
import { useState } from 'react'
import { ButtonGroup, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'

export function PacketFurnishings({ fileInfo, packet, canEdit = true }) {
    const [strategy, setStrategy] = useState('furnishings')
    const [dateRange, setDateRange] = useState([])
    const [showPaidFurnishings, setShowPaidFurnishings] = useState(true)
    const [furnishingSelections, setFurnishingSelections] = useState({})
    const {
        furnishings,
        selectFurnishing,
        selectedFurnishings,
        getFurnishingsPostObject,
        selectMonth,
        furnishingMonths,
    } = packet
    const filteredFurnishings = furnishings
        ?.filter(
            ({ is_deleted, balance }) =>
                !(is_deleted || (!showPaidFurnishings && Number(balance) === 0))
        )
        .reverse()

    const isFurnishingDate = (day) => {
        return !!filteredFurnishings?.find(({ date }) => date === dayjs(day).format('YYYY-MM-DD'))
    }

    const numSelected = selectedFurnishings?.length
    // const firstFurnishing = dayjs(
    //     filteredFurnishings?.[filteredFurnishings.length - 1]?.date
    // ).toDate()
    // const lastFurnishing = dayjs(filteredFurnishings?.[0]?.date).toDate()

    return (
        <Card className={'w-100'}>
            <Card.Header>
                <div className='d-flex justify-content-between align-items-center'>
                    <Card.Title as='h4' className='d-block'>
                        Furnishings
                    </Card.Title>
                    {/* <ShowPaidFurnishingsToggle
                        value={showPaidFurnishings}
                        onChange={setShowPaidFurnishings}
                    /> */}
                </div>
                <div className='pt-4 pb-2'>
                    <Strategies value={strategy} onChange={setStrategy} />
                </div>
            </Card.Header>
            {strategy == 'month' && (
                <MonthList
                    furnishingMonths={furnishingMonths}
                    selectMonth={selectMonth}
                    canEdit={canEdit}
                />
            )}
            {filteredFurnishings?.length === 0 && (
                <div className='my-5 text-center text-muted'>No furnishings found</div>
            )}
            {strategy == 'furnishings' && (
                <FurnishingsList
                    furnishings={filteredFurnishings}
                    selectFurnishing={selectFurnishing}
                    canEdit={canEdit}
                />
            )}
            {/* {strategy == 'daterange' && (
                <Form.Date
                    disabled={!canEdit}
                    inline
                    value={dateRange}
                    onChange={setDateRange}
                    hideInput
                    config={{
                        defaultDate: [lastFurnishing, lastFurnishing],
                        mode: 'range',
                        disable: [
                            (date) => {
                                return date < firstFurnishing || date > lastFurnishing
                            },
                        ],
                    }}
                    onDayCreate={(dObj, dStr, fp, dayElem) => {
                        if (isFurnishingDate(dayElem.dateObj))
                            dayElem.innerHTML += "<span class='event bg-danger'></span>"
                    }}
                />
            )} */}
            {numSelected > 0 && (
                <Card.Footer
                    className='d-flex align-items-center justify-content-center bg-success'
                    style={{ height: 25 }}
                >
                    <span className='text-white fw-bold'>{numSelected} Furnishings Selected</span>
                </Card.Footer>
            )}
        </Card>
    )
}

const strategies = [
    {
        name: 'month',
        label: 'MONTH',
        value: 'month',
    },
    {
        name: 'furnishings',
        label: 'FURNISHINGS',
        value: 'furnishings',
    },
    // {
    //     name: 'daterange',
    //     label: 'DATE RANGE',
    //     value: 'daterange',
    // },
]

function Strategies({ value, onChange }) {
    return (
        <ButtonGroup className='w-100'>
            {strategies.map((strategy, i) => {
                const checked = strategy.value == value
                const label = strategy.label
                return (
                    <ToggleButton
                        key={i}
                        type='radio'
                        id={`strategy-${i}`}
                        size='xs'
                        variant={`outline-${checked ? 'primary' : 'secondary'}`}
                        name={strategy.name}
                        value={strategy.value}
                        checked={checked}
                        onClick={() => {
                            onChange(strategy.value)
                        }}
                    >
                        {label}
                    </ToggleButton>
                )
            })}
        </ButtonGroup>
    )
}

function MonthList({ furnishingMonths, selectMonth, canEdit = true }) {
    return (
        <ListGroup noBorders className='overflow-auto' style={{ height: 500 }}>
            {furnishingMonths
                ?.sort((a, b) => a.sort - b.sort)
                .map(({ month, year, furnishings, totalBalance, selected }) => {
                    return (
                        <ListGroup.Item
                            key={`${year}-${month}`}
                            action
                            className={classnames(
                                'd-flex pe-4 justify-content-start align-items-center',
                                {
                                    'cursor-pointer': canEdit,
                                }
                            )}
                            onClick={() => selectMonth(year, month, !selected)}
                            disabled={!canEdit}
                        >
                            <Form.Check type='checkbox' checked={selected} disable={!canEdit} />
                            <div className='ms-3 w-100 lh-sm'>
                                <div className='fw-bold'>
                                    {month}
                                    <span className='text-muted fs-smaller ms-1 fst-italic'>
                                        ({year})
                                    </span>
                                </div>
                                <small className='text-muted'>{`${furnishings} furnishing${
                                    furnishings > 1 ? 's' : ''
                                }`}</small>
                            </div>

                            <div className='text-muted flex-shrink-0'>
                                {currencyFormatter(totalBalance)}
                            </div>
                        </ListGroup.Item>
                    )
                })}
        </ListGroup>
    )
}

function FurnishingsList({ furnishings, selectFurnishing, canEdit = true }) {
    return (
        <ListGroup noBorders className='overflow-auto' style={{ height: 500 }}>
            {furnishings?.map(({ _id, date, balance, selected }) => {
                return (
                    <ListGroup.Item
                        action
                        className={classnames(
                            'd-flex pe-4 justify-content-start align-items-center',
                            {
                                'cursor-pointer': canEdit,
                            }
                        )}
                        onClick={() => selectFurnishing(_id, !selected)}
                        disabled={!canEdit}
                    >
                        <Form.Check type='checkbox' checked={selected} disable={!canEdit} />

                        <div className='ms-3 w-100'>
                            <div className='fw-bold'>{dayjs(date).format('MMM D, YYYY')}</div>
                        </div>
                        <div className='text-muted flex-shrink-0'>{currencyFormatter(balance)}</div>
                    </ListGroup.Item>
                )
            })}{' '}
        </ListGroup>
    )
}

function ShowPaidFurnishingsToggle({ value, onChange }) {
    return (
        <ToggleButtonGroup
            name='showAddressesToggle'
            value={value}
            type='radio'
            className='btn-group-segment'
            size='xs'
        >
            <ToggleButton value={false} variant='' onClick={() => onChange(false)}>
                {/* <i className='fas fa-location-dot-slash' /> */}
                <span className='fw-bold'>Unpaid</span>
            </ToggleButton>
            <ToggleButton value variant='' onClick={() => onChange(true)}>
                {/* <i className='fas fa-location-dot' /> */}
                <span className='fw-bold'>All</span>
            </ToggleButton>
        </ToggleButtonGroup>
    )
}
