import { useContext, useState } from 'react'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import { useProjectPackets, useTrackerDropdowns } from 'tracker/api'
import PacketSection from './PacketSection'
import { Badge, Button, Card, ListGroup } from 'front'
import { MicroApp, Tooltip, formatDate, useMicroApp } from '@/components/utils'
import { PacketStatusBadge } from 'tracker/file/docgen/PacketStatusBadge'
import PacketOverview from 'tracker/file/docgen/PacketOverview'

export default function PacketCard({ task, preview = false, fileInfo }) {
    const { data: dropdowns } = useTrackerDropdowns()
    const taskProcess = Object.values(dropdowns?.['task.process'] ?? {})?.find(
        (p) => p.id == task?.process_id
    )

    const [selectPacketId, setSelectPacketId] = useState(null)
    const [newPacketId, setNewPacketId] = useState()
    const [selectedPacket, setSelectedPacket] = useState(null)
    const newPacketPopover = useMicroApp()
    const editPacketPopover = useMicroApp()

    let projectId = task?.project_id ?? fileInfo?.project_id
    const { projectId: contextProjectId, canEdit } = useContext(ProjectContext)
    if (!projectId) {
        projectId = contextProjectId
    }
    const { data: packetList, ...packetListApi } = useProjectPackets(projectId, {})

    const servicePacketList = packetList?.filter((p) => p?.service_id == task?.id)
    const filteredList = servicePacketList?.filter((p) => p?.status != 'DELETED')
    if (preview && filteredList?.length === 0) return null

    const noLinkedDate = !(task?._deadline_ids?.length > 0)

    return (
        <>
            {!preview && (
                <MicroApp
                    id={newPacketPopover.id}
                    label={task?._task_code_label}
                    as={PacketOverview}
                >
                    <PacketOverview
                        key={newPacketPopover.id}
                        popover
                        projectId={projectId}
                        taskId={task?.id}
                        task={task}
                        currentEmployeeId={task?.current_employee_id}
                    />
                </MicroApp>
            )}
            {filteredList?.length == 1 || selectPacketId ? (
                <PacketSection
                    task={task}
                    preview={preview}
                    fileInfo={fileInfo}
                    packetId={selectPacketId || filteredList[0]?.id}
                    setSelectPacketId={setSelectPacketId}
                    taskProcess={taskProcess}
                />
            ) : (
                <Card>
                    <Card.Header
                        className={
                            'd-flex justify-content-between align-items-center ' +
                            (preview ? 'sticky-top bg-white' : '')
                        }
                        style={{ top: '-2rem' }}
                    >
                        <div className='d-flex align-items-center'>
                            <Card.Title>Packet{filteredList?.length > 1 ? 's' : ''}</Card.Title>
                            {!!taskProcess?.is_post_print_review && (
                                <Badge bg='success' soft className='ms-2'>
                                    Post-Print Review Enabled
                                </Badge>
                            )}
                        </div>
                        {filteredList?.length === 0 && canEdit && !packetListApi?.isLoading && (
                            <Tooltip
                                content={
                                    noLinkedDate
                                        ? 'You must link a date before creating a packet.'
                                        : null
                                }
                                placement='top'
                            >
                                <div>
                                    <Button
                                        size='xs'
                                        variant='primary'
                                        onClick={newPacketPopover.show}
                                        className={' ms-3'}
                                        disabled={noLinkedDate}
                                    >
                                        Process
                                    </Button>
                                </div>
                            </Tooltip>
                        )}

                        {filteredList?.length > 0 && canEdit && !packetListApi?.isLoading && (
                            <a onClick={newPacketPopover.show} className={'link ms-3'}>
                                New
                            </a>
                        )}
                    </Card.Header>
                    <ListGroup noBorders>
                        {filteredList?.map((packet) => (
                            <ListGroup.Item
                                key={packet.id}
                                className='d-flex justify-content-between align-items-center'
                            >
                                <div>
                                    <div className='fw-bold text-body'>
                                        {task?._task_code_label}{' '}
                                        <PacketStatusBadge
                                            status={packet?.status}
                                            className='ms-2 text-uppercase'
                                        />
                                    </div>
                                    <div className='text-muted'>
                                        <small>
                                            {formatDate(packet?.created_date, 'M/D/YYYY')}
                                        </small>
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        size='sm'
                                        onClick={() => {
                                            setSelectedPacket(packet)
                                            editPacketPopover.show()
                                        }}
                                    >
                                        {canEdit ? 'Edit' : 'View'}
                                    </Button>
                                </div>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Card>
            )}
            <MicroApp id={editPacketPopover.id} label={task?._task_code_label} as={PacketOverview}>
                <PacketOverview
                    key={editPacketPopover.id}
                    popover
                    fileInfo={fileInfo}
                    projectId={projectId}
                    task={task}
                    taskId={task?.id}
                    currentEmployeeId={task?.current_employee_id}
                    packetId={selectedPacket?.id}
                    onDeletePacket={() => {
                        setSelectedPacket(null)
                    }}
                />
            </MicroApp>
        </>
    )
}
