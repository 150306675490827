import { NumericFormat } from 'react-number-format'
import Form from './Form'
export default function PriceInput({ placeholder, label, onChange, ...props }) {
    return (
        <NumericFormat
            {...props}
            prefix='$'
            thousandSeparator
            decimalScale={2}
            customInput={Form.Control}
            placeholder={placeholder ?? `Enter ${label ?? 'price'}`}
            onValueChange={({ floatValue }) => {
                onChange({ target: { name: props?.name, value: floatValue } })
            }}
        />
    )
}
