import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import { useMutation } from '@tanstack/react-query'
import classnames from 'classnames'
import { useModalState } from 'hooks'
import dayjs from 'lib/dayjs'
import Link from 'next/link'
import { forwardRef, useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import Collapse from 'react-bootstrap/Collapse'
import { renderToStaticMarkup } from 'react-dom/server'
import Badge from './front/components/Badge'
import Button from './front/components/Button'
import Dropdown from './front/components/Dropdown'
import ListGroup from './front/components/ListGroup'
import HeaderBar from './front/navbars/HeaderBar'
import { PortalContext } from './GlobalState'
import { buildDraftEmail, sendEmail } from './GraphService'
import Messages from './messages'

export default function FrontHeader({ links }) {
    const { auth, admin, theme, setTheme, errors, removeError, removeAllErrors } =
        useContext(PortalContext)
    const errorModal = useModalState(false)
    const [error, setError] = useState(null)

    function clearLocalAndReload() {
        if (window) {
            window.localStorage.clear()
            window.location.reload()
        }
    }

    useEffect(() => {
        HSBsDropdown.init()
    }, [])

    useEffect(() => {
        new HSMegaMenu('.js-mega-menu', { desktop: { position: 'left' } })
    }, [links])

    return (
        <HeaderBar>
            <HeaderBar.Brand href={'/'} logoSrc={'/images/ncs-logo-dark-theme.png'} />
            <AuthenticatedTemplate>
                <HeaderBar.MainContent>
                    <ul className='navbar-nav me-auto'>
                        {links?.map((link, i) => {
                            if (!link) {
                                return null
                            }
                            return link?.dropdown ? (
                                <li className={'hs-has-sub-menu nav-item'} key={i}>
                                    <a
                                        className={'hs-mega-menu-invoker nav-link dropdown-toggle '}
                                        id={`navbarItem-${i}`}
                                        href={'#'}
                                        data-bs-toggle='dropdown'
                                    >
                                        <span
                                            className={link.icon + ' dropdown-item-icon'}
                                            style={{ color: '#f9fafc' }}
                                        />
                                        {link.title}
                                    </a>
                                    <div
                                        className={'hs-sub-menu dropdown-menu animated'}
                                        aria-labelledby={`navbarItem-${i}`}
                                    >
                                        {link.dropdown.map((item, i) => {
                                            if (!item) {
                                                return null
                                            }
                                            return item?.submenu?.length > 0 ? (
                                                <div className={'dropdown-submenu'} key={i}>
                                                    <NextLink
                                                        href={item.href}
                                                        passHref
                                                        title={item.title}
                                                    >
                                                        <a
                                                            className={'dropdown-item'}
                                                            tabIndex={'-1'}
                                                        >
                                                            {item.title}
                                                        </a>
                                                    </NextLink>
                                                    <div className={'dropdown-menu'}>
                                                        {item.submenu.map((sub, i) => {
                                                            if (!sub) {
                                                                return null
                                                            }
                                                            return (
                                                                <NextLink
                                                                    href={sub.href}
                                                                    passHref
                                                                    key={i}
                                                                    title={sub.title}
                                                                >
                                                                    <a
                                                                        className={'dropdown-item'}
                                                                        href={sub.href}
                                                                    >
                                                                        {sub.title}
                                                                    </a>
                                                                </NextLink>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            ) : (
                                                <NextLink
                                                    href={item.href}
                                                    key={i}
                                                    passHref
                                                    title={item.title}
                                                >
                                                    <a className={'dropdown-item'}>{item.title}</a>
                                                </NextLink>
                                            )
                                        })}
                                    </div>
                                </li>
                            ) : (
                                <Link href={link.href} key={i} passHref>
                                    <li className={'nav-item'}>
                                        <a className='hs-mega-menu-invoker nav-link'>
                                            <span
                                                className={link.icon + ' dropdown-item-icon'}
                                                style={{ color: '#f9fafc' }}
                                            />
                                            {link.title}
                                        </a>
                                    </li>
                                </Link>
                            )
                        })}
                        <li className='nav-item'>
                            <a
                                className='hs-mega-menu-invoker nav-link'
                                href={process.env.PORTAL_COOKIE}
                            >
                                Switch to Portal 1
                            </a>
                        </li>
                    </ul>
                </HeaderBar.MainContent>
                <HeaderBar.SecondaryContent className='text-body'>
                    {errors?.length > 0 && (
                        <li className='nav-item'>
                            <Dropdown>
                                <Dropdown.Toggle as={ErrorDropdownBtn} animation />
                                <Dropdown.Menu align='end' style={{ right: 0 }}>
                                    {errors?.length > 1 && (
                                        <Dropdown.Item
                                            onClick={removeAllErrors}
                                            className='text-center'
                                        >
                                            <h5>Dismiss All</h5>
                                        </Dropdown.Item>
                                    )}
                                    {errors?.map((error, i) => (
                                        <div
                                            className='d-flex align-items-center justify-content-between'
                                            key={i}
                                        >
                                            <Dropdown.Item
                                                onClick={() => {
                                                    errorModal.toggle()
                                                    setError(error)
                                                }}
                                            >
                                                <h5>{error.label}</h5>
                                                <small className='text-muted'>
                                                    {error.message}
                                                </small>
                                                <div className='truncate-lines-3'>
                                                    {error?.data?.errors?.[0]?.message}
                                                </div>
                                                <small className='text-muted mt-3'>
                                                    {dayjs(error?.created)?.fromNow()}
                                                </small>
                                            </Dropdown.Item>
                                            <Button
                                                ghost
                                                variant='secondary'
                                                className='px-3 pointer h-100'
                                                onClick={() => {
                                                    removeError(error.id)
                                                }}
                                            >
                                                <span className='fas fa-times' />
                                            </Button>
                                        </div>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                    )}
                    <Messages />
                    <li className='nav-item text-body'>
                        <div className='dropdown'>
                            <button
                                type='button'
                                className='btn btn-ghost-light btn-icon rounded-circle'
                                id='selectThemeDropdown'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                                data-bs-dropdown-animation
                            >
                                <i
                                    className={classnames('fal', {
                                        'fa-sun-bright': theme == 'light',
                                        'fa-moon': theme == 'dark',
                                    })}
                                ></i>
                            </button>
                            <div
                                className='dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless'
                                aria-labelledby='selectThemeDropdown'
                            >
                                <a
                                    className={classnames('dropdown-item', {
                                        active: theme == 'light',
                                    })}
                                    href='#'
                                    data-icon='fal fa-sun-bright'
                                    data-value='default'
                                    onClick={() => {
                                        setTheme('light')
                                    }}
                                >
                                    <i className='fal fa-sun-bright me-2'></i>
                                    <span className='text-truncate' title='Default (light mode)'>
                                        Light
                                    </span>
                                </a>
                                <a
                                    className={classnames('dropdown-item', {
                                        active: theme == 'dark',
                                    })}
                                    href='#'
                                    data-icon='fal fa-moon'
                                    data-value='dark'
                                    onClick={() => {
                                        setTheme('dark')
                                    }}
                                >
                                    <i className='fal fa-moon me-2'></i>
                                    <span className='text-truncate' title='Dark'>
                                        Dark
                                    </span>
                                </a>
                            </div>
                        </div>
                    </li>
                    <li className={'navbar-nav ms-auto'}>
                        <div className={'nav-item'} id={'navbar_right'}>
                            <HeaderBar.Avatar />
                        </div>
                    </li>
                </HeaderBar.SecondaryContent>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <HeaderBar.MainContent></HeaderBar.MainContent>
                <HeaderBar.SecondaryContent>
                    <li className={'navbar-nav ms-auto'}>
                        <div className={'nav-item'} id={'navbar_right'}>
                            <a className={'nav-link'} onClick={clearLocalAndReload} href={'#'}>
                                <span className={'far fa-times'} /> Clear and Refresh
                            </a>
                        </div>
                        <div className={'nav-item'} id={'navbar_right'}>
                            <a className={'nav-link'} onClick={auth.signIn} href={'#'}>
                                <span className={'far fa-sign-in'} /> Sign In
                            </a>
                        </div>
                    </li>
                </HeaderBar.SecondaryContent>
            </UnauthenticatedTemplate>
            <HeaderBar.Toggler />
            <ErrorModal show={errorModal.isOpen} hide={errorModal.hide} error={error} />
        </HeaderBar>
    )
}

function NextLink(props) {
    if (props?.href?.startsWith('http')) {
        return (
            <a href={props.href} target={'_blank'} className={'dropdown-item'} rel={'noreferrer'}>
                {props.title}
            </a>
        )
    } else {
        return <Link {...props}>{props.children}</Link>
    }
}

const ErrorDropdownBtn = forwardRef(({ onClick }, ref) => {
    const { errors } = useContext(PortalContext)
    return (
        <div className='position-relative'>
            <Button
                icon='fal fa-circle-exclamation'
                ghost
                variant='light'
                circle
                onClick={onClick}
            />
            <Badge
                bg='danger'
                pill
                className='position-absolute translate-middle fs-xs rounded-circle'
                style={{ top: 11, right: -9 }}
            >
                {errors?.length}
            </Badge>
        </div>
    )
})

function ErrorModal({ show, hide, error }) {
    const {
        employee: { Email },
        auth: { authProvider },
        removeError,
    } = useContext(PortalContext)
    const collapse = useModalState(false)
    const { mutate, isLoading } = useMutation({
        mutationFn: async () => {
            const email = await sendBug({ Email, authProvider, error })
            if (email) hide()
            // console.log('email: ', email)
        },
    })

    return (
        <Modal show={show} onHide={hide}>
            <Modal.Header closeButton>
                <Modal.Title>{error?.label}</Modal.Title>
            </Modal.Header>
            <ErrorModalBody error={error} collapse={collapse} />
            <Modal.Footer className='d-flex justify-content-between'>
                <Button onClick={hide}>Close</Button>
                <div>
                    <Button
                        ghost
                        variant='secondary'
                        onClick={() => {
                            removeError(error.id)
                            hide()
                        }}
                        className='me-2'
                    >
                        Dismiss
                    </Button>
                    <Button variant='primary' onClick={mutate} loading={isLoading}>
                        Send To Devs
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

async function sendBug({ Email, authProvider, error }) {
    // Creates email draft
    const builtDraft = await buildDraftEmail(
        {
            from: Email,
            toRecipients:
                process.env.ENVIRONMENT === 'PRODUCTION'
                    ? [
                          'rszemplak@ncscredit.com',
                          'jtaylor@ncscredit.com',
                          'lbidwell@ncscredit.com',
                          'mspangler@ncscredit.com',
                      ]
                    : ['rszemplak@ncscredit.com'],
            ccRecipients: [],
            subject: 'Error From Portal',
            body: renderToStaticMarkup(<ErrorModalBody error={error} />),
        },
        authProvider
    )
    console.log('builtDraft: ', builtDraft)
    return await sendEmail(builtDraft.id, authProvider)
}

function ErrorModalBody({ error, collapse }) {
    return (
        <Modal.Body>
            {error?.data?.data && (
                <div className='mb-3 text-black'>
                    <div>Data</div>
                    {JSON.stringify(error?.data?.data, null, 2)}
                </div>
            )}
            <ListGroup>
                {error?.data?.errors?.map((error) => (
                    <ListGroup.Item>
                        <h4 className='d-flex justify-content-between'>
                            <div>{error?.errorClass}</div>
                            <small className='text-muted mb-3'>{error?.file}</small>
                        </h4>

                        <p className='mt-2'>{error?.message}</p>

                        {error?.stack?.length > 0 && (
                            <div
                                className='py-3 d-flex justify-content-between pointer'
                                onClick={collapse?.toggle}
                            >
                                <div>Stack</div>
                                <div>
                                    <span
                                        className={
                                            'fas fa-chevron-' + (collapse?.isOpen ? 'up' : 'down')
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        <Collapse in={collapse?.isOpen ?? true}>
                            <pre>{JSON.stringify(error?.stack, null, 2)}</pre>
                        </Collapse>
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </Modal.Body>
    )
}
