import { Form } from 'front'
import { useContext } from 'react'
import { ProjectContext } from 'tracker/Context/ProjectContext'

export default function NoteTagList({ search, setTagsSelected, changeTagSelected, tagsSelected }) {
    const { fileInfo } = useContext(ProjectContext)
    const { dynamic_tags } = fileInfo || {}
    let options = dynamic_tags
    const untaggedNoteObj = { value: 'untagged', label: 'Untagged Notes' }
    if (search) {
        options = [untaggedNoteObj, ...options]
    }
    // console.log('options: ', options)
    // console.log('tagsSelected: ', tagsSelected)
    return (
        <>
            <Form.AdvancedSelect
                isMulti
                options={options}
                ignoreGlobalDisable
                onChange={(tags) => {
                    setTagsSelected(tags)
                }}
                value={tagsSelected}
                placeHolder='Select Tags'
            />
        </>
    )
}
