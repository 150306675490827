import classnames from 'classnames'
import dayjs from 'dayjs'
import { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { Badge, Button, Card, ListGroup } from '../../../../../front'
import { NoticeContext } from '../../../Context/NoticeContext'
import { ProjectContext } from '../../../Context/ProjectContext'
import NewTaskModal from './NewTaskModal'
import TaskCard from './TaskCard'
import { toTitleCase } from 'utils'
import { useRouter } from 'next/router'
import { PortalContext } from '@/components/GlobalState'
import Link from 'next/link'

export default function Tasks({}) {
    const { fileInfo, canEdit } = useContext(ProjectContext)
    const { getPacketListForProject } = useContext(NoticeContext)
    const [selectedTaskId, setSelectedTaskId] = useState(null)
    const [showNewTaskModal, setShowNewTaskModal] = useState(false)
    const tasks = fileInfo?.tasks?.filter((t) => t.status != 'DELETED')
    const router = useRouter()

    useEffect(() => {
        const { task_id } = router.query
        setSelectedTaskId(task_id)
    }, [router])

    useEffect(() => {
        getPacketListForProject(fileInfo?.project_id)
    }, [fileInfo?.project_id])

    if (tasks?.length > 0 && !selectedTaskId) {
        setSelectedTaskId(tasks[0]?.id)
    }

    const selectedTask = tasks?.find((task) => task.id == selectedTaskId)

    return (
        <div className='p-3'>
            <Row className={'justify-content-center'}>
                <Col sm={5}>
                    {fileInfo ? (
                        <>
                            <Row className='mb-3'>
                                <Col className='d-flex justify-content-between'>
                                    {/* <Button icon='fal fa-filter'>Filter</Button> */}
                                    <Button
                                        icon='far fa-plus'
                                        onClick={() => setShowNewTaskModal(true)}
                                        disabled={!canEdit}
                                    >
                                        New Task
                                    </Button>
                                </Col>
                            </Row>
                            <Card>
                                <Card.Body style={{ height: '900px', overflow: 'auto' }}>
                                    <ListGroup startBordered noBorders>
                                        {tasks
                                            ?.sort(
                                                (a, b) =>
                                                    new Date(b?.created_date) -
                                                    new Date(a?.created_date)
                                            )
                                            ?.map((task) => (
                                                <TaskListItem
                                                    key={task?.id}
                                                    task={task}
                                                    selected={task.id == selectedTaskId}
                                                />
                                            ))}
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </>
                    ) : (
                        <TaskListLoading />
                    )}
                </Col>
                <Col sm={7}>
                    {selectedTask && (
                        <TaskCard
                            key={selectedTaskId}
                            task={selectedTask}
                            project_id={fileInfo?.project_id}
                        />
                    )}
                    {!fileInfo && <TaskLoading />}
                </Col>
            </Row>
            <NewTaskModal
                show={showNewTaskModal}
                onHide={() => setShowNewTaskModal(false)}
                setSelectedTaskId={setSelectedTaskId}
            />
        </div>
    )
}

export const TaskListItem = ({ task, selected }) => {
    const { fileInfo } = useContext(ProjectContext)

    let completedDate = dayjs(task?.completed_date).format('M/D/YYYY')
    let createdDate = dayjs(task?.created_date).format('M/D/YYYY')
    const [isHovered, setIsHovered] = useState(false)

    return (
        <Link
            href={`/nml/tracker/file/tasks?project_id=${fileInfo?.project_id}&task_id=${task?.id}`}
        >
            <ListGroup.Item
                selected={selected}
                className={classnames('pointer', {
                    'bg-light': selected || isHovered,
                })}
                startBorderVariant={getTaskStatusColor(task?.status)}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div className='d-flex align-items-center pe-2'>
                    <span className='fs-5 fw-bold text-dark'>{task._task_code_label}</span>
                    {task?.is_priority == 1 && (
                        <small>
                            <TaskStatusBadge status={'PRIORITY'} className='ms-2' />
                        </small>
                    )}
                    <small className='ms-auto'>
                        <TaskStatusBadge status={task?.status} />
                    </small>
                </div>
                <div className='d-flex justify-content-between pe-2'>
                    <small className='d-block text-body'>
                        <b>{toTitleCase(task?.service_level)}</b>
                        {' - '}
                        <span>{task?.employee_name}</span>
                    </small>
                    <small className='text-body'>
                        {task?.created_date && <span className=''>{createdDate}</span>}
                    </small>
                </div>
            </ListGroup.Item>
        </Link>
    )
}

export const TaskStatusBadge = ({ status, className, ...rest }) => (
    <Badge
        {...rest}
        soft={status != 'PRIORITY'}
        bg={getTaskStatusColor(status)}
        className={classnames(className)}
    >
        {status}
    </Badge>
)

export const getTaskStatusColor = (status) => {
    switch (status) {
        case 'NEW':
        case 'PENDING':
            return 'primary'
        case 'COMPLETED':
        case 'COLLECTIONS':
            return 'success'
        case 'ARCHIVED':
        case 'CANCELLED':
        case 'DELTED':
        case 'PRIORITY':
        case 'ASAP':
            return 'danger'
        case 'IN PROGRESS':
        default:
            'secondary'
    }
}

const TaskListLoading = () => (
    <Card ghost>
        <Card.Body>
            {[...Array(9)].map((_, i) => (
                <Skeleton key={i} className='w-100 lh-lg mb-4' style={{ height: '65px' }} />
            ))}
        </Card.Body>
    </Card>
)

const TaskLoading = () => (
    <>
        {[...Array(3)].map((_, i) => (
            <Card style={{ height: '250px' }} key={i} skeleton />
        ))}
    </>
)
