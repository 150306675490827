import { useDocuwareSearch } from 'components/docuware/api/dwSearch.api'
import { useEffect, useState } from 'react'
import { usePacket, useTemplatesForTask, useUpdatePacket } from 'tracker/api'

export const usePacketDocuments = (projectId, taskId, packetId, isModalOpen) => {
    // Packet API
    const { data: packet, ...packetApi } = usePacket(packetId, {
        enabled: !!packetId && isModalOpen,
    })
    // Update Packet API
    const { mutate: updatePacket, ...updatePacketApi } = useUpdatePacket(projectId)
    // Template options API
    const { data: templateData, ...templateOptionsApi } = useTemplatesForTask(projectId, taskId, {
        enabled: isModalOpen,
    })
    // Docuware API
    const { data: docuwareResponse, ...docuwareApi } = useDocuwareSearch(
        'nml',
        {
            direct_no: projectId,
        },
        true,
        {
            enabled: isModalOpen,
        }
    )
    const [documents, setDocuments] = useState([])

    useEffect(() => {
        if (packet?.documents) {
            setDocuments(packet?.documents)
        }
    }, [packet])

    // Templates
    function addTemplate(templateId) {
        if (!documents?.includes(({ template_id }) => template_id == templateId)) {
            setDocuments([...documents, { template_id: templateId }])
            if (packetId) {
                updatePacket({
                    packetId,
                    data: {
                        documents: [
                            {
                                template_id: templateId,
                            },
                        ],
                    },
                })
            }
        }
    }

    function addTemplates(templateIds) {
        setDocuments([...documents, ...templateIds?.map((id) => ({ template_id: id }))])
        if (packetId) {
            updatePacket({
                packetId,
                data: {
                    documents: templateIds?.map((id) => ({ template_id: id })),
                },
            })
        }
    }

    function removeTemplate(templateId) {
        setDocuments(documents?.filter(({ template_id }) => template_id != templateId))
        if (packetId) {
            const document = documents.find(({ template_id }) => template_id == templateId)
            updatePacket({
                packetId,
                data: {
                    documents: [
                        {
                            id: document?.id,
                            _delete: true,
                        },
                    ],
                },
            })
        }
    }

    function clearTemplates() {
        setDocuments(documents.filter(({ template_id }) => !template_id))
        if (packetId) {
            updatePacket({
                packetId,
                data: {
                    documents: documents
                        .filter(({ template_id }) => !!template_id)
                        .map((document) => ({ ...document, _delete: true })),
                },
            })
        }
    }

    // Attachments
    function addAttachment(docuwareId) {
        if (!documents?.includes(({ docuware_id }) => docuware_id == docuwareId)) {
            setDocuments([...documents, { docuware_id: docuwareId }])
            if (packetId) {
                updatePacket({
                    packetId,
                    data: {
                        documents: [
                            {
                                docuware_id: docuwareId,
                            },
                        ],
                    },
                })
            }
        }
    }

    function addAttachments(docuwareIds) {
        setDocuments([...documents, ...docuwareIds?.map((id) => ({ docuware_id: id }))])

        if (packetId) {
            updatePacket({
                packetId,
                data: {
                    documents: docuwareIds?.map((id) => ({ docuware_id: id })),
                },
            })
        }
    }

    function removeAttachment(docuwareId) {
        setDocuments(documents?.filter(({ docuware_id }) => docuware_id != docuwareId))
        if (packetId) {
            const document = documents.find(({ docuware_id }) => docuware_id == docuwareId)
            updatePacket({
                packetId,
                data: {
                    documents: [
                        {
                            id: document?.id,
                            _delete: true,
                        },
                    ],
                },
            })
        }
    }

    function clearAttachments() {
        setDocuments(documents.filter(({ docuware_id }) => !docuware_id))
        if (packetId) {
            updatePacket({
                packetId,
                data: {
                    documents: documents
                        .filter(({ docuware_id }) => !!docuware_id)
                        .map((document) => ({ ...document, _delete: true })),
                },
            })
        }
    }

    function removeDocument(documentId) {
        setDocuments(documents?.filter(({ id }) => id != documentId))
        if (packetId) {
            updatePacket({
                packetId,
                data: {
                    documents: [
                        {
                            id: documentId,
                            _delete: true,
                        },
                    ],
                },
            })
        }
    }

    function clearDocuments() {
        setDocuments([])
        if (packetId) {
            updatePacket({
                packetId,
                data: { documents: documents.map((document) => ({ ...document, _delete: true })) },
            })
        }
    }

    return {
        documents: documents?.map((document) => {
            const template =
                templateData?.templates?.find(({ id }) => id == document.template_id) ?? {}
            const attachment =
                docuwareResponse?.documents?.find(({ id }) => id == document.docuware_id) ?? {}
            const label = document?.label ?? template?.label ?? attachment?.document_type

            return {
                ...template,
                ...attachment,
                ...document,
                label,
            }
        }),
        templateOptions: templateData?.templates?.map((template) => ({
            ...template,
            selected: !!documents?.find(({ template_id }) => template_id == template.id),
        })),
        attachmentOptions: docuwareResponse?.documents?.map((dwDoc) => ({
            ...dwDoc,
            selected: !!documents?.find(({ docuware_id }) => docuware_id == dwDoc.id),
        })),
        addTemplate,
        addTemplates,
        removeTemplate,
        clearTemplates,
        addAttachment,
        addAttachments,
        removeAttachment,
        clearAttachments,
        removeDocument,
        clearDocuments,
        isLoading: updatePacketApi.isLoading,
    }
}
