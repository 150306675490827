import React from 'react'
import Bugsnag from '@bugsnag/js'
import Cookies from 'js-cookie'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { EMPLOYEE } from './GlobalState'

Bugsnag.start({
    apiKey: process.env.BUGSNAG_ID,
    appVersion: process.env.BUGSNAG_APP_VERSION,
    releaseStage: process.env.ENVIRONMENT,
    appVersion: process.env.BUGSNAG_APP_VERSION,
    plugins: [new BugsnagPluginReact()],
    onError: function (event) {
        const session = Cookies.getJSON(EMPLOYEE)
        event.addMetadata('user', session)
    },
})

export default Bugsnag

export const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
