import { useEffect, useState } from 'react'

export const useConfirmExitPage = (enable) => {
    const [confirmExitPagePrompt] = useState(() => (e) => {
        e.returnValue = null // this needs to be set otherwise the popup won't appear. Not sure why.
    })
    useEffect(() => {
        if (enable) {
            window.addEventListener('beforeunload', confirmExitPagePrompt)
        } else {
            window.removeEventListener('beforeunload', confirmExitPagePrompt)
        }

        return () => {
            window.removeEventListener('beforeunload', confirmExitPagePrompt)
        }
    }, [enable, confirmExitPagePrompt])
}
