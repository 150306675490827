import React, { useState, Fragment } from 'react'
import Collapse from '../../../utils/Collapse'
import LoadingSpinner from '../../../utils/LoadingSpinner'

const defaultConvert = { service_level: 1, service_code: 'MON', note: '' }

export default function ConvertLTOProject({ save, dropdowns, direct_no }) {
    const [convertLTO, setConvertLTO] = useState({ ...defaultConvert })
    const [isLoading, setIsLoading] = useState(false)

    return (
        <div className={'card mb-4'}>
            <Collapse
                startOpen={false}
                title={<strong>Convert LTO Project</strong>}
                className={'card-header'}
            >
                <div className={'card-body'}>
                    <div className={'row'}>
                        <div className={'col'}>
                            <div className={'mb-3'}>
                                <label>Level</label>
                                <select
                                    className={'form-select'}
                                    value={convertLTO.service_level}
                                    onChange={({ target }) => {
                                        setConvertLTO({
                                            ...convertLTO,
                                            service_level: target.value,
                                        })
                                    }}
                                >
                                    {dropdowns?.['project.service_level']?.map((level) => (
                                        <option key={level.value} value={level.value}>
                                            {level.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className={'col'}>
                            <div className={'mb-3'}>
                                <label>Type</label>
                                <select
                                    className={'form-select'}
                                    value={convertLTO.service_code}
                                    onChange={({ target }) => {
                                        setConvertLTO({ ...convertLTO, service_code: target.value })
                                    }}
                                >
                                    {dropdowns?.types.map((type) => (
                                        <option key={type.id} value={type.serv_code}>
                                            {type.serv_code} - {type.description}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col'}>
                            <div className={'mb-3'}>
                                <label>Note</label>
                                <textarea
                                    className={'form-control'}
                                    rows={3}
                                    value={convertLTO.note}
                                    onChange={({ target }) =>
                                        setConvertLTO({ ...convertLTO, note: target.value })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <button
                                className={'btn btn-primary float-end'}
                                onClick={() => {
                                    setIsLoading(true)
                                    save(
                                        {
                                            direct_no: direct_no,
                                            ...convertLTO,
                                            return_project: true,
                                        },
                                        (data) => {
                                            setIsLoading(false)
                                            setConvertLTO({ ...defaultConvert })
                                        }
                                    )
                                }}
                                disabled={isLoading}
                            >
                                {isLoading ? (
                                    <Fragment>
                                        <LoadingSpinner /> Saving...
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <span className={'far fa-save'} /> Submit
                                    </Fragment>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    )
}
