import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'components/axios'
import { mergeConfigs } from 'utils'

export class UspsApi {
    static keys = {
        uspsAddressValidation: (address1, address2, city, state, zip) => [
            'uspsValidation',
            address1,
            address2,
            city,
            state,
            zip,
        ],
    }

    static validateAddress = ({ address1, address2, city, state, zip }) => {
        return axios
            .post(`/ols/csg/1/usps_test/json`, {
                addressone: address1,
                addresstwo: address2,
                city,
                state,
                zip5: zip,
                zip4: '',
            })
            .then((response) => {
                // If the only difference between the address and the response is the order of the address lines, swap them
                if (
                    response.data?.addressV?.addressone?.toUpperCase() == address2?.toUpperCase() &&
                    response.data?.addressV?.addresstwo?.toUpperCase() == address1?.toUpperCase()
                )
                    return {
                        ...response.data?.addressV,
                        addressone: response.data?.addressV?.addresstwo,
                        addresstwo: response.data?.addressV?.addressone,
                    }
                else return response.data?.addressV
            })
    }
    static lookupCityStateByZip = (zipCode) => {
        return axios
            .post(`/ols/csg/1/usps_citystatelookup/json`, {
                zip: zipCode,
            })
            .then((response) => response.data)
    }
}

export function useUSPSAddressValidation(address, config) {
    return useQuery(
        mergeConfigs(
            {
                queryKey: UspsApi.keys.uspsAddressValidation(address),
                queryFn: () => UspsApi.validateAddress(address),
                cacheTime: Infinity,
                staletime: Infinity,
            },
            config
        )
    )
}

export function useUSPSLookupCityStateByZip(config) {
    return useMutation(
        mergeConfigs(
            {
                mutationFn: UspsApi.lookupCityStateByZip,
            },
            config
        )
    )
}
