// Flat looking button

import React, { Component } from 'react'

export default class FlatButton extends Component {
    render() {
        const { id, classNames, style, onClick, children, disabled } = this.props

        return (
            <button
                className={'flat-button' + (classNames ? ' ' + classNames : '')}
                id={id ? id : null}
                style={style ? style : null}
                onClick={!disabled ? (onClick ? onClick : null) : null}
            >
                {children ? children : ''}
            </button>
        )
    }
}
