import { useEffect, useState } from 'react'
import useBoolean from './useBoolean'

export default function useModalState(defaultValue = false) {
    const {
        value: isOpen,
        setValue: setIsOpen,
        setTrue: show,
        setFalse: hide,
        toggle: toggle,
    } = useBoolean(!!defaultValue)

    // useful for resetting modal's internal state after modal close
    const [key, setKey] = useState(Math.random())

    useEffect(() => {
        if (!isOpen) {
            let timeout = setTimeout(() => setKey(Math.random()), 300)
            return () => clearTimeout(timeout)
        }
    }, [isOpen])

    return {
        isOpen,
        setIsOpen,
        show,
        hide,
        toggle,
        key,
    }
}
