import { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import { useUpdatePayable } from 'tracker/api'
import PayableForm from '../PayableForm'

export default function EditPayableModal({ payable, show, onHide }) {
    const { projectId } = useContext(ProjectContext)
    const { mutate: updatePayable, ...updatePayableApi } = useUpdatePayable(
        { projectId, payableId: payable?.id },
        {
            onSuccess: onHide,
        }
    )
    const [payableEdit, setPayableEdit] = useState(payable)

    const onChange = (update) => {
        console.log('update: ', update)

        setPayableEdit({
            ...payableEdit,
            ...update,
        })
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Payable</Modal.Title>
            </Modal.Header>
            <PayableForm
                projectId={projectId}
                payable={payableEdit}
                onChange={onChange}
                saveLabel='Save'
                onSave={() => updatePayable(payableEdit)}
                saveLoading={updatePayableApi.isLoading}
                onCancel={onHide}
            />
        </Modal>
    )
}
