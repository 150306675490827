import { Fragment, useContext, useState } from 'react'
import { Alert, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import { Badge, Button, Card, ListGroup } from 'front'
import { PortalContext } from '../../../GlobalState'
import LoadingSpinner from '../../../utils/LoadingSpinner'
import formatDate from '../../../utils/formatDate'
import isEmpty from '../../../utils/isEmpty'
import { Context } from '../Context/GlobalState'
import { StepNotes } from 'tracker/Utils/Notes/StepsNotes'
import ConvertLTOProject from './ConvertLTOProject'
import { useProject } from 'tracker/api'
import { useImportProject } from 'tracker/api/projectSearch.api'
import { Offcanvas } from '../../../front'
import { StatusColumn } from 'tracker/file/project/dates/DateRow'
import { NcsIcon } from '@/components/utils'
import { useRouter } from 'next/router'
import { Address, FilebarField, FilebarTitle } from 'tracker/file/filebar/Filebar'
import { Assigned } from './FileList'
import { sortBy } from 'lodash-es'
import PacketCard from 'tracker/file/project/tasks/PacketCard'
import ClientNotes from 'tracker/Utils/Notes/ClientNotes'
import { useQueryClient } from '@tanstack/react-query'
import Collapse from 'react-bootstrap/Collapse'
import { OlsChangesAlert } from 'tracker/file/project/overview/OlsChangesAlert'
import { useModalState } from 'hooks'

function AddressDisplay({ info }) {
    return (
        <Fragment>
            {info?.address1 ?? 'NO ADDRESS'} {info?.city ? info.city + ',' : 'NO CITY'}{' '}
            {info?.state ?? 'NO STATE'} {info?.zip ?? 'NO ZIP'}
        </Fragment>
    )
}

export default function FilePreview({ selectedFile, onHide, ...props }) {
    const previewTaskId = selectedFile?.task?.id ?? selectedFile?.task_id
    // console.log('project id', props?.project_id)
    const { employee } = useContext(PortalContext)
    const { dropdowns, convertLTOProject, docuwareContacts } = useContext(Context)
    const { data: fileInfo, isLoading: fileInfoLoading } = useProject({
        projectId: selectedFile?.project_id,
        takeover: false,
    })
    // console.log('fileInfo: ', fileInfo)
    // console.log('fileInfo: ', fileInfoLoading, fileInfo)
    const [showParties, setShowParties] = useState(false)
    const [showDeadlines, setShowDeadlines] = useState(false)

    const queryClient = useQueryClient()
    const router = useRouter()

    const { mutate: importProject, isLoading: importProjectLoading } = useImportProject(
        {
            project_id: fileInfo?.project_id,
        },
        {
            onSuccess: () => {
                router.push(`/nml/tracker/file/overview?project_id=${fileInfo?.project_id}`)
            },
        }
    )

    function partiesTable(parties) {
        return parties.map((party) => (
            <tr key={party.id}>
                <td>{party.type}</td>
                <td>{party.name}</td>
                <td>
                    <AddressDisplay info={party} />
                </td>
            </tr>
        ))
    }

    // const addToList = dropdowns?.employee?.map((emp) => ({
    //     emailAddress: emp.Email,
    //     title: emp.FirstName + ' ' + emp.LastName,
    // }))
    const task = !previewTaskId ? null : fileInfo?.tasks?.find((t) => t.id == previewTaskId)
    const sortedNotes = sortBy(
        fileInfo?.notes?.filter((note) => !note.tags.archived_note2client),
        [(note) => note.tags['note2ncs']]
    )

    const projectType = dropdowns?.['project.project_type']?.find(
        (type) => type.code == fileInfo?.project?.project_type
    )?.label
    const debtorType = dropdowns?.['associate.type']?.find(
        (type) => type.id == fileInfo?.associates?.[fileInfo?.project?._debtor_id]?.type_id
    )?.label

    const displayDeadlines = Object.values(fileInfo?.deadlines ?? {})
    // ? Object.values(fileInfo?.deadlines ?? {})
    // : Object.values(fileInfo?.deadlines ?? {})?.filter((date) => date.is_next_action)
    const [notesView, setNotesView] = useState('project')

    return (
        <>
            <Offcanvas.Header>
                <Button
                    variant={'white'}
                    icon={'fas fa-arrow-left'}
                    onClick={onHide}
                    circle
                    className='flex-shrink-0 me-3 position-absolute shadow'
                    style={{ marginLeft: '-52px', top: 20 }}
                />
                {!isEmpty(fileInfo?.project) ? (
                    <div className={''}>
                        <FilebarTitle
                            fileInfo={fileInfo}
                            loading={false}
                            clientEdit={false}
                            preview={true}
                        />
                        <div className='d-flex flex-row m-0'>
                            <FilebarField label='State' loading={!fileInfo}>
                                {fileInfo?.project?.state}
                            </FilebarField>
                            <FilebarField label='Type' loading={!fileInfo}>
                                {projectType}
                            </FilebarField>
                            <FilebarField label='Type' loading={!fileInfo}>
                                {debtorType}
                            </FilebarField>
                            <FilebarField label='Debtor' loading={!fileInfo}>
                                {fileInfo?.associates?.[fileInfo?.project?._debtor_id]?.name}
                            </FilebarField>
                            <FilebarField label='First' loading={!fileInfo}>
                                {formatDate(fileInfo?.project?.first_furnishing_date)}
                            </FilebarField>
                            <FilebarField label='Last' loading={!fileInfo}>
                                {formatDate(fileInfo?.project?.last_furnishing_date)}
                            </FilebarField>
                            <FilebarField label='Address' loading={!fileInfo}>
                                <Address {...fileInfo?.project} />
                            </FilebarField>
                        </div>
                    </div>
                ) : (
                    <div>
                        {!fileInfoLoading &&
                            (!fileInfo?.project?._workflow ? (
                                <span>NEW: {fileInfo?.project_id}</span>
                            ) : (
                                fileInfo?.project?.ncs_lno
                            ))}
                        {task?._task_code_label && ' - ' + task._task_code_label}
                    </div>
                )}
            </Offcanvas.Header>
            <Offcanvas.Body>
                {fileInfo?.lien_direct === 1 && (
                    <ConvertLTOProject
                        save={convertLTOProject}
                        dropdowns={dropdowns}
                        direct_no={fileInfo.direct_no}
                    />
                )}
                {fileInfoLoading && (
                    <h3>
                        <LoadingSpinner /> Loading File...
                    </h3>
                )}
                {fileInfo?.project?._workflow === null && (
                    <div className='bg-white sticky-top' style={{ top: '-2em' }}>
                        <Alert
                            variant='soft-primary'
                            className='d-flex justify-content-between align-items-center'
                        >
                            <div>
                                <i className='far fa-file-import me-3 fa-xl' />
                                This project is not imported yet, would you like to import into
                                Portal?
                            </div>
                            <Button
                                variant='primary'
                                loading={importProjectLoading}
                                disabled={importProjectLoading}
                                onClick={importProject}
                            >
                                Yes
                            </Button>
                        </Alert>
                    </div>
                )}
                {fileInfo?.incoming_changes?.version?.id && (
                    <OlsChangesAlert preview fileInfo={fileInfo} />
                )}
                <TaskCard
                    task={task}
                    fileInfo={fileInfo}
                    selectedFile={selectedFile}
                    filters={props?.filters}
                />

                {!fileInfoLoading && <PacketCard task={task} preview={true} fileInfo={fileInfo} />}
                {!fileInfoLoading && (
                    <Card className={'mb-4'}>
                        <Card.Header
                            className='d-flex justify-content-between align-items-center sticky-top bg-white'
                            style={{ top: '-2rem' }}
                        >
                            <Card.Title>
                                Notes{' '}
                                <span className={'badge badge-pill bg-secondary'}>
                                    {sortedNotes.length}
                                </span>{' '}
                            </Card.Title>
                            <ToggleButtonGroup
                                name='showClientNotes'
                                value={notesView}
                                type='radio'
                                className='btn-group-segment d-block'
                                size='xs'
                            >
                                <ToggleButton
                                    value={'project'}
                                    variant=''
                                    onClick={() => setNotesView('project')}
                                >
                                    Project
                                </ToggleButton>
                                <ToggleButton
                                    value={'client'}
                                    variant=''
                                    onClick={() => setNotesView('client')}
                                >
                                    Client
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Card.Header>
                        <Card.Body className='pt-0 overflow-auto' style={{ maxHeight: 350 }}>
                            {notesView == 'project' && (
                                <div className='pt-3'>
                                    {sortedNotes?.length === 0 ? (
                                        <h5 className='mb-0'>No Notes Added</h5>
                                    ) : (
                                        <StepNotes notes={sortedNotes} />
                                    )}
                                </div>
                            )}
                            {notesView == 'client' && (
                                <ClientNotes fileInfo={fileInfo} enableSearch={false} />
                            )}
                        </Card.Body>
                    </Card>
                )}

                {fileInfo?.history?.length > 0 && (
                    <Card>
                        <Card.Header className={'sticky-top bg-white'} style={{ top: '-2rem' }}>
                            <Card.Title>Requests</Card.Title>
                        </Card.Header>
                        <Card.Body className='p-0'>
                            <ListGroup variant='flush'>
                                {fileInfo?.history?.map((row, i) => (
                                    <RequestCollapse key={row?.id} row={row} />
                                ))}
                            </ListGroup>
                        </Card.Body>
                    </Card>
                )}
                {fileInfo?.associates?.length > 0 && (
                    <Card className={'mb-4'}>
                        <Card.Header className={'sticky-top bg-white'} style={{ top: '-2rem' }}>
                            <Card.Title>{showParties ? 'All' : 'Main'} Parties</Card.Title>
                        </Card.Header>
                        <table className={'table'}>
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Name</th>
                                    <th>Address</th>
                                </tr>
                            </thead>
                            <tbody>
                                {partiesTable(
                                    showParties
                                        ? fileInfo?.associates
                                        : fileInfo?.associates.filter(
                                              (assoc) =>
                                                  assoc.type_code === 'GC' ||
                                                  assoc.type_code === 'OW'
                                          )
                                )}
                            </tbody>
                        </table>
                        {fileInfo?.associates?.length > 2 && (
                            <CardFooter
                                show={showParties}
                                onClick={() => {
                                    setShowParties(!showParties)
                                }}
                            />
                        )}
                    </Card>
                )}
                {fileInfo?.deadlines && (
                    <Card className={'mb-4'}>
                        <Card.Header className={'sticky-top bg-white'} style={{ top: '-2rem' }}>
                            <Card.Title>
                                {showDeadlines ? 'All deadlines' : 'Next Actions'}
                            </Card.Title>
                        </Card.Header>
                        <table className={'table'}>
                            <thead>
                                <tr>
                                    <th>Service</th>
                                    <th>Due</th>
                                    {showDeadlines && <th>Completed</th>}
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <NextActions
                                    showDeadlines={showDeadlines}
                                    deadlines={displayDeadlines}
                                    types={fileInfo?.deadline_types}
                                />
                            </tbody>
                        </table>

                        {Object.values(fileInfo?.deadlines).length > 2 && (
                            <CardFooter
                                show={showDeadlines}
                                onClick={() => {
                                    setShowDeadlines(!showDeadlines)
                                }}
                            />
                        )}
                    </Card>
                )}
            </Offcanvas.Body>
            {fileInfo?.project?._workflow === 'PORTAL' && (
                <Offcanvas.Footer className={'d-flex justify-content-end'}>
                    {/* <Button variant='ghost'>Close</Button> */}
                    <Button
                        variant='primary'
                        onClick={() => {
                            router.push(`/nml/tracker/file?project_id=${fileInfo?.project_id}`)
                        }}
                    >
                        Go to project
                        <i className='fal fa-arrow-right ms-2' />
                    </Button>
                </Offcanvas.Footer>
            )}
        </>
    )
}

function NextActions({ showDeadlines, deadlines, types }) {
    if (deadlines?.length === 0) {
        return (
            <tr>
                <td colSpan={showDeadlines ? 4 : 3}>
                    {showDeadlines ? 'No Deadlines' : 'Please Select Next Actions'}
                </td>
            </tr>
        )
    }
    return deadlines.map((date, i) => {
        return (
            <tr key={i}>
                <td>{types?.[date?.deadline_type_id]?.label}</td>
                <td>{formatDate(date?.due_date)}</td>
                {showDeadlines && <td>{formatDate(date.completed)}</td>}
                <td>
                    <StatusColumn date={date} />
                </td>
            </tr>
        )
    })
}

function CardFooter({ show, onClick }) {
    return (
        <div className={'card-footer text-center pointer'} onClick={onClick}>
            {show ? (
                <Fragment>
                    Show Next Actions <span className={'fas fa-caret-up'} />
                </Fragment>
            ) : (
                <Fragment>
                    Show All Deadlines <span className={'fas fa-caret-down'} />
                </Fragment>
            )}
        </div>
    )
}

function TaskCard({ task, fileInfo, selectedFile, filters }) {
    if (!task || !fileInfo) return null

    return (
        <Card>
            <Card.Header className={'sticky-top bg-white'} style={{ top: '-2rem' }}>
                <Card.Title>
                    {task?._task_code_label} - {task?.current_stage}
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <div className='d-flex flex-wrap gap-5'>
                    <div className='pe-3'>
                        <label className='mb-2 fs-sm text-bold fw-semibold'>Service Code</label>
                        <div>
                            <div className='d-flex justify-content-start'>
                                <div>
                                    <Badge
                                        className='me-2'
                                        bg='dark'
                                        soft
                                        style={{ minWidth: '47px' }}
                                    >
                                        {task?.task_code}
                                    </Badge>
                                </div>
                                <small className='d-block' style={{ paddingTop: '0.2em' }}>
                                    {task?._task_code_label}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className='pe-3'>
                        <label className='mb-2 fs-sm fw-semibold'>Service Level</label>
                        <div>
                            <div className='d-flex justify-content-start'>
                                <div>
                                    <NcsIcon
                                        dept='nml'
                                        size='sm'
                                        variant='far'
                                        category='serviceLevel'
                                        value={task?.service_level}
                                        className='me-3'
                                    />
                                </div>
                                <small
                                    className='d-block text-capitalize'
                                    style={{ paddingTop: '0.2em' }}
                                >
                                    {task?.service_level?.toLowerCase()}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className='pe-3'>
                        <label className='mb-1 fs-sm fw-semibold'>Employee</label>
                        <Assigned file={selectedFile} filters={filters} />
                    </div>
                    <div className='pe-3'>
                        <label className='mb-1 fs-sm fw-semibold'>Stage</label>
                        <div className='text-capitalize'>{task?.current_stage?.toLowerCase()}</div>
                    </div>
                    {task?._deadline_ids?.length > 0 && (
                        <div className='pe-3 '>
                            <label className='mb-1 fs-sm fw-semibold'>
                                Date{task?._deadline_ids?.length > 1 && 's'} Linked
                            </label>
                            <div className='text-capitalize'>
                                {task?._deadline_ids?.map((id, i) => {
                                    const deadline = fileInfo?.deadlines?.[id]
                                    const type =
                                        fileInfo?.deadline_types?.[deadline?.deadline_type_id]

                                    return (
                                        <div
                                            key={id}
                                            className={
                                                'pe-3 d-flex justify-content-between ' +
                                                (i > 0 ? 'border-top pt-2 mt-2' : '')
                                            }
                                        >
                                            <div>{type?.label}</div>
                                            <div className='ps-5'>
                                                {deadline?.due_date
                                                    ? formatDate(deadline?.due_date)
                                                    : 'No Date'}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </Card.Body>
        </Card>
    )
}

function RequestCollapse({ row }) {
    const requestToggle = useModalState(false)

    return (
        <ListGroup.Item className='pt-0'>
            <div
                onClick={requestToggle.toggle}
                className={
                    'pointer d-flex justify-content-between pt-3 ' +
                    (requestToggle.isOpen ? 'pb-3 sticky-top bg-white' : '')
                }
                style={requestToggle.isOpen ? { top: 17 } : null}
            >
                <div>
                    {formatDate(row.created)} - {row.subject}
                </div>
                <div>
                    <span className={'far fa-chevron-' + (requestToggle.isOpen ? 'up' : 'down')} />
                </div>
            </div>
            <Collapse in={requestToggle.isOpen}>
                <div className='pt-3 border-top'>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: row.request
                                .replaceAll('\r', '<br />')
                                .replaceAll('\n', '<br />'),
                        }}
                    />
                </div>
            </Collapse>
        </ListGroup.Item>
    )
}
