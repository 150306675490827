import { init as initApm } from '@elastic/apm-rum'
export { ApmRoute, withTransaction } from '@elastic/apm-rum-react'

let env = process.env.ENVIRONMENT

let patterns = []
let registered = false

// const socket_sub = ( ( env === "TESTING" ) ? "qa-socket" : (env === "DEVELOPMENT") ? "dev-socket" : ( env === "LOCAL") ? "socket.loc" : "socket" );

const apm = initApm({
    // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
    serviceName: 'Portal-JS',
    // Set the version of your application
    distributedTracingOrigins: [
        'https://api.ncscredit.com',
        'https://api.loc.ncscredit.com',
        // , 'https://' + socket_sub + '.ncscredit.com'
    ],
    // Used on the APM Server to find the right sourcemap
    serviceVersion: '0.90',
    // Set custom APM Server URL (default: http://localhost:8200)
    serverUrl: 'https://api.ncscredit.com/apm/',
    // distributedTracingOrigins: ['http://localhost:8080'],
    environment: env,
    breakdownMetrics: true,
})
// apm.setUserContext({
// 	id: window.emp_id,
// 	username: window.username
// })
export const registerPatterns = (new_patterns) => {
    if (Array.isArray(new_patterns)) {
        patterns = patterns.concat(new_patterns)
    } else if (typeof new_patterns === 'object' && new_patterns !== null) {
        patterns.push(new_patterns)
    }
    if (registered === false) {
        let t = apm.observe('transaction:end', (transaction) => {
            let name = transaction.name
            // console.log("transaction:end", name, transaction.type);
            // if ( (transaction.type == "page-load" || transaction.type == "route-change") && name.substr(-1,1) === "*") {
            // 	name = window.location.pathname;
            // }
            if (typeof name == 'string') {
                for (let i = 0; i < patterns.length; i++) {
                    let pattern, placeholder
                    if (Array.isArray(patterns[i])) {
                        pattern = patterns[i][0]
                        placeholder = patterns[i][1]
                    } else {
                        pattern = patterns[i].pattern
                        placeholder = patterns[i].placeholder
                    }
                    // console.log("patterns", i, patterns[i], pattern, placeholder);

                    let list
                    if ((list = name.match(pattern))) {
                        name = (list[1] ?? '') + '{' + placeholder + '}' + (list[2] ?? '')
                    }
                }
                if (name != transaction.name) {
                    transaction.name = name
                    // console.log("New Transaction name", name);
                }
            }
        })
        registered = true
    }
}
// In case you want to set the initial page transaction more then 2 folders /nml/tracker/*
// apm.setInitialPageLoadName()

export default apm
