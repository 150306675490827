import {
    FETCH_SEARCH_BOX,
    SET_SAVED_SEARCHES,
    CHANGE_SELECTED_RANGE,
    RESET_SEARCH,
    SAVE_SEARCH_CHANGE,
    UPDATE_PARAMETERS,
    CHANGE_SHRINK,
    initial_default_search,
} from './action'

// import { updateLocalStorage,getFromLocalStorage } from "../localStorage";

// let height = window?.innerHeight;
// let shrink_searchbox = 0;
// if(height < 970){
// 	shrink_searchbox = 1;
// }

// const localSearch = getFromLocalStorage('search',initial_default_search)
// let search = localSearch ? {...localSearch}:{...initial_default_search}

// updateLocalStorage('search',search)

const initial_default_state = {
    search: { ...initial_default_search },
    shrink_searchbox: 0,
    search_box: {
        ucc_emps: [],
    },
    saved_searches: [],
}

export default function SearchBoxReducer(state = { ...initial_default_state }, action) {
    // console.log('search box',state,action)
    switch (action.type) {
        case FETCH_SEARCH_BOX:
            window?.localStorage.setItem(
                'search_box',
                JSON.stringify({ ...action.payload, created: new Date() })
            )
            return { ...state, search_box: action.payload }
        case SET_SAVED_SEARCHES:
            return { ...state, saved_searches: action.saved_searches }
        case UPDATE_PARAMETERS:
            window?.localStorage.setItem('search', JSON.stringify(action.parameters))
            return { ...state, search: action.parameters }
        case CHANGE_SELECTED_RANGE:
            let date_range = state.search
            date_range['selected_range'] = action.value
            if (action.value == 0) {
                date_range['range'] = 0
            }
            return { ...state, search: date_range }
        case RESET_SEARCH:
            window?.localStorage.setItem('search', JSON.stringify({ ...initial_default_search }))
            return {
                ...state,
                search: { ...initial_default_search },
            }
        case SAVE_SEARCH_CHANGE:
            return { ...state, saved_searches: action.payload.data }
        case CHANGE_SHRINK:
            let resetting_search = state.search
            resetting_search.service_code = -1
            resetting_search.state = ''
            return { ...state, shrink_searchbox: action.new_shrink, search: resetting_search }
        default:
            return state
    }
}
