import { EDIT_STAGE_MODAL, EDIT_STAGE_INFO, EDIT_STAGE_CHANGE, UPDATE_FOLLOW_UP } from './action'

export default function EditStageModalReducer(state = {}, action) {
    switch (action.type) {
        case EDIT_STAGE_MODAL:
            return { ...state, show: action.open }
        case EDIT_STAGE_INFO:
            return { ...state, stage_info: action.stage_info.data }
        case EDIT_STAGE_CHANGE:
            let new_stage_info = state.stage_info
            new_stage_info[action.item_to_change] = action.value
            return { ...state, stage_info: new_stage_info }
        case UPDATE_FOLLOW_UP:
            state.stage_info.follow_up = action.follow_up
            return { ...state }
        default:
            return state
    }
}
