// Bootstrap styled file picker button

import React, { Component } from 'react'

export default class Toggle extends Component {
    render() {
        const {
            id,
            className,
            style,
            onClick,
            value,
            children,
            eye,
            star,
            onColor,
            offColor,
            size,
        } = this.props

        let bsIcon = 'fas fa-toggle-' + (value ? 'on' : 'off')

        if (eye) {
            bsIcon = 'fas fa-eye' + (value ? '' : '-slash')
        } else if (star) {
            bsIcon = 'fa' + (value ? 's' : 'r') + ' fa-star'
        }

        // let color = (value ?
        // )

        // let style = {...style, }

        return (
            <span
                className={'utils-toggle pointer ' + (className ? ' ' + className : '')}
                id={id ? id : null}
                style={style ? style : null}
                onClick={onClick ? onClick : null}
            >
                <span
                    className={
                        bsIcon + ' fa-' + (size ? size : 'md') + (value ? ' text-success ' : '')
                    }
                />
                <span>{children ? ' ' + children : ''}</span>
            </span>
        )
    }
}
