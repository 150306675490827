import classnames from 'classnames'
import React from 'react'
import { Offcanvas as BootstrapOffcanvas } from 'react-bootstrap'

export default function Offcanvas({ children, ...bsProps }) {
    return <BootstrapOffcanvas {...bsProps}>{children}</BootstrapOffcanvas>
}

function OffcanvasFooter({ children, className, ...rest }) {
    return (
        <div className={classnames('offcanvas-footer', className)} {...rest}>
            {children}
        </div>
    )
}

Offcanvas.Header = BootstrapOffcanvas.Header
Offcanvas.Title = BootstrapOffcanvas.Title
Offcanvas.Body = BootstrapOffcanvas.Body
Offcanvas.Footer = OffcanvasFooter
