import React, { Component } from 'react'
import LoadingSpinner from './LoadingSpinner'
// import Image from 'next/image'

export default class ImgLoadSpinner extends Component {
    static defaultProps = {
        className: '',
        id: null,
        style: {},
    }

    constructor(props) {
        super(props)

        this.state = {
            imageLoaded: false,
        }
    }

    render() {
        const { id, className, style, src } = this.props
        const { imageLoaded } = this.state

        return (
            <React.Fragment>
                <img
                    src={src}
                    onLoad={() => {
                        this.setState({ imageLoaded: true })
                    }}
                    alt={'img w/ spinner'}
                />
                {!imageLoaded && <LoadingSpinner />}
            </React.Fragment>
        )
    }
}
