import React, { Component } from 'react'
import axios from '../../axios'
const CancelToken = axios.CancelToken
let cancel
import { ClientMaintenanceContext } from '../ClientMaintenanceContext'
import { Button, Form } from 'front'
import { LoadingSpinner } from '../../utils'

export default class Search extends Component {
    static contextType = ClientMaintenanceContext
    constructor(props) {
        super(props)
        this.searchChange = this.searchChange.bind(this)
        this.search = this.search.bind(this)
        this.clearSearch = this.clearSearch.bind(this)
        this.selectClient = this.selectClient.bind(this)
        this.selectContact = this.selectContact.bind(this)
        this.replaceHistory = this.replaceHistory.bind(this)
        this.updateHeight = this.updateHeight.bind(this)
        this.quickSearchChange = this.quickSearchChange.bind(this)
        this.quickSearch = this.quickSearch.bind(this)
        this.openResult = this.openResult.bind(this)

        this.state = { searchTxt: '' }
    }
    componentDidMount() {
        this.UNSAFE_componentWillReceiveProps(this.props)
        window.addEventListener('resize', this.updateHeight)
        setTimeout(
            function () {
                this.updateHeight()
            }.bind(this),
            100
        )
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateHeight)
    }
    updateHeight(e) {
        if (this.searchList === undefined || $(this.searchList).offset() === undefined) {
            return
        }
        let h = $(window).height() - $(this.searchList).offset().top - 150
        if (h >= 300) {
            this.setState({ searchHeight: h + 'px' })
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.quickSearch && nextProps.searchTxt) {
            this.setState({ searchTxt: nextProps.searchTxt })
        }
        if (
            nextProps.location !== undefined &&
            nextProps.location.state !== undefined &&
            nextProps.location.state.searchTxt !== undefined &&
            nextProps.location.state.searchTxt !== this.state.searchTxt
        ) {
            this.setState({ searchTxt: nextProps.location.state.searchTxt })
        }
        if (
            nextProps.match !== undefined &&
            nextProps.match.params !== undefined &&
            nextProps.match.params.search !== undefined
        ) {
            if (
                !this.state.searchTxt ||
                (nextProps.match.params.search &&
                    nextProps.match.params.search !== this.props.match.params.search &&
                    this.props.match.params.search !== this.state.searchTxt)
            ) {
                console.log('setting searchTxt', decodeURIComponent(nextProps.match.params.search))
                this.setState({
                    searchTxt: decodeURIComponent(nextProps.match.params.search),
                })
                this.search(decodeURIComponent(nextProps.match.params.search))
            }
        }
    }
    replaceHistory(value) {
        if (value) {
            this.props.history.replace('/client_maintenance/term/' + encodeURIComponent(value))
        } else {
            this.props.history.replace('/client_maintenance')
        }
    }
    clearSearch(is_quick) {
        console.log('clearSearch called')
        this.setState({
            searchTxt: '',
            searchAgain: undefined,
            searching: undefined,
            error: undefined,
            results: undefined,
            truncated: undefined,
            count: undefined,
        })
        if (!is_quick) {
            this.props.history.push('/client_maintenance')
        }
    }
    quickSearch = () => {
        if (this.state.searchTxt) {
            this.props.history.push(
                '/client_maintenance/term/' + encodeURIComponent(this.state.searchTxt)
            )
        } else {
            this.props.history.push('/client_maintenance')
        }
    }
    quickSearchChange = (e) => {
        let value = e.target.value
        this.setState({ searchTxt: value })
    }
    searchChange(e) {
        let value = e.target.value
        // console.log("searchChange", e, value);
        this.replaceHistory(value)
        this.search(value)
    }
    search(value) {
        // console.log('searching',value)
        if (cancel) {
            // console.log('cancelling')
            cancel()
            // this.setState({searching:undefined, searchAgain:undefined})
        }
        if (value.length >= 3) {
            // if (this.state.searching === undefined) {
            this.setState({ searching: value, mode: 'search' })
            let handleData = function (response) {
                // if (this.state['searchAgain'] !== undefined && this.state['searchAgain'] !== this.state['searching']) {
                // 	let v = this.state['searchAgain'];
                //
                //
                // 	this.setState({
                // 		"searching": undefined,
                // 		"searchAgain": undefined,
                // 		"results": response.data.results,
                // 		"truncated" : response.data.truncated,
                // 		"count" : response.data.count,
                // 		"error": undefined
                // 	});
                // 	if (v !== undefined && v.length > 0) {
                // 		this.search(v);
                // 	}
                // }
                // else {
                // console.log('handling data',response)
                this.setState({
                    searching: undefined,
                    results: response.data.results,
                    truncated: response.data.truncated,
                    count: response.data.count,
                    error: undefined,
                })
                // }
            }.bind(this)
            if (value !== undefined && value.length > 0) {
                let searchVal = value
                // console.log('searchVal', searchVal);
                axios
                    .get('/cli/maintenance/1/client/all/search/json', {
                        params: { q: searchVal },
                        cancelToken: new CancelToken((c) => {
                            cancel = c
                        }),
                    })
                    .then(handleData)
                    .catch(
                        function (error) {
                            if (axios.isCancel(error)) {
                                // console.log('actually cancelling', searchVal, error);
                                //console.log('Request canceled', thrown);
                            } else {
                                // console.log('error searching', searchVal, error);
                                this.setState({
                                    searching: undefined,
                                    // "searchAgain": undefined,
                                    results: undefined,
                                    truncated: undefined,
                                    count: undefined,
                                    error: 'Search resulted in an error',
                                })
                            }
                        }.bind(this)
                    )
            }

            // } else if (this.state["searching"] !== value) {
            // 	console.log('else if',value)
            // 	// this.setState({"searchAgain": value});
            // }
            this.setState({ searchTxt: value })
        } else {
            // console.log('else',value)
            this.setState({ searchTxt: value, results: undefined })
        }
    }
    selectClient(div_id) {
        let searchUrl = '/client_maintenance/'
        if (this.state.searchTxt) {
            searchUrl += 'term/' + this.state.searchTxt
        }
        this.props.history.push('/client_maintenance/dashboard/' + div_id, {
            searchUrl: searchUrl,
            searchTxt: this.state.searchTxt,
        })
    }
    selectContact(cnt_id) {
        let searchUrl = '/client_maintenance/'
        if (this.state.searchTxt) {
            searchUrl += 'term/' + this.state.searchTxt
        }
        this.props.history.push('/client_maintenance/dashboard/contact/' + cnt_id, {
            searchUrl: searchUrl,
            searchTxt: this.state.searchTxt,
        })
    }
    openResult(div_id) {
        if (this.state.openResult === div_id && div_id !== undefined) {
            div_id = ''
        }
        this.setState({ openResult: div_id })
    }
    showHighlight(row, fieldName) {
        if (
            row['highlight'] !== undefined &&
            row['highlight'][fieldName] !== undefined &&
            row['highlight'][fieldName][0] !== undefined &&
            row['highlight'][fieldName][0]
        ) {
            return (
                <span dangerouslySetInnerHTML={{ __html: row['highlight'][fieldName][0] }}></span>
            )
        } else {
            return row[fieldName]
        }
    }
    render() {
        if (this.props.quickSearch !== undefined) {
            return (
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        this.quickSearch()
                    }}
                >
                    <div className={'col-12'}>
                        <div className='mb-3'>
                            <Form.InputGroup>
                                <Form.Control
                                    placeholder='Search'
                                    id='searchInput'
                                    autoComplete='off'
                                    onChange={(e) => {
                                        this.quickSearchChange(e)
                                    }}
                                    value={this.state.searchTxt}
                                />
                                {this.state.searchTxt && (
                                    <Button outline onClick={this.clearSearch.bind(this, 1)}>
                                        <span className='far fa-times' />
                                    </Button>
                                )}
                            </Form.InputGroup>
                        </div>
                    </div>
                </form>
            )
        }
        if (this.state.mode && this.state.mode != 'search') {
            return null
        }
        let br = <br />
        let results = ''
        if (this.state.results) {
            results = (
                <div className='row'>
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-4'>Name</div>
                            <div className='col-4'>Address</div>
                            <div className='col-2'>Contacts</div>
                            <div className='col-2'></div>
                        </div>
                        <div
                            className='search-list'
                            ref={(el) => {
                                this.searchList = el
                            }}
                            style={{ height: this.state.searchHeight || '500px' }}
                        >
                            {this.state.results.map(
                                function (result, i) {
                                    let rowclass = 'row'
                                    if (i % 2 == 0) {
                                        rowclass += ' even'
                                    }
                                    if (i >= 50) {
                                        return null
                                    }
                                    let list
                                    let id
                                    if (
                                        result.type === 'division' ||
                                        result.type === 'client_division'
                                    ) {
                                        list = result.contacts
                                        id = result.div_id
                                        let height = 1
                                        if (result.company.trim().length >= 23) {
                                            height++
                                        }
                                        if (result.company2.trim().length !== 0) {
                                            height++
                                        }
                                        if (result.cust1.trim().length !== 0) {
                                            height++
                                        }
                                        return (
                                            <div key={id} className={rowclass}>
                                                <div
                                                    className='col-4 clickable'
                                                    onClick={this.selectClient.bind(
                                                        this,
                                                        result.div_id
                                                    )}
                                                >
                                                    <a className='search-name search-name-division'>
                                                        <div className='row'>
                                                            <div className='col-12 col-sm-3 col-md-2'>
                                                                <span
                                                                    className={
                                                                        'search-company-icon' +
                                                                        (height > 1
                                                                            ? '-tall'
                                                                            : '') +
                                                                        (height > 2 ? 'er' : '')
                                                                    }
                                                                >
                                                                    {result.active !== '1' ? (
                                                                        <span className='fas fa-pause-circle fa-3x'></span>
                                                                    ) : (
                                                                        <span className='far fa-users fa-3x'></span>
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <div className='col-12 col-sm-9 col-md-10 search-name-text'>
                                                                <span style={{ fontSize: '1.1em' }}>
                                                                    {this.showHighlight(
                                                                        result,
                                                                        'company'
                                                                    )}
                                                                    {' - '}
                                                                    {this.showHighlight(
                                                                        result,
                                                                        'cli_no'
                                                                    )}
                                                                </span>
                                                                {result.company2.trim().length !==
                                                                0 ? (
                                                                    <br />
                                                                ) : null}
                                                                {this.showHighlight(
                                                                    result,
                                                                    'company2'
                                                                )}
                                                                {result.cust1 && (
                                                                    <div>
                                                                        {result.cust1_lbl}{' '}
                                                                        {this.showHighlight(
                                                                            result,
                                                                            'cust1'
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='search-addr'>
                                                        {this.showHighlight(result, 'mainadd1')}
                                                        <br />
                                                        {this.showHighlight(result, 'mainadd2')}
                                                        {result.mainadd2.trim() !== '' ? br : ''}
                                                        {this.showHighlight(result, 'maincity')}
                                                        {', '}
                                                        {this.showHighlight(
                                                            result,
                                                            'mainstate'
                                                        )}{' '}
                                                        {this.showHighlight(result, 'mainzip')}
                                                    </div>
                                                </div>
                                                <div className='col-4 col-sm-2'>
                                                    <div className='search-buttons'>
                                                        <Button
                                                            onClick={(e) => {
                                                                this.openResult('d' + result.div_id)
                                                            }}
                                                        >
                                                            {result.contacts.length}
                                                            &nbsp;Contact
                                                            {result.contacts.length > 1 ? 's' : ''}
                                                            &nbsp;&nbsp;
                                                            <span
                                                                className={
                                                                    'fal fa-caret-' +
                                                                    (this.state.openResult ===
                                                                    'd' + result.div_id
                                                                        ? 'down'
                                                                        : 'right')
                                                                }
                                                            ></span>
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className='col-4 col-sm-2'>
                                                    <div className='search-buttons'>
                                                        <Button
                                                            onClick={this.selectClient.bind(
                                                                this,
                                                                result.div_id
                                                            )}
                                                        >
                                                            Select Client &raquo;
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div
                                                    className='col-12'
                                                    style={{
                                                        display:
                                                            this.state.openResult ===
                                                            'd' + result.div_id
                                                                ? 'block'
                                                                : 'none',
                                                    }}
                                                >
                                                    <h4>
                                                        Contacts
                                                        <span
                                                            className={
                                                                'far fa-times float-end pointer'
                                                            }
                                                            aria-hidden='true'
                                                            onClick={(e) => {
                                                                this.openResult()
                                                            }}
                                                        />
                                                    </h4>
                                                    <div className='row'>
                                                        {list
                                                            .sort(function (a, b) {
                                                                let akey = a.contfname + a.contlname
                                                                if (akey) akey = akey.toLowerCase()
                                                                let bkey = b.contfname + b.contlname
                                                                if (bkey) bkey = bkey.toLowerCase()
                                                                if (akey < bkey) return -1
                                                                if (akey > bkey) return 1
                                                                return 0
                                                            })
                                                            .map(
                                                                function (contact) {
                                                                    return (
                                                                        <div
                                                                            key={contact.cnt_id}
                                                                            className={
                                                                                'col-12 col-sm-6 col-md-4 ' +
                                                                                (list.length % 3 ===
                                                                                0
                                                                                    ? '4'
                                                                                    : '3')
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    'card mb-3 pointer'
                                                                                }
                                                                                onClick={this.selectContact.bind(
                                                                                    this,
                                                                                    contact.cnt_id
                                                                                )}
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        'transparent',
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    className={
                                                                                        'card-body'
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className={
                                                                                            'row'
                                                                                        }
                                                                                    >
                                                                                        <div
                                                                                            className={
                                                                                                'col'
                                                                                            }
                                                                                        >
                                                                                            <span
                                                                                                className={
                                                                                                    'far fa-user fa-lg'
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                        <div
                                                                                            className={
                                                                                                'col-10'
                                                                                            }
                                                                                        >
                                                                                            <div
                                                                                                className={
                                                                                                    'row'
                                                                                                }
                                                                                            >
                                                                                                <div
                                                                                                    className={
                                                                                                        'col-12 pb-1'
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        contact.contfname
                                                                                                    }{' '}
                                                                                                    {
                                                                                                        contact.contlname
                                                                                                    }
                                                                                                </div>
                                                                                                {contact.cntemail !==
                                                                                                    undefined &&
                                                                                                    contact.cntemail.trim() !==
                                                                                                        '' && (
                                                                                                        <div
                                                                                                            className={
                                                                                                                'col-12 pb-1'
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                contact.cntemail
                                                                                                            }
                                                                                                        </div>
                                                                                                    )}
                                                                                                <div
                                                                                                    className={
                                                                                                        'col-12'
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        contact.contcity
                                                                                                    }
                                                                                                    {
                                                                                                        ', '
                                                                                                    }
                                                                                                    {
                                                                                                        contact.contstate
                                                                                                    }{' '}
                                                                                                    {
                                                                                                        contact.contzip
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }.bind(this)
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else if (
                                        result.type === 'contact' ||
                                        result.type === 'client_contact'
                                    ) {
                                        list = result.divisions
                                        id = result.cnt_id
                                        return (
                                            <div key={id} className={rowclass}>
                                                <div
                                                    className='col-4 clickable'
                                                    onClick={this.selectContact.bind(
                                                        this,
                                                        result.cnt_id
                                                    )}
                                                >
                                                    <a className='search-name search-name-contact'>
                                                        <div className='row'>
                                                            <div className='col-12 col-sm-3 col-md-2'>
                                                                <span
                                                                    className={
                                                                        'search-name-icon' +
                                                                        (result.cntemail
                                                                            ? '-taller'
                                                                            : '')
                                                                    }
                                                                >
                                                                    <span className='far fa-user fa-3x'></span>
                                                                </span>
                                                            </div>
                                                            <div className='col-12 col-sm-9 col-md-10 search-name-text'>
                                                                <span style={{ fontSize: '1.1em' }}>
                                                                    {this.showHighlight(
                                                                        result,
                                                                        'contfname'
                                                                    )}{' '}
                                                                    {this.showHighlight(
                                                                        result,
                                                                        'contlname'
                                                                    )}
                                                                </span>
                                                                <br />
                                                                {this.showHighlight(
                                                                    result,
                                                                    'username'
                                                                )}
                                                                <br />
                                                                {this.showHighlight(
                                                                    result,
                                                                    'cntemail'
                                                                )}
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className='col-4'>
                                                    <div className='search-addr'>
                                                        {this.showHighlight(result, 'contadd1')}
                                                        <br />
                                                        {this.showHighlight(result, 'contadd2')}
                                                        {result.contadd2 !== undefined &&
                                                        result.contadd2.trim() !== ''
                                                            ? br
                                                            : ''}
                                                        {this.showHighlight(result, 'contcity')}
                                                        {', '}
                                                        {this.showHighlight(
                                                            result,
                                                            'contstate'
                                                        )}{' '}
                                                        {this.showHighlight(result, 'contzip')}
                                                    </div>
                                                </div>
                                                <div className='col-4 col-sm-2'>
                                                    <div className='search-buttons'>
                                                        <Button
                                                            onClick={this.openResult.bind(
                                                                this,
                                                                'c' + result.cnt_id
                                                            )}
                                                        >
                                                            {result.divisions.length}
                                                            &nbsp;Client
                                                            {result.divisions.length > 1 ? 's' : ''}
                                                            &nbsp;&nbsp;
                                                            <span
                                                                className={
                                                                    'fal fa-caret' +
                                                                    (this.state.openResult ===
                                                                    'c' + result.cnt_id
                                                                        ? 'bottom'
                                                                        : 'right')
                                                                }
                                                            ></span>
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className='col-4 col-sm-2'>
                                                    <div className='search-buttons'>
                                                        <Button
                                                            onClick={this.selectContact.bind(
                                                                this,
                                                                result.cnt_id
                                                            )}
                                                        >
                                                            Select Contact &raquo;
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div
                                                    className='col-12'
                                                    style={{
                                                        display:
                                                            this.state.openResult ===
                                                            'c' + result.cnt_id
                                                                ? 'block'
                                                                : 'none',
                                                    }}
                                                >
                                                    <h4>
                                                        Clients &nbsp; - &nbsp;
                                                        <a onClick={this.openResult}>Close</a>
                                                        <Button
                                                            ghost
                                                            onClick={this.openResult}
                                                            aria-label=''
                                                        >
                                                            <span aria-hidden='true'>&times;</span>
                                                        </Button>
                                                    </h4>
                                                    <div className={'row list '}>
                                                        {list
                                                            .sort(function (a, b) {
                                                                let akey = a.company
                                                                if (akey) akey = akey.toLowerCase()
                                                                let bkey = b.company
                                                                if (bkey) bkey = bkey.toLowerCase()
                                                                if (akey < bkey) return -1
                                                                if (akey > bkey) return 1
                                                                return 0
                                                            })
                                                            .map(
                                                                function (division) {
                                                                    return (
                                                                        <div
                                                                            key={division.div_id}
                                                                            className={
                                                                                'list-item col-12 col-sm-6 col-md-' +
                                                                                (list.length % 3 ===
                                                                                0
                                                                                    ? '4'
                                                                                    : '3')
                                                                            }
                                                                            onClick={this.selectClient.bind(
                                                                                this,
                                                                                division.div_id
                                                                            )}
                                                                        >
                                                                            <a>
                                                                                {division.company}
                                                                            </a>
                                                                            {division.cust1 ? (
                                                                                <div>
                                                                                    {
                                                                                        division.cust1_lbl
                                                                                    }{' '}
                                                                                    {division.cust1}
                                                                                </div>
                                                                            ) : (
                                                                                <br />
                                                                            )}
                                                                            # {division.cli_no}
                                                                            <br />
                                                                            {division.maincity}
                                                                            {division.maincity !==
                                                                                undefined &&
                                                                                division.maincity.trim() !==
                                                                                    '' &&
                                                                                ', '}
                                                                            {division.mainstate}
                                                                            {division.mainzip}
                                                                            <br />
                                                                            &nbsp;
                                                                        </div>
                                                                    )
                                                                }.bind(this)
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                }.bind(this)
                            )}
                        </div>
                    </div>
                </div>
            )
        } else if (this.state.loading) {
            results = (
                <div>
                    <LoadingSpinner />
                    Loading...
                </div>
            )
        }
        let clear = null
        if (this.state.results !== undefined) {
            clear = (
                <div>
                    Search Results: &nbsp;
                    {this.state.results.length > 50 ? '50 out of ' : ''}
                    {this.state.results.length}
                    &nbsp;
                    {this.state.truncated ? ' More results not shown' : null}
                </div>
            )
        } else if (this.state.searching !== undefined) {
            clear = <div>Searching...</div>
        } else {
            clear = <div>Search Results: none</div>
        }
        return (
            <div>
                <form
                    onSubmit={(e) => {
                        console.log('submit', e)
                        e.preventDefault()
                    }}
                >
                    <div className='row' id='search'>
                        <div className='col-12 col-sm-6 col-md-4'>
                            <h2>Client Maintenance</h2>
                        </div>

                        <div className='col-12 col-sm-6'>
                            <div className='mb-3'>
                                <div className={this.state.searchTxt ? 'input-group' : ''}>
                                    <Form.Control
                                        placeholder='Search'
                                        id='searchInput'
                                        autoComplete='off'
                                        onChange={(e) => {
                                            this.searchChange(e)
                                        }}
                                        value={this.state.searchTxt}
                                    />
                                    {this.state.searchTxt && (
                                        <Button outline onClick={this.clearSearch}>
                                            <span className='far fa-times' />
                                        </Button>
                                    )}
                                </div>
                                {this.state.searching && <LoadingSpinner />}
                                {clear}
                            </div>
                        </div>
                    </div>
                </form>
                {results}
            </div>
        )
    }
}
