import classnames from 'classnames'
import React, { useContext } from 'react'
import { ListGroup as BsListGroup } from 'react-bootstrap'

const ListGroupContext = React.createContext({})

export default function ListGroup({
    className,
    noBorders,
    noGutters,
    style,
    children,
    startBordered,
    size = 'md',
    ...bsProps
}) {
    return (
        <ListGroupContext.Provider
            value={{
                startBordered,
            }}
        >
            <BsListGroup
                {...bsProps}
                className={classnames(className, `list-group-${size}`, {
                    'list-group-flush': noBorders,
                    'list-group-no-gutters': noGutters,
                    'list-group-start-bordered': startBordered,
                })}
                style={style}
            >
                {children}
            </BsListGroup>
        </ListGroupContext.Provider>
    )
}

const ListGroupItem = React.forwardRef(
    ({ children, icon, startBorderVariant = 'primary', className, ...bsProps }, ref) => {
        const { startBordered } = useContext(ListGroupContext)

        const body = (
            <>
                {icon && <i className={classnames(icon, 'me-1 list-group-icon')} />}
                {children}
            </>
        )

        return (
            <BsListGroup.Item ref={ref} {...bsProps} className={classnames(className, {})}>
                {startBordered ? (
                    <a
                        className={classnames(
                            'list-group-item-action',
                            `border-${startBorderVariant}`
                        )}
                    >
                        {body}
                    </a>
                ) : (
                    body
                )}
            </BsListGroup.Item>
        )
    }
)

ListGroup.Item = ListGroupItem
