import { useQuery } from '@tanstack/react-query'
import classnames from 'classnames'
import React from 'react'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { getUniqueAvatarColor } from '../../utils'
import axios from '../axios'
import { Avatar, ListGroup } from '../front'
import { useLocal } from 'hooks'

const initialEmployees =
    typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('employees')) : {}

const getEmployee = (empId) => {
    const employees = JSON.parse(localStorage.getItem('employees'))
    if (employees?.[empId]) return Promise.resolve(employees[empId])
    else return axios.get(`/ncs/employee/1/maintenance/${empId}/json`).then(({ data }) => data[0])
}

const useEmployee = (empId) => {
    const [employees, setEmployees] = useLocal('employees', initialEmployees)
    const employee = employees?.[empId]

    return useQuery(
        ['employee', empId],
        () => {
            if (employee) {
                return employee
            } else return getEmployee(empId)
        },
        {
            staleTime: Infinity,
            cacheTime: Infinity,
            refetchOnMount: false,
            enabled: !!empId,
            onSuccess: (data) => {
                setEmployees((prev) => ({ ...prev, [empId]: data }))
            },
            initialData: employee ? employee : undefined,
        }
    )
}

export const EmployeeAvatar = React.forwardRef(
    (
        {
            empId, // Either pass empId
            firstName, // Or first + last
            lastName,
            fullName, // Or full name
            empNameTooltip,
            tooltip,
            tooltipPlacement = 'top',
            soft = false,
            includeLabel = false,
            className,
            labelClassName,
            ...avatarProps
        },
        ref
    ) => {
        const { data: employee, isLoading } = useEmployee(empId)

        if (empId && isLoading)
            return (
                <LoadingAvatar
                    {...avatarProps}
                    className={className}
                    size={avatarProps?.size}
                    soft
                    variant='secondary'
                />
            )

        let fName
        let lName
        if (employee) {
            fName = employee?.FirstName
            lName = employee?.LastName
        } else if (firstName || lastName) {
            fName = firstName
            lName = lastName
        } else if (fullName) {
            const splitName = fullName?.split(' ')
            fName = splitName[0]
            lName = splitName?.[1] ?? ''
        } else {
            return null
        }

        // Initials
        fullName = `${fName} ${lName}`
        let initials = `${fName?.charAt(0)}${lName?.charAt(0)}`
        if (lName?.substring(0, 5) === 'User ') {
            initials = lName?.replace('User ', '')
        } else if (lName?.substring(0, 7) === 'Quatrro') {
            initials = lName?.replace('Quatrro', '')
        } else if (fName === 'Quatrro' && lName === 'Quatrro') {
            initials = 'Q'
        }

        // Tooltip
        let tooltipContent = null
        if (empNameTooltip || tooltip) {
            tooltipContent = (
                <div>
                    {empNameTooltip && <div>{fullName}</div>}
                    {tooltip && <div>{tooltip}</div>}
                </div>
            )
        }

        const avatar = (
            <Avatar
                {...avatarProps}
                ref={ref}
                bg={getUniqueAvatarColor(fullName)}
                tooltipPlacement={tooltipPlacement}
                tooltip={tooltipContent}
                initials={initials}
                soft={soft}
                className={classnames('employee-avatar text-white', className)}
            />
        )

        if (!includeLabel) return avatar
        else
            return (
                <div className='d-flex align-items-center'>
                    {avatar}
                    <span className={classnames('ms-1', labelClassName)}>{fullName}</span>
                </div>
            )
    }
)

export const EmployeePopup = ({
    children,
    empId,
    tooltip = false,
    tooltipPlacement = 'top',
    soft,
    ...avatarProps
}) => {
    const { data: employee, isLoading } = useQuery(['employee', empId], () => getEmployee(empId))

    if (isLoading) return children

    const firstName = employee?.FirstName
    const lastName = employee?.LastName
    const department = employee?.Department
    const title = employee?.title
    const extension = employee?.ext
    const email = employee?.CNTEMAIL

    const fullName = `${firstName} ${lastName}`
    let initials = `${firstName?.charAt(0)}${lastName?.charAt(0)}`
    if (lastName?.substring(0, 5) === 'User ') {
        initials = lastName?.replace('User ', '')
    }

    const popover = (
        <Popover id={`${fullName}Popover`} style={{ maxWidth: 'none' }}>
            <Popover.Header className='fs-5 d-flex justify-content-start align-items-center p-3'>
                <div>
                    <Avatar
                        size='sm'
                        bg={getUniqueAvatarColor(fullName)}
                        initials={initials}
                        className='me-3 text-white'
                    />
                </div>
                <div className={classnames('me-2 lh-1 ', {})}>
                    {fullName}
                    <small className='smaller text-muted lh-1 d-block mt-1'>{title}</small>
                </div>
            </Popover.Header>
            <ListGroup className='popover-list text-muted' size='sm'>
                {email && (
                    <>
                        <ListGroup.Item
                            className='pe-3 py-1 d-flex align-items-center'
                            action
                            href={`https://teams.microsoft.com/l/chat/0/0?users=${email}`}
                            target='_blank'
                        >
                            <img src='/images/MsTeamsLogo.png' style={{ width: '1.8rem' }} />
                            <span className='ms-1 d-block'>Message in Teams</span>
                        </ListGroup.Item>
                        <ListGroup.Item
                            className='pe-3 py-1 d-flex align-items-center'
                            action
                            href={`mailto: ${email}`}
                        >
                            <img src='/images/MsOutlookLogo.png' style={{ width: '1.8rem' }} />
                            <span className='ms-1 d-block'>Email</span>
                        </ListGroup.Item>
                    </>
                )}
                {extension && (
                    <ListGroup.Item
                        className='pe-3'
                        action
                        icon='fas fa-phone fa-sm'
                        href={`tel:4404619661,ext=${extension}`}
                    >
                        ext. {extension}
                    </ListGroup.Item>
                )}
            </ListGroup>
        </Popover>
    )

    return (
        <OverlayTrigger trigger='click' placement='bottom' overlay={popover} rootClose>
            {children}
        </OverlayTrigger>
    )
}

export const EmployeeAvatarPopup = ({ empId, className, ...rest }) => {
    return (
        <EmployeePopup empId={empId}>
            <EmployeeAvatar
                {...rest}
                empId={empId}
                className={classnames(className, 'cursor-pointer')}
            />
        </EmployeePopup>
    )
}

const LoadingAvatar = (props) => <Avatar icon='fal fa-spinner-third fa-spin' {...props} />
