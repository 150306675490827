import { PortalContext } from 'components/GlobalState'
import { formatDate, LoadingSpinner } from 'components/utils'
import { Badge, Button, Steps } from 'front'
import { useContext, useState } from 'react'
import { useTrackerDropdowns, useUpdateNote } from 'tracker/api'
import { useConfirmNote } from 'tracker/api/note.api'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import AddEditNoteModal from './AddEditNoteModal'
import NoteTagIcon from './NoteTagIcon'
import HighlightText from '@/components/utils/HighlightText'
export default function NoteStep({ note, edit, searchText = '' }) {
    const { fileInfo } = useContext(ProjectContext)
    const { data: dropdowns } = useTrackerDropdowns()
    const { mutate: confirmNote, isLoading: confirmNoteLoading } = useConfirmNote(note?.direct_no)
    const { employee } = useContext(PortalContext)
    const [tagsSelected, setTagsSelected] = useState(Object.keys(note?.tags) ?? [])
    const [showAllTags, setShowAllTags] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [tagsOnly, setTagsOnly] = useState(false)

    const assignedEmp =
        note?.assigned_emp_id > 0
            ? dropdowns?.employee?.find((emp) => emp.EmpID == note.assigned_emp_id)
            : false
    const canEdit = note?.is_editable
    const tagsDifferent = JSON.stringify(Object.keys(note.tags)) !== JSON.stringify(tagsSelected)
    const author = dropdowns?.employee?.find((emp) => emp.cnt_id == note?.cnt_id)
    // console.log('note: ', note)
    const tagsToShow = Object.values(note?.tags)
    const assignedToYou = assignedEmp?.EmpID == employee?.EmpID
    const { mutate: updateNote, isLoading: updateSaving } = useUpdateNote(fileInfo?.project_id)
    // console.log('tagsToShow: ', tagsToShow)

    return (
        <>
            <Steps.DateDivider date={note.created} />
            <Steps.Item>
                <NoteIcon note={note} />
                <Steps.Content>
                    <Steps.Text className={'mb-2'}>
                        <div className='d-flex justify-between'>
                            {/* {note?.is_pinned && (
                                <div>
                                    <span className='fas fa-thumbtack me-2' />
                                </div>
                            )} */}
                            <div className='flex-fill me-3 d-flex flex-column'>
                                <div style={{ whiteSpace: 'pre-wrap' }}>
                                    <HighlightText text={note?.text} highlight={searchText} />
                                </div>
                                <div className='mt-2'>
                                    {tagsToShow?.map(
                                        (tag, i) =>
                                            (showAllTags || (!showAllTags && i < 2)) && (
                                                <Badge
                                                    key={i}
                                                    soft
                                                    className='me-2 mb-1'
                                                    bg='secondary'
                                                >
                                                    <NoteTagIcon tag={tag} />
                                                    <span className='ms-1'>{tag?.label}</span>
                                                </Badge>
                                            )
                                    )}
                                    {!canEdit && (
                                        <Badge
                                            soft
                                            className={
                                                'pointer ' +
                                                (tagsToShow?.length > 0 ? 'me-2 mb-1' : '')
                                            }
                                            bg='secondary'
                                            onClick={() => {
                                                setShowEditModal(true)
                                                setTagsOnly(true)
                                            }}
                                        >
                                            <span className='far fa-tags' /> Edit Tags
                                        </Badge>
                                    )}
                                    {tagsToShow?.length > 2 && (
                                        <Button
                                            ghost
                                            className='btn-link'
                                            size='xs'
                                            onClick={() => {
                                                setShowAllTags(!showAllTags)
                                            }}
                                        >
                                            Show {!showAllTags ? 'All' : 'Less'} Tags
                                        </Button>
                                    )}
                                </div>
                            </div>
                            <div className='text-nowrap text-end d-flex flex-column m-0'>
                                <div className='d-flex align-items-center justify-content-end fs-5'>
                                    <strong>{author?.label ?? note?.name}</strong>
                                    {assignedEmp && (
                                        <div
                                            className={
                                                'text-body fw-' +
                                                (assignedToYou ? 'strong' : 'normal')
                                            }
                                        >
                                            <span className='fal fa-arrow-right mx-2' />
                                            {assignedEmp?.FirstName} {assignedEmp?.LastName}
                                        </div>
                                    )}
                                </div>
                                <div>{formatDate(note?.created, 'MMM Do YYYY h:mm a')}</div>
                                <div>
                                    {note?.confirmed && (
                                        <Badge className='ms-2' bg='info' soft>
                                            <span className='far fa-check' /> Confirmed
                                        </Badge>
                                    )}
                                    {note?.need_confirmed && !note?.confirmed && (
                                        <Button
                                            disabled={confirmNoteLoading}
                                            size='xs'
                                            variant='danger'
                                            soft
                                            icon={
                                                confirmNoteLoading
                                                    ? 'fal fa-spinner-third fa-spin'
                                                    : ''
                                            }
                                            onClick={() => {
                                                confirmNote(note?.id)
                                            }}
                                        >
                                            Confirm
                                        </Button>
                                    )}
                                </div>
                                <div>
                                    {canEdit || note?.tags?.note2client ? (
                                        <>
                                            <Button
                                                onClick={() => {
                                                    setShowEditModal(true)
                                                    setTagsOnly(false)
                                                }}
                                                className='p-0 ms-2'
                                                variant='link'
                                            >
                                                Edit
                                            </Button>
                                        </>
                                    ) : (
                                        note?.is_pinned && (
                                            <Button
                                                onClick={() => {
                                                    updateNote({
                                                        ...note,
                                                        is_pinned: false,
                                                    })
                                                }}
                                                className='p-0 ms-2'
                                                variant='link'
                                                disabled={updateSaving}
                                            >
                                                {updateSaving && (
                                                    <LoadingSpinner className='me-1' />
                                                )}
                                                Remove Pin
                                            </Button>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </Steps.Text>
                    <AddEditNoteModal
                        edit={!note?.tags?.note2client}
                        tagsOnly={tagsOnly}
                        show={showEditModal}
                        {...note}
                        cancel={() => {
                            setShowEditModal(false)
                            setTagsOnly(false)
                        }}
                    />
                </Steps.Content>
            </Steps.Item>
        </>
    )
}

function NoteIcon({ note }) {
    let icon
    let tag
    if (note?.is_pinned) {
        icon = { tag: 'pinned' }
    } else if (note?.tags) {
        const tagKeys = Object.keys(note?.tags)
        const tagValues = Object.values(note?.tags)
        if ((tag = tagValues?.find((tag) => tag?.tag == 'task'))) {
            icon = tag
        } else if ((tag = tagValues?.find((tag) => tag?.tag == 'associate'))) {
            icon = tag
        }
    }

    return (
        <Steps.Icon soft variant={'secondary'}>
            <NoteTagIcon tag={icon} defaultIcon='note' />
        </Steps.Icon>
    )
}
