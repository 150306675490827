export function findCurrencyValues(text) {
    // Regex pattern to match currency values (e.g., $100, €1,000.50)
    // This is a simple pattern and might need adjustments to fit all your needs
    const regexPattern = /([$£€]\s?\d{1,3}(,\d{3})*(\.\d{0,2})?)/g

    // Search for matches
    const matches = text.match(regexPattern)

    // Check if any matches are found
    if (matches) {
        // Return the matched currency values
        return matches
    } else {
        // Return a message or an empty array if no matches are found
        return []
    }
}
