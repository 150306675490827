import { useEffect, useState } from 'react'
import { usePacket, useTemplatesForTask, useUpdatePacket } from 'tracker/api'

export const usePacketSofteningLetter = (projectId, taskId, packetId, isModalOpen) => {
    // Packet API
    const { data: packet, ...packetApi } = usePacket(packetId, {
        enabled: !!packetId && isModalOpen,
    })
    // Update Packet API
    const { mutate: updatePacket, ...updatePacketApi } = useUpdatePacket(projectId)
    // Template options API
    const { data: templateData, ...templateOptionsApi } = useTemplatesForTask(projectId, taskId, {
        enabled: !!(projectId && taskId) && isModalOpen,
    })

    const [softeningLetterId, setSofteningLetterId] = useState()
    const [softeningLetterEnabled, setSofteningLetterEnabled] = useState()

    useEffect(() => {
        // If packetId supplied, use packet's softening letter
        if (packetId && packet?.id == packetId) {
            if (packet?.softening_letter_id) {
                setSofteningLetterId(packet?.softening_letter_id)
                setSofteningLetterEnabled(true)
            } else {
                setSofteningLetterEnabled(false)
            }
            // If not, default softening letter to first softening letter option
        } else if (templateData?.softening) {
            if (templateData?.softening?.length > 0) {
                setSofteningLetterId(templateData?.softening[0]?.id)
                setSofteningLetterEnabled(true)
            } else {
                setSofteningLetterEnabled(false)
            }
        }
    }, [packet, templateData])

    // Softening Letter
    function setSofteningLetter(softeningLetterId) {
        setSofteningLetterId(softeningLetterId)
        if (packetId) {
            updatePacket({
                packetId,
                data: {
                    softening_letter_id: softeningLetterId,
                },
            })
        }
    }

    function enableSofteningLetter() {
        setSofteningLetterEnabled(true)

        let softeningId = softeningLetterId ?? templateData?.softening[0]?.id
        if (!softeningLetterId) setSofteningLetter(softeningId)

        if (packetId) {
            updatePacket({
                packetId,
                data: {
                    softening_letter_id: softeningId,
                },
            })
        }
    }

    function disableSofteningLetter() {
        setSofteningLetterEnabled(false)
        if (packetId) {
            updatePacket({
                packetId,
                data: {
                    softening_letter_id: null,
                },
            })
        }
    }

    return {
        softeningLetter: templateData?.softening?.find(({ id }) => id == softeningLetterId),
        softeningLetterOptions: templateData?.softening?.map((letter) => ({
            ...letter,
            selected: letter?.id === softeningLetterId,
        })),
        softeningLetterEnabled,
        setSofteningLetter,
        enableSofteningLetter,
        disableSofteningLetter,
        updatePacketApi,
        isLoading: updatePacketApi.isLoading,
    }
}
