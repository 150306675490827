import { useState, useEffect, createContext, useContext } from 'react'

const ClipboardContext = createContext(null)
export function ClipboardProvider({ children }) {
    const [clipboardText, setClipboardText] = useState('')

    useEffect(() => {
        // Only set the interval if it hasn't been set already
        setInterval(async () => {
            try {
                if (navigator.clipboard) {
                    const text = await navigator.clipboard.readText()
                    const projectNumberPattern = /^[A-Za-z]?\d{7}$/
                    if (projectNumberPattern.test(text)) {
                        setClipboardText(text)
                    }
                }
            } catch (error) {}
        }, 1000)
    }, [])

    return <ClipboardContext.Provider value={clipboardText}>{children}</ClipboardContext.Provider>
}

export const useClipboardProject = () => useContext(ClipboardContext)
