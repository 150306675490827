import { useContext, useState } from 'react'
import { Alert, Modal } from 'react-bootstrap'
import { Button } from '../../../../../front'
import { useCreateTask } from '../../../api'
import { ProjectContext } from '../../../Context/ProjectContext'
import { TaskSelectionMenu } from './TaskCard'

export default function NewTaskModal({ show, onHide, setSelectedTaskId }) {
    const [selectedTaskCode, setSelectedTaskCode] = useState('MON')
    const [selectedServiceLevelCode, setSelectedServiceLevelCode] = useState('PREMIUM')

    const { projectId } = useContext(ProjectContext)
    const { mutate: createTask, ...createTaskApi } = useCreateTask(
        {},
        {
            onSuccess: (response) => {
                setSelectedTaskId(response?.task_id)
                onHide()
            },
        }
    )

    let submit = () =>
        createTask({
            task_code: selectedTaskCode,
            service_level: selectedServiceLevelCode,
        })

    return (
        <Modal centered show={show} onHide={onHide} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>New Task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <TaskSelectionMenu
                    selectedTaskCode={selectedTaskCode}
                    setSelectedTaskCode={setSelectedTaskCode}
                    selectedServiceLevelCode={selectedServiceLevelCode}
                    setSelectedServiceLevelCode={setSelectedServiceLevelCode}
                />
                {createTaskApi?.isError && (
                    <Alert variant='soft-danger'>
                        <div className='d-flex'>
                            <div className='flex-shrink-0'>
                                <i className='fas fa-circle-exclamation' />
                            </div>
                            <div className='flex-grow-1 ms-2'>Error creating task</div>
                        </div>
                    </Alert>
                )}
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <Button onClick={onHide} ghost variant='secondary' className='me-2'>
                    Cancel
                </Button>
                <Button
                    onClick={submit}
                    icon={createTaskApi?.isLoading ? 'fal fa-spinner-third fa-spin' : null}
                    variant='primary'
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
