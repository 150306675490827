import useLocal from './useLocal'

export const useTheme = () => {
    const [theme, setTheme] = useLocal('app-theme', null)

    const setLightTheme = () => setTheme('light')
    const setDarkTheme = () => setTheme('dark')
    const toggleTheme = () =>
        setTheme((currentTheme) => (currentTheme === 'light' ? 'dark' : 'light'))

    return { theme, setTheme, setDarkTheme, setLightTheme, toggleTheme }
}
