import { Button, Form, ListGroup } from 'front'
import { Alert, Modal } from 'react-bootstrap'
import { useUpdatePacket } from 'tracker/api'
import { usePacketDocuments } from '../hooks/usePacketDocuments'
import { TemplateLabel } from '../wizard/templates/TemplateList'
// TODO: clean this code up
export default function AddTemplateModal({ projectId, taskId, packet, show, onHide }) {
    const { mutate: updatePacket, ...updatePacketApi } = useUpdatePacket(projectId, {
        onSuccess: onHide,
    })
    const { documents, templateOptions, addTemplate, removeTemplate } = usePacketDocuments(
        projectId,
        taskId
    )

    let submit = () => {
        if (packet?.id)
            updatePacket({
                packetId: packet?.id,
                data: {
                    documents: documents?.map(({ template_id }) => ({
                        template_id,
                    })),
                },
            })
        else {
            packet.addTemplates(documents?.map(({ template_id }) => template_id))
            onHide()
        }
    }

    return (
        <Modal centered show={show} onHide={onHide} scrollable>
            <Modal.Header closeButton>
                <Modal.Title>Add Template(s)</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-0'>
                <ListGroup noBorders>
                    {templateOptions?.map((template) => (
                        <ListGroup.Item
                            key={template?.id}
                            action
                            className='d-flex justify-content-start'
                            onClick={() => {
                                !template?.selected
                                    ? addTemplate(template?.id)
                                    : removeTemplate(template?.id)
                            }}
                        >
                            <Form.Check type='checkbox' checked={template?.selected} />
                            <TemplateLabel template={template} />
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Modal.Body>

            {updatePacketApi?.isError && (
                <Modal.Body>
                    <AddTemplateErrorAlert />
                </Modal.Body>
            )}

            <Modal.Footer className='justify-content-between'>
                <Button onClick={onHide} ghost variant='secondary' className='me-2'>
                    Cancel
                </Button>
                <Button
                    onClick={submit}
                    icon={updatePacketApi?.isLoading ? 'fal fa-spinner-third fa-spin' : null}
                    variant='primary'
                >
                    Add Template
                    {documents?.length > 1 && 's'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const AddTemplateErrorAlert = () => (
    <Alert variant='soft-danger'>
        <div className='d-flex'>
            <div className='flex-shrink-0'>
                <i className='fas fa-circle-exclamation' />
            </div>
            <div className='flex-grow-1 ms-2'>Error adding template</div>
        </div>
    </Alert>
)
