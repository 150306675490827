import { SortableTable } from 'components/utils'
import { Card } from 'front'
import { useModalState } from 'hooks'
import { useContext } from 'react'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import { useProject, useUpdatePayable } from 'tracker/api'
import { PayableRow } from '../payables/PayableRow'
import NewPayableModal from '../payables/modals/NewPayableModal'

export function PayablesCard({ task }) {
    const { fileInfo, projectLoading, updateFileEdit, canEdit } = useContext(ProjectContext)
    const { data: project } = useProject({ projectId: fileInfo?.project_id })
    const addPayableModal = useModalState()

    let payables =
        fileInfo?.payables?.filter(({ line_items }) =>
            line_items?.find(({ task_id, is_deleted }) => task_id == task?.id && !is_deleted)
        ) ?? []

    const { mutate: updatePayable, isLoading: updatePayableLoading } = useUpdatePayable({
        projectId: fileInfo?.project_id,
    })

    function updatePayableField(id, name, value) {
        updatePayable({ payables: { [id]: { [name]: value } } })
    }

    return (
        <>
            <Card>
                <Card.Header className={'d-flex justify-content-between align-items-center'}>
                    <Card.Title>Payables</Card.Title>
                    {canEdit && (
                        <div>
                            <a className='link p-0' onClick={addPayableModal.show}>
                                Add Payable
                            </a>
                        </div>
                    )}
                </Card.Header>
                {payables?.length > 0 ? (
                    <SortableTable
                        all
                        skeleton={projectLoading}
                        startSort={'created_date'}
                        startSortBy={'asc'}
                        info={payables}
                        renderRow={(row) => (
                            <PayableRow
                                key={row?._id}
                                skeleton={projectLoading}
                                row={row}
                                fileInfo={fileInfo}
                                updatePayableField={updatePayableField}
                                project={project}
                                updateFileEdit={updateFileEdit}
                                updateProjectLoading={updatePayableLoading}
                                taskVariant
                                taskId={task?.id}
                            />
                        )}
                        headers={[
                            {
                                name: 'Invoice',
                                sortField: 'invoice_number',
                            },
                            {
                                name: 'Firm',
                                sortField: 'vendor_number',
                            },
                            {
                                name: 'Amount',
                                sortField: 'fee_amount',
                            },
                            {
                                name: 'Status',
                                sortField: 'status',
                                className: 'text-center',
                            },
                            {
                                name: 'Invoice Date',
                                sortField: 'invoice_date',
                            },
                            {
                                name: '',
                            },
                        ]}
                    />
                ) : (
                    <Card.Body>No payables</Card.Body>
                )}
            </Card>
            <NewPayableModal
                show={addPayableModal.isOpen}
                onHide={addPayableModal.hide}
                key={addPayableModal.key}
                defaultValues={{
                    vendor_number: task?.firm_no,
                    line_items: [
                        {
                            task_id: task?.id,
                        },
                    ],
                }}
            />
        </>
    )
}
