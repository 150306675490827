import { usePacket } from 'tracker/api'
import { usePacketDocuments } from './usePacketDocuments'
import { usePacketPreferences } from './usePacketPreferences'
import { usePacketRecipients } from './usePacketRecipients'
import { usePacketSofteningLetter } from './usePacketSofteningLetter'
import { usePacketFurnishings } from './usePacketFurnishings'
export const usePacketForm = (projectId, taskId, packetId, { isModalOpen = true }) => {
    const { data: packet, ...packetApi } = usePacket(packetId, {
        enabled: !!packetId && isModalOpen,
    })

    const softeningLetterState = usePacketSofteningLetter(projectId, taskId, packetId, isModalOpen)
    const documentsState = usePacketDocuments(projectId, taskId, packetId, isModalOpen)
    const preferencesState = usePacketPreferences(projectId, packetId, isModalOpen)
    const recipientsState = usePacketRecipients(projectId, packetId, preferencesState, isModalOpen)
    const furnishingsState = usePacketFurnishings(projectId, packetId, isModalOpen)

    return {
        ...packet,
        ...softeningLetterState,
        ...documentsState,
        ...preferencesState,
        ...recipientsState,
        ...furnishingsState,
        isLoading:
            recipientsState.isLoading ||
            softeningLetterState.isLoading ||
            documentsState.isLoading ||
            preferencesState.isLoading ||
            furnishingsState.isLoading,
    }
}
