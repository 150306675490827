import { Badge, Button, Card, ListGroup } from 'front'
import { Col } from 'react-bootstrap'
import isEmpty from '../../../../../../utils/isEmpty'
import { DocumentIcon } from '../../components/DocumentIcon'
import { usePacketDraftContext } from '../PacketWizard'
import { TemplateLabel } from './TemplateList'
export function SelectedTemplates({}) {
    const { templateOptions, softeningLetter, setSofteningLetter, clearTemplates } =
        usePacketDraftContext()

    const selectedTemplates = templateOptions?.filter(({ selected }) => selected)

    const showSoftening = !isEmpty(softeningLetter)
    const showTemplates = selectedTemplates?.length > 0

    if (!showSoftening && !showTemplates) return <EmptySelectionPrompt />

    return (
        <Col>
            <Card>
                <Card.Header className='d-flex justify-content-between align-items-center'>
                    <Card.Title as='h4' cardHeaderTitle>
                        Packet
                    </Card.Title>
                    <Button
                        size='sm'
                        variant='danger'
                        ghost
                        onClick={() => {
                            setSofteningLetter()
                            clearTemplates()
                        }}
                    >
                        Clear All
                    </Button>
                </Card.Header>
                <ListGroup noBorders>
                    {showSoftening && <SelectedSofteningLetter />}
                    {showTemplates && <SelectedTemplatesList />}
                </ListGroup>
            </Card>
        </Col>
    )
}

function SelectedSofteningLetter({}) {
    const { softeningLetter, setSofteningLetter } = usePacketDraftContext()

    return (
        <ListGroup.Item className='d-flex align-items-center'>
            <DocumentIcon type='softening' className='mx-2 my-3 flex-shrink-0 fa-fw' />
            <div className='flex-grow-1 fs-5 text-body ms-3'>
                {softeningLetter?.DOC_DESC}
                <Badge bg='success' soft className='ms-2'>
                    Softening Letter
                </Badge>
            </div>
            <Button
                icon='fal fa-times'
                size='sm'
                ghost
                variant='secondary'
                className='flex-shrink-0'
                onClick={() => {
                    setSofteningLetter()
                }}
            />
        </ListGroup.Item>
    )
}

function SelectedTemplatesList({}) {
    const { templateOptions, removeTemplate } = usePacketDraftContext()
    const selectedTemplates = templateOptions?.filter(({ selected }) => selected)

    return (
        <>
            {selectedTemplates?.map((template) => (
                <ListGroup.Item key={template.id} className='d-flex'>
                    <DocumentIcon type='template' className='mx-2 my-3 flex-shrink-0 fa-fw' />
                    <TemplateLabel template={template} />
                    <Button
                        size='sm'
                        ghost
                        variant='secondary'
                        className='ms-2 flex-shrink-0'
                        icon='fal fa-times'
                        onClick={() => {
                            removeTemplate(template?.id)
                        }}
                    />
                </ListGroup.Item>
            ))}
        </>
    )
}

const EmptySelectionPrompt = () => (
    <Col>
        <Card ghost centered style={{ height: '400px' }}>
            <Card.Body>
                <h1 className='mb-3'>
                    <i className='fal fa-file-circle-check fa-xl mb-3 text-muted-3 ' />
                </h1>
                <h2 className='text-muted-3 fw-normal'>Select Templates to Proceed</h2>
            </Card.Body>
        </Card>
    </Col>
)
