import classnames from 'classnames'

export function DocumentIcon({ type = 'template', className }) {
    switch (type) {
        case 'softening':
            return <i className={classnames(className, 'fad fa-envelope text-primary fa-2xl')} />
        case 'attachment':
            return (
                <span className={classnames(className, 'fa-stack fa-1x text-success')}>
                    <i className='fad fa-file fa-stack-2x' />
                    <i
                        className='fad fa-paperclip fa-swap-opacity fa-stack-1x'
                        style={{ top: '4px' }}
                    />
                </span>
            )
        case 'template':
            return <i className={classnames(className, 'fad fa-file-text text-primary fa-2xl')} />
        default:
            return (
                <i
                    className={classnames(
                        className,
                        'fad fa-file-circle-question text-secondary fa-2xl'
                    )}
                />
            )
    }
}
