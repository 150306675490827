export default function ClearStorageAndReload() {
    if (window) {
        window.localStorage.clear()
        window.sessionStorage.clear()
        clearAllCookies()
        window.location.reload()
    }
}

function clearAllCookies() {
    const cookies = document.cookie.split(';')

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i]
        const eqPos = cookie.indexOf('=')
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    }
}
