import { Button, GlobalFormOptions } from 'front'
import { useConfirmExitPage, useLocal, useModalState, usePrevious } from 'hooks'
import _ from 'lodash'
import { useRouter } from 'next/router'
import Queue from 'queue-promise'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useProject, usePublishProject, useUpdateProject } from 'tracker/api'
import ProjectVersion from 'tracker/api/projectVersion.api'
import { Autosave, debounce } from 'utils'
import { PortalContext } from '../../../GlobalState'
import ProjectWarningModal from './ProjectWarningModal'
import { popoutWindow } from '@/components/utils/popoutWindow'
import { Project } from 'tracker/api/project.api'
import { Modal } from 'react-bootstrap'

export const ProjectContext = React.createContext({})

const patchQueue = new Queue({
    concurrent: 1,
    interval: 0,
})

export default function ProjectContextProvider({ children }) {
    const { socket } = useContext(PortalContext)
    const router = useRouter()
    const autosaveDelay = 1

    // STATE ////////////////////////////////////////////////////////////////////////////////////////////

    // Project Data
    const projectId = parseInt(router?.query?.project_id)
    const {
        data: fileInfo,
        isLoading: fileInfoLoading,
        isError: fileInfoError,
        ...projectApi
    } = useProject(
        { projectId, takeover: true },
        {
            onSuccess: (data) => {
                setAutosaveActive(true)
                setShouldSyncFileEdit(true)
                setEditingEnabled(data?.lock_status?.is_mine)
            },
        }
    )
    let changesCount = 0
    if (fileInfo?.display) {
        fileInfo?.display.forEach((section) => {
            section?.fields.forEach((field) => {
                if (field.change_type != 'none') changesCount++
            })
        })
    }
    const publishDisabled =
        fileInfo?.warnings?.debtor_missing ||
        fileInfo?.warnings?.contact_missing ||
        fileInfo?.warnings?.contact_error ||
        fileInfo?.incoming_changes?.version?.id ||
        !fileInfo?.lock_status?.can_edit ||
        !(changesCount > 0)

    useBlockNavigationOnProjectIdChange(
        projectId,
        fileInfo?.lock_status?.can_edit &&
            (fileInfo?.warnings?.debtor_missing ||
                fileInfo?.warnings?.contact_missing ||
                fileInfo?.warnings?.contact_error)
    )

    const errorMessages = []
    if (fileInfo?.warnings?.debtor_missing) {
        errorMessages.push('No debtor selected')
    }
    if (fileInfo?.warnings?.contact_missing) {
        errorMessages.push('No contact selected')
    }

    const [fileEdit, setFileEdit] = useLocal(`FileEdit--${projectId}`, null) // Mutable version of the data, used for editing purposes, only contains editable info
    const [editQueue, setEditQueue] = useState([{}]) // List of edits to be sent out
    const [projectPopoutWindows, setProjectPopoutWindows] = useState({}) // List of popout windows for this project [windowName: windowObject
    // Autosave
    const [autosaveActive, setAutosaveActive] = useState(false) // Determines whether autosave is currently active
    const [autosaveEnabled, setAutosaveEnabled] = useState(true) // Manual override for user to disable/enable autosave

    // Editing Flags
    const [editingEnabled, setEditingEnabled] = useState(fileEdit?.lock_status?.is_mine ?? false) // Says whether the user chose to enable editing
    const [currentChangeId, setCurrentChangeId] = useState(0) // Latest edit ID
    const [latestChangeId, setLatestChangeId] = useState() // Latest edit ID
    const [latestChangeIdSaved, setLatestChangeIdSaved] = useState() // Latest edit ID saved
    const [currentlyEditing, setCurrentlyEditing] = useState(false) // Says whether fileEdit is currently being changed
    const [shouldSyncFileEdit, setShouldSyncFileEdit] = useState(false) // FileEdit needs refreshed from the database (but will wait until editing stops)
    const unsavedChanges = latestChangeId !== latestChangeIdSaved // If latest changeId doesn't match the latest ID saved, then new changes need to go out
    const canEdit =
        editingEnabled &&
        fileInfo?.lock_status?.can_edit &&
        fileInfo?.project?._workflow === 'PORTAL' // Determines if editing is available

    const errorList = [] // Error list

    // Window open/close
    const newWindowRef = useRef(null)
    const openWindow = (url, target = '_blank', features = '') => {
        newWindowRef.current = window.open(url, target, features)
    }

    const closeWindow = () => {
        if (newWindowRef.current) {
            newWindowRef.current.close()
            newWindowRef.current = null
        }
    }

    const openProjectPopoutWindow = (windowName, url, title = '') => {
        if (projectPopoutWindows[windowName] && !projectPopoutWindows[windowName].closed) {
            projectPopoutWindows[windowName].focus()
        } else {
            projectPopoutWindows[windowName] = popoutWindow(url, title)
            setProjectPopoutWindows(projectPopoutWindows)
        }
    }

    const closeProjectPopoutWindows = (windows) => {
        Object.keys(windows).forEach((windowName) => {
            projectPopoutWindows[windowName].close()
        })
        setProjectPopoutWindows({})
    }

    // APIS ////////////////////////////////////////////////////////////////////////////////////////////

    // Update Project
    const updateProjectApi = useUpdateProject({ projectId })
    function queueProjectUpdate(payload) {
        setCurrentChangeId((current) => current + 1)

        patchQueue.enqueue(() =>
            updateProjectApi.mutate(editQueue[payload.__changeId], {
                onSuccess: (data) => {
                    if (data?.success) {
                        setShouldSyncFileEdit(true)
                        setLatestChangeIdSaved(payload?.__changeId)
                    } else {
                        // TODO: Implement success: false logic
                    }
                },
            })
        )
    }
    if (updateProjectApi?.isError)
        errorList.push({ label: 'Update Project Info', details: updateProjectApi?.error })

    // SAVING / AUTOSAVE / SYNCING: methods and useEffects involved with keeping the database, fileInfo, and fileEdit in sync ////////////////////////////////////////////////////////////////////////////////////////

    // Checks if editing is available and sets proper pre-edit and post-edit flags
    function applyEdit(fileEditChanges, editQueueChanges, shouldAutosave = true) {
        if (canEdit) {
            // Assigns a change ID to each edit for syncing purposes
            const changeId = currentChangeId
            setLatestChangeId(changeId)
            if (autosaveActive && autosaveEnabled) {
                setCurrentlyEditing(true)
                stopEditingDebounce()
            }
            setFileEdit((prevFileEdit) => ({
                ...prevFileEdit,
                ...fileEditChanges,
                __autosaveSession: shouldAutosave ? getTrackerWindowId() : null, // Sets this so any other open windows won't trigger their own autosave when their fileEdit receives these changes
                __changeId: changeId,
            }))
            setEditQueue((prevEditQueue) => ({
                ...prevEditQueue,
                [changeId]: {
                    ...prevEditQueue[changeId],
                    ...editQueueChanges,
                },
            }))
        }
    }

    // Called each time autosave is triggered
    const autosaveProject = useCallback(
        (data) => {
            if (data?.__autosaveSession === getTrackerWindowId()) {
                queueProjectUpdate(data)
            }
        },
        [projectId, editQueue]
    )

    // Used if autosave is not enabled (or if an autosave fails and leaves unsaved changes), allows for a manual save
    function manuallySaveProject() {
        queueProjectUpdate(fileEdit)
    }

    // Sync fileEdit w/ latest DB values via debounce
    // Is triggered anytime changes occur but only syncs once the changeIds match
    // Can also be manually triggered by calling triggerFileEditSync()
    useEffect(() => {
        if (!updateProjectApi?.isLoading && !unsavedChanges && shouldSyncFileEdit) {
            const timeout = setTimeout(() => {
                syncFileEdit()
                setShouldSyncFileEdit(false)
            }, 1000)

            return () => clearTimeout(timeout)
        }
    }, [latestChangeId, latestChangeIdSaved, updateProjectApi?.isLoading, shouldSyncFileEdit])

    // Used to sync fileInfo data into fileEdit
    // Only pulls editable info from fileInfo
    function syncFileEdit(projectInfo = fileInfo) {
        const editableInfo = {
            associates: projectInfo?.associates,
            client: projectInfo?.client,
            deadlines: projectInfo?.deadlines,
            furnishings: projectInfo?.furnishings,
            project: projectInfo?.project,
            documentation: projectInfo?.documentation,
        }
        setFileEdit(editableInfo)
    }

    // PROJECT CRUD METHODS: methods used to update fileEdit (not fileInfo) ////////////////////////////////////////////////////////////////////////////////////////
    // ONLY update the fileEdit object using these methods in order for saving to properly work
    // If adding a new CRUD method, be sure to pass your changes into applyEdit

    // CRUD METHOD: use this to update a field in fileEdit.project
    function updateProject(field, value, shouldAutosave = true) {
        const instructions = (targetObj) => ({
            project: {
                ...targetObj?.project,
                [field]: value,
            },
        })

        if (fileEdit?.project[field] != value) {
            applyEdit(
                instructions(fileEdit),
                instructions(editQueue[currentChangeId]),
                shouldAutosave
            )
        }
    }

    // CRUD METHOD: use this to update a field in fileEdit.associate[id]
    function updateAssociate(assocId, field, value, shouldAutosave = true) {
        const instructions = (targetObj) => ({
            associates: {
                ...targetObj?.associates,
                [assocId]: {
                    ...targetObj?.associates?.[assocId],
                    [field]: value,
                },
            },
        })

        if (fileEdit?.associates?.[assocId]?.[field] !== value) {
            applyEdit(
                instructions(fileEdit),
                instructions(editQueue[currentChangeId]),
                shouldAutosave
            )
        }
    }

    // CRUD METHOD: use this to update a field in fileEdit.associate[id]
    function addAssociate(assoc, shouldAutosave = true) {
        const instructions = (targetObj) => ({
            associates: {
                ...targetObj?.associates,
                [assoc?._id]: {
                    ...assoc,
                    _create: true,
                },
            },
        })
        applyEdit(instructions(fileEdit), instructions(editQueue[currentChangeId]), shouldAutosave)
    }

    function updateFileEdit(changes, shouldAutosave = true) {
        applyEdit(
            _.merge(fileEdit, changes),
            _.merge(editQueue[currentChangeId], changes),
            shouldAutosave
        )
    }

    // CRUD METHOD: replaces everything in file edit, use with caution
    // Temporary solution for when you need to edit more than one part of the project at once
    // TODO: change this to a deep merge
    function replaceFileEdit(newFileEdit, shouldAutosave = true) {
        applyEdit(newFileEdit, newFileEdit, shouldAutosave)
    }

    // MISC USE-EFFECTS ///////////////////////////////////////////////////////////////////////////////////////

    // useEffect(() => {
    //     takeoverProject()
    // }, [])

    // useEffect(() => {
    //     console.log('turn off editing if not imported', fileEdit?.project?._workflow, fileEdit)
    //     !fileEdit?.project?._workflow && setEditingEnabled(false)
    // }, [fileEdit])

    // Connect to socket
    useEffect(() => {
        socket?.on('connect', () => {
            socket.emit('room', 'tracker')
        })
    }, [socket])

    // If there are unsaved changes or file is unpublished, confirm before leaving page
    useConfirmExitPage(unsavedChanges || changesCount > 0)

    // If router project_id changes, start listening for relevant socket messages
    useEffect(() => {
        if (router.query.project_id) {
            // TODO: Fix bug where this causes issues upon first edit
            socket?.on?.(`projectLock${router.query.project_id}`, () => {
                projectApi.refetch()
            })

            return () => {
                socket?.off?.(`projectLock${router.query.project_id}`)
            }
        }
    }, [router.query.project_id, projectApi, socket])

    const prevProjectId = usePrevious(projectId)
    const prevLockStatus = usePrevious(fileInfo?.lock_status)

    // Do this when project ID changes
    useEffect(() => {
        // if (prevProjectId && prevProjectId != projectId && prevLockStatus?.is_mine) {
        //     ProjectVersion.releaseLock(prevProjectId)
        // }

        if (projectId && fileInfo?.lock_status?.is_mine) {
            // const pageUnloadCallback = (e) => {
            //     ProjectVersion.releaseLock(projectId)
            // }

            // window.addEventListener('beforeunload', pageUnloadCallback)

            return () => {
                // window.removeEventListener('beforeunload', pageUnloadCallback)
                // ProjectVersion.releaseLock(projectId)
                closeWindow()
                closeProjectPopoutWindows(projectPopoutWindows)
            }
        }
        return () => {
            closeWindow()
            closeProjectPopoutWindows(projectPopoutWindows)
        }
    }, [projectId, prevProjectId, fileInfo?.lock_status?.is_mine])

    const lockStatusRef = useRef(fileInfo?.lock_status?.is_mine)
    const changesCountRef = useRef(changesCount)
    const versionIdRef = useRef(!!fileInfo?.incoming_changes?.version?.id)

    useEffect(() => {
        lockStatusRef.current = fileInfo?.lock_status?.is_mine
        changesCountRef.current = changesCount
        versionIdRef.current = !!fileInfo?.incoming_changes?.version?.id
    }, [fileInfo?.lock_status?.is_mine, changesCount, !!fileInfo?.incoming_changes?.version?.id])

    useEffect(() => {
        return () => {
            if (lockStatusRef.current) {
                if (changesCountRef.current > 0 && !versionIdRef.current) {
                    Project.publish(projectId)
                } else {
                    ProjectVersion.releaseLock(projectId)
                }
            }
        }
    }, [projectId])

    // usePageUnload()

    // MISC FUNCTIONS ///////////////////////////////////////////////////////////////////////////////////////

    // Callback used to set currentlyEditing to false X seconds after editing has stopped
    // (must be manually called in the CRUD methods in order to correctly identify an edit vs random changes to fileEdit)
    const stopEditingDebounce = useCallback(
        debounce(() => {
            setCurrentlyEditing(false)
        }, autosaveDelay),
        []
    )

    let editStatus
    if (currentlyEditing) {
        editStatus = 'Editing'
    } else if (updateProjectApi?.isLoading) {
        editStatus = 'Saving'
    } else if (unsavedChanges) {
        editStatus = 'Unsaved Changes'
    } else if (shouldSyncFileEdit) {
        editStatus = 'Saved'
    } else if (!shouldSyncFileEdit) {
        editStatus = 'Synced'
    } else {
        editStatus = 'Error'
    }

    const projectWarningModal = useModalState()
    const [projectWarningOnSuccess, setProjectWarningOnSuccess] = useState(null)
    const [projectWarningOnCancel, setProjectWarningOnCancel] = useState(null)

    function triggerProjectWarningModal({ onSuccess, onCancel }) {
        if (onSuccess) setProjectWarningOnSuccess(() => onSuccess)
        if (onCancel) setProjectWarningOnCancel(() => onCancel)
        projectWarningModal.show()
    }

    return (
        <ProjectContext.Provider
            value={{
                projectId,
                fileInfo,
                fileInfoLoading,
                fileInfoError,
                changesCount,
                publishDisabled,
                fileEdit,
                editingEnabled,
                setEditingEnabled,
                errorList,
                manuallySaveProject,
                updateProject,
                updateAssociate,
                addAssociate,
                replaceFileEdit,
                autosaveEnabled,
                setAutosaveEnabled,
                editStatus,
                canEdit,
                updateProjectApi,
                updateProjectLoading: updateProjectApi?.isLoading,
                projectLoading: projectApi?.isLoading,
                updateFileEdit,
                triggerProjectWarningModal,
                openWindow,
                closeWindow,
                openProjectPopoutWindow,
            }}
        >
            <NavigationError currentErrors={errorMessages} />
            {fileInfo?.lock_status?.can_edit && autosaveActive && autosaveEnabled && (
                <Autosave
                    data={fileEdit}
                    onSave={autosaveProject}
                    interval={autosaveDelay}
                    saveOnUnmount={false}
                />
            )}
            <GlobalFormOptions disableAll={!canEdit}>{children}</GlobalFormOptions>
            <ProjectWarningModal
                {...projectWarningModal}
                onSuccess={projectWarningOnSuccess}
                onCancel={projectWarningOnCancel}
                hide={() => {
                    projectWarningModal.hide()
                    setProjectWarningOnSuccess(null)
                    setProjectWarningOnCancel(null)
                }}
            />
            {/* </MicroAppContextProvider> */}
        </ProjectContext.Provider>
    )
}

// Creates a unique identifier for this browser window/tab
// Autosave will only post/patch updates if the ID matches the current window
export function getTrackerWindowId() {
    if (typeof window !== 'undefined') {
        const sessionsId = sessionStorage.sessionId
            ? sessionStorage.sessionId
            : (sessionStorage.sessionId = Math.random())
        return sessionsId
    }
    return null
}

const useBlockNavigationOnProjectIdChange = (currentProjectId, shouldBlock) => {
    const router = useRouter()

    useEffect(() => {
        const handleRouteChange = (url) => {
            const urlParams = new URLSearchParams(new URL(url, window.location.origin).search)
            const newProjectId = urlParams.get('project_id')

            if (shouldBlock && newProjectId != currentProjectId) {
                router.events.emit('routeChangeError', 'Navigation blocked', url)
                throw 'Navigation blocked'
            }
        }

        router.events.on('routeChangeStart', handleRouteChange)

        // Cleanup the event listener on unmount
        return () => {
            router.events.off('routeChangeStart', handleRouteChange)
        }
    }, [currentProjectId, router.events, shouldBlock])
}

const NavigationError = ({ currentErrors }) => {
    const router = useRouter()
    const modal = useModalState(false)

    useEffect(() => {
        const handleRouteChangeError = (err, url) => {
            if (err === 'Navigation blocked') {
                modal.show()
            }
        }

        router.events.on('routeChangeError', handleRouteChangeError)

        // Cleanup the event listener on unmount
        return () => {
            router.events.off('routeChangeError', handleRouteChangeError)
        }
    }, [router.events, currentErrors])

    return (
        <Modal show={modal.isOpen} onHide={modal.hide}>
            <Modal.Header className='d-flex justify-content-start align-items-center'>
                <i className='fas fa-exclamation-triangle me-3 text-danger d-block fa-xl' />
                <div>
                    <Modal.Title>Auto-publish blocked</Modal.Title>
                    <span>Fix the following errors before exiting the file</span>
                </div>
            </Modal.Header>
            <ul className='list-group list-group-flush my-4'>
                {currentErrors.map((error, i) => (
                    <li key={i} className='list-group-item px-5 text-danger'>
                        <i className='fad fa-circle-xmark me-3 text-danger' />
                        {error}
                    </li>
                ))}
            </ul>
            <Modal.Footer className='d-flex justify-content-end'>
                <Button onClick={modal.hide}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}
