import classnames from 'classnames'
import { useState } from 'react'
export default function Wizard({ steps, save, loading }) {
    const [selectedStep, setSelectedStep] = useState(0)

    return (
        <div>
            <ul
                id='basicStepFormProgress'
                className='js-step-progress step step-sm step-icon-sm step-inline step-item-between mb-2 w-md-75 mx-md-auto'
            >
                {steps?.map((step, i) => (
                    <li
                        key={i}
                        className={'step-item ' + (selectedStep == i ? 'active focus' : '')}
                        onClick={() => {
                            setSelectedStep(i)
                        }}
                    >
                        <a className='step-content-wrapper' href='#'>
                            <span className='step-icon step-icon-soft-dark'>{i + 1}</span>
                            <div className='step-content'>
                                <span className='step-title'>{step?.name}</span>
                            </div>
                        </a>
                    </li>
                ))}
            </ul>
            <div>
                {steps?.map((step, i) => (
                    <div key={i} className={selectedStep == i ? 'active' : 'd-none'}>
                        <div className={'row my-3'}>
                            <div className={'col-12 d-flex justify-content-between'}>
                                {i !== 0 && (
                                    <button
                                        className={'btn btn-outline-secondary'}
                                        onClick={() => {
                                            setSelectedStep(i - 1)
                                        }}
                                    >
                                        <span className={'far fa-chevron-left'} /> Previous
                                    </button>
                                )}
                                {i === steps?.length - 1 ? (
                                    <button
                                        className={'btn btn-primary'}
                                        onClick={() => {
                                            console.log('saving')
                                            save()
                                        }}
                                    >
                                        Create Packet
                                        <i
                                            className={classnames('fal ms-2', {
                                                'fa-spinner-third fa-spin': loading,
                                                'fa-chevrons-right': !loading,
                                            })}
                                        />
                                    </button>
                                ) : (
                                    <button
                                        className={'btn btn-primary ms-auto'}
                                        onClick={() => {
                                            setSelectedStep(i + 1)
                                        }}
                                    >
                                        Next <span className={'far fa-chevron-right'} />
                                    </button>
                                )}
                            </div>
                        </div>
                        {step.component}
                    </div>
                ))}
            </div>
        </div>
    )
}
