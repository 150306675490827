import React, { useContext } from 'react'
import Badge from '../../../../front/components/Badge'
import Table from '../../../../front/components/Table'
import { PortalContext } from '../../../../GlobalState'

export default function InlineChanges({ changes, changesFirst, sectionFilter = 'all' }) {
    const { theme } = useContext(PortalContext)
    let icons = {
        project: 'folder',
        associates: 'user',
        date: 'calendar',
        service: 'clipboard-list',
    }

    return (
        <div className='changes-popup-body'>
            <Table size='sm' borderless>
                {/* <Table.Body> */}
                {changes &&
                    changes.map((section, i) => {
                        if (sectionFilter == 'all' || sectionFilter == section.section) {
                            if (section.change_type != 'none') {
                                return (
                                    <React.Fragment key={i}>
                                        <Table.Row key={`section-${i}`}>
                                            <Table.Td
                                                style={{ textAlign: 'right' }}
                                                className='border-end pt-4'
                                            >
                                                <span
                                                    className={`fas fa-${icons[section?.section]}`}
                                                />
                                                <span className='popup-section-title text-dark'>
                                                    {String(section.label)?.toUpperCase()}
                                                </span>
                                            </Table.Td>
                                            <Table.Td className='pt-4'>
                                                <div className='pt-1 pb-1'>
                                                    {section.change_type == 'delete' && (
                                                        <Badge
                                                            bg='danger'
                                                            soft={theme == 'dark'}
                                                            className='changes-popup-field-value old'
                                                        >
                                                            REMOVED
                                                        </Badge>
                                                    )}
                                                    {section.change_type == 'add' && (
                                                        <Badge
                                                            bg='success'
                                                            soft={theme == 'dark'}
                                                            className='changes-popup-field-value new'
                                                        >
                                                            NEW
                                                        </Badge>
                                                    )}
                                                </div>
                                            </Table.Td>
                                        </Table.Row>
                                        {section.change_type != 'none' &&
                                            section.fields.map((field, j) => {
                                                if (field.change_type != 'none')
                                                    return (
                                                        <Table.Row key={`field-${j}`}>
                                                            <Table.Td className='border-end'>
                                                                <div className='changes-popup-field-label'>
                                                                    <div>{field.label}</div>
                                                                </div>
                                                            </Table.Td>
                                                            <Table.Td>
                                                                <div className='pt-1 pb-1'>
                                                                    {!changesFirst ? (
                                                                        <>
                                                                            {field.old_display !=
                                                                                '' &&
                                                                                section?.change_type !=
                                                                                    'add' && (
                                                                                    <Badge
                                                                                        soft={
                                                                                            theme ==
                                                                                            'dark'
                                                                                        }
                                                                                        bg='success'
                                                                                        className='changes-popup-field-value old'
                                                                                    >
                                                                                        {
                                                                                            field.old_display
                                                                                        }
                                                                                    </Badge>
                                                                                )}
                                                                            {field.change_type ==
                                                                                'change' && (
                                                                                <span className='fas fa-long-arrow-right discard-arrow' />
                                                                            )}
                                                                            {field.new_display !=
                                                                                '' && (
                                                                                <Badge
                                                                                    soft={
                                                                                        theme ==
                                                                                        'dark'
                                                                                    }
                                                                                    bg='danger'
                                                                                    className='changes-popup-field-value new'
                                                                                >
                                                                                    {
                                                                                        field.new_display
                                                                                    }
                                                                                </Badge>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {field.new_display !=
                                                                                '' && (
                                                                                <Badge
                                                                                    soft={
                                                                                        theme ==
                                                                                        'dark'
                                                                                    }
                                                                                    bg='success'
                                                                                    className='changes-popup-field-value new'
                                                                                >
                                                                                    {
                                                                                        field.new_display
                                                                                    }
                                                                                </Badge>
                                                                            )}
                                                                            {field.old_display !=
                                                                                '' &&
                                                                                section?.change_type !=
                                                                                    'add' && (
                                                                                    <Badge
                                                                                        soft={
                                                                                            theme ==
                                                                                            'dark'
                                                                                        }
                                                                                        bg='danger'
                                                                                        className='changes-popup-field-value old'
                                                                                    >
                                                                                        {
                                                                                            field.old_display
                                                                                        }
                                                                                    </Badge>
                                                                                )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </Table.Td>
                                                        </Table.Row>
                                                    )
                                            })}
                                    </React.Fragment>
                                )
                            }
                        }
                    })}
                {/* </Table.Body> */}
            </Table>
        </div>
    )
}
