import { Button } from 'front'
import { Modal } from 'react-bootstrap'

export default function ProjectWarningModal({ onSuccess, ...warningModal }) {
    return (
        <Modal show={warningModal.isOpen} onHide={warningModal.hide}>
            <Modal.Header>
                <Modal.Title>WARNING</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='fw-semibold'>
                    {`Changing this value will require loading a new set of calculations - and will clear all the date specific information (such as completed dates or disengaging tracking) that you have entered`}
                </p>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-between'>
                <Button
                    variant='secondary'
                    ghost
                    onClick={() => {
                        warningModal.hide()
                        if (warningModal.onCancel) warningModal.onCancel()
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant='primary'
                    onClick={() => {
                        if (onSuccess) onSuccess()
                        warningModal.hide()
                    }}
                >
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
