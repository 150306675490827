import React, { useContext, useState } from 'react'
import { Alert, Col, Modal, Row } from 'react-bootstrap'
import { Form } from '../../../../front/'
import Button from '../../../../front/components/Button'
import { usePublishProject } from '../../api/project.api'
import { ProjectContext } from '../../Context/ProjectContext'

export default function PublishModal({ show, onHide }) {
    const { projectId, publishVersionApi, callPublishVersionApi } = useContext(ProjectContext)
    const { mutate: publishProject } = usePublishProject({ projectId })
    const [subject, setSubject] = useState('')
    const [publishToOls, setPublishToOls] = useState(false)

    const publish = () => {
        publishProject({ subject: subject, public: publishToOls }, { onSuccess: onHide })
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Publish Changes</Modal.Title>
                {/* <div><span className="ncs-modal-subtitle">Submit changes to Online Services</span></div> */}
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Row} className='mb-3'>
                    <Form.Label column lg={3}>
                        Subject
                    </Form.Label>
                    <Col>
                        <Form.Control
                            light
                            value={subject}
                            onChange={(e) => {
                                setSubject(e.target.value)
                            }}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3'>
                    <Form.Label column lg={3}>
                        Notify OLS
                    </Form.Label>
                    <Col className='pt-2'>
                        <Form.Check
                            type='switch'
                            checked={publishToOls}
                            onChange={(e) => {
                                setPublishToOls(!publishToOls)
                            }}
                        />
                    </Col>
                </Form.Group>
                {publishVersionApi?.error && (
                    <Alert variant='soft-danger'>
                        <div className='d-flex'>
                            <div className='flex-shrink-0'>
                                <i className='fas fa-circle-exclamation' />
                            </div>
                            <div className='flex-grow-1 ms-2'>Error publishing changes</div>
                        </div>
                    </Alert>
                )}
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <Button onClick={onHide} ghost variant='secondary'>
                    Cancel
                </Button>
                <Button
                    onClick={publish}
                    icon={
                        !publishVersionApi?.loading
                            ? 'fas fa-cloud-upload'
                            : 'fal fa-spinner-third fa-spin'
                    }
                    variant='primary'
                >
                    {!publishVersionApi?.error ? 'Publish' : 'Retry Publish'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
