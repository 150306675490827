// Bootstrap styled file picker button

import React, { Component } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export default class ErrorBubble extends Component {
    render() {
        const { id, classNames, style, onClick, direction, children, faIcon } = this.props

        var errorIcon = (
            <span
                id={id ? id : null}
                className={
                    'fas + fa-' +
                    (faIcon ? faIcon : 'exclamation-circle') +
                    (classNames ? classNames : '')
                }
                style={{ color: '#ff3131' } + style ? style : null}
                onClick={onClick ? onClick : null}
            />
        )

        if (!children) {
            return errorIcon
        } else {
            return (
                <OverlayTrigger
                    overlay={<Tooltip id={Math.random()}>{children}</Tooltip>}
                    placement={direction ? direction : 'top'}
                >
                    {errorIcon}
                </OverlayTrigger>
            )
        }
    }
}
