import classnames from 'classnames'
import { Dropdown as BsDropdown } from 'react-bootstrap'
import Button from './Button'

export default function Dropdown({ ...bsProps }) {
    return <BsDropdown {...bsProps} />
}

export function DropdownButton({
    className,
    style,

    // Button Props
    buttonClassName,
    buttonStyle,
    title,
    icon,
    soft,
    outline,
    ghost,
    variant = 'white',
    children,
    animation,
    bsPrefix,
    size,
    disabled,
    openOnHover,
    noToggleArrow,
    circle,

    // Menu props
    menuRole,
    renderMenuOnMount,
    rootCloseEvent,
    menuVariant,
    flip,
    menuClassName,
    menuStyle,

    // Dropdown props
    ...bsDropdownProps
}) {
    return (
        <BsDropdown {...bsDropdownProps} style={style} className={className}>
            <DropdownToggle
                as={Button}
                icon={icon}
                soft={soft}
                outline={outline}
                ghost={ghost}
                variant={variant}
                circle={circle}
                size={size}
                bsPrefix={bsPrefix}
                disabled={disabled}
                data-bs-toggle='dropdown'
                animation={animation}
                openOnHover={openOnHover}
                noToggleArrow={noToggleArrow}
                className={buttonClassName}
                style={buttonStyle}
            >
                {title}
            </DropdownToggle>
            <BsDropdown.Menu
                role={menuRole}
                renderOnMount={renderMenuOnMount}
                rootCloseEvent={rootCloseEvent}
                variant={menuVariant}
                flip={flip}
                className={menuClassName}
                style={menuStyle}
            >
                {children}
            </BsDropdown.Menu>
        </BsDropdown>
    )
}

function DropdownItem({ icon, children, ...bsProps }) {
    return (
        <BsDropdown.Item {...bsProps}>
            {icon && <i className={classnames(icon, 'dropdown-icon')} />}
            {children}
        </BsDropdown.Item>
    )
}

function DropdownToggle({
    animation = null,
    openOnHover, // TODO: Get this to work
    noToggleArrow,
    className,
    ...bsProps
}) {
    return (
        <BsDropdown.Toggle
            {...bsProps}
            className={classnames(className, { 'no-dropdown-toggle-arrow': noToggleArrow })}
            data-bs-toggle='dropdown'
            data-bs-dropdown-animation={animation ? true : null}
            data-bs-open-on-hover={openOnHover ? true : null}
        />
    )
}

Dropdown.Toggle = DropdownToggle
Dropdown.Menu = BsDropdown.Menu
Dropdown.Item = DropdownItem
Dropdown.Header = BsDropdown.Header
Dropdown.Divider = BsDropdown.Divider
