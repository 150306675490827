import { useEffect, useState } from 'react'
import { Offcanvas } from '../../../front'
import FileList from './FileList'
import FilePreview from './FilePreview'
import AddEditNoteModal from 'tracker/Utils/Notes/AddEditNoteModal'
import { useModalState } from 'hooks'
import { useSelectedTags } from 'tracker/Utils/Notes/useNotes'
import { useReassignTask } from 'tracker/api/task.api'

export default function Dashboard({ files, isLoading, isRefetching, filters }) {
    const [showPreviewOffcanvas, setShowPreviewOffcanvas] = useState(false)
    const [selectedTaskId, setSelectedTaskId] = useState(false)
    const reassignModal = useModalState(false)
    const selectedFile = files?.find((file) => file.task.id === selectedTaskId)
    const selectedTags = useSelectedTags({ task: selectedFile?.task })

    const { mutate: reassignTask, isLoading: reassingTaskLoading } = useReassignTask(
        {
            taskId: selectedFile?.task?.id,
            projectId: selectedFile?.project_id,
            empId: selectedFile?.task?.current_employee_id,
        },
        {
            onSuccess: () => {
                reassignModal.hide()
                queryClient.invalidateQueries({
                    queryKey: ProjectSearch.keys.type(filters),
                })
                queryClient.invalidateQueries({
                    queryKey: ProjectSearch.keys.baseline(filters.employee_id),
                })
                queryClient.invalidateQueries({
                    queryKey: Project.keys.project(selectedFile?.project_id),
                })
            },
        }
    )

    useEffect(() => {
        if (showPreviewOffcanvas && !selectedFile) setShowPreviewOffcanvas(false)
    }, [selectedFile, showPreviewOffcanvas])

    return (
        <div className={'flex-grow-1 overflow-auto'}>
            <div className={'m-3'}>
                <FileList
                    files={files}
                    filters={filters}
                    isLoading={isLoading}
                    isRefetching={isRefetching}
                    showPreviewOffcanvas={() => {
                        setShowPreviewOffcanvas(true)
                    }}
                    setSelectedFile={(file) => {
                        setSelectedTaskId(file.task.id)
                    }}
                    reassignModal={reassignModal}
                />
            </div>
            <Offcanvas
                show={showPreviewOffcanvas}
                onHide={() => {
                    setShowPreviewOffcanvas(false)
                }}
                placement='end'
                style={{ width: '700px' }}
            >
                <FilePreview
                    selectedFile={selectedFile}
                    filters={filters}
                    onHide={() => {
                        setShowPreviewOffcanvas(false)
                    }}
                />
            </Offcanvas>
            <AddEditNoteModal
                title={'Reassign Task'}
                fileInfo={selectedFile}
                show={reassignModal.isOpen}
                saveFn={(data) => {
                    reassignTask(data)
                }}
                tags={selectedTags}
                loading={reassingTaskLoading}
                cancel={reassignModal.hide}
                assigned_emp_id={selectedFile?.task?.current_employee_id}
            />
        </div>
    )
}
