import { Button } from 'front'
import { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import { useUpdatePayable } from 'tracker/api'
import LinkTaskForm from '../LinkTaskForm'

export default function LinkTaskModal({ payable, show, onHide }) {
    const { projectId } = useContext(ProjectContext)
    const { mutate: updatePayable, ...updatePayableApi } = useUpdatePayable(
        { projectId, payableId: payable?.id },
        {
            onSuccess: onHide,
        }
    )
    const [taskSelected, setTaskSelected] = useState(payable?.task_id)

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>Link invoice {payable?.invoice_number} to Task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LinkTaskForm value={taskSelected} name='task_id' onChange={setTaskSelected} />
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-between'>
                <Button ghost variant='secondary' onClick={onHide}>
                    Cancel
                </Button>
                <Button
                    variant={'primary'}
                    loading={updatePayableApi.isLoading}
                    onClick={() => {
                        updatePayable({ task_id: taskSelected })
                    }}
                >
                    Link
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

function taskOption(task) {
    return {
        value: task?.id,
        label: task?._task_code_label,
    }
}
