import { Button } from 'front'
import { useContext, useEffect, useState } from 'react'
import { Alert, ButtonGroup, Modal, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import axios from '../../../axios'
import { Context } from '../Context/GlobalState'
import SideBySide from './history/SideBySide'
import { useRevertToVersion, useVersion, useVersionList } from 'tracker/api/projectVersion.api'
import Collapse from 'react-bootstrap/Collapse'

export default function DiscardModal({ show, onHide }) {
    const { projectId } = useContext(ProjectContext)
    const [isPreviewOpen, setIsPreviewOpen] = useState(false)

    const { data: versionList, ...versionListApi } = useVersionList(projectId)

    const previousPublishId = versionList?.filter(({ status }) => status == 'COMPLETED').pop()?.id
    const { data: previousPublish, ...latestPublishedVersionApi } = useVersion(
        {
            versionId: previousPublishId,
        },
        {
            enabled: show,
        }
    )
    const { mutate: revertToVersion, ...revertToVersionApi } = useRevertToVersion(projectId, {
        onSuccess: onHide,
    })

    let versionToRevertTo = previousPublish

    return (
        <Modal
            show={show}
            onHide={onHide}
            className='ncs-modal transition'
            size={isPreviewOpen ? 'lg' : 'md'}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Revert Changes
                    <br />
                    <span className='ncs-modal-subtitle text-muted'>
                        Revert all un-published changes
                    </span>
                </Modal.Title>
            </Modal.Header>
            <div className='d-flex justify-content-center mb-4'>
                <Button
                    variant='secondary'
                    onClick={() => setIsPreviewOpen(!isPreviewOpen)}
                    icon='fal fa-eye'
                    ghost
                >
                    {isPreviewOpen ? 'Hide preview' : 'Preview changes'}
                    <i
                        className={`far fa-chevron-down transition ms-2`}
                        style={{ rotate: isPreviewOpen ? '0deg' : '180deg' }}
                    />
                </Button>
            </div>
            {versionToRevertTo?.revert_display && (
                <Collapse in={isPreviewOpen}>
                    <Modal.Body className={'mt-5'}>
                        <div className='mt-3'>
                            <div>
                                <div className='d-flex fs-4 mb-2 fw-bold'>
                                    <div className='w-50 text-center'>Unpublished Changes</div>
                                    <div className='w-50 text-center'>Most Recent Publish</div>
                                </div>
                                <SideBySide
                                    changes={versionToRevertTo?.revert_display}
                                    changesFirst
                                />
                            </div>
                        </div>
                    </Modal.Body>
                </Collapse>
            )}
            {!latestPublishedVersionApi.isLoading && !versionToRevertTo?.id && (
                <Alert variant='danger'>Something went wrong</Alert>
            )}
            {revertToVersionApi.isError && (
                <Alert variant='danger'>Something went wrong when reverting</Alert>
            )}
            <Modal.Footer className='justify-content-between'>
                <Button onClick={onHide} ghost variant='secondary'>
                    Cancel
                </Button>
                {versionToRevertTo?.id && (
                    <Button
                        onClick={() => revertToVersion(versionToRevertTo?.id)}
                        icon={'fas fa-undo'}
                        variant='danger'
                        loading={revertToVersionApi.isLoading}
                    >
                        Revert to most recent publish
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    )
}

function doesVersionHaveEdits(version) {
    return (
        version?.revert &&
        Object.values(version?.revert).find((section) => {
            if (typeof section === 'object') section = Object.values(section)
            return section.find((subsection) => subsection?.length > 0)
        })
    )
}
