import { Button } from 'front'
import { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import { useDeletePayable } from 'tracker/api'

export default function DeletePayableModal({ payable, show, onHide }) {
    const { projectId } = useContext(ProjectContext)
    const { mutate: deletePayable, ...deletePayableApi } = useDeletePayable(
        { projectId, payableId: payable?.id },
        {
            onSuccess: onHide,
        }
    )

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Payable</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this payable?</Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <Button ghost variant='secondary' onClick={onHide}>
                    Cancel
                </Button>
                <Button
                    variant='danger'
                    onClick={deletePayable}
                    loading={deletePayableApi.isLoading}
                >
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
