export function popoutWindow(url, title = 'Popout', w = 1000, h = 900) {
    const left = window.screen.width / 2 - w / 2
    const top = window.screen.height / 2 - h / 2

    if (!url) return console.error('URL is required')
    return window.open(
        url,
        title,
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${top}, left=${left}`
    )
}
