import FrontLayout from '../../../front/layouts/FrontLayout'
import useDashboardQueue from '../hooks/useDashboardQueue.js'
import Dashboard from './Dashboard'
import DashboardNav from './DashboardNav'

export default function Index({ children, searchModal }) {
    const {
        activeTab,
        setActiveTab,
        isLoading,
        filters,
        data: searchedFiles,
        setQueueFilters,
        setAdvancedFilters,
        workflowFilter,
        setWorkflowFilter,
        isRefetching,
        startFilters,
        ...rest
    } = useDashboardQueue()
    let list = searchedFiles?.list ?? []
    if (activeTab === 'userQueues' && (workflowFilter?.length > 0 || workflowFilter === null)) {
        list = list?.filter((file) => file?.workflow == workflowFilter)
    }

    return (
        <FrontLayout>
            <DashboardNav
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                aggs={searchedFiles?.aggs || {}}
                isLoading={isLoading}
                isRefetching={isRefetching}
                setQueueFilters={setQueueFilters}
                setAdvancedFilters={setAdvancedFilters}
                filters={filters}
                workflowFilter={workflowFilter}
                setWorkflowFilter={setWorkflowFilter}
                searchModal={searchModal}
                startFilters={startFilters}
            />
            <Dashboard
                files={list}
                isLoading={isLoading}
                filters={filters}
                isRefetching={isRefetching}
            />
        </FrontLayout>
    )
}
