import React from 'react'
import formatDate from '../../formatDate'
import LabelAndDisplay from '../../LabelAndDisplay'
import SortableTable from '../../SortableTable'

export default function SectionDisplay({ section }) {
    switch (section.type) {
        case 'bullet':
            return (
                section?.info?.length > 0 && (
                    <div className={'row'}>
                        <div className={'col'}>
                            <ul>
                                {section.info.map((row, i) => (
                                    <li key={i}>{row?.date ? formatDate(row.value) : row.value}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )
            )
        case 'info':
            return (
                section?.info?.length > 0 && (
                    <div className='d-flex flex-wrap gap-5'>
                        {section.info.map((row, i) => (
                            <div key={i}>
                                <LabelAndDisplay
                                    label={row.label}
                                    display={row?.date ? formatDate(row.value) : row.value}
                                />
                            </div>
                        ))}
                    </div>
                )
            )
        case 'table':
            return <SortableTable {...section} responsive />
        default:
            return false
    }
}
