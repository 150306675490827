export default function compareProjectIds(id1, id2) {
    if (!id1 || !id2) return false
    // Normalize the IDs by removing any leading letters and leading zeros
    const normalizeId = (id) => {
        if (typeof id === 'number') return id.toString()
        return stripOutLetters(id)
    }

    // Compare the normalized IDs
    return normalizeId(id1) === normalizeId(id2)
}

export function stripOutLetters(id) {
    return id.replace(/^[A-Za-z]/, '').replace(/^0+/, '')
}
