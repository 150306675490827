import Cookies from 'js-cookie'
import _ from 'lodash'
import axios from '../../axios'
import formatDate from '../../utils/formatDate'
import { sortList } from './file_list_reducer'
import { getNotes } from './note_reducer'
// console.log('cookie',Cookies.getJSON('employee'))
const employee = getEmployeeFromCookie()
const emp_id = getEmpIdFromEmp()

function getEmployeeFromCookie() {
    return Cookies.getJSON('employee')
}
function getEmpIdFromEmp() {
    const employee = Cookies.getJSON('employee')
    return employee?.EmpID
}

export const FETCH_UCC_LIST = 'fetch_ucc_list'
export const SHOW_MORE = 'show_more'
export const FETCH_UCC_FILE = 'fetch_ucc_file'
export const SORT_UCC_LIST = 'sort_ucc_list'
export const CHANGE_SORT_DIRECTION = 'change_sort_direction'
export const CHANGE_SELECTED = 'change_selected'
export const FETCH_SEARCH_BOX = 'fetch_search_box'
export const FETCH_DEFAULT = 'fetch_default'
export const CHANGE_SELECTED_RANGE = 'change_selected_range'
export const RESET_SEARCH = 'reset_search'
export const SAVE_SEARCH_CHANGE = 'save_search_change'
export const FIND_DIMENSIONS = 'find_dimensions'
export const CHANGE_FILE_LIST_LOADING = 'change_file_list_loading'
export const CHANGE_FILE_DETAIL_LOADING = 'change_file_detail_loading'
export const UPDATE_PARAMETERS = 'update_parameters'
export const NOTE_MODAL = 'note_modal'
export const EDIT_NOTE = 'edit_note'
export const CHANGE_NOTES = 'change_notes'
export const NOTE_MODAL_CHANGE = 'note_modal_change'
export const NOTE_TYPES = 'note_types'
export const ADD_NOTE_TYPE = 'add_note_type'
export const DELETE_NOTE_TYPE = 'delete_note_type'
export const SEARCH_NOTE = 'search_note'
export const RESET_NOTE_SEARCH = 'reset_note_search'
export const UPDATE_FILE = 'update_file'
export const UPDATE_SERVICES = 'update_services'
export const FILE_VIEW = 'file_view'
export const CHANGE_SHRINK = 'change_shrink'
export const NEXT_STAGE_MODAL = 'next_stage_modal'
export const NEXT_STAGE_INFO = 'next_stage_info'
export const RETURN_NEXT_STAGE = 'return_next_stage'
export const NEXT_STAGE_CHANGE = 'next_stage_change'
export const CLEAR_NEXT_STAGE = 'clear_next_stage'
export const CHANGE_FILE_VIEW_LOADING = 'change_file_view_loading'
export const CLOSE_MODAL = 'close_modal'
export const REMOVE_FILE = 'remove_file'
export const EDIT_STAGE_MODAL = 'edit_stage_modal'
export const EDIT_STAGE_INFO = 'edit_stage_info'
export const EDIT_STAGE_CHANGE = 'edit_stage_change'
export const SET_ROLLBACK = 'set_rollback'
export const ROLLBACK_MODAL = 'rollback_modal'
export const CHANGE_PROCESS_ID_ROLLBACK = 'change_process_id_rollback'
export const SHOW_MORE_NOTES = 'show_more_notes'
export const SHOW_ALL_NOTES = 'show_all_notes'
export const SHOW_LESS_NOTES = 'show_less_notes'
export const DELETE_NOTE_MODAL = 'delete_note_modal'
export const DELETE_NOTE_INFO = 'delete_note_info'
export const RESET_NOTES = 'reset_notes'
export const UPDATE_TIME = 'update_time'
export const FOLLOW_UP_MODAL = 'follow_up_modal'
export const FOLLOW_UP_INFO = 'follow_up_info'
export const SKIP_AHEAD_MODAL = 'skip_ahead_modal'
export const SKIP_AHEAD_INFO = 'skip_ahead_info'
export const NOTIFICATION_LETTER_MODAL = 'notification_letter_modal'
export const TRANSMITTAL_MODAL = 'transmittal_modal'
export const LINK_FILES_MODAL = 'link_files_modal'

const CancelToken = axios.CancelToken
let cancel

export function fetchUccList(parameters) {
    if (cancel) {
        cancel()
    }
    return (dispatch, getState) => {
        dispatch(setIsLoading(CHANGE_FILE_LIST_LOADING, true))
        dispatch({ type: UPDATE_TIME })
        let params = parameters ? parameters : getState().search_box.search
        axios
            .get('/portal/ucc/1/search/json', {
                params,
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c
                }),
            })
            .then((list) => {
                // console.log('list',list);
                dispatch(setFileList(list.data))
            })
            .then(() => {
                dispatch(setIsLoading(CHANGE_FILE_LIST_LOADING, false))
            })
            .then(() => {
                let ucc = getState().ucc.selectedUcc
                if (ucc) dispatch(fetchUcc(ucc))
            })
            .then(() => {
                dispatch(getStageTotals())
            })
            .then(resetScroll())
            .catch(function (thrown) {
                if (axios.isCancel(thrown)) {
                    //console.log('Request canceled', thrown);
                }
            })
    }
}

export function resetScroll() {
    const file_list = document.getElementById('file_list')
    if (file_list) {
        file_list.scrollTop = 0
    }
}

export function setFileList(list) {
    return {
        type: FETCH_UCC_LIST,
        list,
    }
}

export function setIsLoading(component, set) {
    return {
        type: component,
        set,
    }
}

export function resetSearch() {
    return (dispatch) => {
        dispatch({ type: RESET_SEARCH })
        dispatch(fetchUccList())
        dispatch(sortBy('first_follow_up'))
        dispatch(changeStageTotalEmp(getEmpIdFromEmp()))
        dispatch(getStageTotals())
        resetScroll()
    }
}

export function updateParameters(parameters) {
    return {
        type: UPDATE_PARAMETERS,
        parameters,
    }
}

export function showMore(end) {
    end += 100

    return {
        type: SHOW_MORE,
        payload: end,
    }
}

export function fetchUcc(file) {
    if (cancel) {
        cancel()
    }
    return (dispatch, getState) => {
        dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING, true))
        axios
            .get(`/portal/ucc/1/file/${file}/json`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c
                }),
            })
            .then(({ data }) => {
                const file_info = getState().ucc.files.filter(
                    (fileRow) => fileRow.UCC_NO === file
                )[0]
                dispatch({
                    type: FETCH_UCC_FILE,
                    ucc_no: file,
                    ...data,
                    file_info,
                })
                dispatch(updateCurrentIn(data.current_in))
                dispatch(setServicesInNotes(data.services))
            })
            .then(() => {
                dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING, false))
            })
            .catch(function (thrown) {
                if (axios.isCancel(thrown)) {
                    //console.log('Request canceled', thrown);
                }
            })
        dispatch(getNotes(file))
    }
}

export const UPDATE_CURRENT_IN = 'update_current_in'
export function updateCurrentIn(current_in) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_CURRENT_IN,
            current_in,
        })
    }
}

export function searchUccNo(ucc_no, emp_id) {
    return (dispatch) => {
        let params = {
            nyb: 0,
            closed: 0,
            end_date: '',
            open: 1,
            search: ucc_no,
            search_emp: emp_id,
            service_code: -1,
            stage_id: '',
            start_date: '',
            state: '',
            range: 11,
            selected_range: 0,
            range_type: 'follow_up',
            emp_id: emp_id,
            inactive: 0,
            send_via: 0,
        }
        dispatch(updateParameters(params))
        dispatch(fetchUccList(params))
    }
}

export function searchByEmpID(search_emp) {
    return (dispatch) => {
        let params = {
            ...initial_default_search,
            search_emp,
        }
        dispatch(updateParameters(params))
        dispatch(fetchUccList(params))
    }
}

export function changeSelected(ucc_no) {
    return {
        type: CHANGE_SELECTED,
        payload: ucc_no,
    }
}

export function sortBy(sort) {
    return (dispatch, getState) => {
        let ucc_state = getState().ucc
        const sort_list = sortList(ucc_state.files, sort, ucc_state.sortDirection)
        const sorted_selected_ucc = sort_list[0].UCC_NO
        dispatch({
            type: SORT_UCC_LIST,
            sort,
            sort_list,
            sorted_selected_ucc,
        })
        dispatch(fetchUcc(sorted_selected_ucc))
    }
}

export function changeSortDirection(direction) {
    let sortDirection = direction == 'asc' ? 'desc' : 'asc'

    return (dispatch, getState) => {
        let ucc_state = getState().ucc
        const sorted_files = sortList(ucc_state.files, ucc_state.sortBy, sortDirection)
        const selectedUcc = sorted_files[0].UCC_NO
        dispatch({
            type: CHANGE_SORT_DIRECTION,
            sorted_files,
            sortDirection,
            selectedUcc,
        })
        dispatch(fetchUcc(selectedUcc))
    }
}

export function fetchSearchBox() {
    return (dispatch) => {
        const checkLocal = window?.localStorage?.getItem('search_box')
        const localSearchBox = checkLocal ? JSON.parse(checkLocal) : false
        const today = new Date()
        const oneDayAgo = today.setDate(today.getDate() - 1)
        if (Date.parse(localSearchBox?.created) > oneDayAgo) {
            dispatch({
                type: FETCH_SEARCH_BOX,
                payload: localSearchBox,
            })
        } else {
            axios.get('/portal/ucc/1/search_box/json').then((searchBox) => {
                dispatch({
                    type: FETCH_SEARCH_BOX,
                    payload: searchBox.data,
                })
            })
        }
    }
}

export function getSavedSearches() {
    return (dispatch) => {
        axios.get(`/portal/ucc/1/saved/searches/${getEmpIdFromEmp()}/json`).then(({ data }) => {
            dispatch(setSavedSearches(data))
        })
    }
}

export const SET_SAVED_SEARCHES = 'set_saved_searches'
export function setSavedSearches(saved_searches) {
    return {
        type: SET_SAVED_SEARCHES,
        saved_searches,
    }
}

export function changeSearch(ref, value, search) {
    let new_search = search
    if (ref == 'open') {
        new_search.nyb = 0
        new_search.requests = 0
        new_search.range_type = 'follow_up'
        new_search.range = 11
        new_search.sortBy = 'first_follow_up'
    } else if (ref == 'nyb') {
        new_search.open = 0
        new_search.requests = 0
        new_search.range_type = 'follow_up'
        new_search.range = 11
        new_search.sortBy = 'first_follow_up'
    } else if (ref === 'requests') {
        new_search.nyb = 0
        new_search.open = 0
        new_search.range_type = 'STATE_EXP'
        new_search.range = 5
        new_search.sortBy = 'STATE_EXP'
    }
    new_search[ref] = value
    return (dispatch) => {
        dispatch(updateParameters(new_search))
        if (ref !== 'search' && ref !== 'start_date' && ref !== 'end_date' && ref !== '') {
            dispatch(fetchUccList(new_search))
        }
    }
}

export function changeSelectedRange(value) {
    return {
        type: CHANGE_SELECTED_RANGE,
        value,
    }
}

export function saveSearch(parameters, set_as_default, name) {
    parameters.default = set_as_default
    if (name) {
        parameters.name = name
    } else {
        parameters.name = formatDate(new Date())
    }
    return (dispatch) => {
        axios.post('/portal/ucc/1/save/search/json', parameters).then((save) => {
            dispatch({
                type: SAVE_SEARCH_CHANGE,
                payload: save,
            })
        })
    }
}

export function changeDefault(id) {
    return (dispatch) => {
        axios
            .patch(`/portal/ucc/1/change/default/${id}/${getEmpIdFromEmp()}/json`)
            .then((change) => {
                dispatch({
                    type: SAVE_SEARCH_CHANGE,
                    payload: change,
                })
            })
    }
}

export function deleteSearch(id) {
    return (dispatch) => {
        axios.delete(`/portal/ucc/1/delete/search/${id}/json`).then((delete_search) => {
            dispatch({
                type: SAVE_SEARCH_CHANGE,
                payload: delete_search,
            })
        })
    }
}

export function selectSavedSearch(saved_search) {
    return (dispatch) => {
        dispatch(updateParameters(saved_search))
        dispatch(fetchUccList(saved_search))
    }
}

export function openModal(modal) {
    return {
        type: modal,
        open: true,
    }
}

export const CLEAR_SKIP_AHEAD = 'clear_skip_ahead'
export function closeModal(modal) {
    return (dispatch) => {
        dispatch({
            type: modal,
            open: false,
        })
        dispatch({ type: CLEAR_NEXT_STAGE })
        dispatch({ type: CLEAR_SKIP_AHEAD })
    }
}

export function changeNotes(notes) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_NOTES,
            notes,
        })
    }
}

export function editNote(id) {
    return (dispatch) => {
        axios
            .get(`/portal/ucc/1/edit/note/${id}/json`)
            .then((notes) => {
                dispatch({
                    type: EDIT_NOTE,
                    data: notes.data,
                })
            })
            .then(dispatch(openModal(NOTE_MODAL)))
    }
}

export function noteModalChange(value, component) {
    return {
        type: NOTE_MODAL_CHANGE,
        value,
        component,
    }
}

export function findNoteTypes() {
    return (dispatch) => {
        axios.get('/portal/ucc/1/note_types/json').then((note_types) => {
            dispatch({
                type: NOTE_TYPES,
                note_types: note_types.data,
            })
        })
    }
}

export function addNoteType(note_type_id) {
    return {
        type: ADD_NOTE_TYPE,
        note_type_id,
    }
}

export function saveNote(note) {
    return (dispatch) => {
        if (_.toInteger(note.id) > 0) {
            axios.put('/portal/ucc/1/update/note/json', note).then((response) => {
                dispatch(changeNotes(response.data.notes))
                dispatch(updateFile(response.data.file))
            })
        } else {
            note['emp_id'] = getEmpIdFromEmp()
            axios.post('/portal/ucc/1/add/note/json', note).then((response) => {
                dispatch(changeNotes(response.data.notes))
                dispatch(updateFile(response.data.file))
            })
        }
    }
}

export function searchNotes(search) {
    return (dispatch) => {
        dispatch(updateSearchNote(search))
        axios.get('/portal/ucc/1/search/notes/json', { params: search }).then((notes) => {
            dispatch(changeNotes(notes.data))
        })
    }
}

export function updateSearchNote(search) {
    return {
        type: SEARCH_NOTE,
        search,
    }
}

export function resetSearchNote(ucc_no) {
    return (dispatch) => {
        dispatch({ type: RESET_NOTE_SEARCH })
        dispatch(searchNotes({ note_type: 0, emp_id: 0, search: '', ucc_no }))
    }
}

export function makePriority(serv_id) {
    return (dispatch) => {
        dispatch(setIsLoading(CHANGE_FILE_LIST_LOADING, true))
        axios
            .patch(`/portal/ucc/1/update/priority/${serv_id}/json`)
            .then((services) => {
                dispatch(updateServices(services.data.services))
                dispatch({
                    type: UPDATE_FILE,
                    file: services.data.info,
                })
            })
            .then(() => {
                dispatch(setIsLoading(CHANGE_FILE_LIST_LOADING, false))
            })
    }
}

export function saveNextStep(edit_stage, file_view_ucc_no = null) {
    edit_stage['updated_by'] = emp_id
    return (dispatch) => {
        axios
            .patch('/portal/ucc/1/update/completed/json', edit_stage)
            .then((services) => {
                dispatch(updateServices(services.data.services))
                dispatch(updateStageTotals(services.data.stage_totals))
                dispatch(getStageTotals())
                if (file_view_ucc_no) {
                    dispatch(fetchFileView(file_view_ucc_no))
                }
            })
            .then(dispatch(closeModal(NEXT_STAGE_MODAL)))
        // .then(()=>{dispatch(getStageTotals())});
    }
}

export function updateServices(services) {
    return {
        type: UPDATE_SERVICES,
        services,
    }
}

export const UPDATE_COMPLETED_SERVICES = 'update_completed_services'
export function updateCompletedServices(services) {
    return {
        type: UPDATE_COMPLETED_SERVICES,
        services,
    }
}

export function fetchFileView(ucc_no) {
    return (dispatch) => {
        dispatch(setIsLoading(CHANGE_FILE_VIEW_LOADING, true))
        axios
            .get(`/portal/ucc/1/file_view/${ucc_no}/json`)
            .then((file_view) => {
                dispatch({
                    type: FILE_VIEW,
                    file_view: file_view.data,
                })
            })
            .then(() => {
                dispatch(setIsLoading(CHANGE_FILE_VIEW_LOADING, false))
            })
    }
}

export const NEXT_STAGE_LOADING = 'next_stage_loading'
export function nextStage(process_id, file_view_ucc_no = null) {
    return (dispatch) => {
        dispatch({ type: CLEAR_NEXT_STAGE })
        dispatch(setIsLoading(NEXT_STAGE_LOADING, true))
        axios
            .get(`/portal/ucc/1/next/stage/${process_id}/json`)
            .then((next_stage) => {
                dispatch({
                    type: NEXT_STAGE_INFO,
                    next_stage: next_stage.data,
                    file_view_ucc_no,
                })
            })
            .then(() => {
                dispatch(setIsLoading(NEXT_STAGE_LOADING, false))
            })
    }
}

export function returnNextStage() {
    return {
        type: RETURN_NEXT_STAGE,
    }
}

export function nextStageChange(item_to_change, value) {
    return {
        type: NEXT_STAGE_CHANGE,
        item_to_change,
        value,
    }
}

export function editStageChange(item_to_change, value) {
    return {
        type: EDIT_STAGE_CHANGE,
        item_to_change,
        value,
    }
}

export function openCloseModal(process_id, file_view_ucc_no = null) {
    return {
        type: CLOSE_MODAL,
        open: true,
        process_id,
        file_view_ucc_no,
    }
}

export function updateFile(file) {
    return {
        type: UPDATE_FILE,
        file,
    }
}

export function setEditStageInfo(stage_info) {
    return {
        type: EDIT_STAGE_INFO,
        stage_info,
    }
}

export function editStageModal(process_id) {
    return (dispatch) => {
        dispatch(setEditStageInfo({}))
        axios.get(`/portal/ucc/1/stage/info/${process_id}/json`).then((stage_info) => {
            dispatch(setEditStageInfo(stage_info))
        })
    }
}

export function saveEditStage(stage_info) {
    return (dispatch) => {
        axios
            .put(`/portal/ucc/1/edit/stage/info/json`, {
                ...stage_info,
                edit_updated_by: getEmpIdFromEmp(),
            })
            .then((services) => {
                dispatch(updateServices(services.data))
            })
            .then(dispatch(setEditStageInfo({})))
            .then(dispatch(getStageTotals()))
    }
}

export function getRollback(process_id) {
    return (dispatch) => {
        dispatch(openModal(ROLLBACK_MODAL))
        dispatch(setRollback({}))
        axios.get(`/portal/ucc/1/rollback/${process_id}/json`).then((stages) => {
            dispatch(setRollback(stages.data))
        })
    }
}

export function setRollback(stages) {
    return {
        type: SET_ROLLBACK,
        stages,
    }
}

export const CHANGE_ROLLBACK_EMP = 'change_rollback_emp'
export function changeRollbackEmp(emp_id) {
    return {
        type: CHANGE_ROLLBACK_EMP,
        emp_id,
    }
}

export function rollbackStage(process_id, follow_up, emp_id) {
    return (dispatch) => {
        axios
            .patch(
                `/portal/ucc/1/stage/rollback/${process_id}/${getEmpIdFromEmp()}/${follow_up}/${emp_id}/json`
            )
            .then((services) => {
                dispatch(updateServices(services.data.service))
                dispatch(updateStageTotals(services.data.stage_totals))
            })
            .then(dispatch(closeModal(ROLLBACK_MODAL)))
            .then(dispatch(setRollback({})))
        // .then(dispatch(getStageTotals()));
    }
}

export function changeProcessIdRollback(process_id) {
    return {
        type: CHANGE_PROCESS_ID_ROLLBACK,
        process_id,
    }
}

export function closeStage(process_id, file_view_ucc_no) {
    return (dispatch) => {
        dispatch(setIsLoading(CHANGE_FILE_LIST_LOADING, true))
        axios
            .patch(`/portal/ucc/1/complete/service/${process_id}/${emp_id}/json`)
            .then((services) => {
                dispatch(updateServices(services.data.services))
                dispatch(updateFile(services.data.file_info))
                if (file_view_ucc_no) {
                    dispatch(fetchFileView(file_view_ucc_no))
                }
            })
            .then(dispatch(closeModal(CLOSE_MODAL)))
            .then(() => {
                dispatch(setIsLoading(CHANGE_FILE_LIST_LOADING, false))
            })
    }
}

export function showMoreNotes() {
    return {
        type: SHOW_MORE_NOTES,
    }
}

export function showAllNotes(note_length) {
    return {
        type: SHOW_ALL_NOTES,
        note_length,
    }
}

export function showLessNotes() {
    return {
        type: SHOW_LESS_NOTES,
    }
}

export function openDeleteNoteModal(note, ucc_no) {
    return (dispatch) => {
        dispatch(openModal(DELETE_NOTE_MODAL))
        dispatch({
            type: DELETE_NOTE_INFO,
            note,
            ucc_no,
        })
    }
}

export function resetNotes() {
    return {
        type: RESET_NOTES,
    }
}

export function getFollowUps() {
    return (dispatch) => {
        axios.get(`/portal/ucc/1/follow_up/${emp_id}/json`).then((follow_ups) => {
            dispatch(updateFollowUps(follow_ups.data))
        })
    }
}

export function updateFollowUps(follow_ups) {
    return {
        type: FOLLOW_UP_INFO,
        follow_ups,
    }
}

export const SKIP_AHEAD_LOADING = 'skip_ahead_loading'
export function skipAhead(process_id) {
    return (dispatch) => {
        dispatch(setIsLoading(SKIP_AHEAD_LOADING, true))
        axios
            .get(`/portal/ucc/1/skip/${process_id}/${emp_id}/json`)
            .then((skip_ahead) => {
                dispatch({
                    type: SKIP_AHEAD_INFO,
                    info: skip_ahead.data,
                })
            })
            .then(() => {
                dispatch(setIsLoading(SKIP_AHEAD_LOADING, false))
            })
    }
}

export const EDIT_SKIP_AHEAD = 'edit_skip_ahead'
export function editSkipAhead() {
    return {
        type: EDIT_SKIP_AHEAD,
    }
}

export const RETURN_SKIP_AHEAD = 'return_skip_ahead'
export function returnSkipAhead() {
    return {
        type: RETURN_SKIP_AHEAD,
    }
}

export const CHANGE_SKIP_AHEAD_EMP = 'change_skip_ahead_emp'
export function changeSkipAheadEmp(e) {
    return {
        type: CHANGE_SKIP_AHEAD_EMP,
        e,
    }
}

export function saveSkipAhead(info = null, complete = 0) {
    return (dispatch, getState) => {
        if (!info) {
            info = getState().next_stage.selected
        }
        info['updated_by'] = getEmpIdFromEmp()
        info['complete'] = complete
        if (!info.emp_id) {
            info.emp_id = getEmpIdFromEmp()
        }
        if (info?.follow_up) {
            info.follow_up = formatDate(info.follow_up, 'YYYY-MM-DD')
        }
        dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING, true))
        axios
            .post(`/portal/ucc/1/save/skip/json`, info)
            .then((services) => {
                dispatch(updateServices(services.data.services))
                dispatch(updateStageTotals(services.data.stage_totals))
                dispatch(getStageTotals())
            })
            .then(dispatch(closeModal(SKIP_AHEAD_MODAL)))
            .then(() => {
                dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING, false))
            })
    }
}

export function insertFromPortal(ucc_no) {
    return (dispatch) => {
        dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING, true))
        axios
            .post(`/portal/ucc/1/portal/insert/json`, {
                ucc_no: ucc_no,
                updated_by: getEmpIdFromEmp(),
            })
            .then(({ data }) => {
                dispatch(replaceServices(data.services))
                dispatch(replaceCompletedServices(data.completed_services))
            })
            .then(() => {
                dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING, false))
            })
    }
}

export function preparedByClient(serv_id) {
    return (dispatch) => {
        dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING, true))
        axios
            .put(`/portal/ucc/1/prepared_by_client/${serv_id}/${getEmpIdFromEmp()}/json`)
            .then((services) => {
                dispatch(replaceServices(services.data.services))
                dispatch(updateStageTotals(services.data.stage_totals))
            })
            .then(dispatch(closeModal(CLOSE_MODAL)))
            .then(() => {
                dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING, false))
            })
    }
}

export const CHANGE_SKIP_FOLLOW_UP = 'change_skip_follow_up'
export function changeSkipFollowUp(follow_up) {
    return {
        type: CHANGE_SKIP_FOLLOW_UP,
        follow_up,
    }
}

export const LOG_DEFAULT = 'log_default'
export function logDefault() {
    return (dispatch) => {
        axios.get(`/portal/ucc/1/log/options/json`).then((options) => {
            dispatch({
                type: LOG_DEFAULT,
                range_options: options.data.range_options,
                stages: options.data.stages,
            })
        })
    }
}

export const MANAGEMENT_INFO = 'management_info'
export const LOG_LOADING = 'log_loading'
export function searchLog(post) {
    return (dispatch, getState) => {
        dispatch(setIsLoading(LOG_LOADING, true))
        axios
            .post(`/portal/ucc/1/management/log/json`, post)
            .then((log) => {
                if (log.data.result == false) {
                    post['emp_id'] = getEmpIdFromEmp()
                    dispatch(selectEmp(post))
                } else if (window?.admin > 14 && window?.admin < 1) {
                    dispatch(selectEmp(post))
                } else {
                    let selected_emp = getState().log.selected_emp
                    if (selected_emp) {
                        dispatch(selectEmp(post))
                    } else {
                        post['emp_id'] = log.data[0].emp_id
                        dispatch(selectEmp(post))
                    }
                }
                dispatch({
                    type: MANAGEMENT_INFO,
                    management_info: log.data,
                })
            })
            .then(() => {
                dispatch(setIsLoading(LOG_LOADING, false))
            })
    }
}

export const CHANGE_LOG_RANGE = 'change_log_range'
export function changeLogRange(value) {
    return {
        type: CHANGE_LOG_RANGE,
        value,
    }
}

export const SELECT_EMP = 'select_emp'
export const LOG_INFO = 'log_info'
export function selectEmp(post) {
    return (dispatch) => {
        dispatch(setIsLoading(LOG_LOADING, true))
        dispatch({
            type: SELECT_EMP,
            selected_emp: post.emp_id,
        })
        axios
            .post('/portal/ucc/1/emp/log/json', post)
            .then((log) => {
                dispatch({
                    type: LOG_INFO,
                    log: log.data,
                })
            })
            .then(() => {
                dispatch(setIsLoading(LOG_LOADING, false))
            })
    }
}

export function unclose(process_id) {
    return (dispatch) => {
        dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING, true))
        axios
            .put(`/portal/ucc/1/unclose/${process_id}/${getEmpIdFromEmp()}/json`)
            .then((service) => {
                dispatch(updateServices(service.data.service))
                dispatch(updateStageTotals(services.data.stage_totals))
                // dispatch(getStageTotals());
            })
            .then(() => {
                dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING, false))
            })
    }
}

export function findDimensions() {
    // console.log('finding dimensions')
    return (dispatch) => {
        let height = window?.innerHeight
        let width = window?.innerWidth
        let header = document.getElementById('ucc_top')?.offsetTop
        let footer = document.getElementById('footer')
        footer = footer ? footer.clientHeight : 50
        let search_box = document.getElementById('ucc_search_box')
        if (search_box) {
            search_box = search_box.clientHeight
            let subtract_height = document.getElementById('subtract_height')
            if (!subtract_height) {
                subtract_height = 41
            } else {
                subtract_height = subtract_height.clientHeight
            }
            let file_list_height = height - header - footer - 50
            // console.log('file list height',file_list_height,height,header,footer)

            let file_detail_height = file_list_height
            if (width <= 985) {
                // console.log('small width')
                file_list_height = 350
                if (height > 1000) {
                    file_detail_height = height - header - footer - 50
                } else {
                    file_detail_height = 20000
                }
            } else {
                // console.log('big width')
                if (file_list_height < search_box) {
                    file_list_height = search_box - subtract_height
                    file_detail_height = search_box - subtract_height
                }
            }

            dispatch({
                type: FIND_DIMENSIONS,
                file_list_height,
                file_detail_height,
            })
        }
    }
}

export function change_shrink(shrink) {
    return (dispatch) => {
        let new_shrink = shrink == 1 ? 0 : 1
        dispatch({
            type: CHANGE_SHRINK,
            new_shrink,
        })
    }
}

export const FIND_SERVICE = 'find_service'
export function findService(serv_id) {
    return (dispatch) => {
        dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING, true))
        axios
            .get(`/portal/ucc/1/service/${serv_id}/json`)
            .then((service) => {
                // console.log('service',service);
                dispatch({
                    type: FIND_SERVICE,
                    service: service.data.service,
                    ucc_no: service.data.ucc_no,
                })
                if (!service.data.service.result) {
                    dispatch(setFileList([service.data.file]))
                }
            })
            .then(() => {
                dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING, false))
            })
    }
}

export const SEARCH_NOTIFICATION_LETTERS = 'search_notification_letters'
export function searchNotificationLetters(search) {
    return {
        type: SEARCH_NOTIFICATION_LETTERS,
        search,
    }
}

export const CLEAR_LETTER_SEARCH = 'clear_letter_search'
export function clearLetterSearch() {
    return {
        type: CLEAR_LETTER_SEARCH,
    }
}

export const SET_NOTIFICATION_LETTERS = 'set_notification_letters'
export const NOTIFICATION_LETTER_LOADING = 'notification_letter_loading'
export function getNotificationLetters(ucc_no, serv_id) {
    return (dispatch) => {
        dispatch(setIsLoading(NOTIFICATION_LETTER_LOADING, true))
        axios
            .get(`/portal/ucc/1/letters/${ucc_no}/${serv_id}/json`)
            .then((letters) => {
                dispatch({
                    type: SET_NOTIFICATION_LETTERS,
                    letters: letters.data,
                })
            })
            .then(() => {
                dispatch(setIsLoading(NOTIFICATION_LETTER_LOADING, false))
            })
    }
}

export const CHANGE_LOG_SORT = 'change_log_sort'
export function changeLogSort(sort) {
    return {
        type: CHANGE_LOG_SORT,
        sort,
    }
}

export const FLIP_LOG_SORT = 'flip_log_sort'
export function flipLogSort() {
    return {
        type: FLIP_LOG_SORT,
    }
}

export const REVIEW_NOTIFICATION_LETTER = 'review_notification_letter'
export function review_notification_letter(viewed_id, viewed) {
    return (dispatch) => {
        let set_viewed = viewed == 1 ? 0 : 1
        axios
            .patch(`/portal/ucc/1/review/notification/letter/${viewed_id}/${set_viewed}/json`)
            .then((viewed) => {
                dispatch({
                    type: REVIEW_NOTIFICATION_LETTER,
                    viewed: viewed.data,
                })
            })
    }
}

export const UPDATE_FOLLOW_UP = 'update_follow_up'
export function updateFollowup(process_id) {
    return (dispatch) => {
        axios.get(`/portal/ucc/1/update/follow_up/${process_id}/json`).then((follow_up) => {
            dispatch({
                type: UPDATE_FOLLOW_UP,
                follow_up: follow_up.data,
            })
        })
    }
}

export const PASS_TRANSMITTAL = 'pass_transmittal'
export function passTransmittal(transmittal) {
    return {
        type: PASS_TRANSMITTAL,
        transmittal,
    }
}

export function addAttachment(ucc_no) {
    return (dispatch) => {
        axios.post(`/portal/ucc/1/add_attachment/${ucc_no}/json`).then((ucc) => {
            dispatch(updateFile(ucc.data.file))
            dispatch(updateServices(ucc.data.services))
        })
    }
}

export function addDocument(ucc_no) {
    return (dispatch) => {
        axios.post(`/portal/ucc/1/add_document/${ucc_no}/json`).then((ucc) => {
            dispatch(updateFile(ucc.data.file))
            dispatch(updateServices(ucc.data.services))
        })
    }
}

export const LINK_FILES_LOADING = 'link_files_loading'
export const CLEAR_LINK_FILES = 'clear_link_files'
export function linkFiles(ucc_no) {
    return (dispatch) => {
        dispatch(openModal(LINK_FILES_MODAL))
        dispatch(linkFilesApi(ucc_no))
    }
}

export function linkFilesApi(ucc_no) {
    return (dispatch) => {
        dispatch({ type: CLEAR_LINK_FILES })
        dispatch(setIsLoading(LINK_FILES_LOADING, true))
        axios
            .get(`/portal/ucc/1/linked_files/${ucc_no}/json`)
            .then((linked_files) => {
                if (linked_files.data.result) {
                    dispatch(updateLinkInfo({}))
                } else {
                    dispatch(updateLinkInfo(linked_files.data))
                }
            })
            .then(() => {
                setIsLoading(LINK_FILES_LOADING, false)
            })
    }
}

export const LINK_FILES = 'link_files'
export function updateLinkInfo(info) {
    return {
        type: LINK_FILES,
        info,
    }
}

export const CHANGE_GROUP_NAME = 'change_group_name'
export function changeGroupName(value) {
    return {
        type: CHANGE_GROUP_NAME,
        value,
    }
}

export const LINK_FILES_SEARCH = 'link_files_search'
export const LINK_FILE_SEARCH_LOADING = 'link_file_search_loading'
export const UPDATE_SEARCH_TEXT = 'update_search_text'
export function linkFilesSearch(ucc_no) {
    if (cancel) {
        cancel()
    }
    return (dispatch) => {
        dispatch({
            type: UPDATE_SEARCH_TEXT,
            ucc_no,
        })
        dispatch(setIsLoading(LINK_FILE_SEARCH_LOADING, true))
        axios
            .get(`/portal/ucc/1/search/link_files/${ucc_no}/json`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c
                }),
            })
            .then((search) => {
                dispatch({
                    type: LINK_FILES_SEARCH,
                    search: search.data,
                })
            })
            .then(() => {
                dispatch(setIsLoading(LINK_FILE_SEARCH_LOADING, false))
            })
            .catch(function (thrown) {
                if (axios.isCancel(thrown)) {
                    //console.log('canceled link file search', thrown);
                }
            })
    }
}

export const PUSH_UCC_NO = 'add_ucc_no'
export function addUccNo(ucc_no, group_id) {
    if (group_id) {
        return (dispatch) => {
            axios
                .get(`/portal/ucc/1/group/linked_files/${group_id}/json`)
                .then((linked_files_group) => {
                    dispatch(addLinkFilesGroup(linked_files_group.data))
                })
        }
    } else {
        return {
            type: PUSH_UCC_NO,
            ucc_no,
        }
    }
}

export const ADD_LINK_GROUP = 'add_link_group'
export function addLinkFilesGroup(group) {
    return {
        type: ADD_LINK_GROUP,
        group,
    }
}

export const REMOVE_LINK = 'remove_link'
export function removeLink(ucc_no) {
    return {
        type: REMOVE_LINK,
        ucc_no,
    }
}

export const UPDATE_LINKED_FILES = 'update_link_files'
export function updateLinkedFiles(linked_files) {
    return {
        type: UPDATE_LINKED_FILES,
        linked_files,
    }
}

export function saveLinkFiles(info, ucc_no) {
    info['ucc_no'] = ucc_no
    return (dispatch) => {
        axios
            .post('/portal/ucc/1/save/linked_files/json', info)
            .then((ucc) => {
                ucc.data.files.map((row) => {
                    dispatch(updateFile(row))
                })
                dispatch(updateLinkedFiles(ucc.data.linked_files))
            })
            .then(() => {
                dispatch(closeModal(LINK_FILES_MODAL))
            })
    }
}

export function searchLogWithCustomRange(start_date, end_date) {
    return (dispatch) => {
        dispatch(setIsLoading(LOG_LOADING, true))
        axios
            .get(`/portal/ucc/1/log/custom_range/${start_date}/${end_date}/json`)
            .then((log) => {
                if (log.data.result == false) {
                    dispatch(selectEmp(0))
                } else if (window?.admin > 14 && window?.admin < 1) {
                    dispatch(selectEmp(emp_id))
                } else {
                    let selected_emp = getState().log.selected_emp
                    if (selected_emp) {
                        dispatch(selectEmp(selected_emp, range))
                    } else {
                        dispatch(selectEmp(log.data[0].emp_id, range))
                    }
                    dispatch({
                        type: MANAGEMENT_INFO,
                        management_info: log.data,
                    })
                }
            })
            .then(() => {
                dispatch(setIsLoading(LOG_LOADING, false))
            })
    }
}

export const GET_STAGE_TOTALS = 'get_stage_totals'
export const STAGE_TOTALS_LOADING = 'stage_totals_loading'
export function getStageTotals() {
    return (dispatch, getState) => {
        const emp_id = getState().stage_totals.selected
        dispatch(setIsLoading(STAGE_TOTALS_LOADING, true))
        dispatch(getAlerts())
        axios
            .get(`/portal/ucc/1/stage_totals/${emp_id}/json`)
            .then((stage_totals) => {
                dispatch(updateStageTotals(stage_totals.data))
            })
            .then(() => {
                dispatch(setIsLoading(STAGE_TOTALS_LOADING, false))
            })

        axios.get(`/portal/ucc/1/nyb/${emp_id}/json`).then((nyb) => {
            dispatch(updateNYB(nyb.data))
        })
    }
}

export function updateStageTotals(stage_totals) {
    return {
        type: GET_STAGE_TOTALS,
        stage_totals,
    }
}

export const SET_NYB = 'set_nyb'
export function updateNYB(nyb) {
    if (nyb.result !== false) {
        return {
            type: SET_NYB,
            nyb,
        }
    } else {
        return {
            type: SET_NYB,
            nyb: false,
        }
    }
}

export const initial_default_search = {
    nyb: 0,
    closed: 0,
    end_date: '',
    open: 1,
    search: '',
    search_emp: getEmpIdFromEmp(),
    service_code: -1,
    stage_id: '',
    start_date: '',
    state: '',
    range: 11,
    selected_range: 0,
    range_type: 'follow_up',
    emp_id: getEmpIdFromEmp(),
    inactive: 0,
    send_via: 0,
    requests: 0,
}
export function stageFilter(stage_id, emp_id) {
    let params = {
        ...initial_default_search,
    }
    params.search_emp = emp_id
    if (stage_id == -1) {
        params.range = 13
        params.stage_id = 0
    } else if (stage_id == -2) {
        params.nyb = 1
        params.open = 0
    } else {
        params.stage_id = stage_id
    }
    return (dispatch) => {
        dispatch(updateParameters(params))
        dispatch(fetchUccList(params))
    }
}

export function searchLinkedFiles(ucc_nos) {
    return (dispatch, getState) => {
        let change_search = getState().search_box.search
        change_search.search = ucc_nos.join('+')
        dispatch(updateParameters(change_search))
        dispatch(fetchUccList(change_search))
    }
}

export const SNOOZE_PROJECT = 'snooze_project'
export const SNOOZE_MODAL = 'snooze_modal'
export function snoozeProject(ucc_no) {
    return (dispatch) => {
        dispatch(openModal(SNOOZE_MODAL))
        axios.get(`/portal/ucc/1/snooze/project/${ucc_no}/json`).then((open_service) => {
            dispatch({
                type: SNOOZE_PROJECT,
                open_service: open_service.data,
            })
        })
    }
}

export const SNOOZE_FOLLOW_UP_CHANGE = 'snooze_follow_up_change'
export function snoozeFollowUpChange(follow_up) {
    return {
        type: SNOOZE_FOLLOW_UP_CHANGE,
        follow_up,
    }
}

export function saveSnooze(post) {
    return (dispatch) => {
        dispatch(closeModal(SNOOZE_MODAL))
        axios.post('/portal/ucc/1/snooze/project/json', post).then((services) => {
            dispatch(replaceServices(services.data))
        })
    }
}

export const REPLACE_SERVICES = 'replace_services'
export function replaceServices(services) {
    return {
        type: REPLACE_SERVICES,
        services,
    }
}

export const REPLACE_COMPLETED_SERVICES = 'replace_completed_services'
export function replaceCompletedServices(completed_services) {
    return {
        type: REPLACE_COMPLETED_SERVICES,
        completed_services,
    }
}

export const CHANGE_SNOOZED_CHECKED = 'change_snoozed_checked'
export function changeSnoozeChecked(key, checked) {
    return {
        type: CHANGE_SNOOZED_CHECKED,
        key,
        checked,
    }
}

export const CHANGE_STAGE_TOTAL_EMP = 'change_stage_total_emp'
export function changeStageTotalEmp(emp_id) {
    return {
        type: CHANGE_STAGE_TOTAL_EMP,
        emp_id,
    }
}
export function selectStageTotalEmp(emp_id) {
    return (dispatch) => {
        dispatch(searchByEmpID(emp_id))
        dispatch(updateStageTotals([]))
        dispatch(updateNYB({ result: false }))
        dispatch(changeStageTotalEmp(emp_id))
        dispatch(getStageTotals())
    }
}

export function fileXml(serv_id) {
    axios.post(`/portal/ucc/1/file_xml/${serv_id}/json`).then(({ data }) => {
        console.log('file xml', data)
    })
}

export function getAlerts(emp_id = getEmpIdFromEmp()) {
    return (dispatch) => {
        axios.get(`/portal/ucc/1/alerts/${emp_id}/json`).then(({ data }) => {
            dispatch(setAlerts(data))
            // console.log('alerts',data)
        })
    }
}

export function listenForAlerts() {
    return (dispatch) => {
        socket?.on?.('alerts', (data) => {
            console.log('getting alerts', data)
            dispatch(setAlerts(data))
        })
    }
}

export const ALERTS = 'alerts'
export function setAlerts(alerts) {
    return {
        type: ALERTS,
        alerts,
    }
}

export function dismissAlert(id, ucc_no, emp_id) {
    return (dispatch) => {
        axios
            .patch(`/portal/ucc/1/dismiss/alert/${id}/${emp_id}/json`)
            .then(({ data }) => {
                dispatch(setAlerts(data))
            })
            .then(() => {
                dispatch(getNotes(ucc_no))
            })
    }
}

export function saveReply(replyText, alert) {
    alert.replyText = replyText
    alert.reply_emp = getEmpIdFromEmp()
    console.log('sending reply', alert)
    return (dispatch) => {
        axios.post('/portal/ucc/1/reply/alert/json', alert).then(({ data }) => {
            dispatch(setAlerts(data))
        })
    }
}

export const OPEN_DOCUWARE_MODAL = 'open_docuware_modal'
export function openDocuwareModal(ucc_no, serv_id) {
    // console.log({ucc_no, service})
    return (dispatch) => {
        // dispatch(openDocuware("ucc", ucc_no, serv_id));
        dispatch(openModal(OPEN_DOCUWARE_MODAL))
    }
}

export const CLOSE_DOCUWARE_MODAL = 'close_docuware_modal'
export function closeDocuwareModal() {
    return (dispatch) => {
        dispatch(closeModal(CLOSE_DOCUWARE_MODAL))
    }
}

export function isEmpty(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) return false
    }
    return true
}

export const ERROR_DETAIL = 'error_detail'
export const NOTE_SERVICES = 'note_services'
export function setServicesInNotes(services) {
    return (dispatch) => {
        dispatch({
            type: NOTE_SERVICES,
            services,
        })
    }
}
export function uFileView(service_id) {
    return (dispatch) => {
        axios
            .get(`/portal/ucc/1/service/${service_id}/${getEmpIdFromEmp()}/json`)
            .then((file_info) => {
                const { ucc_no, error } = file_info.data
                if (error) {
                    dispatch({
                        type: ERROR_DETAIL,
                        error,
                    })
                } else {
                    dispatch({
                        type: FETCH_UCC_FILE,
                        ucc_no,
                        ...file_info.data,
                        file_info: file_info.data.file,
                    })
                    dispatch(setFileList([file_info.data.file]))
                    dispatch(getNotes(ucc_no))
                    // dispatch(getDocuware("ucc", ucc_no, service_id))
                    dispatch(setServicesInNotes(file_info.data.note_services))
                    dispatch(setIsLoading(CHANGE_FILE_DETAIL_LOADING))
                }
            })
    }
}

export function keyboardPress(press) {
    return (dispatch, getState) => {
        const state = getState().ucc
        let current_index = _.findIndex(state.files, ['UCC_NO', state.selectedUcc])
        if (press == 'down' && current_index != state.files.length - 1) {
            if (state.end - 1 > current_index) {
                current_index++
                const selectedUcc = state.files[current_index].UCC_NO
                dispatch(moveSelected(selectedUcc))
            } else if (state.end < state.files.length) {
                dispatch(showMore(state.end))
                current_index++
                const selectedUcc = state.files[current_index].UCC_NO
                dispatch(moveSelected(selectedUcc))
            }
        } else if (press == 'up') {
            if (current_index > 0) {
                current_index--
                const selectedUcc = state.files[current_index].UCC_NO
                dispatch(moveSelected(selectedUcc))
            }
        }
    }
}

function scrollToSelectedUcc(ucc_no) {
    const file_list = document.getElementById('file_list')
    const file = document.getElementById(ucc_no)
    if (file_list) {
        file_list.scrollTo(0, file.offsetTop - 150)
    }
}

export const MOVE_SELECTED = 'move_selected'
export function moveSelected(selectedUcc) {
    return (dispatch) => {
        scrollToSelectedUcc(selectedUcc)
        dispatch(openFile(selectedUcc))
        dispatch({
            type: MOVE_SELECTED,
            selectedUcc,
        })
    }
}

export const MOVE_UP_LIST = 'move_up_list'
export function moveUpList() {
    return {
        type: MOVE_UP_LIST,
    }
}

export function openFile(ucc_no) {
    return (dispatch, getState) => {
        // console.log('get state',getState())
        if (getState().ucc.selectedUcc != ucc_no) {
            dispatch(changeSelected(ucc_no))
            dispatch(linkFilesApi(ucc_no))
            dispatch(fetchUcc(ucc_no))
        }
    }
}

export const NEXT_STAGE_NEW_LOADING = 'next_stage_new_loading'
export const NEXT_STAGE_NEW_INFO = 'next_stage_new_info'
export function nextStageInfo(serv_id) {
    return (dispatch) => {
        dispatch(setIsLoading(NEXT_STAGE_NEW_LOADING, true))
        axios
            .get(`/portal/ucc/1/next/stages/${serv_id}/${getEmpIdFromEmp()}/json`)
            .then(({ data }) => {
                dispatch({
                    type: NEXT_STAGE_NEW_INFO,
                    data,
                })
                dispatch(setIsLoading(NEXT_STAGE_NEW_LOADING, false))
            })
    }
}

export const CHANGE_NEXT_STAGE_SELECTED = 'change_next_stage_selected'
export function changeNextStageSelected(stage_id) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_NEXT_STAGE_SELECTED,
            stage_id,
        })
    }
}

export const CHANGE_NEXT_STAGE_SELECTED_INFO = 'change_next_stage_selected_info'
export function changeNextStageSelectedInfo(target, value) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_NEXT_STAGE_SELECTED_INFO,
            target,
            value,
        })
    }
}

export function saveNextStage() {
    return (dispatch, getState) => {
        axios.post('/portal/ucc/1/next/stages/json', getState().next_stage.selected)
    }
}

export const CHANGE_PREPARED_BY_CLIENT = 'change_prepared_by_client'
export function changePreparedByClient(set) {
    return {
        type: CHANGE_PREPARED_BY_CLIENT,
        set,
    }
}

export function saveComplete() {
    return (dispatch, getState) => {
        const state = getState()
        // console.log('save complete',state)
        if (state.complete.preparedByClient == 0) {
            dispatch(saveSkipAhead(state.skip_ahead.info, 1))
            dispatch(closeModal(CLOSE_MODAL))
        } else {
            dispatch(preparedByClient(state.skip_ahead.info.serv_id))
            dispatch(changePreparedByClient(0))
        }
    }
}

export const OPEN_BUILD_DOCS_MODAL = 'open_build_docs_modal'
export function openBuildDocsModal(servId) {
    return (dispatch) => {
        dispatch(openModal(OPEN_BUILD_DOCS_MODAL))
    }
}

export const CLOSE_BUILD_DOCS_MODAL = 'close_build_docs_modal'
export function closeBuildDocsModal(servId) {
    return (dispatch) => {
        dispatch(closeModal(CLOSE_BUILD_DOCS_MODAL))
    }
}

export const SERVICE_CURRENT_OPEN = 'service_current_open'
export function setCurrentServiceOpen(servId) {
    return (dispatch) => {
        dispatch({ type: SERVICE_CURRENT_OPEN, servId })
    }
}
