import AttorneyMaintenanceContext from './attorney_maintenance/Context/AttorneyMaintenanceContext'
import EmployeeMaintenanceContext from './emp_maintenance/context/GlobalState'
import LTOPostageContext from './lto_postage_administration/Context/GlobalState'
import StateMaintenanceContext from './StateMaintenance/StateMaintenanceContext'
import { useRouter } from 'next/router'
import { applyMiddleware, createStore } from 'redux'
import reducers from './emp_maintenance/reducers'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import React from 'react'
import Head from 'next/head'
const store = createStore(reducers, applyMiddleware(thunk))

export default function AdministratorLayout({ children }) {
    const { pathname } = useRouter()
    return pathname.includes('attorney_maintenance') ? (
        <AttorneyMaintenanceContext>
            <Head>
                <title>Attorney Maintenance</title>
            </Head>
            <div className={'page-container'}>{children}</div>
        </AttorneyMaintenanceContext>
    ) : pathname.includes('emp_maintenance') ? (
        <Provider store={store}>
            <>
                <Head>
                    <title>Employee Maintenance</title>
                </Head>
                <EmployeeMaintenanceContext>
                    <div className={'container-fluid'}>{children}</div>
                </EmployeeMaintenanceContext>
            </>
        </Provider>
    ) : pathname.includes('lto_postage_administration') ? (
        <LTOPostageContext>
            <div className={'page-container'}>
                <div className={'page'}>{children}</div>
            </div>
        </LTOPostageContext>
    ) : pathname.includes('state_maintenance') ? (
        <StateMaintenanceContext>
            <div className={'container-fluid'}>
                <Head>
                    <title>State Maintenance</title>
                </Head>
                <div className={'p-3'}>{children}</div>
            </div>
        </StateMaintenanceContext>
    ) : (
        <div className={'page-container'}>
            <Head>
                <title>Portal Admin</title>
            </Head>
            {children}
        </div>
    )
}
