import { useRouter } from 'next/router'
import { useContext } from 'react'
import Nav from '../../../../front/components/Nav'
import { TrackerLinksContext } from '../TrackerLinksContext'

export default function FileBarTabs(props) {
    const { trackerTab } = useContext(TrackerLinksContext)
    const router = useRouter()

    return (
        <div className='position-absolute-bottom' id='filebarTabs'>
            <Nav variant='tabs' noBottomBorder>
                {trackerTab?.links?.map(
                    (link, i) =>
                        link?.href && (
                            <Nav.Link
                                key={i}
                                active={router.pathname.startsWith(link?.href)}
                                href={{
                                    pathname: link?.href,
                                    query: {
                                        project_id: router.query.project_id,
                                    },
                                }}
                            >
                                {link?.title}
                            </Nav.Link>
                        )
                )}
            </Nav>
        </div>
    )
}
