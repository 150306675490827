import classnames from 'classnames'
import React from 'react'
import {
    Card as BootstrapCard,
    CardGroup as BootstrapCardGroup,
    ProgressBar,
} from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

export default function Card({
    className,
    ghost,
    dashed,
    bounceOnHover,
    shadowOnHover,
    centered,
    skeleton,
    style,
    ...rest
}) {
    return (
        <div className='position-relative'>
            <BootstrapCard
                {...rest}
                className={classnames(className, {
                    'card-transition': bounceOnHover,
                    'card-ghost': ghost,
                    'card-dashed': dashed,
                    'card-hover-shadow': shadowOnHover,
                    'card-centered': centered,
                })}
                style={{
                    transition: '.3s',
                    opacity: skeleton ? 0 : 1,
                    ...style,
                }}
            />
            {skeleton && (
                <div className='position-absolute top-0 start-0 w-100 h-100'>
                    <Skeleton height='100%' borderRadius='.75rem' />
                </div>
            )}
        </div>
    )
}

const CardBody = React.forwardRef(({ fixedHeight, className, ...bsProps }, ref) => {
    return (
        <BootstrapCard.Body
            ref={ref}
            {...bsProps}
            className={classnames(className, {
                'card-body-height': fixedHeight,
            })}
        />
    )
})

function CardHeader({ className, contentBetween, ...rest }) {
    return (
        <BootstrapCard.Header
            {...rest}
            className={classnames(className, {
                'card-header-content-between': contentBetween,
            })}
        />
    )
}

function CardTitle({ className, cardHeaderTitle, ...rest }) {
    return (
        <BootstrapCard.Title
            {...rest}
            className={classnames(className, {
                'card-header-title': cardHeaderTitle,
            })}
        />
    )
}

function CardSubtitle({ allCaps, className, children }) {
    return (
        <span
            className={classnames('text-muted', className, {
                'card-subtitle': allCaps,
            })}
        >
            {children}
        </span>
    )
}

function CardImg({ variant, ...bsProps }) {
    return (
        <BootstrapCard.Img
            {...bsProps}
            className={classnames({
                'card-img-top': variant === 'top' || variant == null,
                'card-img-bottom': variant === 'bottom' || variant == null,
            })}
        />
    )
}
CardImg.defaultProps = {
    as: 'img',
}

function CardProgressBar({ children, ...bsProps }) {
    return (
        <div className='card-progress-wrap'>
            <ProgressBar {...bsProps} className='card-progress' />
        </div>
    )
}

Card.Body = CardBody
Card.Footer = BootstrapCard.Footer
Card.Header = CardHeader
Card.Img = CardImg
Card.ImgOverlay = BootstrapCard.ImgOverlay
Card.Link = BootstrapCard.Link
Card.Subtitle = BootstrapCard.Subtitle
Card.Text = BootstrapCard.Text
Card.Title = CardTitle
Card.CardGroup = BootstrapCardGroup
Card.Subtitle = CardSubtitle
Card.Link = BootstrapCard.Link
Card.Text = BootstrapCard.Text
Card.ProgressBar = CardProgressBar
