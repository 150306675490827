import React, { useEffect, useState } from 'react'
import { Col, Collapse, Row } from 'react-bootstrap'
import { Badge, Button, Card, Form } from '../../../../../front'
import Divider from '../../../../../front/components/Divider'
import { useAttorneysByFirm, useFirmAndAttorneySearch, useFirms, useUpdateTask } from '../../../api'

export default function AttorneySection({ taskId, firmNo, attorneyId, state }) {
    // Firm state
    const { data: allFirms, ...allFirmsApi } = useFirms()
    const [firmSearch, setFirmSearch] = useState()
    const { data: firmSearchResults, ...searchApi } = useFirmAndAttorneySearch(firmSearch)
    const firms = firmSearchResults?.filter((result) => result?.contact_record !== 1)
    const [selectedFirmNo, setSelectedFirmNo] = useState(firmNo)
    const selectedFirm = allFirms?.find((firm) => firm.firm_no == selectedFirmNo)

    let firmOptions = []
    if (firms && firms?.length != 0) {
        firmOptions = [
            {
                label: `Search results for "${firmSearch}"`,
                options: firms?.map(firmOption),
            },
        ]
    }

    let noOptionsMessage = null
    if (!firms) noOptionsMessage = 'Start typing to search'
    else if (firms?.length === 0) noOptionsMessage = 'Search returned no firms'

    // Attorney state
    const { data: attorneys, ...attorneysApi } = useAttorneysByFirm(selectedFirmNo, {
        onSuccess: (data) => {
            if (data?.length === 1) {
                setSelectedAttorneyId(data[0]?.id)
            }
        },
    })
    const [selectedAttorneyId, setSelectedAttorneyId] = useState(attorneyId)
    const selectedAttorney = attorneys?.find((attorney) => attorney.id == selectedAttorneyId)

    let attorneyPlaceholder = 'Select attorney'
    if (!selectedFirmNo) attorneyPlaceholder = 'Select firm to show attorney options'
    else if (attorneys?.length === 0) attorneyPlaceholder = 'Firm has no attorneys'

    const { mutate: updateTask, ...updateTaskApi } = useUpdateTask()
    useEffect(() => {
        if (selectedFirmNo != firmNo || selectedAttorneyId != attorneyId)
            updateTask({
                taskId: taskId,
                data: {
                    firm_no: selectedFirmNo,
                    attorney_id: selectedAttorneyId,
                },
            })
    }, [selectedFirmNo, selectedAttorneyId])

    return (
        <Card>
            <Card.Header className={'d-flex justify-content-between align-items-center'}>
                <Card.Title>
                    Attorney
                    {updateTaskApi.isLoading && (
                        <i className='far fa-spinner-third fa-spin ms-2 text-muted' />
                    )}
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Row className={'mb-4'}>
                    <Col>
                        <Form.Label>Firm</Form.Label>
                        <Form.AdvancedSelect
                            debounce
                            isLoading={searchApi?.isFetching || allFirmsApi?.isFetching}
                            onDebounce={(input) => {
                                if (input?.length !== 0) setFirmSearch(input)
                            }}
                            value={firmOption(selectedFirm)}
                            options={firmOptions}
                            onChange={(firm) => {
                                setSelectedFirmNo(firm?.firmNo ?? null)
                                setSelectedAttorneyId(null)
                            }}
                            noOptionsMessage={() => noOptionsMessage}
                            placeholder='Select firm'
                        />
                    </Col>
                    <Col>
                        <Form.Label>Attorney</Form.Label>
                        <Form.AdvancedSelect
                            isLoading={attorneysApi.isFetching}
                            value={attorneyOption(selectedAttorney)}
                            options={attorneys?.map(attorneyOption)}
                            onChange={(attorney) => {
                                setSelectedAttorneyId(attorney?.attorneyId)
                            }}
                            placeholder={attorneyPlaceholder}
                            isDisabled={!selectedFirmNo || attorneys?.length === 0}
                        />
                    </Col>
                </Row>
                <AttorneyContactDetails attorney={selectedAttorney} />
            </Card.Body>
        </Card>
    )
}

const AttorneyContactDetails = ({ attorney }) => {
    const [showContactDetails, setShowContactDetails] = useState(false)

    if (
        !attorney?.e_mail &&
        !attorney?.cc_email &&
        !attorney?.cc_email_two &&
        !attorney?.cc_email_three &&
        !attorney?.phone_one &&
        !attorney?.mobile_phone_one &&
        !attorney?.toll_free_phone_one &&
        !attorney?.fax_one
    )
        return null

    return (
        <>
            <Collapse in={showContactDetails}>
                <Row>
                    <div className='d-flex flex-wrap'>
                        <EmailAddress label='Email' emailAddress={attorney?.e_mail} />
                        <EmailAddress label='CC Email (1)' emailAddress={attorney?.cc_email} />
                        <EmailAddress label='CC Email (2)' emailAddress={attorney?.cc_email_two} />
                        <EmailAddress
                            label='CC Email (3)'
                            emailAddress={attorney?.cc_email_three}
                        />
                    </div>
                    <Divider />
                    <div className='d-flex flex-wrap'>
                        <PhoneNumber
                            label='Phone'
                            areaCode={attorney?.area_code}
                            secondPart={attorney?.phone_one}
                            thirdPart={attorney?.phone_two}
                            extension={attorney?.extension}
                        />
                        <PhoneNumber
                            label='Cell'
                            areaCode={attorney?.mobile_area_code}
                            secondPart={attorney?.mobile_phone_one}
                            thirdPart={attorney?.mobile_phone_two}
                        />
                        <PhoneNumber
                            label='Toll-Free'
                            areaCode={attorney?.toll_free_area_code}
                            secondPart={attorney?.toll_free_phone_one}
                            thirdPart={attorney?.toll_free_phone_two}
                            extension={attorney?.toll_fee_extension}
                        />
                        <PhoneNumber
                            label='Fax'
                            areaCode={attorney?.fax_area}
                            secondPart={attorney?.fax_one}
                            thirdPart={attorney?.fax_two}
                            extension={attorney?.fax_extension}
                        />
                    </div>
                </Row>
            </Collapse>
            <div className='w-100 text-center'>
                <small
                    className='link p-0 cursor-pointer text-muted'
                    onClick={() => setShowContactDetails(!showContactDetails)}
                >
                    {showContactDetails ? 'Hide ' : 'Show '}
                    Contact Details
                </small>
            </div>
        </>
    )
}

const firmOption = (firm) => {
    if (!firm?.firm_no) return null

    return {
        firmNo: firm?.firm_no,
        value: `${firm?.firm_no} - ${firm?.firm}`,
        label: (
            <div className='d-flex justify-content-start'>
                <div>
                    <Badge className='me-2' bg='dark' soft style={{ minWidth: '50px' }}>
                        {firm?.firm_no}
                    </Badge>
                </div>
                <small className='d-block' style={{ paddingTop: '0.2em' }}>
                    {firm?.firm}
                </small>
            </div>
        ),
    }
}

const attorneyOption = (attorney) => {
    if (!attorney?.id) return null

    return {
        attorneyId: attorney?.id,
        value: `${attorney?.name} ${attorney?.title}`,
        label: (
            <div className='lh-sm'>
                <small className='d-block'>{attorney?.name}</small>
                <small className='d-block text-muted'>{attorney?.title}</small>
            </div>
        ),
    }
}

const PhoneNumber = ({ label, areaCode, secondPart, thirdPart, extension }) => {
    if (!(areaCode?.length > 1 && secondPart?.length > 1 && thirdPart?.length > 1)) return null

    return (
        <div className='p-3'>
            <span className='d-block fw-bold'>{label}</span>
            <span>
                {`(${areaCode}) ${secondPart}-${thirdPart}`}
                {extension ?? `, ext. ${extension}`}
            </span>
        </div>
    )
}

const EmailAddress = ({ label, emailAddress }) => {
    if (!emailAddress) return null

    return (
        <div className='p-3'>
            <span className='d-block fw-bold'>{label}</span>
            <span>{emailAddress}</span>
        </div>
    )
}
