import dayjs from 'dayjs'
import { Wizard } from 'front'
import { useRouter } from 'next/router'
import React, { useContext } from 'react'
import { useCreatePacket } from 'tracker/api'
import { usePacketForm } from '../hooks/usePacketForm'
import AttachmentsPage from './attachments/AttachmentsPage'
import OptionsPage from './options/OptionsPage'
import TemplatesPage from './templates/TemplatesPage'

const PacketDraftContext = React.createContext({})
export const usePacketDraftContext = () => useContext(PacketDraftContext)
export default function PacketWizard({}) {
    const router = useRouter()
    const { project_id: projectId, task_id: taskId } = router?.query || {}

    const packet = usePacketForm(projectId, taskId)

    const { mutate: createPacket, ...createPacketApi } = useCreatePacket(projectId, {
        onSuccess: (data) => {
            router.push(
                `/nml/tracker/file/packet/?project_id=${projectId}&task_id=${taskId}&packet_id=${data?.id}`
            )
        },
    })

    function create() {
        createPacket({
            taskId,
            data: {
                merge: true,
                documents: packet?.documents?.map(({ template_id, docuware_id }) => ({
                    template_id,
                    docuware_id,
                })),
                min_mail_date: packet?.minMailDate
                    ? dayjs(packet.minMailDate).format('YYYY-MM-DD')
                    : dayjs().add(1, 'day'),
                recipients: packet?.getRecipientsPostObject(),
                softening_letter_id: packet?.softeningLetter?.id,
            },
        })
    }

    return (
        <PacketDraftContext.Provider value={packet}>
            <Wizard
                steps={[
                    {
                        name: 'Templates',
                        component: <TemplatesPage />,
                    },
                    {
                        name: 'Attachments',
                        component: <AttachmentsPage />,
                    },
                    {
                        name: 'Options',
                        component: <OptionsPage />,
                    },
                ]}
                save={create}
                loading={createPacketApi.isLoading}
            />
        </PacketDraftContext.Provider>
    )
}
