import { useContext } from 'react'
import { ProjectContext } from 'tracker/Context/ProjectContext'

export function useSelectedTags({ associate, task }) {
    const { fileInfo } = useContext(ProjectContext)
    const { dynamic_tags } = fileInfo || {}

    return dynamic_tags?.filter(
        (tag) =>
            (associate && tag?.associate && tag?.associate?.id == associate?._id) ||
            (task && tag?.task && tag?.task?.id == task?.id)
    )
}

export function useFilteredNotes({ notes, associate, task }) {
    if (!notes) {
        const { fileInfo } = useContext(ProjectContext)
        notes = fileInfo?.notes ?? []
    }

    return notes?.filter((note) => {
        const tags = Object.values(note?.tags) ?? []
        return (
            tags?.filter(
                (tag) => associate && tag?.associate && tag?.associate?.id == associate?._id
            )?.length > 0 ||
            tags?.filter((tag) => task && tag?.task && tag?.task?.id == task?.id)?.length > 0
        )
    })
}
