import classnames from 'classnames'
import { Badge, Button, Card, Form, Table } from 'front'
import { useContext, useState } from 'react'
import { useResetFees, useTrackerDropdowns } from 'tracker/api'
import { ProjectContext } from '../../../Context/ProjectContext'
import AddFeeModal from './AddFeeModal'
import EditFeeModal from './EditFeeModal'
import { Tooltip } from '@/components/utils'
import { PortalContext } from '@/components/GlobalState'

export default function Fees({ task, updateTask }) {
    const { projectId, canEdit } = useContext(ProjectContext)
    const { mutate: resetFees, ...resetFeesApi } = useResetFees({ projectId, taskId: task?.id })
    const [showAddFeeModal, setShowAddFeeModal] = useState(false)
    const { isAdmin } = useContext(PortalContext)

    if (task?.fees?.length == 0) return null

    let feesTotal = 0
    let showResetFeesButton = false
    let feesToEdit = false
    task?.fees?.forEach(({ amount, is_overridden, is_deleted, fee_type, status }) => {
        if (!is_deleted) feesTotal += parseFloat(amount)

        let isDeletedFee = is_deleted && fee_type !== 'CHARGES'
        let isOverriddenFee = is_overridden && fee_type !== 'CHARGES'
        let isAddedCharge = !is_deleted && fee_type === 'CHARGES'
        const isFeeExported = status == 'EXPORTED' || status == 'FINALIZED'

        if (isDeletedFee || isOverriddenFee || isAddedCharge) showResetFeesButton = true
        if (canEdit && (!isFeeExported || isAdmin) && (!is_deleted || isAddedCharge))
            feesToEdit = true
    })
    feesTotal = feesTotal.toFixed(2)

    return (
        <Card>
            <Card.Header className={'d-flex justify-content-between align-items-center'}>
                <Card.Title>
                    Fees
                    {resetFeesApi?.isLoading && (
                        <span className={'far fa-spinner-third fa-spin me-2'} />
                    )}
                </Card.Title>
                {canEdit && (
                    <div>
                        <a
                            className='link p-0'
                            onClick={() => {
                                setShowAddFeeModal(true)
                            }}
                        >
                            Add Charge
                        </a>
                        {showResetFeesButton && (
                            <a className='link ms-3' onClick={resetFees}>
                                Reset
                            </a>
                        )}
                    </div>
                )}
            </Card.Header>
            <Card.Body>
                <div className='d-flex flex-column gap-3'>
                    {task?.fees
                        ?.filter(
                            ({ is_deleted, fee_type }) => !(is_deleted && fee_type === 'CHARGES')
                        )
                        ?.map((fee, i) => (
                            <Fee fee={fee} canEdit={canEdit} key={i} feesToEdit={feesToEdit} />
                        ))}
                </div>
                <AddFeeModal
                    taskId={task?.id}
                    show={showAddFeeModal}
                    onHide={() => {
                        setShowAddFeeModal(false)
                    }}
                />
            </Card.Body>
            <Card.Footer className='d-flex justify-content-between align-items-center border-top-0 pt-0 pb-2'>
                <div className='d-flex gap-3'>
                    <Tooltip content='This task was billed under a different service'>
                        <span>
                            <Form.Check
                                type='switch'
                                label='Billed Separately'
                                name='is_billed_separate'
                                checked={task?.is_billed_separate}
                                onChange={({ target: { checked } }) =>
                                    updateTask({
                                        taskId: task?.id,
                                        data: {
                                            is_billed_separate: checked,
                                        },
                                    })
                                }
                            />
                        </span>
                    </Tooltip>
                    <Tooltip content='This task was billed as a priority service'>
                        <span>
                            <Form.Check
                                type='switch'
                                label='Billed Priority'
                                name='is_billed_priority'
                                checked={task?.is_billed_priority}
                                onChange={({ target: { checked } }) =>
                                    updateTask({
                                        taskId: task?.id,
                                        data: {
                                            is_billed_priority: checked,
                                        },
                                    })
                                }
                            />
                        </span>
                    </Tooltip>
                </div>
                <div className='d-flex flex-column text-end'>
                    <div className='text-body' style={{ marginBottom: -5 }}>
                        Total
                    </div>
                    <h3 className='fw-bolder '>${feesTotal}</h3>
                </div>
            </Card.Footer>
        </Card>
    )
}

function Fee({ fee, canEdit, feesToEdit }) {
    const { isAdmin } = useContext(PortalContext)
    const [showEditFeeModal, setShowEditFeeModal] = useState(false)
    const { data: dropdowns } = useTrackerDropdowns()

    const isFeeExported = fee.status == 'EXPORTED' || fee.status == 'FINALIZED'
    const isFeeEditable = canEdit && (!isFeeExported || isAdmin) && !fee.is_deleted

    return (
        <div className='d-flex justify-content-between align-items-center gap-3'>
            <div>
                <div className='d-flex flex-column' style={{ opacity: fee?.is_deleted ? 0.5 : 1 }}>
                    <small
                        className={classnames('fw-bolder smaller', {
                            'text-decoration-line-through': fee.is_deleted,
                        })}
                    >
                        {fee.fee_type}
                    </small>

                    <div
                        className={classnames(
                            {
                                'text-decoration-line-through': fee.is_deleted,
                            },
                            'fw-bolder text-dark'
                        )}
                    >
                        {fee.fee_type == 'POSTAGE'
                            ? dropdowns?.['docgen.postage']?.find(
                                  ({ code, legacy_value }) =>
                                      code == fee.rate_code || legacy_value == fee.rate_code
                              )?.label
                            : fee.description}
                    </div>
                    {fee.quantity != 1 && (
                        <div className='d-flex gap-1'>
                            <span>{fee.quantity} x </span>
                            <span>${fee.unit_rate}</span>
                        </div>
                    )}
                </div>
            </div>
            <div className='d-flex justify-content-between align-items-center gap-4'>
                <div className='d-flex gap-1 align-items-center'>
                    {fee.is_deleted && (
                        <Badge soft bg='danger'>
                            Deleted
                        </Badge>
                    )}
                    {fee.is_overridden == 1 && !fee.is_deleted && fee.fee_type != 'CHARGES' && (
                        <Badge soft bg='secondary'>
                            Edited
                        </Badge>
                    )}
                    {fee.is_overridden == 1 && !fee.is_deleted && fee.fee_type == 'CHARGES' && (
                        <Badge soft bg='success'>
                            Added
                        </Badge>
                    )}
                    {isFeeExported && <Badge bg='success'>Exported</Badge>}
                </div>
                <div
                    className={classnames('text-start', {
                        'text-decoration-line-through': fee.is_deleted,
                    })}
                >
                    ${fee.amount}
                </div>
                {feesToEdit && (
                    <div
                        style={{
                            opacity: isFeeEditable ? 1 : 0,
                        }}
                    >
                        <Button
                            className='p-0'
                            variant='link'
                            onClick={() => {
                                setShowEditFeeModal(true)
                            }}
                            disabled={!isFeeEditable}
                        >
                            Edit
                        </Button>
                        <EditFeeModal
                            show={showEditFeeModal}
                            onHide={() => {
                                setShowEditFeeModal(false)
                            }}
                            fee={fee}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

const FeesTotal = ({ total }) => (
    <Table.Row className='fw-bolder'>
        <Table.Td colSpan={4} className=''></Table.Td>
        <Table.Td className='text-start'>
            <small className='p-0'>TOTAL</small>
        </Table.Td>
        <Table.Td className='text-center'>
            <i className='fas fa-equals fa-xs mx-2' />
        </Table.Td>
        <Table.Td className='text-start'>${total}</Table.Td>
    </Table.Row>
)
