import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { mergeConfigs } from 'utils'

const DW_API = '/docuware/3'

export default class DwSearchApi {
    static keys = {
        all: ['docuware'],
        search: (cabinet, query, showPrivate) => [this.keys.all, cabinet, query, showPrivate],
    }

    static search(cabinet, query, showPrivate) {
        let searchType = showPrivate ? 'search_private' : 'search'
        return axios
            .post(`${DW_API}/${cabinet}/${searchType}/json`, { query })
            .then(({ data }) => data)
    }
}

export const useDocuwareSearch = (cabinet, query, showPrivate, config) => {
    const key = DwSearchApi.keys.search(cabinet, query, showPrivate)
    const api = () => DwSearchApi.search(cabinet, query, showPrivate)
    const configs = mergeConfigs(
        {
            enabled: !!(cabinet && query && showPrivate),
        },
        config
    )

    return useQuery(key, api, configs)
}
