import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { mergeConfigs } from '../../../../utils'
import axios from '../Context/AxiosSetup'

export class Printing {
    static keys = {
        all: ['printing'],
        queue: () => [...this.keys.all, 'queue'],
        queueHistory: () => [...this.keys.all, 'queueHistory'],
        printJobs: () => [...this.keys.all, 'printJobs'],
        printJobsHistory: () => [...this.keys.all, 'printJobsHistory'],
        noticeAdminLocked: () => [...this.keys.all, 'noticeAdminLocked'],
        printJobPackets: (printJobId) => [...this.keys.all, 'printJobPackets', printJobId],
    }

    static getQueue = () => {
        return axios.get('/nml/tracker/2/docgen/queue/json').then(({ data }) => data)
    }
    static getPrintJobs = () => {
        return axios.get('/nml/tracker/2/docgen/print/queue/json').then(({ data }) => data)
    }
    static getPrintJobPackets = (printJobId) => {
        return axios
            .get(`/nml/tracker/2/docgen/print/job/packets/${printJobId}/json`)
            .then(({ data }) => data)
    }

    static getQueueHistory = () => {
        return axios.get('/nml/tracker/2/docgen/queue/history/json').then(({ data }) => data)
    }
    static getPrintJobHistory = () => {
        return axios.get('/nml/tracker/2/docgen/print/queue/history/json').then(({ data }) => data)
    }

    static getNoticeAdminLocked = () => {
        return axios
            .get('/nml/tracker/2/version/list/notice_admin_locked/json')
            .then(({ data }) => data)
    }

    static unlockNoticeAdminPackets = (project_ids) => {
        return axios
            .post(`/nml/tracker/2/version/release/notice_admin/json`, { project_ids })
            .then((response) => response.data)
    }
    static cancelPacket = (packetId) => {
        return axios
            .delete(`/nml/tracker/2/docgen/queue/packet/${packetId}/json`, { cancel: true })
            .then((response) => response.data)
    }

    static cancelPrintJob = (printJobId) => {
        return axios
            .post(`/nml/tracker/2/docgen/cancel/print/job/${printJobId}/json`)
            .then((response) => response.data)
    }

    static createPrintJob = (packets) => {
        return axios
            .post('/nml/tracker/2/docgen/create/print/job/json', { packets })
            .then((response) => response.data)
    }

    static startPreprocessing = (printJobId) => {
        return axios
            .post(`/nml/tracker/2/print/job/preprocess/print_job/${printJobId}/json`)
            .then((response) => response.data)
    }

    static startPostProcessing = (printJobId) => {
        return axios
            .post(`/nml/tracker/2/docgen/print/job/postprocess/${printJobId}/json`)
            .then((response) => response.data)
    }
    static startResetRejects = (printJobId) => {
        return axios
            .post(`/nml/tracker/2/docgen/reset/rejected/print/job/packets/${printJobId}/json`)
            .then((response) => response.data)
    }

    static sendPrintJobToPrinter = (printJobId) => {
        return axios
            .post(`/nml/tracker/2/docgen/print/job/trigger/${printJobId}/json`)
            .then((response) => response.data)
    }

    static fakeSendPrintJobToPrinter = (printJobId) => {
        return axios
            .post(`/nml/tracker/2/docgen/print/job/fake/trigger/${printJobId}/json`)
            .then((response) => response.data)
    }

    static resetPrintJob = (printJobId) => {
        return axios
            .post(`/nml/tracker/2/docgen/print/job/reset/${printJobId}/json`)
            .then((response) => response.data)
    }

    static confirmPrinted = (printJobId) => {
        return axios
            .post(`/nml/tracker/2/docgen/print/job/confirm/printed/${printJobId}/json`)
            .then((response) => response.data)
    }

    static sendPacketBackToReview = (packetId) => {
        return axios
            .delete(`/nml/tracker/2/docgen/queue/packet/${packetId}/json`)
            .then((response) => response.data)
    }
}

export const usePrintQueue = (config) => {
    return useQuery(Printing.keys.queue(), Printing.getQueue, mergeConfigs({}, config))
}

export const usePrintJobs = (config) => {
    return useQuery(Printing.keys.printJobs(), Printing.getPrintJobs, mergeConfigs({}, config))
}

export const usePrintQueueHistory = (config) => {
    return useQuery(
        Printing.keys.queueHistory(),
        Printing.getQueueHistory,
        mergeConfigs({}, config)
    )
}

export const usePrintJobsHistory = (config) => {
    return useQuery(
        Printing.keys.printJobsHistory(),
        Printing.getPrintJobHistory,
        mergeConfigs({}, config)
    )
}

export const useNoticeAdminLocked = (config) => {
    return useQuery(
        Printing.keys.noticeAdminLocked(),
        Printing.getNoticeAdminLocked,
        mergeConfigs({}, config)
    )
}

export const useUnlockNoticeAdminPackets = (config) => {
    const queryClient = useQueryClient()

    return useMutation(
        (project_ids) => Printing.unlockNoticeAdminPackets(project_ids),
        mergeConfigs(
            {
                onSuccess: (data) => {
                    if (typeof data === 'array') {
                        queryClient.setQueryData(Printing.keys.noticeAdminLocked(), data)
                    }
                },
            },
            config
        )
    )
}

export const useCancelPacket = (config) => {
    const queryClient = useQueryClient()

    return useMutation(
        (packetId) => Printing.cancelPacket(packetId),
        mergeConfigs(
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(Printing.keys.printJobs())
                    queryClient.invalidateQueries(Printing.keys.queue())
                },
            },
            config
        )
    )
}

export const useCancelPrintJob = (config) => {
    const queryClient = useQueryClient()

    return useMutation(
        (printJobId) => Printing.cancelPrintJob(printJobId),
        mergeConfigs(
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(Printing.keys.printJobs())
                    queryClient.invalidateQueries(Printing.keys.queue())
                },
            },
            config
        )
    )
}

export const useCreatePrintJob = (config) => {
    const queryClient = useQueryClient()

    return useMutation(
        (packets) => Printing.createPrintJob(packets),
        mergeConfigs(
            {
                onSuccess: async ({ print_job_id }) => {
                    queryClient.invalidateQueries(Printing.keys.printJobs())
                    queryClient.invalidateQueries(Printing.keys.queue())
                    await Printing.startPreprocessing(print_job_id)
                },
            },
            config
        )
    )
}

export const useStartPreprocessing = (printJobId, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        (packets) => Printing.startPreprocessing(printJobId),
        mergeConfigs(
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(Printing.keys.printJobs())
                    queryClient.invalidateQueries(Printing.keys.queue())
                },
            },
            config
        )
    )
}

export const useStartPostProcessing = (printJobId, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        (packets) => Printing.startPostProcessing(printJobId),
        mergeConfigs(
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(Printing.keys.printJobs())
                    queryClient.invalidateQueries(Printing.keys.queue())
                },
            },
            config
        )
    )
}

export const useStartResetRejects = (printJobId, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        (packets) => Printing.startResetRejects(printJobId),
        mergeConfigs(
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(Printing.keys.printJobs())
                    queryClient.invalidateQueries(Printing.keys.queue())
                },
            },
            config
        )
    )
}

export const useSendPrintJobToPrinter = (printJobId, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        () => Printing.sendPrintJobToPrinter(printJobId),
        mergeConfigs(
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(Printing.keys.printJobs())
                    queryClient.invalidateQueries(Printing.keys.queue())
                },
            },
            config
        )
    )
}
export const useFakeSendPrintJobToPrinter = (printJobId, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        () => Printing.fakeSendPrintJobToPrinter(printJobId),
        mergeConfigs(
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(Printing.keys.printJobs())
                    queryClient.invalidateQueries(Printing.keys.queue())
                },
            },
            config
        )
    )
}
export const useResetPrintJob = (printJobId, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        () => Printing.resetPrintJob(printJobId),
        mergeConfigs(
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(Printing.keys.printJobs())
                    queryClient.invalidateQueries(Printing.keys.queue())
                },
            },
            config
        )
    )
}

export const useConfirmPrinted = (printJobId, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        () => Printing.confirmPrinted(printJobId),
        mergeConfigs(
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(Printing.keys.printJobs())
                    queryClient.invalidateQueries(Printing.keys.queue())
                },
            },
            config
        )
    )
}

export const useSendPacketBackToReview = (config) => {
    const queryClient = useQueryClient()

    return useMutation(
        (packetId) => Printing.sendPacketBackToReview(packetId),
        mergeConfigs(
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(Printing.keys.queue())
                },
            },
            config
        )
    )
}

export const useGetPrintJobPackets = (printJobId, config) => {
    return useQuery(
        Printing.keys.printJobPackets(printJobId),
        () => Printing.getPrintJobPackets(printJobId),
        mergeConfigs({}, config)
    )
}
