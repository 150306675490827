import { CopyButton, MicroAppContextProvider } from 'components/utils'
import { Button } from 'front'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { CollapsingHeaderWrapper } from '../../front/layouts/CollapsingHeader'
import FrontLayout from '../../front/layouts/FrontLayout'
import Page from '../../front/layouts/Page'
import MaintenanceLayout from '../maintenance/MainteanceLayout'
import ReportLayout from '../reports/ReportLayout'
import ProjectContextProvider, { ProjectContext } from './Context/ProjectContext'
import Dashboard from './dashboard'
import Nav from './file/Nav'
import Filebar from './file/filebar/Filebar'
import SearchModal from './Utils/KeyboardShortcuts/SearchModal'
import { useKeyDown } from './Utils/KeyboardShortcuts'
import { useModalState } from 'hooks'
import FileAlerts from './file/FileAlerts'
import { ClipboardProvider } from './Utils/ClipboardContext'
import TrackerLinkContextProvider from './file/TrackerLinksContext'
export default function TrackerLayout({ children }) {
    const { pathname } = useRouter()
    const display = 'file-display'
    const searchModal = useModalState(false)
    //open search modal
    useKeyDown(searchModal.toggle, 'k', ['ctrlKey'])

    return (
        <>
            {/* <div className={'page-container'}> */}
            <Head>
                <title>Portal Tracker</title>
            </Head>
            <ClipboardProvider>
                {/* <div className={'extra-content'}> */}
                {pathname.startsWith('/nml/maintenance') ? (
                    <div className={'extra-content'}>
                        <MaintenanceLayout>
                            <Head>
                                <title>Portal Tracker Maintenance</title>
                            </Head>
                            {children}
                        </MaintenanceLayout>
                    </div>
                ) : pathname.startsWith('/nml/report') ? (
                    <div className={'extra-content'}>
                        <ReportLayout>
                            <Head>
                                <title>Portal Tracker Reports</title>
                            </Head>
                            {children}
                        </ReportLayout>
                    </div>
                ) : pathname.startsWith('/nml/tracker/request') ? (
                    <div className={'p-5'}>
                        <Head>
                            <title>New Request</title>
                        </Head>
                        {children}
                    </div>
                ) : pathname.startsWith('/nml/tracker/printing') ? (
                    <>{children}</>
                ) : pathname.startsWith('/nml/tracker/accounting-export') ? (
                    <>{children}</>
                ) : (
                    <ProjectContextProvider>
                        <SearchModal show={searchModal.isOpen} onHide={searchModal.hide} />
                        {pathname.startsWith('/nml/tracker/dashboard') ? (
                            <MicroAppContextProvider key='dashboard-micro-app'>
                                <TrackerLinkContextProvider>
                                    <Dashboard searchModal={searchModal} />
                                </TrackerLinkContextProvider>
                            </MicroAppContextProvider>
                        ) : (
                            <MicroAppContextProvider key='file-micro-app'>
                                <TrackerLinkContextProvider>
                                    <TrackerFileLayout
                                        searchModal={searchModal}
                                        pathname={pathname}
                                        children={children}
                                    />
                                </TrackerLinkContextProvider>
                            </MicroAppContextProvider>
                        )}
                    </ProjectContextProvider>
                )}
            </ClipboardProvider>
        </>
    )
}

function TrackerFileLayout({ pathname, children, searchModal }) {
    const { fileInfo, fileEdit } = useContext(ProjectContext)
    return (
        <>
            <Head>
                <title>
                    Portal Tracker -{' '}
                    {fileInfo?.project?.ncs_lno?.length > 0
                        ? fileInfo?.project?.ncs_lno
                        : 'ID #' + fileInfo?.project_id}
                </title>
            </Head>
            {pathname.startsWith('/nml/tracker/notes') ||
            pathname.startsWith('/nml/tracker/file/popout') ? (
                <>
                    <style
                        dangerouslySetInnerHTML={{
                            __html: `
                    header {
                        visibility: hidden !important;
                    }
                    .main-content {
                        margin-top: 0 !important;
                    }
                `,
                        }}
                    />
                    <div className='p-3'>{children}</div>
                </>
            ) : (
                <>
                    {fileInfo?.project?.is_in_collection && (
                        <div className='bg-coll p-3 text-white text-center fw-bold'>
                            <i className='far fa-circle-exclamation me-3' />
                            This project is in collections, any changes should be communicated to
                            the collections department.
                        </div>
                    )}
                    <FrontLayout>
                        <Nav searchModal={searchModal} />
                        <div
                            id='collapsingHeaderWrapper'
                            className='d-flex flex-column flex-grow-1 overflow-hidden'
                        >
                            <Filebar />
                            <div
                                className='flex-grow-1 overflow-auto position-relative pt-3'
                                id='fileContent'
                            >
                                <Block
                                    show={fileInfo?.project?.workflow === 'DESKTOP'}
                                    ncs_lno={fileInfo?.project?.ncs_lno}
                                />
                                <FileAlerts fileEdit={fileEdit} fileInfo={fileInfo} />
                                {children}
                            </div>
                        </div>
                    </FrontLayout>
                </>
            )}
        </>
    )
}

function Block({ show, ncs_lno }) {
    return (
        <Modal show={show} backdrop='static' centered>
            <Modal.Header>
                <Modal.Title>Already In Use</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                This project is already imported into Desktop Tracker. If you want to make any
                changes please make them there.
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-between'>
                <CopyButton copy={ncs_lno}>{ncs_lno}</CopyButton>
                <Link href={'/nml/tracker/dashboard'}>
                    <Button>
                        <span className='far fa-house' /> Dashboard
                    </Button>
                </Link>
            </Modal.Footer>
        </Modal>
    )
}
