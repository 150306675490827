import classNames from 'classnames'

export default function NcsLoadingSpinner({ className = '', height = 25, width = 25 }) {
    return (
        <div className={classNames(`ncs-logo-container`, className)}>
            <img
                src={'/images/ncs-logo-orange.png'}
                title='orange logo'
                height={height}
                width={width}
            />
            <img
                src={'/images/ncs-logo-green.png'}
                title='green logo'
                height={height}
                width={width}
            />
            <img
                src={'/images/ncs-logo-blue.png'}
                title='blue logo'
                height={height}
                width={width}
            />
        </div>
    )
}
