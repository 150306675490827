import { Badge, Button } from 'front'
import { useDebounce, useLocal } from 'hooks'
import Link from 'next/link'
import { Fragment, useContext, useEffect, useState } from 'react'
import { useEmployeeBaseLine } from 'tracker/api'
import Collapse from '../../../utils/Collapse'
import EmployeeDropdown from '../../../utils/EmployeeDropdown'
import formatDate from '../../../utils/formatDate'
import isEmpty from '../../../utils/isEmpty'
import Overlay from '../../../utils/Overlay'
import { Context } from '../Context/GlobalState'
import { sortBy } from 'lodash'

export default function NavFilters({ aggs, isLoading, setFilters, filters, startFilters }) {
    const { dropdowns } = useContext(Context)
    const [tempFilters, setTempFilters] = useLocal('navFiltersTemp', filters ?? startFilters)
    const debouncedFilters = useDebounce(tempFilters, 1500)
    const { data: baseLine } = useEmployeeBaseLine(filters?.employee_id)
    //
    const [currentAggs, setCurrentAggs] = useLocal('navFiltersCurrent', {})

    useEffect(() => {
        //
        if (
            !isEmpty(baseLine?.aggs) &&
            JSON.stringify(baseLine?.aggs) !== JSON.stringify(currentAggs)
        ) {
            setCurrentAggs(baseLine?.aggs)
        }
    }, [baseLine?.aggs])

    useEffect(() => {
        setFilters(debouncedFilters)
    }, [debouncedFilters])

    // const NavQueueFilters = sortBy(dropdowns?.employee, ['FirstName', 'LastName'])

    const searchAggSections = isEmpty(currentAggs)
        ? []
        : [
              {
                  title: 'Count',
                  items: [...currentAggs?.is_active],
                  startOpen: true,
                  filter: 'is_active',
              },
              {
                  title: 'Clients',
                  items: [
                      ...(currentAggs?.class ?? [])?.map((c) => ({ ...c, type: 'class' })),
                      ...currentAggs?.client_id?.map((c) => ({ ...c, type: 'client_id' })),
                  ],
                  startOpen: true,
                  filter: ['client_id', 'class'],
              },
              {
                  title: 'Type',
                  items: [...currentAggs?.project_type],
                  startOpen: true,
                  filter: 'project_type',
              },
              {
                  title: 'Priority',
                  items: [...(currentAggs?.is_priority ?? [])],
                  startOpen: false,
                  filter: 'is_priority',
              },
              {
                  title: 'Tracking',
                  items: [...currentAggs?.project_service_level],
                  startOpen: false,
                  filter: 'project_service_level',
              },
              {
                  title: 'Stage',
                  items: [...currentAggs?.current_stage],
                  startOpen: false,
                  filter: 'current_stage',
              },
              {
                  title: 'State',
                  items: [...currentAggs?.state],
                  startOpen: false,
                  filter: 'state',
              },
              {
                  title: 'Created',
                  items: [...(currentAggs?.created_date ?? [])],
                  startOpen: false,
                  filter: 'created_date',
              },
          ]

    function changeFilter(name, value) {
        const inArray = tempFilters?.[name]?.find((r) => r == value) != undefined
        const section = tempFilters?.[name] ?? []
        setTempFilters({
            ...tempFilters,
            [name]: inArray ? section?.filter((r) => r != value) : [...section, value],
        })
    }

    function clearFilters(filter) {
        const newFilters = { ...tempFilters, [filter]: [] }
        setTempFilters(newFilters)
        setFilters(newFilters)
    }

    function showClearAllFilters() {
        let currentFilters = structuredClone(tempFilters)
        let startingFilters = structuredClone(startFilters)
        delete currentFilters?.employee_id
        delete startingFilters?.employee_id
        return JSON.stringify(startingFilters) !== JSON.stringify(currentFilters)
    }

    const applyButton = (
        <button
            className={'btn btn-outline-primary btn-xs'}
            onClick={() => {
                setFilters(tempFilters)
            }}
        >
            <span className={'far fa-check'} />
        </button>
    )
    return (
        <>
            <div className='sticky bg-white'>
                {dropdowns?.employee?.length > 0 && (
                    <div className={'mb-3'}>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <EmployeeDropdown
                                    employees={sortBy(dropdowns?.employee, [
                                        'FirstName',
                                        'LastName',
                                    ])}
                                    onChange={(employee_id) => {
                                        if (employee_id) {
                                            setFilters({ ...filters, employee_id })
                                            setTempFilters({ ...filters, employee_id })
                                        } else {
                                            const { employee_id, ...rest } = filters
                                            setFilters({ ...rest })
                                            setTempFilters({ ...rest })
                                        }
                                    }}
                                    selected={filters?.employee_id}
                                    clearsToCurrentUser
                                    clearsToCurrentUserLabel='My Queue'
                                />
                            </div>
                        </div>
                    </div>
                )}
                {!isLoading && (
                    <>
                        {showClearAllFilters() && (
                            <Button
                                outline
                                variant='secondary'
                                className='w-100'
                                onClick={() => {
                                    setFilters({ ...startFilters })
                                    setTempFilters({ ...startFilters })
                                }}
                            >
                                <span className={'far fa-filter-circle-xmark'} /> Remove Filters
                            </Button>
                        )}
                    </>
                )}
                {!isLoading && aggs?.all > 0 && (
                    <div className={'pt-2 mt-2 border-top'}>
                        <strong>Total</strong>
                        <span className={'float-end'}>
                            <Badge variant='secondary' soft>
                                {aggs?.all}
                            </Badge>
                        </span>
                    </div>
                )}
            </div>
            {!isLoading && searchAggSections?.length > 0 && (
                <div className={'pt-2 mt-3 border-top'}>
                    <h5 className={'text-center'}>Filters</h5>
                </div>
            )}
            {searchAggSections?.map(
                (agg, i) =>
                    agg && (
                        <SearchItem
                            key={i}
                            {...agg}
                            selectedFilters={tempFilters ?? {}}
                            first={i === 0}
                            onChange={changeFilter}
                            clearFilters={clearFilters}
                            applyBtn={applyButton}
                            currentFilters={filters ?? {}}
                        />
                    )
            )}
        </>
    )
}

function SearchItem({
    title,
    items,
    startOpen,
    onChange,
    filter,
    selectedFilters,
    currentFilters,
    applyBtn,
    clearFilters,
    link,
    first,
}) {
    const [viewMore, setMore] = useState(false)
    let selected = selectedFilters?.[filter] ?? []
    let current = currentFilters?.[filter] ?? []
    if (typeof filter === 'object') {
        filter.map((f) => {
            selected = [...selected, ...(selectedFilters?.[f] ?? [])]
            current = [...current, ...(currentFilters?.[f] ?? [])]
        })
    }
    // const compare =
    //     JSON.stringify(currentFilters?.[filter]) !== JSON.stringify(selectedFilters?.[filter])
    // const startVsSelected =
    //     JSON.stringify(startFilters?.[filter]) !== JSON.stringify(selectedFilters?.[filter])

    return (
        <Collapse
            className={first ? 'pt-1' : ' border-top pt-2 mt-3'}
            title={
                <strong>
                    {title}
                    {selected?.length > 0 ? ' (' + selected?.length + ')' : ''}
                </strong>
            }
            startOpen={startOpen}
        >
            {items
                ?.sort((i) => (current?.includes(i.id) ? -1 : 1))
                .slice(0, viewMore ? items.length : 5)
                .map((item, i) =>
                    link ? (
                        <Link key={i} to={`/nml/tracker/file/tasks?project_id=${item.id}`}>
                            <div className={'d-block pt-1'}>
                                <div className={'d-flex justify-content-between'}>
                                    <span>
                                        <ItemName name={item.name} />
                                    </span>
                                    <span>{formatDate(item.recentFileDate, 'hh:mm a')}</span>
                                </div>
                            </div>
                        </Link>
                    ) : (
                        <div
                            key={i}
                            className='form-check pointer mt-1'
                            onClick={() => {
                                onChange(item?.type ?? filter, item.id)
                            }}
                        >
                            <input
                                type='checkbox'
                                className='form-check-input pointer'
                                checked={selected?.includes(item.id) ?? false}
                                onChange={() => {
                                    onChange(item?.type ?? filter, item.id)
                                }}
                            />
                            <label className='form-check-label pointer w-100'>
                                <div className={'row'}>
                                    <div className='col-9'>
                                        {item?.name?.length > 14 ? (
                                            <Overlay text={item.name}>
                                                <div className={'row'}>
                                                    <div
                                                        className={'col'}
                                                        style={{
                                                            textOverflow: 'ellipsis',
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap',
                                                        }}
                                                    >
                                                        <ItemName name={item.name} />
                                                    </div>
                                                </div>
                                            </Overlay>
                                        ) : (
                                            <ItemName name={item.name} />
                                        )}
                                    </div>
                                    <div className={'col-3'}>
                                        <Badge variant='secondary' soft className='float-end'>
                                            {item?.count}
                                        </Badge>
                                    </div>
                                </div>
                            </label>
                        </div>
                    )
                )}
            <div
                className={
                    'row' +
                    (items?.length > 5 || JSON.stringify(current) !== JSON.stringify(selected)
                        ? ' mt-2'
                        : '')
                }
            >
                <div className={'col-12'}>
                    {items?.length > 5 && (
                        <Fragment>
                            <span className={'far fa-chevron-' + (viewMore ? 'up' : 'down')} />{' '}
                            <a
                                href={'#'}
                                onClick={() => {
                                    setMore(!viewMore)
                                }}
                            >
                                View {viewMore ? 'Less' : 'More'}
                            </a>
                        </Fragment>
                    )}
                    {/* <span className={'float-end'}>
                        {(compare || startVsSelected) && (
                            <button
                                className={'btn btn-outline-danger btn-xs me-1'}
                                onClick={() => {
                                    clearFilters(filter)
                                }}
                            >
                                <span className={'far fa-trash-alt'} />
                            </button>
                        )}
                        {compare && applyBtn}
                    </span> */}
                </div>
            </div>
        </Collapse>
    )
}

function ItemName({ name }) {
    return name
        ? name?.length > 0
            ? name == '0'
                ? 'No'
                : name == '1'
                ? 'Yes'
                : name
            : 'All'
        : 'All'
}
