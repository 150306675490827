import { useEffect, useContext, Component } from 'react'
import { PortalContext } from '../../GlobalState'
import { setCurrentServiceOpen } from '../ucc_reducers/action'
import { connect } from 'react-redux'

function ServiceSelectedChild({ update }) {
    const { socket } = useContext(PortalContext)

    useEffect(() => {
        // console.log('socket',socket)
        socket?.removeListener('UCCServiceOpen')
        socket?.on('UCCServiceOpen', (servId) => {
            update(servId)
        })
    }, [socket, update])

    return <></>
}

class ServiceSelected extends Component {
    render() {
        return <ServiceSelectedChild update={this.props.setCurrentServiceOpen} />
    }
}

export default connect(() => ({}), {
    setCurrentServiceOpen,
})(ServiceSelected)
