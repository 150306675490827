import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import DwApi from '../../docuware/api/DwApi'
import { PortalContext } from '../../GlobalState'
import makeKeysLowerCase from '../makeKeysLowerCase'
import EmailForm from './EmailForm'
import { email } from './emailFunctions'

export default function EmailModal({
    contacts,
    cabinet,
    file,
    fromList,
    service,
    btnStyle,
    children,
    from,
    to,
    cc,
    addToList,
    iconOnly,
}) {
    const { employee } = useContext(PortalContext)
    const [showModal, setShowModal] = useState(false)
    const [divisionContacts, setDivisionContacts] = useState(contacts ?? [])

    useEffect(() => {
        let mounted = true
        if ((!contacts || contacts?.length === 0) && showModal) {
            console.log('email modal contacts call')
            let div_id
            if (cabinet === 'ucc') {
                div_id = file?.div_id
            } else {
                div_id = file?.client?.div_id
            }
            div_id &&
                DwApi.getContacts(div_id).then((data) => {
                    mounted && setDivisionContacts(data)
                })
        } else if (contacts?.length > 0) {
            // console.log('else',contacts)
            setDivisionContacts(contacts)
        }
        return () => (mounted = false)
    }, [contacts, file, showModal])

    const emailHeaders = email(cabinet, divisionContacts, file, service)
    let toList = divisionContacts
    if (addToList) {
        toList = [...toList, ...addToList]
    }

    return (
        <Fragment>
            <button className={btnStyle ?? 'btn btn-secondary'} onClick={() => setShowModal(true)}>
                {children ?? (
                    <Fragment>
                        <span className={'far fa-envelope'} /> {iconOnly ? '' : 'Email'}
                    </Fragment>
                )}
            </button>
            <Modal
                show={showModal}
                onHide={() => {
                    setShowModal(false)
                }}
                size={'lg'}
                className={'docuware-modal'}
                backdrop={'static'}
            >
                <Modal.Header closeButton>
                    <h4>Email</h4>
                </Modal.Header>
                <EmailForm
                    contacts={toList}
                    to={to ?? emailHeaders.to}
                    cc={cc}
                    attachments={[]}
                    subject={emailHeaders.subject}
                    sendBtn={() => {
                        setShowModal(false)
                    }}
                    closeBtn={() => {
                        setShowModal(false)
                    }}
                    file={file}
                    service={service ? makeKeysLowerCase(service) : {}}
                    emp={employee}
                    cabinet={cabinet}
                    modal={true}
                    from={from && employee?.Email}
                    fromList={fromList ?? []}
                />
            </Modal>
        </Fragment>
    )
}
