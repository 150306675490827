import { useRouter } from 'next/router'
import Head from 'next/head'
import NMLGlobalState from './Context/GlobalState'
import TrackerLayout from './TrackerLayout'
import KeyboardShortcuts from './Utils/KeyboardShortcuts'

export default function NmlLayout({ children }) {
    const { pathname } = useRouter()

    return (
        <>
            {pathname.startsWith('/nml/attorney') ? (
                <>
                    {/*<BootstrapCSS/>*/}
                    <Head>
                        <title>Attorney Follow Up</title>
                    </Head>
                    {children}
                </>
            ) : (
                <NMLGlobalState>
                    <TrackerLayout>
                        <KeyboardShortcuts />
                        {/*<FrontCSS/>*/}
                        {children}
                    </TrackerLayout>
                </NMLGlobalState>
            )}
        </>
    )
}
