import { Card } from 'front'
import { useRouter } from 'next/router'
import { Col, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { useTemplatesForTask } from 'tracker/api'
import { SelectedTemplates } from './SelectedTemplates'
import { SofteningLetterList } from './SofteningLetterList'
import { TemplateList } from './TemplateList'

export default function TemplatesPage({}) {
    const router = useRouter()
    const { project_id, task_id } = router?.query || {}
    const { data: templateOptions, ...templateOptionsApi } = useTemplatesForTask(
        project_id,
        task_id
    )
    const templateList = templateOptions?.templates
    const softeningList = templateOptions?.softening

    if (templateOptionsApi.isLoading) return <LoadingSkeleton />

    return (
        <Row>
            <Col>
                {softeningList?.length > 0 && <SofteningLetterList softeningList={softeningList} />}
                {templateList?.length > 0 && <TemplateList templateList={templateList} />}
            </Col>

            <SelectedTemplates />
        </Row>
    )
}

const LoadingSkeleton = () => (
    <Row>
        <Col>
            <Card ghost>
                <Card.Header>
                    <Skeleton className='w-50 lh-lg mb-2 w-50' />
                </Card.Header>
                <Card.Body>
                    <Skeleton className='w-100 lh-lg mb-4' style={{ height: '65px' }} />
                </Card.Body>
            </Card>
            <Card ghost>
                <Card.Header>
                    <Skeleton className='w-50 lh-lg mb-2 w-50' />
                </Card.Header>
                <Card.Body>
                    {[...Array(3)].map((i) => (
                        <Skeleton key={i} className='w-100 lh-lg mb-4' style={{ height: '65px' }} />
                    ))}
                </Card.Body>
            </Card>
        </Col>
        <Col>
            <Card ghost>
                <Card.Header>
                    <Skeleton className='w-50 lh-lg mb-2 w-50' />
                </Card.Header>
                <Card.Body>
                    {[...Array(3)].map((i) => (
                        <div key={i} className='d-flex justify-content-start mb-1'>
                            <div className='pe-3'>
                                <Skeleton circle style={{ width: '42px', height: '42px' }} />
                            </div>
                            <div className='w-100'>
                                <Skeleton className='w-100 lh-lg mb-4' style={{ height: '65px' }} />
                            </div>
                        </div>
                    ))}
                </Card.Body>
            </Card>
        </Col>
    </Row>
)
