import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'components/axios'
export class PdrApi {
    static keys = {
        all: ['pdr'],
        project: (directNo) => [...this.keys.all, directNo],
    }

    static get = (directNo) => {
        return axios.get(`/nml/tracker/2/project/${directNo}/json`).then(({ data }) => data)
    }

    static update = (data) => {
        return axios
            .post('/tracker/nml/pdr/1/search_data/json', data)
            .then((response) => response.data)
    }
}

export const usePdrLatestSearch = (direct_no, config) => {
    return useQuery({
        queryKey: PdrApi.keys.project(direct_no),
        queryFn: () =>
            axios
                .post('tracker/nml/pdr/1/search_data/json', {
                    search_type: 'address',
                    full_address: '',
                    direct_no,
                    rerun: 0,
                })
                .then((data) => data?.data),
        enabled: !!direct_no,
        ...config,
    })
}

export const usePdrNewSearch = (direct_no) => {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (body) =>
            axios
                .post('tracker/nml/pdr/1/search_data/json', { ...body, direct_no, rerun: 1 })
                .then((data) => data?.data),
        onSuccess: (data) => {
            queryClient.setQueryData(PdrApi.keys.project(direct_no), data)
        },
    })
}

export const useMarkPrimaryProperty = (direct_no, config) => {
    return useMutation({
        mutationFn: (property_id) =>
            axios
                .patch('tracker/nml/pdr/1/primary_property/json', {
                    property_id,
                    direct_no,
                })
                .then((data) => data),
        ...config,
    })
}

export const useGetPropertyReport = (direct_no) => {
    return useMutation({
        mutationFn: (property_id) =>
            axios
                .post('tracker/nml/pdr/1/create_report_property_id/json', {
                    property_id: [property_id],
                    direct_no,
                    rerun: 1,
                })
                .then((data) => data?.data),
    })
}
