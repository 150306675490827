// Bootstrap styled file picker button

import React, { Component } from 'react'

export default class LoadingSpinner extends Component {
    render() {
        const { id, classNames, bsStyle, bsIcon, style, pulse, size } = this.props

        let iconStyle = 'r'
        switch (bsStyle) {
            case 's':
            case 'solid':
                iconStyle = 's'
                break
            case 'r':
            case 'regular':
                iconStyle = 'r'
                break
            case 'l':
            case 'light':
                iconStyle = 'l'
                break
            default:
                iconStyle = 'r'
                break
        }

        let icon = ''
        switch (bsIcon) {
            case 'spinner-third':
            case 'spinner':
            case 'sync':
            case 'circle-notch':
            case 'cog':
                icon = bsIcon
                break
            default:
                icon = 'spinner-third'
                break
        }

        let iconSize = size?.length > 0 ? ' fa-' + size + ' ' : ''

        return (
            <span
                id={id ? id : null}
                className={
                    'fa' +
                    iconStyle +
                    ' fa-' +
                    icon +
                    iconSize +
                    (pulse ? ' fa-pulse fa-fw' : ' fa-spin') +
                    (classNames ? ' ' + classNames : '')
                }
                style={style ? style : null}
            />
        )
    }
}
