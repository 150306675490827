import classnames from 'classnames'
import { formatDate } from 'components/utils'
import { Form } from 'front'
import { InputGroup } from 'react-bootstrap'
import { isEmpty } from '../../../../../utils'
export default function ProjectDates({ project, updateProject }) {
    // console.log('project: ', project)
    const documents = !isEmpty(project?.documentation) ? Object.values(project.documentation) : []
    const ownerContract = documents?.find((doc) => doc?.document_type == 'GCCONTRACT')
    const noticeOfCompletion = documents?.find((doc) => doc?.document_type == 'COMP')
    return (
        <>
            <DateInput
                className='ms-3'
                label='First Furnishing'
                onChange={([date]) => {
                    updateProject({
                        project: {
                            first_furnishing_date: formatDate(date, 'YYYY-MM-DD'),
                        },
                    })
                }}
                value={formatDate(project?.project?.first_furnishing_date)}
                id='firstFurnishingEstimated'
                estimatedOnChange={() => {
                    updateProject({
                        project: {
                            is_first_furnishing_estimated:
                                !project?.project?.is_first_furnishing_estimated,
                        },
                    })
                }}
                estimatedValue={project?.project?.is_first_furnishing_estimated}
                includeEstimateCheck
                disabled={project?.project?._is_table_state}
            />
            <DateInput
                className='ms-3'
                label='Last Furnishing'
                onChange={([date]) => {
                    updateProject({
                        project: {
                            last_furnishing_date: formatDate(date, 'YYYY-MM-DD'),
                        },
                    })
                }}
                value={formatDate(project?.project?.last_furnishing_date)}
                id='lastFurnishingEstimated'
                estimatedOnChange={() => {
                    updateProject(
                        'is_last_furnishing_estimated',
                        !project?.project?.is_last_furnishing_estimated
                    )
                    updateProject({
                        project: {
                            is_last_furnishing_estimated:
                                !project?.project?.is_last_furnishing_estimated,
                        },
                    })
                }}
                estimatedValue={project?.project?.is_last_furnishing_estimated}
                includeEstimateCheck
                disabled={project?.project?._is_table_state}
            />
            <DateInput
                className='ms-3'
                label={`Owner's Contract`}
                onChange={([date]) => {
                    const payload = {
                        status: 'EXISTS',
                        date: formatDate(date, 'YYYY-MM-DD'),
                    }
                    if (ownerContract) {
                        updateProject({
                            documentation: {
                                ...project.documentation,
                                [ownerContract._id]: {
                                    ...ownerContract,
                                    ...payload,
                                },
                            },
                        })
                    } else {
                        updateProject({
                            documentation: {
                                ...project.documentation,
                                '-1': {
                                    ...payload,
                                    document_type: 'GCCONTRACT',
                                    _create: true,
                                },
                            },
                        })
                    }
                }}
                value={ownerContract?.date ? formatDate(ownerContract?.date) : null}
            />
            <DateInput
                className='ms-3'
                label={`Completion Date`}
                onChange={([date]) => {
                    const payload = {
                        status: 'EXISTS',
                        date: formatDate(date, 'YYYY-MM-DD'),
                    }
                    if (noticeOfCompletion) {
                        updateProject({
                            documentation: {
                                ...project.documentation,
                                [noticeOfCompletion._id]: {
                                    ...noticeOfCompletion,
                                    ...payload,
                                },
                            },
                        })
                    } else {
                        updateProject({
                            documentation: {
                                ...project.documentation,
                                '-1': {
                                    ...payload,
                                    document_type: 'COMP',
                                    _create: true,
                                },
                            },
                        })
                    }
                }}
                value={noticeOfCompletion?.date ? formatDate(noticeOfCompletion?.date) : null}
            />
        </>
    )
}

function DateInput({
    label,
    value,
    onChange,
    estimatedValue,
    estimatedOnChange,
    className,
    id,
    includeEstimateCheck,
    disabled,
}) {
    return (
        <div className={classnames('position-relative', className)}>
            <label className='fs-smaller'>{label}</label>
            <InputGroup>
                <Form.Date
                    className={'py-1 input-group-child'}
                    style={{
                        maxWidth: 130,
                    }}
                    light
                    onChange={onChange}
                    value={value}
                    calendarIconPosition='start'
                    disabled={disabled}
                />
                {includeEstimateCheck && (
                    <Form.Button
                        className='py-1 px-3'
                        id={id}
                        onClick={estimatedOnChange}
                        value={estimatedValue}
                        variant={'white'}
                    >
                        <i
                            className={classnames('fa-swap-opacity', {
                                'fad fa-check-square text-primary': estimatedValue,
                                'fal fa-square text-muted-2': !estimatedValue,
                            })}
                            style={{
                                '--fa-primary-color': 'white',
                                '--fa-secondary-opacity': 1,
                                '--fa-secondary-color': 'var(--bs-primary)',
                            }}
                        />
                    </Form.Button>
                )}
            </InputGroup>
            {includeEstimateCheck && (
                <div
                    className='position-absolute fst-italic text-muted-2 fs-xs'
                    style={{ top: 4, right: 3 }}
                >
                    Estimate
                </div>
            )}
        </div>
    )
}
