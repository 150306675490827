// Children expected in the following format:
//      <li><a href="#"> </a></li>

import { Component } from 'react'

export default class Dropdown extends Component {
    static defaultProps = {
        className: '',
        id: null,
        style: {},
        text: 'Select',
        icon: null,
    }

    render() {
        const { id, className, style, children, text, icon, disabled } = this.props

        return (
            <div className={'btn-group'}>
                <div className='dropdown'>
                    <button
                        className={`btn btn-white dropdown-toggle ${className}`}
                        id={id}
                        style={{ ...style }}
                        data-bs-toggle='dropdown'
                        disabled={disabled}
                    >
                        <span className={icon} />
                        &nbsp;
                        {text}&nbsp;
                    </button>

                    <div className='dropdown-menu'>{children}</div>
                </div>
            </div>
        )
    }
}
