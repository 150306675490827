import React from 'react'
import CopyButton from './CopyButton'

export default function CopyAndSearch({ label, display, btnClass }) {
    return (
        <div className='labeled-input'>
            <div className='info-label'>{label}</div>
            <div className={'btn-group'}>
                <button
                    className={btnClass ? btnClass : 'btn btn-outline-secondary'}
                    onClick={() => {
                        window.open('https://google.com/search?q=' + encodeURIComponent(display))
                    }}
                >
                    <span className={'fab fa-google'} /> {display}
                </button>
                <CopyButton className={'btn btn-outline-secondary'} copy={display} />
            </div>
        </div>
    )
}
