import { useEffect, useState } from 'react'
import axios from '../axios'
import Alert from './Alert'
import EmailTemplateModal from './EmailTemplateModal'
import SortableTable from './SortableTable'
import { Button } from 'front'

export default function EmailTemplates({ department, noHeader }) {
    const [isLoading, setLoading] = useState(true)
    const [templates, setTemplates] = useState([])
    const [addToPersonal, setAddToPersonal] = useState(false)
    const [modal, setModal] = useState(false)
    const [dropdowns, setDropdowns] = useState([])
    const [error, setError] = useState('')
    const [editTemplate, setEditTemplate] = useState(null)
    const ROOT_URL = '/email/template/1/' + department
    const ROOT_URL2 = '/email/template/2/' + department

    useEffect(() => {
        setLoading(true)
        axios.get(`${ROOT_URL}/json`).then(({ data }) => {
            setLoading(false)
            setTemplates(data)
        })
        axios.get(`${ROOT_URL2}/dropdowns/json`).then(({ data }) => {
            setDropdowns(data)
        })
    }, [])

    function deleteTemplate(id) {
        var c = confirm('Are you sure you want to delete this template?')
        if (c) {
            setLoading(true)
            axios.delete(`${ROOT_URL}/${id}/json`).then(({ data }) => {
                if (data.error) {
                    setError(data.error)
                } else {
                    setTemplates(data)
                }
                setLoading(false)
            })
        }
    }

    function openModal(addPersonal) {
        setModal(true)
        setAddToPersonal(addPersonal)
    }

    function headers(general = true) {
        const templatesFind = general ? templates?.general : templates.personal
        return [
            { name: 'Title', value: 'title' },
            { name: 'Subject', value: 'subject' },
            { name: 'Body', value: 'body' },
            {
                name: '',
                value: 'id',
                custom: function customTemplate(id) {
                    return (
                        <div style={{ minWidth: 50 }}>
                            <span
                                className={'far fa-lg fa-edit pointer'}
                                style={{ marginRight: 5 }}
                                onClick={() => {
                                    // console.log('template to edit',id,templatesFind)
                                    setModal(true)
                                    setEditTemplate(
                                        templatesFind.find((template) => template.id === id)
                                    )
                                }}
                            />
                            <span
                                className={'far fa-lg fa-trash text-danger pointer'}
                                onClick={() => {
                                    deleteTemplate(id)
                                }}
                            />
                        </div>
                    )
                },
            },
        ]
    }

    return (
        <div className={'pt-3'}>
            {error.length > 0 && (
                <Alert
                    onClick={() => {
                        setError('')
                    }}
                >
                    {error}
                </Alert>
            )}
            {!noHeader && (
                <div className={'row mb-3'}>
                    <div className={'col-12'}>
                        <h2>Email Templates</h2>
                    </div>
                </div>
            )}
            {isLoading && (
                <div className={'row'}>
                    <div className={'col-12'}>
                        <h3 style={{ margin: 0 }}>
                            <span className={'far fa-spin fa-spinner'} /> Loading...
                        </h3>
                    </div>
                </div>
            )}
            {templates?.general?.length === 0 && !isLoading && (
                <div className={'row'}>
                    <div className={'col-12'}>
                        <h3>
                            No general template please add a template
                            <Button
                                className={'float-end mb-2'}
                                onClick={() => {
                                    openModal(false)
                                }}
                            >
                                <span className={'far fa-plus'} /> General
                            </Button>
                        </h3>
                    </div>
                </div>
            )}
            {templates?.general?.length > 0 && (
                <div className={'row'}>
                    <div className={'col-12 col-md-6'}>
                        <h4>General</h4>
                    </div>
                    <div className={'col-12 col-md-6'}>
                        <Button
                            className={'float-end mb-2'}
                            onClick={() => {
                                openModal(false)
                            }}
                        >
                            <span className={'far fa-plus'} /> General
                        </Button>
                    </div>
                    <div className={'col-12'}>
                        <SortableTable
                            headers={headers(true)}
                            info={templates?.general}
                            startSort={'title'}
                        />
                    </div>
                </div>
            )}
            {templates?.personal?.length === 0 && !isLoading && (
                <div className={'row'}>
                    <div className={'col-12'}>
                        <h3>
                            No personal template please add a template
                            <Button
                                className={'float-end mb-2'}
                                onClick={() => {
                                    openModal(true)
                                }}
                            >
                                <span className={'far fa-plus'} /> Personal
                            </Button>
                        </h3>
                    </div>
                </div>
            )}
            {templates?.personal?.length > 0 && (
                <div className={'row'}>
                    <div className={'col-12 col-md-6'}>
                        <h4>Personal</h4>
                    </div>
                    <div className={'col-12 col-md-6'}>
                        <Button
                            className={'float-end mb-2'}
                            onClick={() => {
                                openModal(true)
                            }}
                        >
                            <span className={'far fa-plus'} /> Personal
                        </Button>
                    </div>
                    <div className={'col-12'}>
                        <SortableTable
                            headers={headers(false)}
                            info={templates?.personal}
                            startSort={'title'}
                        />
                    </div>
                </div>
            )}
            <EmailTemplateModal
                show={modal}
                hide={() => {
                    setModal(false)
                    setEditTemplate([])
                }}
                addToPersonal={addToPersonal}
                dropdown={dropdowns}
                edit={editTemplate}
                updateTemplates={(templates) => {
                    setTemplates(templates)
                }}
                department={department}
            />
        </div>
    )
}
