import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { mergeConfigs } from '../../../../utils'
import axios from '../Context/AxiosSetup'
import { Project } from './project.api'

// Used for attorney and firm related APIs
export class RecordedFilings {
    static keys = {
        search: (search) => ['recordedFilings', 'search', search],
    }

    static createFiling = (filing) =>
        axios
            .post('/nml/tracker/2/project/recorded_filing/json', filing)
            .then((response) => response.data)

    static updateFiling = (id, update) =>
        axios
            .patch(`/nml/tracker/2/project/recorded_filing/${id}/json`, update)
            .then((response) => response.data)

    static deleteFiling = (id) =>
        axios
            .delete(`/nml/tracker/2/project/recorded_filing/${id}/json`)
            .then((response) => response.data)

    static searchFilings = (fileNumberSearch) =>
        axios
            .get(`/nml/tracker/2/project/recorded_filing/search/${fileNumberSearch}/json`)
            .then((response) => response.data)
}

export const useCreateFiling = (project_id, config) => {
    const queryClient = useQueryClient()
    return useMutation(
        ({ file_number, recorded_date }) =>
            RecordedFilings.createFiling({
                project_id,
                file_number,
                recorded_date,
            }),
        mergeConfigs(
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(Project.keys.project(project_id))
                },
            },
            config
        )
    )
}

export const useUpdateFiling = (projectId, filingId, config) => {
    const queryClient = useQueryClient()
    return useMutation(
        (update) => RecordedFilings.updateFiling(filingId, update),
        mergeConfigs(
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(Project.keys.project(projectId))
                },
            },
            config
        )
    )
}

export const useDeleteFiling = (projectId, config) => {
    const queryClient = useQueryClient()
    return useMutation(
        (id) => RecordedFilings.deleteFiling(id),
        mergeConfigs(
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(Project.keys.project(projectId))
                },
            },
            config
        )
    )
}

export const useSearchFilings = (search, config) => {
    return useQuery(
        RecordedFilings.keys.search(search),
        () => RecordedFilings.searchFilings(search),
        mergeConfigs(
            {
                enabled: !!search,
            },
            config
        )
    )
}
