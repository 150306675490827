import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import useDashboardQueue from '../../hooks/useDashboardQueue.js'
import AddEditNoteModal from '../Notes/AddEditNoteModal'
import NoteOverlay from '../Notes/NoteOverlay'
import ProjectListModal from './ProjectListModal'

export const useKeyDown = (callback, key, specialKeys) => {
    const onKeyDown = (event) => {
        const wasAnyKeyPressed =
            event.key?.toLowerCase() == key?.toLowerCase() &&
            (specialKeys?.length > 0
                ? specialKeys?.filter((key) => event?.[key])?.length === specialKeys?.length
                : true)

        if (wasAnyKeyPressed) {
            event.preventDefault()
            callback()
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown)
        return () => {
            document.removeEventListener('keydown', onKeyDown)
        }
    }, [onKeyDown])
}

export default function Index({}) {
    const [modalType, setModalType] = useState(0)
    const [messages, setMessages] = useState([])
    const { fileInfo } = useContext(ProjectContext)
    const router = useRouter()
    const { project_id } = fileInfo || {}
    const { currentList } = useDashboardQueue()
    const files = currentList ?? []
    // console.log('project_id: ', project_id)
    // console.log('fileInfo: ', fileInfo)

    useKeyDown(() => {
        setModalType(0)
    }, 'Escape')

    //TOP SECRET ADDING SPIN TO EVERY DIV
    useKeyDown(
        () => {
            let divs = document.getElementsByTagName('div')

            for (let i = 0; i < divs?.length; i++) {
                const div = divs[i]
                if (div?.className?.includes('fa-spin')) {
                    div?.classList?.remove('fa-spin')
                } else {
                    div?.classList?.add('fa-spin')
                }i
            }
        },
        'z',
        ['ctrlKey', 'altKey', 'shiftKey']
    )

    //open file list modal
    useKeyDown(
        () => {
            setModalType(2)
        },
        'j',
        ['ctrlKey']
    )

    //open next project in list
    useKeyDown(
        () => {
            const index = getFileIndex()
            if (index == files?.length - 1) {
                setMessages((current) => [...current, 'This is the last project in the list'])
            } else {
                const file = files?.[index + 1]
                router.push(`/nml/tracker/file/overview?project_id=${file?.project_id}`)
            }
        },
        'i',
        ['ctrlKey']
    )

    //open previous project in list
    useKeyDown(
        () => {
            const index = getFileIndex()
            if (index == 0) {
                setMessages((current) => [...current, 'First project in list already'])
            } else {
                const file = files?.[index - 1]
                router.push(`/nml/tracker/file/overview?project_id=${file?.project_id}`)
            }
        },
        'u',
        ['ctrlKey']
    )

    //open notes overlay
    useKeyDown(
        () => {
            setModalType(3)
        },
        'n',
        ['altKey', 'shiftKey']
    )

    //open add note modal
    useKeyDown(
        () => {
            setModalType(4)
        },
        'n',
        ['altKey']
    )

    //open overview page
    useKeyDown(
        () => {
            router.push(`/nml/tracker/file/overview?project_id=${project_id}`)
        },
        '1',
        ['ctrlKey']
    )

    //open tasks page
    useKeyDown(
        () => {
            router.push(`/nml/tracker/file/tasks?project_id=${project_id}`)
        },
        '2',
        ['ctrlKey']
    )

    //open associates page
    useKeyDown(
        () => {
            router.push(`/nml/tracker/file/associates?project_id=${project_id}`)
        },
        '3',
        ['ctrlKey']
    )

    //open dates page
    useKeyDown(
        () => {
            router.push(`/nml/tracker/file/dates?project_id=${project_id}`)
        },
        '4',
        ['ctrlKey']
    )

    function getFileIndex() {
        // console.log('project_id: ', project_id)
        if (project_id) {
            return files?.findIndex((file) => {
                return file?.project_id == project_id
            })
        } else {
            return 0
        }
    }

    return (
        <>
            <ProjectListModal
                show={modalType === 2}
                onHide={() => {
                    setModalType(0)
                }}
            />
            <AddEditNoteModal
                show={modalType === 3}
                cancel={() => {
                    setModalType(0)
                }}
            />
            <NoteOverlay
                noButton
                show={modalType === 4}
                onHide={() => {
                    setModalType(0)
                }}
            />

            <div style={{ position: 'absolute', top: 20, right: 20, zIndex: 500 }}>
                {messages?.map((message, i) => {
                    return (
                        <AlertDismissible
                            key={i}
                            message={message}
                            onClose={() => {
                                setMessages((current) => current?.filter((m, key) => key != i))
                            }}
                        />
                    )
                })}
            </div>
        </>
    )
}

function AlertDismissible({ message, onClose }) {
    return (
        <Alert variant='danger' onClose={onClose} dismissible>
            <p>{message}</p>
        </Alert>
    )
}
