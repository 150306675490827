import { GET_STAGE_TOTALS, STAGE_TOTALS_LOADING, CHANGE_STAGE_TOTAL_EMP, SET_NYB } from './action'
import Cookies from 'js-cookie'
const employee = Cookies.getJSON('employee')
const getEmpID = function () {
    return Cookies.getJSON('employee')?.emp_id
}

export default function StageTotalsReducer(
    state = { isLoading: true, stage_totals: [], selected: getEmpID() },
    action
) {
    switch (action.type) {
        case GET_STAGE_TOTALS:
            // console.log('reducer',state.stage_totals)
            const nyb = state.stage_totals.find((stage) => stage.stage_id == -2)
            let stage_totals = action.stage_totals
            nyb && stage_totals.push(nyb)
            return { ...state, stage_totals }
        case STAGE_TOTALS_LOADING:
            return { ...state, isLoading: action.set }
        case CHANGE_STAGE_TOTAL_EMP:
            return { ...state, selected: action.emp_id }
        case SET_NYB:
            const current_totals = state.stage_totals.filter((stage) => stage.stage_id !== -2)

            if (action.nyb !== false) {
                current_totals.push(action.nyb)
            }
            // console.log('nyb',action.nyb,current_totals)
            return { ...state, stage_totals: current_totals }
        default:
            return state
    }
}
