import React, { useState } from 'react'
import LoadingSpinner from '../../utils/LoadingSpinner'

export default function FileDownload({ download, id, name, className }) {
    const [isLoading, setIsLoading] = useState(false)

    function onClick() {
        if (!isLoading) {
            setIsLoading(true)
            download(id, () => {
                setIsLoading(false)
            })
        }
    }

    return (
        <div className={'download-message-doc ' + className} onClick={onClick}>
            {isLoading && <LoadingSpinner />}
            Download - {name}
            <span className={'float-end far fa-download pt-1 ms-1'} />
        </div>
    )
}
