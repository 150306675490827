import { DataList, formatDate } from 'components/utils'
import { Button, Form } from 'front'
import { useContext, useEffect, useState } from 'react'
import { Col, InputGroup, Modal, Row } from 'react-bootstrap'
import { useTrackerDropdowns } from 'tracker/api'
import { ProjectContext } from 'tracker/Context/ProjectContext'

export default function NewDateModal({ show, onHide, edit, customLabel, onSave }) {
    const [date, setDate] = useState(edit?.due_date ?? undefined)
    const [typeId, setTypeId] = useState(undefined)
    const [ncsOnly, setNcsOnly] = useState(edit?.is_internal_only ?? false)
    const [custom_label, setCustomLabel] = useState(customLabel ?? '')
    const { updateFileEdit, fileInfo } = useContext(ProjectContext)
    const { data: dropdowns } = useTrackerDropdowns()

    useEffect(() => {
        if (edit) {
            setCustomLabel(customLabel ?? '')
            setDate(edit?.due_date)
            setNcsOnly(edit?.is_internal_only ?? false)
        }
    }, [edit, customLabel])

    const options =
        dropdowns?.deadline_custom_defaults?.map((type) => ({
            alt: type?.label,
            value: type?.label,
        })) ?? []

    const hideModal = () => {
        if (!edit) {
            setCustomLabel('')
            setDate(undefined)
            setTypeId(undefined)
            setNcsOnly(false)
        }
        onHide()
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Add Custom Date</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={7}>
                        <Form.Label>Label</Form.Label>
                        <InputGroup>
                            <DataList
                                value={custom_label}
                                list={'addCustomDeadlineDate'}
                                options={options}
                                onChange={({ target: { value } }) => {
                                    setCustomLabel(value)
                                }}
                                placeHolder='Type or choose label'
                                classNames={
                                    'cursor-text form-control-light ' +
                                    (custom_label?.length > 0 ? 'form-control' : 'form-select')
                                }
                            />
                            {custom_label?.length > 0 && (
                                <Button
                                    onClick={() => {
                                        setCustomLabel('')
                                    }}
                                >
                                    <span className='far fa-times' />
                                </Button>
                            )}
                        </InputGroup>
                    </Col>
                    <Col>
                        <Form.Label>Due Date</Form.Label>
                        <div className='d-flex'>
                            <Form.Date
                                light
                                value={formatDate(date)}
                                onChange={([newDate]) => {
                                    console.log('newDate: ', newDate)
                                    setDate(newDate ? formatDate(newDate, 'YYYY-MM-DD') : null)
                                }}
                                removeDateButton
                            />
                        </div>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col>
                        <Form.Check
                            type='switch'
                            label='Only viewable by NCS'
                            checked={ncsOnly}
                            onChange={({ target: { checked } }) => setNcsOnly(checked)}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <Button ghost variant='secondary' onClick={hideModal}>
                    Cancel
                </Button>
                <Button
                    variant='primary'
                    onClick={() => {
                        console.log('creating', typeId)
                        const id = edit?._id ?? Math.random() * -1
                        const data = {
                            due_date: date,
                            custom_label,
                            is_internal_only: ncsOnly,
                        }

                        if (onSave) {
                            onSave(id, data)
                        } else {
                            updateFileEdit({
                                deadlines: {
                                    [Math.random() * -1]: {
                                        ...data,
                                        deadline_source: 'CUSTOM',
                                        _create: true,
                                    },
                                },
                            })
                        }
                        hideModal()
                    }}
                    disabled={!custom_label}
                >
                    {edit ? 'Update' : 'Add'} Date
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
