import React, { useContext } from 'react'
import EmailModal from '../../email_form/EmailModal'
import { DynamicCardContext } from '../Context/DynamicCardContext'
// import Modal from "../../Modal";

export default function ActionOption({ data }) {
    const { docuwareOptions, apiCall } = useContext(DynamicCardContext)
    // const [ apiReturn, setApiReturn ] = useState('')
    // console.log('action option',data)
    switch (data.type) {
        case 'email':
            return (
                <EmailModal {...docuwareOptions} {...data.info} btnStyle={'dropdown-item'}>
                    {data.label}
                </EmailModal>
            )
        case 'api':
            return (
                <div
                    className={'dropdown-item'}
                    onClick={() => {
                        apiCall(data, (data) => {
                            console.log('api call from dropdown', data)
                        })
                    }}
                >
                    {data.label}
                    {/*<Modal title={'Info Returned'}>*/}

                    {/*</Modal>*/}
                </div>
            )
        default:
            return false
    }
}
