import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { orderBy } from 'lodash'
import axios from '../Context/AxiosSetup'
import { useRouter } from 'next/router'
import { mergeConfigs } from 'utils'
import { Project } from './project.api'
import { useLocal } from 'hooks'
import { useEffect } from 'react'

export class ProjectSearch {
    static keys = {
        all: ['projectSearch'],
        type: (type) => [...this.keys.all, type],
        project: (projectId) => [...this.keys.advanced, projectId],
        baseline: (employee_id) => [...this.keys.all, 'baseline' + employee_id],
    }

    static search = (payload) =>
        axios.post('/nml/tracker/2/search/json', payload).then(({ data }) => data)

    static import = (project_id) =>
        axios
            .post(`/nml/tracker/1/project/${project_id}/workflow/PORTAL/json`)
            .then(({ data }) => data)
}

export const useProjectSearch = (filters, config) => {
    const [currentSearchCriteria, setCurrentSearchCriteria] = useLocal(
        'current-tracker-search',
        filters
    )

    useEffect(() => {
        setCurrentSearchCriteria(filters)
    }, [filters])

    return useQuery(ProjectSearch.keys.type(filters), () => ProjectSearch.search(filters), {
        enabled: !!filters,
        ...config,
    })
}

export const useEmployeeBaseLine = (employee_id, config) => {
    return useQuery(
        ProjectSearch.keys.baseline(employee_id),
        () => ProjectSearch.search({ employee_id }),
        {
            ...config,
        }
    )
}

function orderFiles(files, sort, sortDirection) {
    return orderBy(files, [sort], [sortDirection])
}

export const useImportProject = ({ project_id, filters }, config) => {
    const queryClient = useQueryClient()
    const router = useRouter()

    return useMutation(
        () => ProjectSearch.import(project_id),
        mergeConfigs(
            {
                onSuccess: (response) => {
                    queryClient.setQueryData(Project.keys.project(project_id), {
                        ...queryClient.getQueryData(Project.keys.project(project_id)),
                        ...response,
                    })
                },
            },
            config
        )
    )
}
