import classnames from 'classnames'
import { getNcsIcon } from '../../utils'
export default function NcsIcon({
    variant = 'fal',
    dept,
    category,
    value,
    className,
    size = 'md',
    fallback,
    ...props
}) {
    let icon = getNcsIcon({ dept, category, variant, value, fallback, size })
    return <i className={classnames(icon, className)} {...props} />
}
