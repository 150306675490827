import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { mergeConfigs } from '../../../../utils'
import axios from '../Context/AxiosSetup'
import { Project } from './project.api'
import { Printing } from './printing.api'
import { Workflow, useUpdateServiceWorkflow } from './workflow.api'
import { Task } from './task.api'
export class Docgen {
    static keys = {
        all: ['docgen'],
        templatesForProject: (projectId) => [
            ...this.keys.all,
            'templateOptionsByProject',
            projectId?.toString(),
        ],
        templatesForTask: (projectId, taskId) => [
            ...this.keys.templatesForProject(projectId),
            'templateOptionsByTaskId',
            taskId?.toString(),
        ],
        projectPackets: (projectId) => [...this.keys.all, 'projectPackets', projectId?.toString()],
        packet: (packetId) => [...this.keys.all, 'packet', packetId?.toString()],
    }

    static getTemplatesForTask = (projectId, taskId) => {
        return axios
            .get(`/nml/tracker/2/template/list/${projectId}/${taskId}/json`)
            .then(({ data }) => data)
    }

    static getProjectPackets = (projectId) => {
        return axios
            .post(`/nml/tracker/2/packet/list/${projectId}/json`)
            .then((response) => response.data)
    }

    static getPacket = (packetId) => {
        return axios.get(`/nml/tracker/2/packet/${packetId}/json`).then((response) => response.data)
    }

    static createPacket = (projectId, taskId, data) => {
        return axios
            .post(`/nml/tracker/2/packet/create/${projectId}/${taskId}/json`, data)
            .then((response) => {
                return response.data
            })
    }

    static updatePacket = (packetId, data) => {
        return axios
            .patch(`/nml/tracker/2/packet/${packetId}/json`, data)
            .then((response) => response.data)
    }

    static revertPacketToDraft = (packetId) => {
        return axios
            .post(`/nml/tracker/2/packet/submit/draft/${packetId}/json`)
            .then((response) => response.data)
    }

    static submitPacketForReview = async (packetId) => {
        return axios
            .post(`/nml/tracker/2/packet/submit/pending_review/${packetId}/json`)
            .then((response) => response.data)
    }

    static submitPacketForPrint = (packetId) => {
        return axios
            .post(`/nml/tracker/2/packet/submit/print_queued/${packetId}/json`)
            .then((response) => response.data)
    }

    // Used when you don't want to print the packet but mark it as complete
    static completePacketElectronically = (packetId) => {
        return axios
            .post(`/nml/tracker/2/packet/submit/completed/${packetId}/json`)
            .then((response) => response.data)
    }

    static deletePacket = (packetId) => {
        return axios
            .post(`/nml/tracker/2/packet/submit/deleted/${packetId}/json`)
            .then((response) => response.data)
    }
}

export const useTemplatesForTask = (projectId, taskId, config) => {
    const key = Docgen.keys.templatesForTask(taskId)
    const api = () => Docgen.getTemplatesForTask(projectId, taskId)
    const configs = mergeConfigs(
        {
            enabled: !!(projectId && taskId),
            // staleTime: 20 * 60 * 1000, // 20 mins
        },
        config
    )

    return useQuery(key, api, configs)
}

export const useProjectPackets = (projectId, config) => {
    const key = Docgen.keys.projectPackets(projectId)
    const api = () => Docgen.getProjectPackets(projectId)
    const configs = mergeConfigs(
        {
            enabled: !!projectId,
        },
        config
    )

    return useQuery(key, api, configs)
}

export const usePacket = (packetId, config) => {
    const key = Docgen.keys.packet(packetId)
    const api = () => Docgen.getPacket(packetId)
    const configs = mergeConfigs(
        {
            enabled: !!packetId,
        },
        config
    )

    return useQuery(key, api, configs)
}

export const useCreatePacket = (projectId, config) => {
    const queryClient = useQueryClient()

    const api = ({ taskId, data }) => Docgen.createPacket(projectId, taskId, data)
    const configs = mergeConfigs(
        {
            onSuccess: (response) => {
                queryClient.invalidateQueries(Project.keys.project(projectId))
                queryClient.setQueryData(Docgen.keys.packet(response?.packet?.id), response?.packet)

                const currentPackets = queryClient.getQueryData(
                    Docgen.keys.projectPackets(response?.packet?.project_id)
                )
                queryClient.setQueryData(Docgen.keys.projectPackets(response?.packet?.project_id), [
                    ...currentPackets,
                    response?.packet,
                ])
            },
        },
        config
    )

    return useMutation(api, configs)
}

export const useUpdatePacket = (projectId, config) => {
    const queryClient = useQueryClient()

    const api = ({ packetId, data }) => Docgen.updatePacket(packetId, data)
    const configs = mergeConfigs(
        {
            onSuccess: (response) => {
                const curretProject = queryClient.getQueryData(
                    Project.keys.project(response?.packet?.project_id)
                )

                queryClient.setQueryData(Docgen.keys.packet(response?.packet?.id), response?.packet)
                queryClient.setQueryData(Project.keys.project(response?.packet?.project_id), {
                    ...curretProject,
                    tasks: curretProject?.tasks?.map((task) => {
                        if (task?.id === response?.task?.id) {
                            return {
                                ...task,
                                ...response?.task,
                            }
                        } else return task
                    }),
                })
                queryClient.setQueryData(
                    Docgen.keys.projectPackets(response?.packet?.project_id),
                    queryClient
                        .getQueryData(Docgen.keys.projectPackets(response?.packet?.project_id))
                        .map((packet) => {
                            if (packet?.id === response?.packet?.id) return response?.packet
                            else return packet
                        })
                )
            },
        },
        config
    )

    return useMutation(api, configs)
}

export const useRevertPacketToDraft = ({ config, project_id, task_id, employee_id }) => {
    const queryClient = useQueryClient()

    const { mutate: updateWorkflow } = useUpdateServiceWorkflow({
        project_id,
        task_id,
    })

    const mutationFn = async (packetId) => {
        const docgenPacket = await Docgen.revertPacketToDraft(packetId)
        updateWorkflow({
            stage: 'PREPARING',
            process: 'rollback',
        })

        return docgenPacket
    }

    const configs = mergeConfigs(
        {
            onSuccess: (response) => {
                queryClient.setQueryData(Docgen.keys.packet(response?.id), response)
                queryClient.invalidateQueries(Printing.keys.queue())
                queryClient.invalidateQueries(Project.keys.project(project_id))
                queryClient.invalidateQueries(Docgen.keys.projectPackets(project_id))
            },
        },
        config
    )
    return useMutation({ mutationFn, ...configs })
}

export const useSubmitPacketForReview = ({ config, project_id, task_id, employee_id }) => {
    const queryClient = useQueryClient()

    const { mutate: updateWorkflow } = useUpdateServiceWorkflow({
        project_id,
        task_id,
    })

    const mutationFn = async (packetId) => {
        const docgenPacket = await Docgen.submitPacketForReview(packetId)
        updateWorkflow({
            stage: 'REVIEW',
            process: 'skipto',
        })

        return docgenPacket
    }
    const configs = mergeConfigs(
        {
            onSuccess: (response) => {
                queryClient.setQueryData(Docgen.keys.packet(response?.id), response)
                queryClient.invalidateQueries(Printing.keys.queue())
                queryClient.invalidateQueries(Project.keys.project(project_id))
                queryClient.invalidateQueries(Docgen.keys.projectPackets(project_id))
            },
        },
        config
    )
    return useMutation({ mutationFn, ...configs })
}

export const useSubmitPacketForPrint = ({ config, project_id, task_id, employee_id }) => {
    const queryClient = useQueryClient()

    const { mutate: updateWorkflow } = useUpdateServiceWorkflow({
        project_id,
        task_id,
    })

    const mutationFn = async (packetId) => {
        const docgenPacket = await Docgen.submitPacketForPrint(packetId)
        updateWorkflow({
            stage: 'SERVING',
            process: 'skipto',
            employee_id,
        })

        return docgenPacket
    }
    const configs = mergeConfigs({
        onSuccess: (response) => {
            queryClient.invalidateQueries(Project.keys.project(project_id))
            queryClient.invalidateQueries(Docgen.keys.projectPackets(project_id))
            queryClient.setQueryData(Docgen.keys.packet(response?.id), response)
        },
        config,
    })
    return useMutation({ mutationFn, ...configs })
}

export const useCompletePacketElectronically = ({ config, project_id, task_id, employee_id }) => {
    const queryClient = useQueryClient()

    const { mutate: updateWorkflow } = useUpdateServiceWorkflow({
        project_id,
        task_id,
    })

    const mutationFn = async (packetId) => {
        const docgenPacket = await Docgen.completePacketElectronically(packetId)
        // updateWorkflow({
        //     stage: 'SERVING',
        //     process: 'skipto',
        //     employee_id,
        // })

        return docgenPacket
    }
    const configs = mergeConfigs({
        onSuccess: (response) => {
            queryClient.invalidateQueries(Project.keys.project(project_id))
            queryClient.invalidateQueries(Docgen.keys.projectPackets(project_id))
            queryClient.setQueryData(Docgen.keys.packet(response?.id), response)
        },
        config,
    })
    return useMutation({ mutationFn, ...configs })
}

export const useDeletePacket = (config) => {
    const queryClient = useQueryClient()

    const api = (packetId) => Docgen.deletePacket(packetId)
    const configs = mergeConfigs(
        {
            onSuccess: (response) => {
                queryClient.invalidateQueries(Project.keys.project(response?.project_id))
                queryClient.setQueryData(Docgen.keys.packet(response?.id), response)
                queryClient.setQueryData(
                    Docgen.keys.projectPackets(response?.project_id),
                    queryClient
                        .getQueryData(Docgen.keys.projectPackets(response?.project_id))
                        .map((packet) => {
                            if (packet?.id === response?.id) return response
                            else return packet
                        })
                )
            },
        },
        config
    )
    return useMutation(api, configs)
}
