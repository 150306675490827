import classnames from 'classnames'
import { Col, Row } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { Button } from '../../../../front'
import { Tooltip } from '@/components/utils'
export default function NavbarInfo() {
    const shortcuts = [
        {
            section: 'General',
            shortcuts: [
                { code: ['Ctrl', 'K'], display: 'Search' },
                { code: ['Ctrl', 'J'], display: 'Project List' },
                { code: ['Ctrl', 'U'], display: 'Previous Project' },
                { code: ['Ctrl', 'I'], display: 'Next Project' },
                { code: ['Alt', 'N'], display: 'Open Notes' },
                { code: ['Alt', 'Shift', 'N'], display: 'Add Note' },
            ],
        },
        {
            section: 'Pages',
            shortcuts: [
                { code: ['Ctrl', '1'], display: 'Overview' },
                { code: ['Ctrl', '2'], display: 'Tasks' },
                { code: ['Ctrl', '3'], display: 'Associates' },
                { code: ['Ctrl', '4'], display: 'Dates' },
            ],
        },
    ]

    return (
        <OverlayTrigger
            trigger={'click'}
            placement={'top'}
            rootClose
            overlay={
                <Popover id={'keyboard-shortcuts'} style={{ maxWidth: 1000 }}>
                    <Popover.Header as='h3'>Keyboard Shortcuts</Popover.Header>
                    <Popover.Body>
                        <Row>
                            {shortcuts?.map((shortcut, i) => (
                                <ShortcutDisplay key={i} {...shortcut} />
                            ))}
                        </Row>
                    </Popover.Body>
                </Popover>
            }
            style={{ maxWidth: '50%', width: 500 }}
        >
            <div>
                <Tooltip content='Keyboard Shortcuts' trigger={['hover', 'click']}>
                    <div className='position-relative'>
                        <Button icon='fal fa-keyboard fa-lg' circle ghost variant='secondary' />
                    </div>
                </Tooltip>
            </div>
        </OverlayTrigger>
    )
}

function ShortcutDisplay({ section, shortcuts, margin }) {
    return (
        <Col className={classnames({ 'mt-3': margin })}>
            <h5>{section}</h5>
            {shortcuts?.map((shortcut, i) => (
                <div key={i} className={'d-flex align-items-center'}>
                    <div className='p-2 ps-0 text-center me-3' style={{ whiteSpace: 'nowrap' }}>
                        {shortcut?.display}
                    </div>
                    <div className='p-2 text-end flex-grow-1' style={{ whiteSpace: 'nowrap' }}>
                        {shortcut?.code?.map((code, i) => (
                            <>
                                <kbd>{code}</kbd>
                                {i !== shortcut?.code?.length - 1 && (
                                    <i className='fal fa-plus fa-xs mx-2' />
                                )}
                            </>
                        ))}
                    </div>
                </div>
            ))}
        </Col>
    )
}
