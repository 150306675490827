import { useEffect, useState } from 'react'
import { Button } from '../front'

export default function CustomizeColumns({ customizeColumnOptions, saveCustomColumns }) {
    const [options, setOptions] = useState([])

    useEffect(() => {
        if (customizeColumnOptions) {
            setOptions(customizeColumnOptions)
        }
    }, [customizeColumnOptions])

    function save() {
        saveCustomColumns(options)
    }

    function onChange({ target }) {
        setOptions([
            ...options?.map((d) =>
                d.option == target.name ? { ...d, value: target.checked } : { ...d }
            ),
        ])
    }

    return (
        <div className={'dropdown'}>
            <Button ghost variant='secondary' icon='far fa-gear' data-bs-toggle='dropdown' />
            <div className={'dropdown-menu'}>
                {options?.map((option, i) => (
                    <div
                        key={i}
                        className='d-flex align-items-center justify-content-start form-check form-switch form-switch-between mb-3 fw-normal'
                    >
                        <input
                            name={option.option}
                            type='checkbox'
                            className='form-check-input'
                            checked={option.value == 1}
                            value={option.value}
                            onChange={onChange}
                        />
                        <label className='form-check-label'>{option.label}</label>
                    </div>
                ))}
                <Button className={'w-100'} onClick={save} data-bs-toggle='dropdown'>
                    Save
                </Button>
            </div>
        </div>
    )
}
