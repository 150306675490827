import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { mergeConfigs } from '../../../../utils'
import axios from '../Context/AxiosSetup'
import { Project } from './project.api'
import { ProjectHistory } from './projectHistory.api'

export default class ProjectVersion {
    static keys = {
        all: ['projectVersion'],
        version: (versionId) => [...this.keys.all, 'version', versionId],
        versionList: (projectId) => [...this.keys.all, 'versionList', projectId],
    }

    static getVersion = (versionId) => {
        return axios.get(`/nml/tracker/2/version/detail/${versionId}/json`).then(({ data }) => data)
    }

    static getVersionList = (projectId) => {
        return axios.get(`/nml/tracker/2/version/list/${projectId}/json`).then(({ data }) => data)
    }

    static releaseLock = (projectId) => {
        console.log('api - releasing lock', projectId)
        return axios
            .post(`/nml/tracker/2/version/release/${projectId}/json`)
            .then(({ data }) => data)
    }

    static acceptOls = (projectId, versionId, changes) => {
        return axios
            .post(`/nml/tracker/2/version/review/incoming/accept/${projectId}/json`, {
                version_id: versionId,
            })
            .then(({ data }) => data)
    }
    static rejectOls = (projectId, versionId, changes) => {
        return axios
            .post(`/nml/tracker/2/version/review/incoming/discard/${projectId}/json`, {
                version_id: versionId,
            })
            .then(({ data }) => data)
    }
    static mergeOls = (projectId, versionId, changes) => {
        return axios
            .post(`/nml/tracker/2/version/review/incoming/merge/${projectId}/json`, {
                version_id: versionId,
                changes,
            })
            .then(({ data }) => data)
    }
    static revertToVersion = (projectId, versionId) => {
        return axios
            .post('/nml/tracker/2/version/revert/json', {
                project_id: projectId,
                version_id: versionId,
            })
            .then(({ data }) => data)
    }
}

export const useVersion = ({ versionId }, config) => {
    return useQuery(
        ProjectVersion.keys.version(versionId),
        () => ProjectVersion.getVersion(versionId),
        mergeConfigs(
            {
                enabled: !!versionId,
            },
            config
        )
    )
}

export const useVersionList = (projectId, config) => {
    return useQuery(
        ProjectVersion.keys.version(projectId),
        () => ProjectVersion.getVersionList(projectId),
        mergeConfigs(
            {
                enabled: !!projectId,
            },
            config
        )
    )
}

export const useReleaseLock = (config) => {
    return useMutation(
        (projectId) => ProjectVersion.releaseLock(projectId),
        mergeConfigs({}, config)
    )
}

export const useAcceptOls = (projectId, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        ({ versionId }) => ProjectVersion.acceptOls(projectId, versionId),
        mergeConfigs(
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries(Project.keys.project(projectId))
                    queryClient.invalidateQueries(ProjectHistory.keys.project(projectId))
                },
            },
            config
        )
    )
}
export const useRejectOls = (projectId, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        ({ versionId }) => ProjectVersion.rejectOls(projectId, versionId),
        mergeConfigs(
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries(Project.keys.project(projectId))
                    queryClient.invalidateQueries(ProjectHistory.keys.project(projectId))
                },
            },
            config
        )
    )
}
export const useMergeOls = (projectId, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        ({ versionId, changes }) => ProjectVersion.mergeOls(projectId, versionId, changes),
        mergeConfigs(
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries(Project.keys.project(projectId))
                    queryClient.invalidateQueries(ProjectHistory.keys.project(projectId))
                },
            },
            config
        )
    )
}

export const useRevertToVersion = (projectId, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        (versionId) => ProjectVersion.revertToVersion(projectId, versionId),
        mergeConfigs(
            {
                onSuccess: () => {
                    queryClient.invalidateQueries(Project.keys.project(projectId))
                    queryClient.invalidateQueries(ProjectHistory.keys.project(projectId))
                },
            },
            config
        )
    )
}
