import { Card, ListGroup } from 'front'
import { useState } from 'react'
import { toTitleCase } from 'utils'
import { DocumentIcon } from './DocumentIcon'
export default function InitialTemplatePicker({ packet }) {
    const [showAll, setShowAll] = useState(false)

    const templateOptions = showAll ? packet?.templateOptions : packet?.templateOptions?.slice(0, 5)

    return (
        <>
            <Card style={{ marginBottom: 24 }}>
                <Card.Header className='d-flex justify-content-between align-items-center'>
                    <Card.Title as='h4'>Choose Template</Card.Title>
                </Card.Header>
                <ListGroup noBorders>
                    {templateOptions?.map((template) => (
                        <Template
                            key={template?.id}
                            packet={packet}
                            template={template}
                            onClick={() => {
                                packet.addTemplate(template?.id)
                            }}
                        />
                    ))}
                    {!showAll && (
                        <ListGroup.Item
                            action
                            onClick={() => setShowAll(true)}
                            className='d-flex justify-content-center cursor-pointer'
                        >
                            Show All
                        </ListGroup.Item>
                    )}
                </ListGroup>
            </Card>
        </>
    )
}

function Template({ template, onClick }) {
    return (
        <ListGroup.Item
            className='d-flex align-items-center cursor-pointer'
            action
            onClick={onClick}
        >
            <DocumentIcon type={'template'} className='mx-2 my-2 flex-shrink-0 fa-fw' />
            <div className='ms-2 w-100'>
                <div className='fw-bold text-dark'>
                    {template?.label ? (
                        toTitleCase(template?.label)
                    ) : (
                        <i className='text-body'>No template name</i>
                    )}
                </div>
                <div className='text-muted'>
                    {[
                        toTitleCase(template?.task_category),
                        toTitleCase(template?.project_type_scope),
                        template?.state,
                    ]
                        .filter(Boolean)
                        .join(', ')}
                </div>
            </div>
        </ListGroup.Item>
    )
}
