import { MicroApp, formatDate, useMicroApp } from 'components/utils'
import { Badge, Button, Card } from 'front'
import { useContext, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useProjectPackets } from 'tracker/api'
import PacketOverview from 'tracker/file/docgen/PacketOverview'
import { PortalContext } from '../../../../../GlobalState'
import { ProjectContext } from '../../../Context/ProjectContext'
import { PacketStatusBadge } from 'tracker/file/docgen/PacketStatusBadge'
import { usePacketRecipients } from 'tracker/file/docgen/hooks/usePacketRecipients'
export default function PacketSection({
    task,
    preview = false,
    fileInfo,
    packetId,
    setSelectPacketId,
    taskProcess,
}) {
    let projectId = task?.project_id
    const { employee } = useContext(PortalContext)
    const { projectId: contextProjectId, canEdit } = useContext(ProjectContext)
    if (!projectId) {
        projectId = contextProjectId
    }
    const { data: packetList, ...packetListApi } = useProjectPackets(projectId, {})
    // console.log('packetList: ', packetList)

    const servicePacketList = packetList?.filter((p) => p?.service_id == task?.id)
    const filteredList = servicePacketList?.filter((p) => p?.status != 'DELETED')
    let packet = filteredList?.[0]
    if (packetId) {
        packet = servicePacketList?.find((p) => p?.id == packetId)
    }
    const { recipients } = usePacketRecipients(projectId, packet?.id, false)
    const newPacketPopoverId = `new-${task?.id}`
    const newPacketPopover = useMicroApp({ id: newPacketPopoverId })
    const [newPacketId, setNewPacketId] = useState()
    const editPacketPopoverId = `edit-${task?.id}`
    const editPacketPopover = useMicroApp({ id: editPacketPopoverId })

    return (
        <>
            {!packet && (
                <Card>
                    <Card.Header className={'d-flex justify-content-between align-items-center'}>
                        <Card.Title>
                            {packetListApi?.isLoading && (
                                <span className={'far fa-spinner-third fa-spin me-2'} />
                            )}
                            Document Packet
                        </Card.Title>
                        <div>
                            {!packetListApi?.isLoading && canEdit && (
                                <Button
                                    size='xs'
                                    variant='primary'
                                    onClick={newPacketPopover.show}
                                    className={' ms-3'}
                                >
                                    Process
                                </Button>
                            )}
                        </div>
                    </Card.Header>
                    <Card.Body>No packets created yet</Card.Body>
                </Card>
            )}
            {packet && (
                <Card>
                    <Card.Header className={'d-flex align-items-center'}>
                        {filteredList?.length > 1 && (
                            <Button
                                size='xs'
                                circle
                                className='me-2'
                                onClick={() => {
                                    setSelectPacketId(null)
                                }}
                                icon='far fa-chevron-left'
                                style={{ marginLeft: -10 }}
                            />
                        )}
                        {packetListApi?.isLoading && (
                            <span className={'far fa-spinner-third fa-spin me-2'} />
                        )}
                        <div className='me-auto'>
                            <div className='fw-bold text-dark'>
                                <Card.Title className='d-inline-block'>
                                    {task?._task_code_label}{' '}
                                </Card.Title>
                                <PacketStatusBadge
                                    status={packet?.status}
                                    className='ms-2 text-uppercase'
                                />
                                {!!taskProcess?.is_post_print_review && (
                                    <Badge bg='success' soft className='ms-2'>
                                        Post-Print Review Enabled
                                    </Badge>
                                )}
                            </div>
                            <div className='text-muted fw-normal'>
                                {packet?.queued_date ? (
                                    <div className='d-flex gap-2 align-items-center'>
                                        <div>
                                            <small>Queued on </small>
                                            <small className='fw-bold'>
                                                {formatDate(packet?.queued_date, 'M/D/YYYY h:ma')}
                                            </small>
                                        </div>
                                        {packet?.printed_date && (
                                            <>
                                                <div>
                                                    <i className='d-block fas fa-arrow-right mx-2 fa-xs' />
                                                </div>
                                                <div>
                                                    <small>Printed on </small>
                                                    <small className='fw-bold'>
                                                        {formatDate(
                                                            packet?.printed_date,
                                                            'M/D/YYYY h:ma'
                                                        )}
                                                    </small>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        <small>Created on </small>
                                        <small className='fw-bold'>
                                            {formatDate(packet?.created_date, 'M/D/YYYY h:ma')}
                                        </small>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            {canEdit && filteredList?.length == 1 && (
                                <a onClick={newPacketPopover.show} className={'link me-3'}>
                                    New
                                </a>
                            )}
                            <a onClick={editPacketPopover.show} className={'link'}>
                                {canEdit ? 'Edit' : 'View'}
                            </a>
                            {preview && task?.current_stage == 'REVIEW' && (
                                <Button
                                    size='xs'
                                    variant='primary'
                                    onClick={editPacketPopover.show}
                                >
                                    Review
                                </Button>
                            )}
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Row className='col-divider'>
                            <Col>
                                {packet?.softening_letter_id && (
                                    <>
                                        <h4>Softening Letter</h4>
                                        <div>
                                            {
                                                packet?.softening?.find(
                                                    (letter) =>
                                                        letter.id == packet?.softening_letter_id
                                                )?.DOC_DESC
                                            }
                                        </div>
                                    </>
                                )}
                                {packet?.documents?.length > 0 && (
                                    <div className={packet?.softening_letter_id ? 'mt-3' : ''}>
                                        <h4>Documents</h4>
                                        {packet?.documents?.map((packet, i) => (
                                            <div key={packet.id} className={i == 0 ? '' : 'mt-3'}>
                                                {packet?.label}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </Col>

                            <Col>
                                {recipients?.length > 0 && (
                                    <div>
                                        <h4>Recipients</h4>
                                        {recipients
                                            ?.filter((r) => r?.selected)
                                            ?.map((recipient, i) => (
                                                <div
                                                    key={recipient?.id}
                                                    className={
                                                        'd-flex justify-content-start align-items-center ' +
                                                        (i == 0 ? '' : 'mt-3')
                                                    }
                                                >
                                                    <div className='w-100 d-flex align-items-center me-3'>
                                                        <div>
                                                            <div className={'fw-bold'}>
                                                                {recipient?._type_name}
                                                                {recipient?.is_debtor && (
                                                                    <Badge
                                                                        bg='secondary'
                                                                        soft
                                                                        className='ms-2'
                                                                    >
                                                                        Debtor
                                                                    </Badge>
                                                                )}
                                                            </div>
                                                            <div className='text-muted'>{name}</div>
                                                            <div className='mt-1'>
                                                                {[
                                                                    recipient?.address1,
                                                                    recipient?.address2,
                                                                    recipient?.city,
                                                                    recipient?.state,
                                                                    recipient?.zip,
                                                                ]
                                                                    ?.filter((item) => !!item)
                                                                    ?.join(', ')}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {recipient?.postage_type}
                                                </div>
                                            ))}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            )}
            <MicroApp id={editPacketPopover.id} label={task?._task_code_label} as={PacketOverview}>
                <PacketOverview
                    key={editPacketPopover.id}
                    popover
                    fileInfo={fileInfo}
                    projectId={projectId}
                    task={task}
                    taskId={task?.id}
                    currentEmployeeId={task?.current_employee_id}
                    packetId={packet?.id}
                    onDeletePacket={() => {
                        setSelectPacketId(null)
                    }}
                />
            </MicroApp>
            <MicroApp id={newPacketPopover.id} label={task?._task_code_label} as={PacketOverview}>
                <PacketOverview
                    key={newPacketPopover.id}
                    popover
                    projectId={projectId}
                    taskId={task?.id}
                    task={task}
                    currentEmployeeId={task?.current_employee_id}
                    // packetId={newPacketId}
                    // onPacketCreate={(id) => setNewPacketId(id)}
                    // onDeletePacket={() => {
                    //     setNewPacketId(null)
                    //     setSelectPacketId(null)
                    // }}
                />
            </MicroApp>
        </>
    )
}
