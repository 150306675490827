import { Badge, Card, Form, ListGroup } from 'front'
import { useContext, useState } from 'react'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import { usePacketDraftContext } from '../PacketWizard'
export function TemplateList({ templateList }) {
    const { templateOptions, addTemplate, removeTemplate } = usePacketDraftContext()

    const [search, setSearch] = useState('')

    const filteredList = templateOptions?.filter(
        ({ label }) => label.toLowerCase().includes(search.toLowerCase()) || search.length === 0
    )

    return (
        <Card>
            <Card.Header>
                <Card.Title>Select Template(s)</Card.Title>
            </Card.Header>

            <ListGroup noBorders>
                <ListGroup.Item>
                    <Search search={search} setSearch={setSearch} />
                </ListGroup.Item>
                {filteredList?.map((template) => (
                    <ListGroup.Item
                        key={template?.id}
                        action
                        className='d-flex justify-content-start'
                        onClick={() => {
                            !template?.selected
                                ? addTemplate(template?.id)
                                : removeTemplate(template?.id)
                        }}
                    >
                        <Form.Check type='checkbox' checked={template?.selected} />
                        <TemplateLabel template={template} />
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </Card>
    )
}

function Search({ search, setSearch }) {
    return (
        <Form.InputGroup inputGroupMerge>
            <Form.InputGroup.Text prepend>
                <i className='fal fa-magnifying-glass' />
            </Form.InputGroup.Text>
            <Form.Control
                value={search}
                name='name'
                onChange={({ target: { value } }) => {
                    setSearch(value)
                }}
                placeholder={'Search templates'}
                ignoreGlobalDisable
            />
            {search.length > 0 && (
                <Form.InputGroup.Text
                    append
                    className='cursor-pointer'
                    onClick={() => {
                        setSearch('')
                    }}
                >
                    <i className='fal fa-xmark' />
                </Form.InputGroup.Text>
            )}
        </Form.InputGroup>
    )
}

export const TemplateLabel = ({
    template: { label, client_id, task_code, task_category, state, project_type_scope },
}) => {
    const { fileInfo } = useContext(ProjectContext)

    return (
        <div className='d-flex justify-content-between align-items-center w-100 ms-3'>
            <div>
                <div>
                    <span className='text-body'>{label}</span>
                    {task_category && (
                        <Badge bg='success' soft className='ms-2'>
                            {task_category}
                        </Badge>
                    )}
                </div>
                {client_id && (
                    <small className='d-block text-muted'>{fileInfo?.client?.name}</small>
                )}
            </div>
            <div>
                {state && (
                    <Badge bg='secondary' soft className='ms-1'>
                        {state}
                    </Badge>
                )}

                {task_code && (
                    <Badge bg='secondary' soft className='ms-1'>
                        {task_code}
                    </Badge>
                )}
                {project_type_scope && (
                    <Badge bg='secondary' soft className='ms-1'>
                        {project_type_scope}
                    </Badge>
                )}
            </div>
        </div>
    )
}
