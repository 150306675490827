import {
    LOG_DEFAULT,
    LOG_INFO,
    CHANGE_LOG_RANGE,
    MANAGEMENT_INFO,
    SELECT_EMP,
    LOG_LOADING,
    CHANGE_LOG_SORT,
    FLIP_LOG_SORT,
} from './action'
import Cookies from 'js-cookie'
const employee = Cookies.getJSON('employee')
const getEmpID = function () {
    return Cookies.getJSON('employee')?.emp_id
}

export default function LogReducer(
    state = {
        isLoading: true,
        selected_emp: getEmpID(),
        management_info: [],
        log: {},
        sort_column: 'updated',
        sort_direction: 'desc',
    },
    action
) {
    switch (action.type) {
        case LOG_DEFAULT:
            return { ...state, range_options: action.range_options, stages: action.stages }
        case MANAGEMENT_INFO:
            return { ...state, management_info: action.management_info }
        case LOG_INFO:
            // const emp_in_log = action.log.filter(act=>(act.emp_id == state.selected_emp))
            // let selected_emp = state.selected_emp
            // if(!emp_in_log){
            // 	selected_emp = action.log[0].emp_id
            // }
            // console.log('selected emp',sle)
            return { ...state, log: action.log }
        case CHANGE_LOG_RANGE:
            return { ...state, range: action.value }
        case SELECT_EMP:
            return { ...state, selected_emp: action.selected_emp }
        case LOG_LOADING:
            return { ...state, isLoading: action.set }
        case CHANGE_LOG_SORT:
            return { ...state, sort_column: action.sort }
        case FLIP_LOG_SORT:
            let sort_direction = state.sort_direction == 'desc' ? 'asc' : 'desc'
            return { ...state, sort_direction }
        default:
            return state
    }
}
