import React, { useState } from 'react'
import request from '../../request'

export const DynamicCardContext = React.createContext({})

export function DynamicCardState(props) {
    // console.log('dynamic card',props)
    const [isLoading, setIsLoading] = useState(false)
    const [collapse, setCollapse] = useState(props?.cardProps?.data?.startCollapsed ?? false)
    const [collapseClass] = useState('multi-collapse-' + Math.round(Math.random() * 10000))
    // const CardRef = createRef()
    // console.log('dynamic card contexts',CardRef,props,props.__proto__.constructor.name)

    function apiCall(options, callback) {
        let axiosOptions = {
            method: options.method,
            url: options.url,
        }
        if (options?.data) {
            axiosOptions.data = options.data
        }
        request(axiosOptions).then(({ data }) => {
            callback(data)
        })
    }

    return (
        <DynamicCardContext.Provider
            value={{
                ...props.cardProps,
                isLoading,
                setIsLoading,
                apiCall,
                collapse,
                setCollapse,
                collapseClass,
            }}
        >
            {props.children}
        </DynamicCardContext.Provider>
    )
}
