import Link from 'next/link'
import { stripOutLetters } from '../compareProjectIds'
import { useRouter } from 'next/router'
import { useClipboardProject } from '../ClipboardContext'

export default function GoToClipboard() {
    const clipboardProject = useClipboardProject()
    if (!clipboardProject) return null
    const router = useRouter()
    const { project_id } = router.query ?? {}
    if (project_id === stripOutLetters(clipboardProject)) return null

    return (
        <Link href={`/nml/tracker/file/overview/?project_id=${stripOutLetters(clipboardProject)}`}>
            <div className='border fst-italic p-2 rounded-bottom border-top-0 border pointer d-flex justify-content-between'>
                <div>Go to {clipboardProject}</div>
                <div>
                    <span className='far fa-arrow-right' />
                </div>
            </div>
        </Link>
    )
}
