import { useRef, useState } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import classnames from 'classnames'
import { Col, Row } from 'react-bootstrap'
import { Badge, Button } from 'front'
import { useConfirmNote } from 'tracker/api'
import { Tooltip } from '@/components/utils'

function ConfirmNotePopover({ className, children, notes }) {
    const [show, setShow] = useState(false)
    const [currentNoteIndex, setCurrentNoteIndex] = useState(0)

    const handleOnMouseEnter = () => {
        setShow(true)
    }
    const handleOnMouseLeave = () => {
        setShow(false)
    }
    const ref = useRef(null)

    const currentNote = notes[currentNoteIndex]

    return (
        <OverlayTrigger
            placement='auto'
            show={show}
            container={ref}
            overlay={
                <Popover
                    id={`confirm-note`}
                    onMouseEnter={handleOnMouseEnter}
                    onMouseLeave={handleOnMouseLeave}
                    style={{ minWidth: 300 }}
                >
                    <Popover.Header className='d-flex justify-content-between fs-6 fw-bold py-1 px-3 border-bottom border-1'>
                        <div>
                            <small>
                                Confirm Note{notes.length > 1 && 's'}
                                <Badge bg='secondary' soft className='ms-2'>
                                    {notes.length ?? 0}
                                </Badge>
                            </small>
                        </div>
                        <div>
                            <button
                                className={classnames('btn btn-link m-0 p-0')}
                                onClick={() => setCurrentNoteIndex((current) => current - 1)}
                                disabled={currentNoteIndex == 0}
                            >
                                <i className='fas fa-arrow-left' />
                            </button>
                            <button
                                className={classnames('btn btn-link m-0 p-0')}
                                onClick={() => setCurrentNoteIndex((current) => current + 1)}
                                disabled={currentNoteIndex == notes.length - 1}
                            >
                                <i className='fas fa-arrow-right' />
                            </button>
                        </div>
                    </Popover.Header>
                    <Popover.Body className='p-3'>
                        {currentNote?.id && <NoteNeedingConfirmed note={currentNote} />}
                    </Popover.Body>
                </Popover>
            }
        >
            <div
                ref={ref}
                className={classnames('d-inline-block', className)}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
            >
                {children}
            </div>
        </OverlayTrigger>
    )
}

function NoteNeedingConfirmed({ note }) {
    const { text, id, direct_no } = note
    const { mutate: confirmNote, ...confirmNoteApi } = useConfirmNote(direct_no)

    return (
        <Row className='py-2'>
            <Col sm='10' className=''>
                {text}
            </Col>
            <Col sm='2'>
                <Button
                    size='xs'
                    circle
                    variant='success'
                    icon='fas fa-check'
                    loading={confirmNoteApi.isLoading}
                    disabled={confirmNoteApi.isLoading}
                    onClick={() => confirmNote(id)}
                />
                {confirmNoteApi.isError && (
                    <Tooltip content={'Error confirming note'} placement='end'>
                        <Badge bg='danger'>
                            <i className='fas fa-circle-exclamation' />
                        </Badge>
                    </Tooltip>
                )}
            </Col>
        </Row>
    )
}

export default ConfirmNotePopover
