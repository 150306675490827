import { useEffect, useState } from 'react'

export default function useDebounce(data, interval) {
    const [liveData, setLiveData] = useState(data)

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const handler = setTimeout(() => {
                setLiveData(data)
            }, interval)
            return () => {
                clearTimeout(handler)
            }
        }
    }, [data, interval])

    return liveData
}
