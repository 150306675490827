import { useEffect, useState } from 'react'
import { tryParseJson } from '../utils'

export default function useSession(key, defaultValue) {
    const updateEventName = (targetKey) => `value-for-${targetKey}-updated`

    // Retrieves raw local storage value (needs JSON parsed if object/array)
    let valueInLocalStorage = () => window.sessionStorage.getItem(key)

    // Returns true if React State value != Local Storage value
    // Note: If React State value is an object or array, it stringifies before comparison
    let valuesDifferent = () => {
        if (typeof window !== 'undefined') {
            let comparibleReactStateValue = valueInReactState
            if (valueInReactState instanceof Object || valueInReactState instanceof Array)
                comparibleReactStateValue = JSON.stringify(valueInReactState)

            return comparibleReactStateValue != window.sessionStorage.getItem(key)
        }
    }

    // Update React State from Local Storage
    let updateReactStateFromLocalStorage = () => {
        if (typeof window !== 'undefined') {
            if (valuesDifferent()) {
                setValueInReactState(tryParseJson(valueInLocalStorage()))
            }
        }
    }

    // Update Local Storage from React State
    let updateLocalStorageFromReactState = () => {
        if (typeof window !== 'undefined') {
            if (valuesDifferent()) {
                window.sessionStorage.setItem(key, JSON.stringify(valueInReactState))
                const updateEvent = new Event(updateEventName(key))
                window.dispatchEvent(updateEvent)
            }
        }
    }

    // TODO: Check for error retrieving local storage and fall back to just react state
    /*  Callback that does one of the following:
    - if localStorage key already has value, ignores defaultValue param and sets state to match localStorage
    - if localStorage key does NOT already have value, assigns defaultValue to state (localStorage will then be updated via useEffect)
    */
    function getAppropriateDefaultValue() {
        if (typeof window !== 'undefined') {
            if (valueInLocalStorage() !== null && valueInLocalStorage() != 'undefined') {
                return tryParseJson(valueInLocalStorage())
            } else if (defaultValue != null) {
                updateLocalStorageFromReactState()
                return defaultValue
            } else {
                return null
            }
        } else {
            return defaultValue
        }
    }

    // React State
    const [valueInReactState, setValueInReactState] = useState(getAppropriateDefaultValue())

    // Listener: If React State changes -> Update Local Storage
    useEffect(() => {
        if (valueInReactState != null) updateLocalStorageFromReactState()
    }, [valueInReactState])

    // Listener: If Key Prop changes -> Rerun through setDefault logic on new key and delete old key (unless told not to)
    useEffect(() => {
        setValueInReactState(getAppropriateDefaultValue())
    }, [key])

    // Listener: If another useLocal updates the same Local Storage key -> Update this React State also
    useEffect(() => {
        window.addEventListener(updateEventName(key), updateReactStateFromLocalStorage)

        return () => {
            window.removeEventListener(updateEventName(key), updateReactStateFromLocalStorage)
        }
    }, [key])

    // Listener: If Local Storage is updated in a different window -> Update this React State also
    useEffect(() => {
        window.addEventListener('storage', updateReactStateFromLocalStorage)

        return () => {
            window.removeEventListener('storage', updateReactStateFromLocalStorage)
        }
    }, [key])

    // TODO:    Add functionality to update react state when localStorage is updated
    //          manually (without useLocal) in the same window

    return [valueInReactState, setValueInReactState]
}
