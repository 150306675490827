import classnames from 'classnames'
import { Tab as BsTab, Tabs as BsTabs } from 'react-bootstrap'
import Anchor from '../../utils/Anchor'

export default function Tabs({
    pills,
    fillWidth,
    children,
    noBottomBorder,
    width,
    style,
    className,
    ...bsProps
}) {
    return (
        <BsTabs
            {...bsProps}
            className={classnames(className, {
                'nav-segment': pills,
                // 'nav-tabs': !pills,
                'text-center nav-fill': fillWidth,
            })}
            style={{
                ...style,
                width,
                borderBottom: noBottomBorder ? 'initial' : null,
            }}
        >
            {children}
        </BsTabs>
    )
}

function TabsTabLink({ children, active, href }) {
    return (
        <BsTab>
            <Anchor href={href} passHref>
                <a className={`nav-link ${active ? 'active' : ''}`}>{children}</a>
            </Anchor>
        </BsTab>
    )
}

Tabs.List = Tabs
Tabs.Tab = BsTab
Tabs.TabLink = TabsTabLink
Tabs.Content = BsTab.Content
Tabs.Pane = BsTab.Pane
