import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import axios from '../axios'
import EmailMergeFieldDropdown from './email_form/EmailMergeFieldDropdown'
import { Button, Form } from 'front'

const defaultState = {
    id: 0,
    body: '',
    title: '',
    subject: '',
    error: '',
    isLoading: false,
    bodyCursor: 0,
    subjectCursor: 0,
}

export default function EmailTemplateModal(props) {
    const { department } = props
    const ROOT_URL = '/email/template/1/' + department

    const body = React.createRef()
    const subject = React.createRef()

    const [state, changeState] = useState({ ...defaultState })
    function setState(newState) {
        changeState({ ...state, ...newState })
    }

    useEffect(() => {
        setState({ ...props.edit })
    }, [props.edit])

    const save = () => {
        setState({ isLoading: true })
        if (state.id > 0) {
            axios
                .put(`${ROOT_URL}/json`, { ...state, personal: props.addToPersonal ?? false })
                .then(({ data }) => {
                    if (data.error) {
                        setState({ error: data.error, isLoading: false })
                    } else {
                        close()
                        props.updateTemplates(data)
                    }
                })
        } else {
            axios
                .post(`${ROOT_URL}/json`, { ...state, personal: props.addToPersonal ?? false })
                .then(({ data }) => {
                    if (data.error) {
                        setState({ error: data.error, isLoading: false })
                    } else {
                        close()
                        props.updateTemplates(data)
                    }
                })
        }
    }
    const close = () => {
        setState({ ...defaultState })
        props.hide()
    }

    const inputChange = (name, value) => {
        cursorPosition(name)
        setState({ [name]: value })
        name === 'body' ? body.current.focus() : subject.current.focus()
    }

    const addMergeField = (mergeField, name = 'body') => {
        const cursorPosition = state[name + 'Cursor']
        const firstSlice = state[name].slice(0, cursorPosition)
        const lastSlice = state[name].slice(cursorPosition)
        setState({
            [name]: firstSlice + mergeField + lastSlice,
        })
        name === 'body' ? body.current.focus() : subject.current.focus()
    }

    const addSubjectMerge = (mergeField) => {
        addMergeField(mergeField, 'subject')
    }

    const cursorPosition = (name) => {
        const selection = name === 'body' ? body : subject
        setState({
            [name + 'Cursor']: selection.current.selectionStart,
        })
    }

    // console.log('email template',state.subjectCursor,state.bodyCursor)
    return (
        <Modal size={'lg'} show={props.show} onHide={close} backdrop={'static'}>
            <Modal.Header closeButton>
                <Modal.Title>Email Template</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className={'row'}>
                    <div className={'col-12'}>
                        {state.error.length > 0 && (
                            <div className={'alert alert-danger'}>{state.error}</div>
                        )}
                        <div className={'mb-3'}>
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                value={state.title}
                                onChange={(e) => {
                                    setState({ title: e.target.value })
                                }}
                            />
                        </div>
                        <div className={'mb-3'}>
                            <div className={'d-flex justify-content-between mb-2'}>
                                <Form.Label>Subject</Form.Label>
                                <EmailMergeFieldDropdown
                                    select={addSubjectMerge}
                                    show={props.show}
                                    dropdown={props.dropdown?.dropdowns}
                                />
                            </div>
                            <Form.Control
                                ref={subject}
                                value={state.subject}
                                onClick={() => {
                                    cursorPosition('subject')
                                }}
                                name={'subject'}
                                onChange={({ target }) => {
                                    inputChange(target.name, target.value)
                                }}
                            />
                        </div>
                        <div className={'mb-3'}>
                            <Form.Label className={'mb-3'}>Body</Form.Label>
                            <div className={'float-end'}>
                                <EmailMergeFieldDropdown
                                    select={addMergeField}
                                    show={props.show}
                                    dropdown={props.dropdown?.dropdowns}
                                />
                            </div>
                            <Form.Control
                                as={'textarea'}
                                style={{
                                    border: '0px solid white',
                                    boxShadow: 'none',
                                    resize: 'none',
                                    borderRadius: '10px',
                                    height: '300px',
                                }}
                                className='bg-light mt-3'
                                rows='5'
                                ref={body}
                                onClick={() => {
                                    cursorPosition('body')
                                }}
                                onKeyDown={() => {
                                    cursorPosition('body')
                                }}
                                name={'body'}
                                onChange={({ target }) => {
                                    inputChange(target.name, target.value)
                                }}
                                value={state.body}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button outline variant={'primary'} onClick={save} disabled={state.isLoading}>
                    {state.isLoading ? (
                        <span className={'far fa-spin fa-spinner'} />
                    ) : (
                        <span className='far fa-save' />
                    )}{' '}
                    Save
                </Button>
                <Button onClick={close}>
                    <span className='far fa-times' /> Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
