import React, { useContext } from 'react'
import { AvatarGroup, CollapsingHeader } from '../../../../front'
import { EmployeeAvatarPopup } from '../../../../utils'
import { useProjectHistory, useTrackerDropdowns } from '../../api'
import { ProjectContext } from '../../Context/ProjectContext'

export default function RecentUsers({}) {
    const { projectId, fileInfo } = useContext(ProjectContext)

    const { data: history } = useProjectHistory({ projectId })
    const { data: dropdowns } = useTrackerDropdowns()

    // let mainContact = dropdowns['employee']?.find(
    //     (emp) => emp?.UserName?.toUpperCase() === fileInfo?.main_contact?.username
    // )
    let latestAuthors = [...new Set(history?.map((version) => version?.created_contact_id))]
        .map((contactId) => ({
            authorEmpId: dropdowns?.employee?.find((emp) => emp?.cnt_id == contactId)?.id,
            authorContactId: contactId,
            lastEditDate: history?.find((version) => version?.created_contact_id == contactId)
                ?.created_date,
        }))
        .filter((e) => e.authorEmpId !== undefined)

    return (
        <>
            {fileInfo?.version && (
                <CollapsingHeader.Transition
                    className='text-body fst-italic'
                    expandedStyle={{ marginTop: '2px', fontSize: '0.75rem' }}
                    collapsedStyle={{ marginTop: '5px', fontSize: '.65625rem' }}
                >
                    <span className='me-1'>
                        {fileInfo?.version?.is_locked ? 'Being edited by ' : 'Last edited by '}
                        {!fileInfo?.version?.is_mine
                            ? fileInfo?.version?._created_contact_name
                            : 'you'}
                    </span>
                </CollapsingHeader.Transition>
            )}
            <AvatarGroup size='sm' maxLength={5}>
                {latestAuthors?.map(({ authorEmpId, lastEditDate, authorContactId }) => {
                    const currentlyEditing =
                        fileInfo?.version?.is_locked &&
                        authorContactId == fileInfo?.version?.created_contact_id

                    return (
                        <EmployeeAvatarPopup
                            key={authorEmpId}
                            empId={authorEmpId}
                            empNameTooltip
                            size='sm'
                            spinner={currentlyEditing}
                            tooltip={
                                <small className='fst-italic'>
                                    {currentlyEditing
                                        ? 'Currently Editing'
                                        : getRelativeDate(lastEditDate)}
                                </small>
                            }
                        />
                    )
                })}
            </AvatarGroup>
            {/* {mainContact && (
                <EmployeeAvatarPopup
                    empId={mainContact?.EmpID}
                    empNameTooltip
                    size='lg'
                    status={<Avatar.Status variant='primary' icon='fas fa-user fa-xs' />}
                    tooltip={<small className='fst-italic'>Main Contact</small>}
                />
            )} */}
        </>
    )
}

const getRelativeDate = (date) => {
    if (typeof date.getMonth !== 'function') {
        date = new Date(date)
        date = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    }

    let now = new Date()
    now = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())

    const differenceInDays = Math.floor((now - date) / (1000 * 3600 * 24))

    if (differenceInDays == 0) {
        return 'Today'
    } else if (differenceInDays == 1) {
        return 'Yesterday'
    } else if (differenceInDays < 7) {
        return `${differenceInDays} days ago`
    } else if (differenceInDays < 31) {
        const numWeeks = Math.floor(differenceInDays / 7)
        return `${numWeeks} ${numWeeks == 1 ? 'week' : 'weeks'} ago`
    } else if (differenceInDays < 365) {
        const numMonths = Math.floor(differenceInDays / 31)
        return `${numMonths} ${numMonths == 1 ? 'month' : 'months'} ago`
    } else {
        const numYears = Math.floor(differenceInDays / 365)
        return `${numYears} ${numYears == 1 ? 'year' : 'years'} ago`
    }
}
