import React, { useState, useEffect, useContext } from 'react'
import { Table as BootstrapTable } from 'react-bootstrap'
import classnames from 'classnames'

const TableContext = React.createContext({})

export default function Table({
    lightHeader,
    noWrap,
    borderless,
    cardTable,
    borderedHeader,
    centerVertically,
    alignCenterHorizontally,
    alignLeftHorizontally,
    alignRightHorizontally,
    className,
    ...bsProps
}) {
    const [theadStyleContext, setTheadStyleContext] = useState()

    useEffect(() => {
        setTheadStyleContext(lightHeader)
    }, [lightHeader])

    return (
        <TableContext.Provider
            value={{
                theadStyleContext,
                setTheadStyleContext,
            }}
        >
            <BootstrapTable
                {...bsProps}
                className={classnames(className, {
                    'table-nowrap': noWrap,
                    'table-borderless': borderless,
                    'table-align-middle': centerVertically,
                    'table-text-center': alignCenterHorizontally,
                    'table-text-start': alignLeftHorizontally,
                    'table-text-end': alignRightHorizontally,
                    'table-thead-bordered': borderedHeader, // TODO: Fix their styling so this works in dark mode too
                    'card-table': cardTable,
                })}
            />
        </TableContext.Provider>
    )
}

function TableHead({ children, bordered, ...rest }) {
    const { theadStyleContext } = useContext(TableContext)

    return (
        <thead
            {...rest}
            className={classnames({
                'thead-light': theadStyleContext,
            })}
        >
            {children}
        </thead>
    )
}

function TableTh({ children, ...rest }) {
    return (
        <th scope='col' {...rest}>
            {children}
        </th>
    )
}

function TableBody({ children, ...rest }) {
    return <tbody {...rest}>{children}</tbody>
}

function TableRow({ children, ...rest }) {
    return <tr {...rest}>{children}</tr>
}

function TableTd({ children, ...rest }) {
    return <td {...rest}>{children}</td>
}

Table.Head = TableHead
Table.Th = TableTh
Table.Body = TableBody
Table.Row = TableRow
Table.Td = TableTd
