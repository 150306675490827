import React, { useState, useEffect } from 'react'
import axios from '../../axios'
import { useRouter } from 'next/router'

export const StateMaintenanceContext = React.createContext({})
const ROOT_URL = '/portal/state/maintenance/1'

export default function StateMaintenanceContextProvider(props) {
    const router = useRouter()
    const [loading, setLoading] = useState(false)
    const [stateInfo, setStateInfo] = useState([])
    const [dropdowns, setDropdowns] = useState({})

    useEffect(() => {
        if (router.pathname.includes('/state_maintenance') && stateInfo?.length === 0) {
            getAllStatesInfo()
            getDropdowns()
        }
    }, [router.pathname])

    function getAllStatesInfo() {
        setLoading(true)
        axios
            .get(`${ROOT_URL}/all/json`)
            .then(({ data }) => {
                setStateInfo(data)
            })
            .then(() => {
                setLoading(false)
            })
    }

    function saveCounty(county) {
        setLoading(true)
        axios.post(`${ROOT_URL}/save/county/json`, county).then(({ data }) => {
            setLoading(false)
            setStateInfo(data.states)
        })
    }

    function deleteCounty(county_id) {
        setLoading(true)
        axios.patch(`${ROOT_URL}/delete/county/${county_id}/json`).then(({ data }) => {
            setLoading(false)
            setStateInfo(data.states)
        })
    }

    function getDropdowns() {
        axios.get(`${ROOT_URL}/state_maintenance/dropdowns/json`).then(({ data }) => {
            setDropdowns(data)
        })
    }

    function saveStateLink(link) {
        setLoading(true)
        axios.post(`${ROOT_URL}/save/state_link/json`, link).then(({ data }) => {
            setLoading(false)
            setStateInfo(data.states)
        })
    }

    function deleteStateLink(link_id) {
        setLoading(true)
        axios.patch(`${ROOT_URL}/delete/state_link/${link_id}/json`).then(({ data }) => {
            setLoading(false)
            setStateInfo(data.states)
        })
    }

    function saveUccXml(ucc_xml) {
        setLoading(true)
        axios.post(`${ROOT_URL}/save/ucc_xml/json`, ucc_xml).then(({ data }) => {
            setLoading(false)
            setStateInfo(data.states)
        })
    }

    async function saveResearchLink(link) {
        setLoading(true)
        return axios.post(`${ROOT_URL}/save/research_link/json`, link).then(({ data }) => {
            setLoading(false)
            setStateInfo(data.states)
        })
    }

    async function deleteResearchLink(link_id) {
        setLoading(true)
        return axios.delete(`${ROOT_URL}/delete/research_link/${link_id}/json`).then(({ data }) => {
            setLoading(false)
            setStateInfo(data.states)
        })
    }

    return (
        <StateMaintenanceContext.Provider
            value={{
                loading,
                setLoading,
                stateInfo,
                saveUccXml,
                deleteStateLink,
                saveStateLink,
                getDropdowns,
                deleteCounty,
                getAllStatesInfo,
                saveCounty,
                dropdowns,
                saveResearchLink,
                deleteResearchLink,
            }}
        >
            {props.children}
        </StateMaintenanceContext.Provider>
    )
}
