import { Badge, Button } from 'front'
import { useModalState } from 'hooks'
import { useContext } from 'react'
import { Alert } from 'react-bootstrap'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import QuickMergeModal from 'tracker/file/history/QuickMergeModal'
export function OlsChangesAlert({ preview = false, fileInfo }) {
    const mergeModal = useModalState(false)
    const { fileInfo: contextFileInfo } = useContext(ProjectContext)
    if (!fileInfo) {
        fileInfo = contextFileInfo
    }

    return (
        <>
            <Alert variant='soft-primary' className='d-flex align-items-center'>
                <i className='far fa-merge me-3 d-block' />
                <div>
                    <div className='fw-bold'>Incoming changes from OLS need reviewed</div>
                    <div className=''>
                        Reasons for review:{' '}
                        {fileInfo?.incoming_changes?.reasons &&
                            Object.values(fileInfo?.incoming_changes?.reasons)?.length > 0 &&
                            Object.values(fileInfo?.incoming_changes?.reasons)?.map((reason, i) => (
                                <Badge bg='primary' className='me-1' key={i}>
                                    {reason?.[0]?.label}
                                </Badge>
                            ))}
                    </div>
                </div>
                <Button variant='primary' size='sm' onClick={mergeModal.show} className='ms-auto'>
                    Review
                </Button>
            </Alert>
            <QuickMergeModal
                show={mergeModal.isOpen}
                onHide={mergeModal.hide}
                preview={preview}
                fileInfo={fileInfo}
            />
        </>
    )
}
