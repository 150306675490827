export function nldUrl(type_id, state) {
    let endurl
    switch (type_id) {
        case 'PRIVATE':
        case 1:
            endurl = 'Private?Value='
            break
        case 'PUBLIC':
        case 2:
            endurl = 'Public?Value='
            break
        case 'FEDERAL':
        case 3:
            endurl = 'Federal?Value=ML&ViewAll='
    }
    return `http://internalnld.ncscredit.com/NLD/States/States${endurl}${state}`
}

export default function OpenNLD({ type_id, state, className, btnDisplay }) {
    return (
        <a
            className={className ?? 'btn btn-outline-secondary'}
            target='_blank'
            href={nldUrl(type_id, state)}
            rel='noreferrer'
        >
            <span className='far fa-ncs-hex' /> {btnDisplay ?? 'NLD'}
        </a>
    )
}
