export default function useSession(key, defaultValue) {
    const isBrowser = typeof window !== 'undefined'

    const updateEventName = (targetKey) => `value-for-${targetKey}-updated`

    const getValue = () => {
        if (!isBrowser) return defaultValue
        const value = window.sessionStorage.getItem(key)
        return value ? JSON.parse(value) : defaultValue
    }

    const setValue = (value) => {
        if (!isBrowser) return
        window.sessionStorage.setItem(key, JSON.stringify(value))
        const updateEvent = new Event(updateEventName(key))
        window.dispatchEvent(updateEvent)
    }

    const updateValueFromSessionStorage = () => {
        if (!isBrowser) return
        const valueInStorage = window.sessionStorage.getItem(key)
        if (valueInStorage !== JSON.stringify(getValue())) {
            const parsedValue = JSON.parse(valueInStorage)
            setValue(parsedValue)
        }
    }

    if (isBrowser) {
        window.addEventListener(updateEventName(key), updateValueFromSessionStorage)
        window.addEventListener('storage', updateValueFromSessionStorage)
    }

    return [getValue, setValue]
}
