import classnames from 'classnames'
import React, { useCallback, useContext, useState } from 'react'
import { Alert, Col, Modal, Row } from 'react-bootstrap'
import { Badge, Button, Form } from '../../../front'
import isEmpty from '../../../utils/isEmpty'
import { useSearchClients, useUpdateProject } from '../api'
import { ProjectContext } from 'tracker/Context/ProjectContext'

export default function ClientModal({
    show,
    closeModal,
    division_id,
    contact_id,
    nat_no,
    projectId,
}) {
    const { fileInfo } = useContext(ProjectContext)
    // Client state
    const [searchTerm, setSearchTerm] = useState(division_id)
    const { data: initialClientSearch, ...initialClientSearchApi } = useSearchClients(division_id)
    let initialClient = companyDisplay(
        initialClientSearch?.find((client) => client?.div_id == division_id)
    )

    console.log('🚀 ~ initialClient:', initialClient)
    const { data: clients, ...searchClientsApi } = useSearchClients(searchTerm, {
        placeholderData: [],
    })
    const [selectedClient, setSelectedClient] = useState()

    let noOptionsMessage = null
    if (!initialClientSearch) noOptionsMessage = 'Start typing to search'
    else if (initialClientSearch?.length === 0) noOptionsMessage = 'Search returned no clients'

    // Contact state
    const [selectedContact, setSelectedContact] = useState()
    let initialContact = contactDisplay(
        initialClient?.client?.contacts?.find((contact) => contact?.cnt_id == contact_id)
    )

    // Affiant state
    const [selectedAffiant, setSelectedAffiant] = useState()
    let initialAffiant = contactDisplay(
        initialClient?.client?.contacts?.find(
            (contact) => contact?.cnt_id == fileInfo?.project?.affiant_id
        )
    )

    const { mutate: updateProject, ...updateProjectApi } = useUpdateProject(
        { projectId },
        { onSuccess: closeModal }
    )

    const onClose = useCallback(() => {
        setSearchTerm(division_id)
        closeModal()
    }, [closeModal, nat_no])

    const onSave = useCallback(() => {
        let update = {}
        if (selectedClient?.id) update.division_id = parseInt(selectedClient?.id)
        if (selectedContact?.id) update.contact_id = selectedContact?.id
        if (selectedAffiant?.id) update.affiant_id = selectedAffiant?.id

        if (Object.keys(update).length === 0) return onClose()

        updateProject({
            project: update,
        })
    }, [selectedClient, selectedContact, selectedAffiant, updateProject])

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h5 className='m-0 p-0'>Client</h5>
                </Modal.Title>
            </Modal.Header>
            {initialClientSearchApi?.isLoading && (
                <Modal.Body className='d-flex justify-content-center my-9'>
                    <div className='fal fa-spinner-third fa-2xl fa-spin' />
                </Modal.Body>
            )}
            {initialClientSearchApi?.isSuccess && (
                <Modal.Body>
                    <Row>
                        <Col className='mb-3'>
                            <label>Company</label>
                            <Form.AdvancedSelect
                                debounce
                                isLoading={searchClientsApi?.isFetching}
                                defaultValue={initialClient}
                                onDebounce={setSearchTerm}
                                value={selectedClient}
                                options={clients?.map(companyDisplay)}
                                noOptionsMessage={() => noOptionsMessage}
                                onChange={(value) => {
                                    setSelectedClient(value)
                                    setSelectedAffiant(null)
                                    if (value?.client?.contacts?.length == 1)
                                        setSelectedContact(
                                            contactDisplay(value?.client?.contacts[0])
                                        )
                                    else setSelectedContact(null)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mb-3'>
                            <label>Contact</label>
                            <Form.AdvancedSelect
                                isLoading={searchClientsApi?.isLoading}
                                defaultValue={initialContact}
                                value={selectedContact}
                                options={
                                    selectedClient?.client?.contacts?.map(contactDisplay) ??
                                    initialClient?.client?.contacts?.map(contactDisplay)
                                }
                                onChange={(value) => {
                                    setSelectedContact(value)
                                }}
                                placeholder='Select contact'
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='mb-3'>
                            <label>Affiant</label>
                            <Form.AdvancedSelect
                                isLoading={searchClientsApi?.isLoading}
                                defaultValue={initialAffiant}
                                value={selectedAffiant}
                                options={
                                    selectedClient?.client?.contacts?.map(contactDisplay) ??
                                    initialClient?.client?.contacts?.map(contactDisplay)
                                }
                                onChange={(value) => {
                                    setSelectedAffiant(value)
                                }}
                                placeholder='Select affiant'
                            />
                        </Col>
                    </Row>
                    {updateProjectApi?.isError && <ClientSaveError />}
                </Modal.Body>
            )}
            <Modal.Footer className='justify-content-between'>
                <Button onClick={onClose} ghost variant='secondary' className='me-2'>
                    Cancel
                </Button>
                <Button
                    onClick={onSave}
                    variant='primary'
                    icon={updateProjectApi?.isLoading ? 'fal fa-spinner-third fa-spin' : null}
                >
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

function companyDisplay(company) {
    if (isEmpty(company)) return

    return {
        value: `${company.cli_no} - ${company.company}`,
        label: `${company.cli_no} - ${company.company} - ${company.maincity},${company.mainstate} ${
            company.active === '1' ? '- Active' : '- Inactive'
        }${company.master === '1' ? ' - Master' : ''}`,
        label: (
            <div className={classnames('d-flex justify-content-start align-items-center', {})}>
                <Badge className='d-block me-2' bg='dark' soft style={{ minWidth: '50px' }}>
                    {company.cli_no}
                </Badge>
                <div className='lh-sm' style={{ paddingTop: '0.2em' }}>
                    <small className='d-block fw-bold'>{company.company}</small>
                    <small className='d-block text-muted'>
                        {company.maincity && `${company?.maincity}, `}
                        {company.mainstate && company?.mainstate}
                    </small>
                </div>
                {company.master === '1' && (
                    <Badge bg='primary' soft className='d-block ms-3'>
                        Master
                    </Badge>
                )}
                {company.active !== '1' && (
                    <Badge bg='danger' soft className='d-block ms-3'>
                        Inactive
                    </Badge>
                )}
            </div>
        ),
        client: company,
        id: company?.div_id,
    }
}

function contactDisplay(contact) {
    if (isEmpty(contact)) return

    return {
        value: `${contact.contfname} ${contact.contlname} ${contact?.title}`,
        label: (
            <div className='lh-sm'>
                <small className='d-block fw-bold'>
                    {contact.contfname} {contact.contlname}
                </small>
                <small className='d-block text-muted'>{contact?.title}</small>
            </div>
        ),
        contact,
        id: contact?.cnt_id,
    }
}

function ClientSaveError() {
    return (
        <Alert variant='soft-danger'>
            <div className='d-flex'>
                <div className='flex-shrink-0'>
                    <i className='fas fa-circle-exclamation' />
                </div>
                <div className='flex-grow-1 ms-2'>Error saving client</div>
            </div>
        </Alert>
    )
}
