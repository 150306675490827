import { useQuery } from '@tanstack/react-query'
import { mergeConfigs } from '../../../../utils'
import axios from '../../../axios'

const apiHost =
    process.env.API_HOST != 'https://api.loc.ncscredit.com/LOCAL'
        ? process.env.API_HOST
        : 'https://api.ncscredit.com/DEVELOPMENT'
export class ClientSearch {
    static keys = {
        all: ['clientSearch'],
        search: (search) => [...this.keys.all, search],
    }

    static search = (search) =>
        axios
            .get(`${apiHost}/cli/maintenance/1/client/search/json`, {
                params: { q: search, simple: 0 },
            })
            .then(({ data }) => data.results)
}

export const useSearchClients = (search, config) => {
    const key = ClientSearch.keys.search(search)
    const api = () => ClientSearch.search(search)
    const configs = mergeConfigs(
        {
            enabled: !!search,
            staleTime: 20 * 60 * 1000, // 20 mins
        },
        config
    )

    return useQuery(key, api, configs)
}
