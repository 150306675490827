import axios from 'axios'
// const api_key = window.api_key;

export const ROOT_URL = '/nml/tracker/1'
export const DOC_URL = '/doc/notice/1'
// axios.defaults.headers.common['X-Api-Key'] = api_key;

export const CancelToken = axios.CancelToken
export let cancel

export default axios

export const startPath = '/nml/tracker'
