import classnames from 'classnames'
import { useState } from 'react'
import TypeIcon from '../../utils/TypeIcon'
import DocPreviewer from './DocPreviewer'
export default function DocPreviewIcon({ type, docId, cabinet, className }) {
    const [preview, setPreview] = useState(false)

    return (
        <div
            className={classnames(className, 'pointer')}
            onClick={() => {
                setPreview(true)
            }}
        >
            <TypeIcon type={type} size={'fa-2x'} />
            <DocPreviewer
                docId={docId}
                cabinet={cabinet}
                show={preview}
                onClose={() => {
                    setPreview(false)
                }}
            />
        </div>
    )
}
