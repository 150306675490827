import _ from 'lodash'
import {
    FETCH_UCC_FILE,
    CHANGE_FILE_DETAIL_LOADING,
    FETCH_SEARCH_BOX,
    UPDATE_SERVICES,
    NEXT_STAGE_MODAL,
    NEXT_STAGE_INFO,
    RETURN_NEXT_STAGE,
    NEXT_STAGE_CHANGE,
    CLEAR_NEXT_STAGE,
    CLOSE_MODAL,
    FIND_SERVICE,
    FIND_DIMENSIONS,
    UPDATE_FOLLOW_UP,
    TRANSMITTAL_MODAL,
    PASS_TRANSMITTAL,
    NEXT_STAGE_LOADING,
    REPLACE_SERVICES,
    UPDATE_LINKED_FILES,
    ERROR_DETAIL,
    SERVICE_CURRENT_OPEN,
    REPLACE_COMPLETED_SERVICES,
} from './action'

const defaultState = {
    isLoading: true,
    add_note_modal: false,
    next_stage_modal: false,
    close_modal: false,
    next_stage: {},
    edit_next_stage: 0,
    edit_stage: {},
    file_detail_height: 900,
    services: [],
    next_stage_loading: true,
    extras: {},
    linked_files: { ucc_nos: [] },
    debtors: [],
    secured_parties: [],
    error: false,
    requests: [],
    file_info: { STATE_EXP: '0000-00-00' },
    serviceCurrentOpen: undefined,
    completed_services: [],
}

export default function FileDetailsReducer(state = { ...defaultState }, action) {
    switch (action.type) {
        case FETCH_UCC_FILE:
            if (action.services) {
                return {
                    ...state,
                    ...action,
                }
            } else {
                return { ...state, ucc_no: action.ucc_no, not_selected: action.payload }
            }
        case CHANGE_FILE_DETAIL_LOADING:
            return { ...state, isLoading: action.set }
        case FETCH_SEARCH_BOX:
            return { ...state, emp_dropdown: action.payload.ucc_emps }
        case UPDATE_SERVICES:
            const updateService = { ...action.services }
            const serviceIndex = _.findIndex(state.services, ['SERV_ID', action.services.SERV_ID])
            // console.log('updating services',serviceIndex,updateService)
            if (serviceIndex > -1) {
                let new_services = [...state.services]
                new_services[serviceIndex] = updateService
                return { ...state, services: [...new_services] }
            } else {
                const completedIndex = _.findIndex(state.completed_services, [
                    'SERV_ID',
                    action.services.SERV_ID,
                ])
                let newCompletedServices = [...state.completed_services]
                newCompletedServices[completedIndex] = updateService
                // console.log('completed service',completedIndex,newCompletedServices)
                return { ...state, completed_services: [...newCompletedServices] }
            }
        case NEXT_STAGE_MODAL:
            return { ...state, next_stage_modal: action.open, edit_next_stage: 0 }
        case NEXT_STAGE_INFO:
            const edit_stage_info = action.next_stage
            return {
                ...state,
                edit_stage: edit_stage_info,
                file_view_ucc_no: action.file_view_ucc_no,
            }
        case RETURN_NEXT_STAGE:
            let return_next_stage = state.next_stage
            return { ...state, edit_next_stage: 0, edit_stage: return_next_stage }
        case NEXT_STAGE_CHANGE:
            let edit_stage_change = state.edit_stage
            edit_stage_change[action.item_to_change] = action.value
            return { ...state, edit_stage: edit_stage_change }
        case CLEAR_NEXT_STAGE:
            return { ...state, edit_stage: {}, next_stage: {} }
        case CLOSE_MODAL:
            return {
                ...state,
                close_modal: action.open,
                close_modal_process_id: action.process_id,
                file_view_ucc_no: action.file_view_ucc_no,
            }
        case FIND_SERVICE:
            return {
                ...state,
                services: action.service,
                ucc_no: action.ucc_no,
                extras: action.extras,
            }
        case FIND_DIMENSIONS:
            return { ...state, file_detail_height: action.file_detail_height }
        case UPDATE_FOLLOW_UP:
            state.edit_stage.follow_up = action.follow_up
            return { ...state }
        case TRANSMITTAL_MODAL:
            return { ...state, transmittal_show: action.open }
        case PASS_TRANSMITTAL:
            return { ...state, transmittal: action.transmittal }
        case NEXT_STAGE_LOADING:
            return { ...state, next_stage_loading: action.set }
        case REPLACE_SERVICES:
            return { ...state, services: action.services }
        case REPLACE_COMPLETED_SERVICES:
            return { ...state, completed_services: action.completed_services }
        case UPDATE_LINKED_FILES:
            return { ...state, linked_files: action.linked_files }
        case ERROR_DETAIL:
            return { ...state, error: action.error }
        case SERVICE_CURRENT_OPEN:
            return { ...state, serviceCurrentOpen: action.servId }
        default:
            return state
    }
}
