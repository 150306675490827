import React, { useState, useEffect, useContext } from 'react'
import { Context as TrackerContext } from './GlobalState'
import { useRouter } from 'next/router'

export const RequestContext = React.createContext({})

export const initialFormPageData = {
    contacts: [],
    additionalContacts: [],
    contracted_with: '',
    source: 'Job Sheet',
    date_calc: '',
    ff_est: '0',
    ff_today: '0',
    ff_date: '',
    first_furnishing: '',
    furnishings: [],
    last_furnishing: '',
    lf_est: '0',
    lf_today: '0',
    lf_date: '',
    lien_direct: 0,
    project_address1: '',
    project_address2: '',
    project_city: '',
    project_name: '',
    project_state: '',
    project_type: '',
    project_zip: '',
    reference_no: '',
    rental_type: '',
    service_code: '',
    service_level: '1',
    service_type: '',
    service_text: '',
    description_of_materials: '',
    invoice_terms: '',
    completion_date: '',
    contract_date: '',
    total_claim_amt: '',
    total_contract_amt: '',
    material_type: '',
    construction_type: '',
    div_id: '',
    cnt_id: '',
    comment: '',
    bond_number: '',
}

export default function RequestContextProvider({ children }) {
    const router = useRouter()
    const [formPage, setFormPage] = useState(initialFormPageData)
    const { dropdowns } = useContext(TrackerContext)

    useEffect(() => {
        // Fills request form with url query data on load
        if (router.pathname.startsWith('/nml/tracker/request/form')) {
            if (Object.keys(router.query)?.length > 0) {
                setFormPage({ ...formPage, ...router.query })
            }
        }
    }, [router])

    return (
        <RequestContext.Provider
            value={{
                formPage,
                setFormPage,
                dropdowns,
            }}
        >
            {children}
        </RequestContext.Provider>
    )
}
