import classnames from 'classnames'
import DocPreviewer from 'components/docuware/components/DocPreviewer'
import { formatDate, isEmpty } from 'components/utils'
import { Button, Card, Form, ListGroup } from 'front'
import { useModalState } from 'hooks'
import { toTitleCase } from 'utils'
import AddAttachmentModal from './components/AddAttachmentModal'
import AddTemplateModal from './components/AddTemplateModal'
import AddSofteningLetterModal from './components/AddSofteningLetterModal'
import DeleteDocumentModal from './components/DeleteDocumentModal'
import { DocumentIcon } from './components/DocumentIcon'
import { useContext } from 'react'
import { ProjectContext } from 'tracker/Context/ProjectContext'
export default function Documents({ packet, projectId, taskId, canEdit = true }) {
    return (
        <>
            <Card style={{ marginBottom: 24 }}>
                <Card.Header className='d-flex justify-content-between align-items-center'>
                    <Card.Title as='h4'>Documents</Card.Title>
                    {canEdit && (
                        <AddDocumentsButtons
                            projectId={projectId}
                            taskId={taskId}
                            packet={packet}
                        />
                    )}
                </Card.Header>
                <ListGroup noBorders>
                    <SofteningLetter packet={packet} canEdit={canEdit} projectId={projectId} />
                    {packet?.documents?.map((document) => (
                        <Document
                            key={document?.id}
                            projectId={projectId}
                            packet={packet}
                            document={document}
                            canEdit={canEdit}
                        />
                    ))}
                </ListGroup>
            </Card>
        </>
    )
}

function Document({ projectId, packet, document, canEdit }) {
    const deleteDocModal = useModalState(false)
    const attachmentPreviewPopup = useModalState(false)
    const { openWindow } = useContext(ProjectContext)

    const docType = !!document?.template_id
        ? 'template'
        : !!document?.docuware_id
        ? 'attachment'
        : null

    const openDocument = () => {
        if (packet?.id && document?.id) {
            openWindow(
                `/editor?project_id=${projectId}&packetId=${packet?.id}&documentId=${document?.id}&t=${document?.label}`,
                'document_editor',
                `width=1000,height=900,location=no`
            )
        }
    }

    const onClick = () => {
        if (docType === 'template') openDocument()
        if (docType === 'attachment') attachmentPreviewPopup.show()
    }

    return (
        <>
            <ListGroup.Item
                className={classnames('d-flex align-items-center', {
                    'cursor-pointer': docType === 'attachment' || document?.filename,
                })}
                action={docType === 'attachment' || document?.filename}
                onClick={onClick}
            >
                <DocumentIcon type={docType} className='mx-2 my-2 flex-shrink-0 fa-fw' />
                <div className='ms-2 w-100'>
                    <div className='fw-bold text-dark'>
                        {document?.label ? (
                            toTitleCase(document?.label)
                        ) : (
                            <i className='text-body'>No template name</i>
                        )}
                    </div>
                    <div className='text-muted'>
                        {docType == 'template' && (
                            <>
                                {[
                                    toTitleCase(document?.task_category),
                                    toTitleCase(document?.project_type_scope),
                                    document?.state,
                                ]
                                    .filter(Boolean)
                                    .join(', ')}
                            </>
                        )}
                        {docType == 'attachment' && (
                            <>{formatDate(document?.create_date, 'M/D/YYYY')}</>
                        )}
                    </div>
                </div>
                <div className='d-flex flex-shrink-0'>
                    {docType === 'template' && document?.filename && (
                        <Button
                            ghost
                            variant='secondary'
                            icon={canEdit ? 'fal fa-edit' : 'fal fa-eye'}
                            onClick={openDocument}
                        />
                    )}
                    {docType === 'attachment' && (
                        <Button
                            ghost
                            variant='secondary'
                            icon='fal fa-eye'
                            onClick={attachmentPreviewPopup.show}
                        />
                    )}
                    {canEdit && (
                        <Button
                            ghost
                            variant='danger'
                            icon='fal fa-xmark'
                            onClick={(e) => {
                                e.stopPropagation()
                                deleteDocModal.show()
                            }}
                        />
                    )}
                </div>
            </ListGroup.Item>
            <DeleteDocumentModal
                projectId={projectId}
                packet={packet}
                doc={document}
                show={deleteDocModal.isOpen}
                onHide={deleteDocModal.hide}
            />
            {docType === 'attachment' && (
                <DocPreviewer
                    docId={document?.docuware_id}
                    cabinet={'nml'}
                    show={attachmentPreviewPopup.isOpen}
                    onClose={attachmentPreviewPopup.hide}
                />
            )}
        </>
    )
}

function SofteningLetter({ packet, canEdit, projectId }) {
    const { openWindow } = useContext(ProjectContext)
    const document = packet?.softeningLetter

    const openDocument = () => {
        if (packet?.id && document?.id) {
            openWindow(
                `/editor?project_id=${projectId}&packetId=${packet?.id}&documentId=${document?.id}&softening=true`,
                'document_editor',
                `width=1000,height=900,location=no`
            )
        }
    }
    if (!packet.softeningLetterEnabled) return null
    return (
        <ListGroup.Item className='p-0' action={packet?.status} onClick={openDocument}>
            <div className='d-flex align-items-center p-3'>
                <DocumentIcon type='softening' className='mx-2 my-3 flex-shrink-0 fa-fw' />
                <div className='ms-2 me-2 w-100'>
                    {!packet.id ? (
                        !canEdit || packet?.softeningLetterOptions?.length === 1 ? (
                            softeningOption(packet?.softeningLetter)?.label
                        ) : (
                            <div onClick={(e) => e.stopPropagation()}>
                                <Form.AdvancedSelect
                                    styleOverrides={{
                                        control: () => ({ backgroundColor: 'transparent' }),
                                    }}
                                    onChange={(letter) => {
                                        packet?.setSofteningLetter(letter?.id)
                                    }}
                                    value={softeningOption(packet?.softeningLetter)}
                                    options={packet?.softeningLetterOptions?.map(softeningOption)}
                                    isClearable={false}
                                    isSearchable={false}
                                />
                            </div>
                        )
                    ) : (
                        <div className='fw-bold text-dark'>{packet?.softeningLetter?.DOC_DESC}</div>
                    )}
                </div>
                <div className='d-flex flex-shrink-0'>
                    {packet?.status && (
                        <Button
                            ghost
                            variant='secondary'
                            icon={canEdit ? 'fal fa-edit' : 'fal fa-eye'}
                            onClick={openDocument}
                        />
                    )}
                </div>
                <div className='d-flex flex-shrink-0'>
                    {canEdit && (
                        <Button
                            ghost
                            variant='danger'
                            icon='fal fa-xmark'
                            loading={packet?.updatePacketApi?.isLoading}
                            onClick={(e) => {
                                e.stopPropagation()
                                packet?.disableSofteningLetter()
                            }}
                        />
                    )}
                </div>
            </div>
        </ListGroup.Item>
    )
}
function softeningOption(softeningLetter) {
    if (isEmpty(softeningLetter)) return

    return {
        value: softeningLetter?.DOC_DESC,
        label: (
            <div className=''>
                <div className='fw-bold text-dark'>{softeningLetter?.DOC_DESC}</div>
            </div>
        ),
        id: softeningLetter?.id,
    }
}

function AddDocumentsButtons({ packet, projectId, taskId }) {
    const addTemplateModal = useModalState(false)
    const addSofteningLetterModal = useModalState(false)
    const addAttachmentModal = useModalState(false)

    return (
        <div>
            {!packet?.softeningLetterEnabled && (
                <Button
                    ghost
                    variant='secondary'
                    size='sm'
                    icon='fal fa-plus'
                    className='me-2'
                    onClick={() => {
                        packet?.id
                            ? addSofteningLetterModal.show()
                            : packet?.enableSofteningLetter()
                    }}
                >
                    Softening Letter
                </Button>
            )}
            {!packet.id && (
                <Button
                    ghost
                    variant='secondary'
                    size='sm'
                    icon='fal fa-plus'
                    className='me-2'
                    onClick={addTemplateModal.show}
                >
                    Template
                </Button>
            )}
            <Button
                ghost
                variant='secondary'
                size='sm'
                icon='fal fa-plus'
                onClick={addAttachmentModal.show}
            >
                Attachment
            </Button>
            <AddSofteningLetterModal
                packet={packet}
                show={addSofteningLetterModal.isOpen}
                onHide={addSofteningLetterModal.hide}
            />
            <AddTemplateModal
                projectId={projectId}
                taskId={taskId}
                packet={packet}
                key={addTemplateModal.key}
                templates={packet?.templateOptions}
                show={addTemplateModal.isOpen}
                onHide={addTemplateModal.hide}
            />
            <AddAttachmentModal
                projectId={projectId}
                taskId={taskId}
                packet={packet}
                key={addAttachmentModal.key}
                show={addAttachmentModal.isOpen}
                onHide={addAttachmentModal.hide}
            />
        </div>
    )
}
