import { useMutation, useQueryClient } from '@tanstack/react-query'
import { mergeConfigs } from '../../../../utils'
import axios from '../Context/AxiosSetup'
import { Project } from './project.api'

export class Workflow {
    static updateWorkflow = (process, task_id, project_id, stage, employee_id) => {
        return axios
            .post(`/nml/tracker/2/stage/${process}/json`, {
                project_id,
                task_id,
                stage,
                employee_id,
                _return: 'project',
            })
            .then((response) => response.data)
    }
}

export const useUpdateServiceWorkflow = ({ project_id, task_id }, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        ({ stage, employee_id, process }) =>
            Workflow.updateWorkflow(process, task_id, project_id, stage, employee_id),
        mergeConfigs(
            {
                onSuccess: (data) => {
                    queryClient.invalidateQueries(Project.keys.project(project_id))
                    queryClient.setQueryData(Project.keys.project(project_id), {
                        ...queryClient.getQueryData(Project.keys.project(project_id)),
                        tasks: data?.tasks,
                    })
                    return data
                },
            },
            config
        )
    )
}
