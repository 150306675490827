import React, { Fragment, useEffect, useState } from 'react'

export default function Collapse({ children, startOpen, title, className, style, onClick }) {
    const [id] = useState(Math.round(Math.random() * 1000))
    const [opened, setOpened] = useState(startOpen)
    const [startCollapsed] = useState('collapse ' + (startOpen ? 'show' : ''))

    useEffect(() => {
        if (startOpen !== opened) {
            setOpened(startOpen)
            $('#collapse' + id).collapse('toggle')
        }
    }, [startOpen])

    return (
        <Fragment>
            <div
                className={'pointer ' + (className ?? '')}
                onClick={() => {
                    setOpened(!opened)
                    if (typeof onClick === 'function') {
                        onClick()
                    }
                }}
                data-bs-toggle={'collapse'}
                data-bs-target={'#collapse' + id}
                style={style}
            >
                {title}
                <span className={`far fa-chevron-${opened ? 'up' : 'down'} float-end`} />
            </div>
            <div className={startCollapsed} id={'collapse' + id}>
                {children}
            </div>
        </Fragment>
    )
}
