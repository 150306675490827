import { Button } from 'front'
import { Alert, Col, Modal, Row } from 'react-bootstrap'
import { useUpdatePacket } from 'tracker/api'

export default function DeleteDocumentModal({ projectId, packet, show, onHide, doc }) {
    const { mutate: updatePacket, ...updatePacketApi } = useUpdatePacket(projectId, {
        onSuccess: onHide,
    })

    let submit = () => {
        if (packet?.id)
            updatePacket({
                packetId: packet?.id,
                data: {
                    documents: [
                        {
                            ...packet?.documents?.find(({ id }) => id === doc?.id),
                            _delete: true,
                        },
                    ],
                },
            })
        else {
            if (doc?.template_id) packet.removeTemplate(doc?.template_id)
            else if (doc?.docuware_id) packet.removeAttachment(doc?.docuware_id)

            onHide()
        }
    }

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>Are you sure you want to delete this document?</Col>
                </Row>
                {updatePacketApi?.isError && (
                    <DeleteDocumentErrorAlert error={updatePacketApi?.error} />
                )}
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <Button onClick={onHide} ghost variant='secondary' className='me-2'>
                    Cancel
                </Button>
                <Button
                    onClick={submit}
                    icon={updatePacketApi?.isLoading ? 'fal fa-spinner-third fa-spin' : null}
                    variant='danger'
                >
                    Delete Document
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const DeleteDocumentErrorAlert = ({ error }) => (
    <Alert variant='soft-danger mt-3'>
        <div className='d-flex'>
            <div className='flex-shrink-0'>
                <i className='fas fa-circle-exclamation' />
            </div>
            <div className='flex-grow-1 ms-2'>{error?.response?.data?.errors?.[0]?.message}</div>
        </div>
    </Alert>
)
