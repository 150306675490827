import classnames from 'classnames'
import React, { useRef } from 'react'
import { Tooltip } from '../../utils'

const Avatar = React.forwardRef(
    (
        {
            className,
            style,
            square,
            variant = 'primary',
            bg,
            soft,
            imgSrc,
            initials = '',
            maxInitialsLength = 2,
            icon,
            size,
            status = null,
            tooltip,
            tooltipPlacement,
            fallback,
            spinner,
            id = Math.random(),
            ...rest
        },
        ref
    ) => {
        const element = useRef(null)

        if (soft) variant = `soft-${variant}`
        if (initials.length > maxInitialsLength) initials = fallback ?? initials.substring(0, 2)

        return (
            <Tooltip content={tooltip} placement={tooltipPlacement}>
                <span
                    {...rest}
                    ref={ref}
                    id={id}
                    className={classnames(className, 'avatar', 'position-relative', {
                        [`avatar-${variant}`]: variant && !bg,
                        'avatar-circle': !square,
                        [`avatar-${size}`]: size != null,
                    })}
                    style={{
                        ...style,
                    }}
                >
                    {imgSrc ? (
                        <img className='avatar-img' src={imgSrc} />
                    ) : (
                        <span
                            className='avatar-initials'
                            style={{
                                ...style,
                                backgroundColor: bg,
                            }}
                        >
                            {icon ? <i className={icon} /> : initials}
                        </span>
                    )}
                    {status}
                    {spinner && (
                        <div className='fa-spin position-absolute w-100 h-100 top-0 left-0'>
                            <div className='avatar-spinner' />
                        </div>
                    )}
                </span>
            </Tooltip>
        )
    }
)
export default Avatar

const AvatarStatus = ({ className, variant, icon, size = 'sm' }) => {
    return (
        <span
            className={classnames(className, 'avatar-status', `avatar-status-${variant}`, {
                [`avatar-${size}-status`]: size,
            })}
        >
            {icon && <i className={classnames(icon)} />}
        </span>
    )
}

export function AvatarGroup({ className, size, maxLength, children }) {
    const childrenArray = React.Children.toArray(children)

    return (
        <div
            className={classnames(className, 'avatar-group', {
                [`avatar-group-${size}`]: size,
            })}
        >
            {childrenArray.map((avatar, i) => {
                if (!maxLength || i < maxLength) return avatar
            })}
            {maxLength && childrenArray.length > maxLength && (
                <Avatar
                    variant='primary'
                    initials={`+${childrenArray.length - maxLength}`}
                    tooltip={`${childrenArray.length - maxLength} more`}
                    tooltipPlacement='bottom'
                    fallback={<i className='fas fa-ellipsis' />}
                />
            )}
        </div>
    )
}

Avatar.Status = AvatarStatus
