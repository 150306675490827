import { useLocal } from 'hooks'
import Cookies from 'js-cookie'
import { orderBy } from 'lodash'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import axios from '../../../axios'
import DwApi from '../../../docuware/api/DwApi'
import formatDate from '../../../utils/formatDate'
import MaintenanceContext from '../../maintenance/MaintenanceContext'
import { nldUrl } from '../Utils/OpenNLD'
import { useTrackerDropdowns } from '../api'
import NoticeContext from './NoticeContext'
import RequestContext from './RequestContext'
import TrackerLinkContext from './TrackerLinkContext'

const CancelToken = axios.CancelToken
const ROOT_URL = '/nml/tracker/1'
const TRACKER_2_URL = '/nml/tracker/2'

const employee = Cookies.getJSON('employee')
const emp_id = parseInt(employee?.EmpID)

export const Context = React.createContext({
    files: [],
})

export const EmployeeContext = React.createContext({
    employees: [],
})

let cancel

// const { localStorage } = window;
const COLLAPSED_NAV = 'collapsedNav'
const SORT_OPTION = 'sortOption'
const RECENT_FILE_HISTORY = 'recent_file_history'
const SHOW_HISTORY_SEARCH = 'show_history_search'
const SELECTED_FILE = 'selected_file'

function setCollapsedNav(value = 0) {
    Cookies.set(COLLAPSED_NAV, value)
}

function changeSortBy(value = 'ncs_lno') {
    Cookies.set(SORT_OPTION, value)
}

export const initialFilters = {
    is_active: [],
    client_id: [],
    project_type: [],
    project_service_level: [],
    current_stage: [],
    state: [],
}

function GlobalState(props) {
    const router = useRouter()
    let localStorage = null

    const [isLoading, setLoading] = useState(false)
    const [files, setFiles] = useState([])
    const [collapsed, setCollapsed] = useState(Cookies.getJSON(COLLAPSED_NAV))
    const [historyEmpId, setHistoryEmpId] = useState(emp_id)
    const [filters, setFilters] = useState({ ...initialFilters })
    const [search, setSearch] = useState('')
    const [queueEmpId, setQueueEmpId] = useState(emp_id)
    const [searchAggs, setSearchAggs] = useState({})
    const [selectedFile, setSelectedFile] = useLocal(SELECTED_FILE, {})
    const [fileInfo, setFileInfo] = useLocal(SELECTED_FILE, {})
    const [fileInfoLoading, setFileInfoLoading] = useState(false)
    const { data: dropdowns } = useTrackerDropdowns()
    const [sort, setSort] = useLocal(SORT_OPTION, 'ncs_lno')
    const [sortDirection, setSortDirection] = useState('desc')
    const [show, setShow] = useState(99)
    const [researchLoading, setResearchLoading] = useState(false)
    const [researchCards, setResearchCards] = useState([])
    const [clientHistory, setClientHistory] = useState([])
    const [recentFileHistory, setRecentFileHistory] = useLocal(RECENT_FILE_HISTORY, [])
    const [docuwareContacts, setDocuwareContacts] = useState([{}])
    const [fileBarCollapsed, setFileBarCollapse] = useState(true)
    const [draftSaveState, setDraftSaveState] = useState('saved')
    const [fileHistory, setFileHistory] = useState([])

    function getClientHistory(searchTerm, callback) {
        setLoading(true)
        axios
            .get(`/cli/maintenance/1/client/all/search/json?q=${searchTerm}`)
            .then((response) => {
                const { data } = response
                // console.log("response", data);
                setClientHistory(data)
                setLoading(false)
                callback?.()
            })
            .catch(() => {
                setLoading(false)
                callback?.()
            })
    }

    useEffect(() => {
        // console.log('files changing',files)
        files.length > 0 && setFiles(orderFiles(files))
        // orderFiles.length > 0 && selectFile(orderFiles[0])
    }, [sort, sortDirection])

    function getDivisionContacts(div_id) {
        DwApi.getContacts(div_id).then((contacts) => setDocuwareContacts(contacts))
    }

    function orderFiles(files) {
        return orderBy(files, [sort], [sortDirection])
    }

    useEffect(() => {
        // setLocalStorage(SELECTED_FILE, fileInfo)
    }, [fileInfo])

    function toggleCollapse() {
        setCollapsedNav(!collapsed)
        setCollapsed(!collapsed)
    }

    function toggleFileBarCollapse() {
        setFileBarCollapse(!fileBarCollapsed)
    }

    function changeSortOption(value) {
        changeSortBy(value)
        setSort(value)
    }

    function selectFile(file) {
        // console.log('selecting file',file)
        if (cancel) {
            cancel()
        }
        setSelectedFile(file)
        setFileInfo({})
        setFileInfoLoading(true)
        // setLoading(true)
        axios
            .get(`${TRACKER_2_URL}/project/${file?.project_id ?? file?.direct_no}/json`, {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c
                }),
            })
            .then(({ data }) => {
                setFileInfo(data)
                setFileInfoLoading(false)
                // setLoading(false)
                setSelectedFile(data)
                // setLocalStorage(SELECTED_FILE, data)
                data?.client?.div_id &&
                    DwApi.getContacts(data.client.div_id).then((contacts) =>
                        setDocuwareContacts(contacts)
                    )
                // updateFileHistory(data)
                // if (files.length === 0) {
                // 	const file_list = [{...data}];
                // 	setFiles(file_list);
                // 	// console.log('no files',data,file_list)
                // 	getFiles(false, [], {...data})
                // }
            })
            .catch(function (thrown) {
                setFileInfo(false)
                if (axios.isCancel(thrown)) {
                    // console.log('Request canceled', thrown);
                }
            })
    }

    function setLocalStorage(value, item) {
        if (typeof window !== 'undefined') {
            const { localStorage } = window
            localStorage?.setItem(value, JSON.stringify(item))
        }
    }

    function getLocalStorage(value) {
        if (typeof window !== 'undefined') {
            const { localStorage } = window
            const info = localStorage?.getItem(value)
            // console.log('info',typeof info,info)
            if (info !== 'undefined') {
                return JSON.parse(info)
            } else {
                return undefined
            }
            // return info ? JSON.parse(info) : undefined
        }
    }

    function baseSearch(queueEmp = queueEmpId, historyEmp = historyEmpId) {
        setSearch('')
        setFilters({ ...initialFilters })
        setQueueEmpId(queueEmp)
        setHistoryEmpId(historyEmp)
    }

    function applyFilters(newFilters) {
        setFilters(newFilters)
        getFiles(false, newFilters)
    }

    function getFiles(search = false, newFilters = [], addFileToList = false) {
        if (cancel) {
            cancel()
        }
        setLoading(true)
        if (!addFileToList) {
            // setFiles([]);
            setFileInfo({})
            setSelectedFile({})
        }
        let data
        // setSearch({...initialSearch})
        if (search?.length > 0) {
            data = { search }
        } else {
            data = { emp_id: queueEmpId }
            data = { ...data, ...filters, ...newFilters }
        }
        axios
            .post(`${ROOT_URL}/search/json`, data, {
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c
                }),
            })
            .then(({ data }) => {
                if (data?.list?.length > 0) {
                    // const lienDirect = data.filter(file=>file.lien_direct === 1)
                    // console.log('lien direct files',lienDirect)
                    let fileList = orderFiles(data.list)
                    if (addFileToList) {
                        fileList = [
                            { ...addFileToList },
                            ...fileList.filter((r) => r.project_id != addFileToList.project_id),
                        ]
                    }
                    setFiles(fileList)
                    setSearchAggs(data.aggs)
                }
                setLoading(false)
            })
            .catch(function (thrown) {
                if (axios.isCancel(thrown)) {
                    // console.log('Request canceled', thrown);
                } else {
                    console.log('search error', thrown)
                }
            })
    }

    function stageChange(process, project_id, service_id, stage_id, emp_id, note = false) {
        axios
            .post(`${ROOT_URL}/stage/${process}/json`, {
                project_id,
                service_id,
                stage_id,
                emp_id,
                note,
                _return: 'project',
            })
            .then(({ data }) => {
                setFileInfo({ ...data })
            })
    }

    function changeFilters(name, value) {
        setFilters({ ...search, [name]: value })
    }

    function resetFilters() {
        setFilters({ ...initialFilters })
    }

    function getResearchCards(clearCards = true) {
        setResearchLoading(true)
        clearCards && setResearchCards([])
        axios
            .get(`${ROOT_URL}/research/cards/${selectedFile.project_id}/json`)
            .then(({ data }) => {
                setResearchLoading(false)
                setResearchCards(data)
                // console.log('research cards for direct no',selectedFile.project_id,data)
            })
            .catch((e) => {
                setResearchLoading(false)
            })
    }

    function createShippingLabel(contact, shipping_option, file, callback, herc = false) {
        setLoading(true)
        const { project_id, ncs_lno, reference_no } = file
        axios
            .post(`${ROOT_URL}/create/shipment/json`, {
                contact,
                shipping_option,
                direct_no: project_id,
                ncs_lno,
                herc,
                reference_no,
            })
            .then((response) => {
                if (response.data.fedex) {
                    setFileInfo({
                        ...fileInfo,
                        fedex: [...fileInfo.fedex, { ...response.data.fedex }],
                    })
                }
                callback(response)
                setLoading(false)
            })
            .catch((e) => {
                console.log('create shipping label error', e)
                callback(e)
                setLoading(false)
            })
    }

    function convertLTOProject(post, callback) {
        axios.post(`${ROOT_URL}/project/convert/json`, post).then(({ data }) => {
            setFileInfo(data)
            setSelectedFile(data)
            callback()
        })
    }

    function updateFileHistory(addToHistory) {
        const oneday = 60 * 60 * 24 * 1000
        const oneDayAgo = Date.now() - oneday
        // console.log('recent',oneDayAgo,recentFileHistory)

        const newHistory = [
            ...recentFileHistory?.filter(
                (r) =>
                    (r.recentFileDate > oneDayAgo && r.id !== addToHistory.project_id) ||
                    r.id !== addToHistory.project_id
            ),
            {
                name: addToHistory.ncs_lno,
                id: addToHistory.project_id,
                recentFileDate: Date.now(),
            },
        ]
        localStorage?.setItem(RECENT_FILE_HISTORY, JSON.stringify(newHistory))
        setRecentFileHistory(newHistory)
    }

    function saveAssociate(assoc) {
        if (assoc._id === 0) {
            createAssociate(assoc)
        } else {
            setLoading(true)
            setDraftSaveState('loading')
            axios
                .patch(`/nml/tracker/2/version/associate/${assoc._id}/json`, assoc)
                .then(({ data }) => {
                    // console.log('saving changes',data)
                    setLoading(false)
                    updateProject(data)
                })
                .catch((e) => {
                    setLoading(false)
                    setDraftSaveState('error')
                })
        }
    }

    async function saveProject(data) {
        setDraftSaveState('loading')
        setLoading(true)

        axios
            .patch(`/nml/tracker/2/version/project/${data._id}/json`, data)
            .then(({ data }) => {
                // console.log('saving changes',data)
                setLoading(false)
                updateProject(data)
            })
            .catch((e) => {
                setLoading(false)
                setDraftSaveState('error')
            })
    }

    function updateProject(data) {
        if (data?.success) {
            let updateFileInfo = JSON.parse(JSON.stringify(fileInfo))
            updateFileInfo = { ...updateFileInfo, ...data.refresh }

            setFileInfo(updateFileInfo)
            setDraftSaveState('saved')
        } else {
            setDraftSaveState('error')
        }
    }

    function createAssociate(data) {
        setLoading(true)
        axios
            .post(`/nml/tracker/2/version/associate/create/${fileInfo.project._id}/json`, data)
            .then(({ data }) => {
                // console.log('creating assoc',data)
                setLoading(false)
                if (data?.success) {
                    updateProject(data)
                }
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    function deleteAssociate(_id) {
        saveAssociate({ _id, is_deleted: true })
    }

    function getFileHistory() {
        setLoading(true)
        axios
            .get(`/nml/tracker/2/version/list/${fileInfo?.project_id}/json`)
            .then(({ data }) => {
                setLoading(false)
                if (Array.isArray(data)) setFileHistory(data.reverse())
            })
            .catch((e) => setLoading(false))
    }

    function addFurnishing(project_id) {
        setLoading(true)
        axios
            .post(`${TRACKER_2_URL}/version/furnishing/create/${project_id}json`)
            .then(({ data }) => {
                setLoading(false)
                updateProject(data)
            })
    }

    function addDeadline(project_id) {
        setLoading(true)
        axios
            .post(`${TRACKER_2_URL}/version/deadline/create/${project_id}json`)
            .then(({ data }) => {
                setLoading(false)
                updateProject(data)
            })
    }

    function saveEditFurnishing(info) {
        // console.log('saving edit',info)
        if (info?.date) {
            info.date = formatDate(info.date, 'YYYY-MM-DD')
        }
        setLoading(true)
        axios
            .patch(`${TRACKER_2_URL}/version/furnishing/${info?._id}/json`, { ...info })
            .then(({ data }) => {
                setLoading(false)
                updateProject(data)
            })
    }

    function saveEditDeadline(info) {
        // console.log('saving edit',info)
        if (info?.date) {
            info.date = formatDate(info.date, 'YYYY-MM-DD')
        }
        setLoading(true)
        axios
            .patch(`${TRACKER_2_URL}/version/deadline/${info?._id}/json`, { ...info })
            .then(({ data }) => {
                setLoading(false)
                updateProject(data)
            })
    }

    const project_id = router.query?.['project_id']
    const history_id = router.query?.history_id ?? false
    const url = '/nml/tracker/file'

    const trackerLinks = [
        {
            title: 'Project',
            href: `${url}`,
            icon: 'fal fa-folder-open',
            links: [
                {
                    title: 'Overview',
                    href: `${url}/overview`,
                    icon: 'fal fa-folder-closed',
                },
                {
                    title: 'Tasks',
                    href: `${url}/tasks`,
                    icon: 'fal fa-list-check',
                },
                {
                    title: 'Associates',
                    href: `${url}/associates`,
                    icon: 'fal fa-users',
                },
                {
                    title: 'Dates',
                    href: `${url}/dates`,
                    icon: 'fal fa-calendars',
                },
                {
                    title: 'Payables',
                    href: `${url}/payables`,
                    icon: 'fal fa-money-check-dollar-pen',
                },
                // {
                // 	title:'Dates',
                // 	href:`${url}/dates`
                // },
                {
                    title: 'Counties',
                    href: `${url}/counties`,
                    icon: 'fal fa-flag-usa',
                },
                {
                    title: 'Documentation',
                    href: `${url}/documentation`,
                    icon: 'fal fa-clipboard-check',
                },
                fileInfo?.extra?.length > 0 && {
                    title: 'Extra',
                    href: `${url}/extra`,
                    icon: 'fal fa-asterisk',
                },
            ],
        },
        {
            title: 'History',
            href: `${url}/history`,
            icon: 'fal fa-history',
            links: [
                {
                    title: 'Project History',
                    href: `${url}/history/file`,
                    icon: 'fal fa-history',
                },
                {
                    title: 'Mail History',
                    href: `${url}/history/mail`,
                    icon: 'fal fa-envelope',
                },
                {
                    title: 'Request History',
                    href: `${url}/history/requests`,
                    icon: 'fal fa-inbox-full',
                },
            ],
        },
        {
            title: 'Research',
            href: `${url}/research`,
            icon: 'fal fa-binoculars',
            links: [
                // {
                // 	title:'Dashboard',
                // 	href: `${url}/research/dashboard`
                // },
                {
                    title: 'Dashboard',
                    href: `${url}/research/dashboard`,
                    icon: 'fal fa-binoculars',
                },
                {
                    title: 'Property Detail Reports',
                    href: `${url}/research/pdr`,
                    icon: 'fal fa-building',
                },
            ],
        },
        {
            title: 'Documents',
            href: `${url}/documents`,
            icon: 'fal fa-file-signature',
            links: [
                {
                    title: 'Docuware',
                    href: `${url}/documents/docuware`,
                    icon: 'fab fa-dochub',
                },
                {
                    title: 'Generated',
                    href: `${url}/documents/generated`,
                    icon: 'fal fa-file-contract',
                },
                // {
                // 	title:'Waivers',
                // 	href: `${url}/documents/waivers`
                // }
            ],
        },
        {
            title: 'NCS',
            icon: 'fal fa-folder-open',
            links: [
                {
                    title: 'Email',
                    href: `${url}/ncs/email`,
                    icon: 'fal fa-at',
                    links: [],
                },
                {
                    title: 'Open in NLD',
                    href: nldUrl(fileInfo?.project?.project_type, fileInfo?.project?.state),
                    icon: 'fal fa-book-open-cover',
                    links: [],
                    newWindow: true,
                },
                {
                    title: 'Open in OLS',
                    href: `${process.env.OLS_HOST}/onlineservices/construction/account_management/?direct_no=${project_id}`,
                    icon: 'fal fa-screen-users',
                    links: [],
                    newWindow: true,
                },
                // {
                // 	title:'Notes',
                // 	href:`/nml/tracker/file/${direct_no}/state_maintenance`,
                // 	icon:'fa-cogs'
                // },
            ],
        },
    ]

    const filteredTrackerTabs = trackerLinks.filter(
        (l) => l?.href && router.pathname.startsWith(l.href)
    )
    const trackerTab =
        filteredTrackerTabs?.length > 1 ? filteredTrackerTabs[1] : filteredTrackerTabs[0]

    return (
        <Context.Provider
            value={{
                files,
                getFiles,
                isLoading,
                collapsed,
                toggleCollapse,
                setSearch,
                selectedFile,
                selectFile,
                dropdowns,
                fileInfoLoading,
                show,
                setShow,
                sort,
                changeSortOption,
                sortDirection,
                setSortDirection,
                setFileInfo,
                researchLoading,
                stageChange,
                changeFilters,
                resetFilters,
                search,
                getResearchCards,
                researchCards,
                createShippingLabel,
                getClientHistory,
                clientHistory,
                setClientHistory,
                convertLTOProject,
                searchAggs,
                setSearchAggs,
                queueEmpId,
                setQueueEmpId,
                baseSearch,
                applyFilters,
                filters,
                recentFileHistory,
                updateFileHistory,
                historyEmpId,
                docuwareContacts,
                setDocuwareContacts,
                fileBarCollapsed,
                toggleFileBarCollapse,
                saveAssociate,
                createAssociate,
                deleteAssociate,
                draftSaveState,
                setDraftSaveState,
                saveProject,
                getFileHistory,
                fileHistory,
                canEdit:
                    fileInfo?.lock_status?.is_mine ||
                    !fileInfo?.lock_status?.is_locked ||
                    fileInfo?.project?._workflow === 'PORTAL',
                saveEditFurnishing,
                saveEditDeadline,
                addFurnishing,
                addDeadline,
                trackerLinks,
                trackerTab,
            }}
        >
            <MaintenanceContext>
                <NoticeContext>
                    <RequestContext>{props.children}</RequestContext>
                </NoticeContext>
            </MaintenanceContext>
        </Context.Provider>
    )
}

export default GlobalState
