import { Modal } from 'react-bootstrap'
import Dates from '../dates/Dates'

export default function EditDatesModal({ show, onHide, deadlines }) {
    return (
        <Modal show={show} onHide={onHide} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>Edit Dates</Modal.Title>
            </Modal.Header>
            <div className='mt-5'>
                <Dates onlyShowTable filterOnDeadlineIds={deadlines?.map(({ _id }) => _id)} />
            </div>
        </Modal>
    )
}
