import classnames from 'classnames'

export default function TypeIcon({ type, size, weight = 'fad', className }) {
    switch (type) {
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
        case 'application/vnd.ms-word.document.macroEnabled.12':
        case 'application/vnd.ms-word.template.macroEnabled.12':
        case 'application/msword':
            return (
                <span
                    className={classnames(weight, 'fa-file-lines text-primary', size, className)}
                />
            )
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return (
                <span
                    className={classnames(
                        weight,
                        'fa-file-spreadsheet text-success',
                        size,
                        className
                    )}
                />
            )
        case 'application/pdf':
            return (
                <span className={classnames(weight, 'fa-file-pdf text-danger', size, className)} />
            )
        case 'image/png':
            return <span className={classnames(weight, 'fa-image text-primary', size, className)} />
        case 'application/zip':
            return (
                <span
                    className={classnames(weight, 'fa-file-zipper text-danger', size, className)}
                />
            )
        default:
            return <span className={classnames(weight, 'fa-file text-primary', size, className)} />
    }
}
