import { useEffect } from 'react'

export function usePageInactivity(callback, timeInMs) {
    useEffect(() => {
        let cancel = monitorForInactivity(callback, timeInMs)
        return cancel
    }, [callback, timeInMs])
}

var monitorForInactivity = (callback, time) => {
    var timer

    var events = ['load', 'mousedown', 'mousemove', 'keypress', 'keydown', 'scroll', 'touchstart']
    events.forEach((name) => {
        document.addEventListener(name, resetTimer, true)
    })

    function resetTimer() {
        clearTimeout(timer)
        timer = setTimeout(callback, time)
    }

    function cancel() {
        clearTimeout(timer)
        events.forEach((name) => {
            document.removeEventListener(name, resetTimer, true)
        })
    }

    return cancel
}
