import React, { useState, Fragment } from 'react'

export default function MessageListItem({ selected, message, onClick, hide }) {
    const [hovering, setHovering] = useState(false)
    const [areYouSure, setAreYouSure] = useState(false)

    return (
        <li
            className={
                'list-group-item p-3 pointer message-list-item ' +
                (selected ? 'message-selected' : '')
            }
            onClick={onClick}
            onMouseEnter={() => {
                setHovering(true)
            }}
            onMouseLeave={() => {
                setHovering(false)
            }}
        >
            {areYouSure ? (
                <Fragment>
                    Are you sure you want to hide this?
                    <div className={'row'}>
                        <div className={'col-6'}>
                            <button
                                className={'btn btn-xs btn-outline-secondary w-100'}
                                onClick={() => {
                                    hide()
                                    setAreYouSure(false)
                                }}
                            >
                                <span className={'far fa-check'} /> Yes
                            </button>
                        </div>
                        <div className={'col-6'}>
                            <button
                                className={'btn btn-xs btn-outline-secondary w-100'}
                                onClick={() => {
                                    setAreYouSure(false)
                                }}
                            >
                                <span className={'far fa-times'} /> No
                            </button>
                        </div>
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    {message.viewed === 0 && (
                        <span className={'fas fa-circle fa-xs'} style={{ color: '#18c3fb' }} />
                    )}
                    {message.display}
                    {hovering ? (
                        <span
                            className={'far fa-eye-slash pointer float-end'}
                            onClick={() => {
                                setAreYouSure(true)
                            }}
                        />
                    ) : (
                        selected && <span className={'float-end far fa-chevron-right'} />
                    )}
                </Fragment>
            )}
        </li>
    )
}
