import { useQuery } from '@tanstack/react-query'
import { mergeConfigs } from '../../../../utils'
import axios from '../Context/AxiosSetup'

export class ProjectHistory {
    static keys = {
        all: ['projectHistory'],
        project: (projectId) => [...this.keys.all, projectId],
    }

    static getHistory = (projectId) => {
        return axios.get(`/nml/tracker/2/version/list/${projectId}/json`).then(({ data }) => {
            return data.reverse()
        })
    }
}

export const useProjectHistory = ({ projectId }, config) => {
    return useQuery(
        ProjectHistory.keys.project(projectId),
        () => ProjectHistory.getHistory(projectId),
        mergeConfigs(
            {
                enabled: !!projectId,
            },
            config
        )
    )
}
