import React, { useContext, useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import MessageList from './MessageList'
import Messages from './Messages'
import { MessageContext } from '../Context/GlobalState'
import { Modal } from 'react-bootstrap'

export default function MessagesModal({ show, hide }) {
    const { selected, findSelected } = useContext(MessageContext)
    const [mounted, setMounted] = useState(false)
    const selectedMessages = findSelected(selected)

    useEffect(() => {
        setMounted(true)
        return () => {
            setMounted(false)
        }
    }, [])

    return mounted
        ? createPortal(
              <Modal
                  show={show}
                  onHide={hide}
                  size='xl'
                  dialogClassName='h-95'
                  contentClassName='h-100'
              >
                  <Modal.Header closeButton>
                      <Modal.Title>
                          Messages{' '}
                          {selectedMessages?.display && <>from {selectedMessages.display}</>}
                      </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className='p-0'>
                      <div className={'row no-gutters'} style={{ height: '100%' }}>
                          <div className={'col-md-3 col-sm-1 pe-0'}>
                              <MessageList />
                          </div>
                          <div className={'col-md-9 col-sm-11 ps-0 pe-3'}>
                              <Messages />
                          </div>
                      </div>
                  </Modal.Body>
              </Modal>,
              document.body
          )
        : null
}
