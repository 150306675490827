import { useContext, useState } from 'react'
import { Accordion, Alert, Col, Row } from 'react-bootstrap'
import { Badge, Button, Offcanvas } from '../../../../front'
import { ProjectContext } from '../../Context/ProjectContext'
import { usePublishProject } from '../../api/project.api'
import InlineChanges from '../history/inlineChanges'
import SideBySide from '../history/SideBySide'
import { Tooltip } from '@/components/utils'
import { useModalState } from 'hooks'

export default function ChangesOffcanvas({
    showChangesOffcanvas,
    changesCount,
    fileInfo,
    setShowChangesOffcanvas,
    showDiscardModal,
}) {
    const { projectId, publishDisabled } = useContext(ProjectContext)
    const { mutate: publishProject, ...publishVersionApi } = usePublishProject(
        { projectId },
        { onSuccess: () => setShowChangesOffcanvas(false) }
    )
    const [subject, setSubject] = useState('')
    const [publishToOls, setPublishToOls] = useState(false)

    const nonDeadlinesChanges = fileInfo?.display?.filter((row) => row?.section !== 'deadlines')
    const deadlineChanges = fileInfo?.display?.filter(
        (row) => row?.section === 'deadlines' && row?.change_type != 'none'
    )

    return (
        <Offcanvas
            show={showChangesOffcanvas}
            onHide={() => {
                setShowChangesOffcanvas(false)
            }}
            placement='end'
            style={{ width: '700px' }}
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    Changes{' '}
                    <Badge soft bg='dark' className='ms-1'>
                        {changesCount}
                    </Badge>
                </Offcanvas.Title>
            </Offcanvas.Header>

            {(fileInfo?.warnings?.contact_missing || fileInfo?.warnings?.contact_error) && (
                <Alert
                    variant='soft-danger'
                    className='d-flex justify-content-between align-items-center'
                >
                    <div>
                        <i className='far fa-circle-exclamation me-3' />
                        There is no contact selected for this file. Please select one before
                        publishing.
                    </div>
                </Alert>
            )}
            {fileInfo?.incoming_changes?.version?.id && (
                <Alert
                    variant='soft-danger'
                    className='d-flex justify-content-between align-items-center'
                >
                    <div>
                        <i className='far fa-circle-exclamation me-3' />
                        Accept or decline OLS changes before publishing.
                    </div>
                </Alert>
            )}

            <Offcanvas.Body>
                {publishVersionApi?.error && (
                    <Alert variant='soft-danger'>
                        <div className='d-flex'>
                            <div className='flex-shrink-0'>
                                <i className='fas fa-circle-exclamation' />
                            </div>
                            <div className='flex-grow-1 ms-2'>Error publishing changes</div>
                        </div>
                    </Alert>
                )}
                {/* <InlineChanges changes={fileInfo?.display} changesFirst /> */}
                {changesCount > 0 ? (
                    <div className='d-flex flex-column'>
                        {nonDeadlinesChanges?.length > 0 && (
                            <SideBySide changes={nonDeadlinesChanges} changesFirst />
                        )}
                        {deadlineChanges?.length > 0 && (
                            <>
                                <DeadlineChanges changes={deadlineChanges} />
                            </>
                        )}
                    </div>
                ) : (
                    <div
                        className='mx-auto fs-3 text-muted'
                        style={{ width: 'fit-content', marginTop: 250 }}
                    >
                        There are no changes to show
                    </div>
                )}
            </Offcanvas.Body>
            {fileInfo?.lock_status?.can_edit && changesCount > 0 && (
                <Offcanvas.Footer
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        <Button variant='secondary' ghost onClick={showDiscardModal}>
                            <i className='fas fa-arrow-rotate-left me-2' />
                            Revert Changes
                        </Button>
                    </div>
                    <Tooltip
                        content={
                            fileInfo?.warnings?.contact_missing || fileInfo?.warnings?.contact_error
                                ? 'Please select a contact before publishing'
                                : fileInfo?.incoming_changes?.version?.id
                                ? 'Review OLS changes before publishing'
                                : null
                        }
                        placement='top'
                    >
                        <div>
                            <Button
                                onClick={publishProject}
                                icon={
                                    !publishVersionApi?.loading
                                        ? 'fas fa-cloud-upload'
                                        : 'fal fa-spinner-third fa-spin'
                                }
                                disabled={publishDisabled}
                                variant='primary'
                            >
                                {!publishVersionApi?.error ? 'Publish' : 'Retry Publish'}
                            </Button>
                        </div>
                    </Tooltip>
                </Offcanvas.Footer>
            )}
        </Offcanvas>
    )
}

function DeadlineChanges({ changes }) {
    return (
        <Accordion alwaysOpen className='mt-3'>
            <Accordion.Item eventKey={'deadlineChanges'}>
                <Accordion.Header>
                    <Row>
                        <Col className='pt-4 pb-2'>
                            <span className='popup-section-title text-dark'>
                                Deadline Changes ({changes?.length})
                            </span>
                        </Col>
                    </Row>
                </Accordion.Header>
                <Accordion.Body className='mb-5'>
                    <SideBySide changes={changes} changesFirst />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}
