import {
    GoogleMap,
    Marker,
    MarkerClusterer,
    useGoogleMap,
    useJsApiLoader,
} from '@react-google-maps/api'
import { Button, Form } from 'front'
import { useTheme } from 'hooks'
import { useCallback, useEffect, useRef, useState } from 'react'
import ParcelMapScript from './ParcelMapScript'

const center = { lat: 37.7749, lng: -122.4194 }
const mapContainerStyle = { height: '100%', width: '100%' }
const googleMapsApiKey = 'AIzaSyBaNQdTgz9e3YVJS3Pt34yM1ap8heNrQiY'

const GoogleMapsCluster = ({
    markers,
    onClusterMouseOver,
    onClusterMouseOut,
    children,
    onVisibleMarkersChange,
}) => {
    const [markerCoords, setMarkerCoords] = useState()
    const [error, setError] = useState(false)
    const { theme } = useTheme()
    const mapRef = useRef()
    const map = mapRef?.current
    const [googleMapLoaded, setGoogleMapLoaded] = useState(false)
    const [parcelsEnabled, setParcelsEnabled] = useState(false)
    const [googleLoaded, setGoogleLoaded] = useState(false)

    useEffect(() => {
        if (window?.google) {
            setGoogleLoaded(true)
        }
    }, [googleLoaded, window?.google])

    useEffect(() => {
        getCoordinates(markers)
            .then((coordinates) => {
                setMarkerCoords(coordinates)
            })
            .catch((error) => {
                setError(true)
                console.error(error)
            })
    }, [markers])

    const [showResetButton, setShowResetButton] = useState(false)
    const enableResetButton = useCallback(() => {
        if (!showResetButton) setShowResetButton(true)
    }, [showResetButton])

    const resetBounds = (map) => {
        setShowResetButton(false)
        const bounds = new window.google.maps.LatLngBounds()
        markerCoords?.forEach((marker) => {
            if (marker?.coordinates && !marker?.error) bounds.extend(marker.coordinates)
            map?.fitBounds(bounds)
        })
    }

    const onLoad = useCallback(
        (map) => {
            resetBounds(map)
            setGoogleMapLoaded(true)
            mapRef.current = map
        },
        [markerCoords]
    )

    const onBoundsChanged = useCallback(() => {
        const { lat: north, lng: east } = map.getBounds()?.getNorthEast()
        const { lat: south, lng: west } = map.getBounds()?.getSouthWest()

        onVisibleMarkersChange(
            markerCoords?.filter(
                ({ coordinates: { lat, lng } }) =>
                    lat >= south() && lat <= north() && lng >= west() && lng <= east()
            )
        )
    }, [map, markerCoords])

    // const { isLoaded } = useJsApiLoader({ googleMapsApiKey })
    // if (!isLoaded) {
    //     return null
    // }
    if (!googleLoaded) return null

    if (!markerCoords)
        return (
            <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                <i className='fal fa-spinner-third fa-spin fa-6x text-muted d-block' />
            </div>
        )

    return (
        <>
            {parcelsEnabled && <ParcelMapScript map={map} googleMapLoaded={googleMapLoaded} />}
            <GoogleMap
                center={center}
                zoom={10}
                mapContainerStyle={mapContainerStyle}
                onLoad={onLoad}
                onZoomChanged={enableResetButton}
                onDragStart={enableResetButton}
                onBoundsChanged={onBoundsChanged}
                options={{
                    styles: theme == 'dark' ? darkStyles : [],
                    mapTypeControl: true,
                    mapTypeControlOptions: {
                        style: window.google?.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                        position: window.google?.maps.ControlPosition.LEFT_TOP,
                    },
                    zoomControl: true,
                    zoomControlOptions: {
                        position: window.google?.maps.ControlPosition.RIGHT_BOTTOM,
                    },
                    streetViewControl: true,
                    streetViewControlOptions: {
                        position: window.google?.maps.ControlPosition.RIGHT_TOP,
                    },
                    fullscreenControl: false,
                }}
            >
                <MarkerClusterer
                    averageCenter
                    enableRetinaIcons
                    gridSize={60}
                    onMouseOver={onClusterMouseOver}
                    onMouseOut={onClusterMouseOut}
                >
                    {(clusterer) =>
                        markerCoords?.map((marker, index) => (
                            <Marker
                                key={index}
                                position={marker?.coordinates}
                                clusterer={clusterer}
                                label={marker?.label}
                                title={marker?.address}
                                onMouseDown={marker?.onMouseDown}
                                onMouseOver={marker?.onMouseOver}
                                onMouseOut={marker?.onMouseOut}
                            />
                        ))
                    }
                </MarkerClusterer>
                {children}
                <ResetBoundsControl resetBounds={resetBounds} show={showResetButton} />
                <EnableParcelsControl
                    parcelsEnabled={parcelsEnabled}
                    setParcelsEnabled={setParcelsEnabled}
                />
            </GoogleMap>
        </>
    )
}

const ResetBoundsControl = ({ resetBounds, show }) => {
    const map = useGoogleMap()
    return (
        <MapControl position='RIGHT_BOTTOM'>
            {show && (
                <div style={{ marginRight: 2 }}>
                    <Button
                        variant='white'
                        onClick={() => resetBounds(map)}
                        className='me-2 google-maps-button p-1'
                        icon='fas fa-expand-wide'
                        size='sm'
                        style={{ width: 41 }}
                    />
                </div>
            )}
        </MapControl>
    )
}

const EnableParcelsControl = ({ parcelsEnabled, setParcelsEnabled }) => {
    const map = useGoogleMap()
    return (
        <MapControl position='LEFT_BOTTOM'>
            <div className='bg-white px-2 py-1 m-2 rounded-1 google-maps-button'>
                <Form.Check
                    className='mt-2'
                    type={'switch'}
                    checked={parcelsEnabled}
                    onClick={() => setParcelsEnabled(!parcelsEnabled)}
                    label='Enable Parcels'
                />
            </div>
        </MapControl>
    )
}

export const MapControl = ({ position, children }) => {
    const map = useGoogleMap()
    const ref = useRef()
    useEffect(() => {
        if (map && ref) {
            map.controls[window.google?.maps.ControlPosition[position]].push(ref.current)
        }
    }, [map, ref])
    return <div ref={ref}>{children}</div>
}

async function getCoordinates(markers) {
    const coordinates = []

    for (const marker of markers) {
        const formattedAddress = encodeURIComponent(marker?.address)
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${formattedAddress}&key=${googleMapsApiKey}`

        try {
            const response = await fetch(url)
            const data = await response.json()

            if (data.status === 'OK' && data.results.length > 0) {
                const location = data.results[0].geometry.location
                coordinates.push({
                    address: marker?.address,
                    label: marker?.label,
                    coordinates: location,
                    ...marker,
                })
            } else {
                coordinates.push({
                    address: marker,
                    error: 'No results found',
                })
            }
        } catch (error) {
            coordinates.push({
                address: marker,
                error: 'An error occurred',
            })
        }
    }

    return coordinates
}

const darkStyles = [
    { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
    { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
    { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
    {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [{ color: '#263c3f' }],
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#6b9a76' }],
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#38414e' }],
    },
    {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#212a37' }],
    },
    {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#9ca5b3' }],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{ color: '#746855' }],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#1f2835' }],
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#f3d19c' }],
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [{ color: '#2f3948' }],
    },
    {
        featureType: 'transit.station',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#d59563' }],
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#17263c' }],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{ color: '#515c6d' }],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.stroke',
        stylers: [{ color: '#17263c' }],
    },
]

export default GoogleMapsCluster
