import {
    currencyFormatter,
    CustomizeColumns,
    formatDate,
    Highlighter,
    isEmpty,
    Pagination,
} from 'components/utils'
import { Button, Card, Table } from 'front'
import { useDebounce, useSession } from 'hooks'
import filter from 'lodash/filter'
import orderBy from 'lodash/orderBy'
import times from 'lodash/times'
import uniqBy from 'lodash/uniqBy'
import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { Fragment, useMemo, useRef, useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'

function SortableTable({ filterKey, ...props }) {
    const router = useRouter()
    const tableData = useMemo(() => {
        return props?.info?.map((row) => {
            const searchValues = props?.headers
                ?.map((header) => {
                    if (header?.search != false) {
                        const searchField = header?.searchField ?? header?.value
                        if (Array.isArray(searchField)) {
                            return searchField
                                ?.map((val) => {
                                    const value = getValue(row, val)
                                    if (value) {
                                        return typeof value === 'string'
                                            ? value?.toLowerCase()
                                            : value?.toString()?.toLowerCase()
                                    } else {
                                        return ''
                                    }
                                })
                                .join(' ')
                        }
                        if (searchField) {
                            const value = getValue(row, searchField)
                            return typeof value === 'object'
                                ? ''
                                : typeof value === 'string'
                                ? value?.toLowerCase()
                                : value?.toString()?.toLowerCase()
                        } else {
                            return ''
                        }
                    }
                })
                .join(' ')

            return {
                ...row,
                searchValues,
            }
        })
    }, [props?.info, props?.headers])
    // console.log('router: ', router)
    const defaultView = 15
    const [state, updateState] = useState({
        view: props?.view ? props?.view : defaultView,
        start: 0,
        end: props?.all ? props?.info.length : props?.view ? props?.view - 1 : defaultView - 1,
        sort: props.startSort || '',
        sortBy: props.startSortBy || 'desc',
        selected: [],
        selectedAll: false,
        colTotals: null,
        filter: {},
        search: '',
        timeout: 0,
        searchedData: props?.info,
        tableId: 'SortableTable' + Math.random() * 10000,
    })
    // console.log('table id', state.tableId)
    const [view, setView] = useSession('sortableTableView-' + router.pathname, 15)
    const [start, setStart] = useSession('sortableTableStart-' + router.pathname, 0)
    const [end, setEnd] = useSession(
        'sortableTableEnd-' + router.pathname,
        props?.all ? props?.info.length : props?.view ? props?.view - 1 : defaultView - 1
    )
    const tableRef = useRef(null)
    const searchValue = useDebounce(state.search, 250)

    const [savedSortSettings, setSavedSortSettings] = useSession(
        'sortableTableSortSettings-' + router.pathname,
        []
    )

    useEffect(() => {
        if (filterKey) {
            const savedSetting = savedSortSettings?.find((setting) => setting.id === filterKey)
            if (savedSetting) {
                updateState((prevState) => ({
                    ...prevState,
                    sort: savedSetting.sort,
                    sortBy: savedSetting.sortBy,
                }))
            } else {
                updateState((prevState) => ({
                    ...prevState,
                    sort: props.startSort || '',
                    sortBy: props.startSortBy || 'desc',
                }))
            }
        }
    }, [filterKey])

    function changeSort(variable) {
        const newSortBy = state.sortBy === 'desc' ? 'asc' : 'desc'
        const newSort = state.sort === variable ? state.sort : variable
        const newSortByState = state.sort === variable ? newSortBy : 'desc'

        updateState({
            ...state,
            sort: newSort,
            sortBy: newSortByState,
        })

        if (filterKey) {
            setSavedSortSettings((prevSettings) => {
                const newSettings = [...prevSettings]
                const index = newSettings.findIndex((setting) => setting.id === filterKey)

                if (index !== -1) {
                    newSettings[index] = {
                        id: filterKey,
                        sort: newSort,
                        sortBy: newSortByState,
                    }
                } else {
                    newSettings.push({
                        id: filterKey,
                        sort: newSort,
                        sortBy: newSortByState,
                    })
                }

                return newSettings
            })
        }
    }

    function sortDirection(variable) {
        // console.log(
        //     'sort',
        //     JSON.stringify(state?.sort) === JSON.stringify(variable),
        //     JSON.stringify(state?.sort),
        //     JSON.stringify(variable),
        //     state?.sort,
        //     variable
        // )
        if (JSON.stringify(state?.sort) === JSON.stringify(variable)) {
            return state?.sortBy === 'desc' ? (
                <span className={'fad fa-sort-down text-primary ms-2 fa-sm'} />
            ) : (
                <span className={'fad fa-sort-up text-primary ms-2 fa-sm'} />
            )
        } else {
            return <span className={'fas fa-sort ms-2 fa-sm'} />
        }
    }

    function multiRow(header, row) {
        return header?.value?.map((val, key) => (
            <div key={'multiRow' + key + props?.id ?? Math.random()}>
                {!header?.noHr && key > 0 && <hr className={'my-1'} />}
                {val?.custom ? (
                    val.custom(row)
                ) : (
                    <Highlighter check={row[val]}>
                        <span style={key > 0 ? { color: '#8f8f8f' } : {}}>
                            {getValue(row, val)}
                        </span>
                    </Highlighter>
                )}
            </div>
        ))
    }

    function getValue(row, value) {
        // console.log('inside obj',value,row)
        let rowValue = row
        let splitValue = []
        if (typeof value === 'boolean' || !value) {
            rowValue = row
            splitValue = ''
        } else if (typeof value === 'string') {
            rowValue = row?.[value]
            splitValue = value
        } else if (typeof value === 'object') {
            rowValue = row?.[value?.value]
            splitValue = value?.value
        }
        const split = splitValue?.split('.')
        let returnValue = ''
        if (split?.length > 1) {
            let returnRow = row
            split?.map((val) => {
                returnRow = returnRow?.[val]
            })
            if (!returnRow) {
                returnRow = 'None'
            }
            returnValue = returnRow
        } else if (!rowValue) {
            returnValue = 'None'
        } else {
            returnValue = rowValue
        }

        if (typeof value === 'object') {
            if (value?.date) {
                returnValue = formatDate(returnValue)
            } else if (value?.currency) {
                returnValue = currencyFormatter(returnValue)
            }

            if (value?.className) {
                returnValue = <div className={value?.className}>{returnValue}</div>
            }
        }
        return returnValue
    }

    function getLinkForReportTable(row) {
        let baseURL = props?.baseURL
        let metadataOnePageAllTables = props?.metadataOnePageAllTables
        let detailKey = props?.detailKey
        let detail_id = row[detailKey]
        let to = undefined
        let link = undefined
        if (baseURL && detail_id && metadataOnePageAllTables) {
            link = baseURL + detail_id + '/'
            to = {
                pathname: link,
                state: { definition: metadataOnePageAllTables, fromURL: props?.fromURL },
            }
        }
        return { link, to }
    }

    function tableCell(row, header) {
        // This function takes in an entire table row and one of the table's headers ('Name' or 'Address' for examples),
        // picks out the row data that corresponds to the header, and returns a div containing that data.
        // Usually, the data is simply equal to row[header.value].  However, if the given header has a 'custom' field,
        // 'custom' is assumed to be a function which can produce the contents of the table cell (e.g.,
        // a checkbox), and that function is called instead of using row[header.value].
        return (
            <>
                {header.custom ? (
                    header.custom(
                        Array.isArray(header.value)
                            ? header.value.map((val) => {
                                  return getValue(row, val)
                              })
                            : getValue(row, header.value)
                    )
                ) : header.date ? (
                    formatDate(row[header.value])
                ) : header.currency ? (
                    currencyFormatter(row[header.value])
                ) : Array.isArray(header.value) ? (
                    multiRow(header, row)
                ) : typeof row[header.value] === 'string' &&
                  props?.highlightThis !== '' &&
                  row[header.value].includes(props?.highlightThis) ? (
                    <Highlighter check={row[header.value]}>
                        <span className='highlighted-value'>{getValue(row, header.value)}</span>
                    </Highlighter>
                ) : (
                    <span>{getValue(row, header.value)}</span>
                )}
            </>
        )
    }

    const renderRow = (row, i) => {
        let { link, to } = getLinkForReportTable(row)
        let linkStyle = {
            textDecoration: 'inherit',
        }
        const sticky = props?.stickyRow
            ? row[props?.stickyRow.field] === props?.stickyRow.value
            : false
        const stickyClass = sticky ? 'sortableTableStickyRow' : ''
        const stickyHeaderOffset = document.getElementById('sortableTableHeader')?.clientHeight
        const stickyTopSyle = sticky
            ? {
                  top:
                      (stickyHeaderOffset ? stickyHeaderOffset - 2 : 0) +
                      (props?.stickyHeaderOffset ?? 0),
              }
            : {}

        let rowProps = {}
        if (typeof props?.rowProps === 'function') {
            rowProps = props?.rowProps(row)
        } else if (props?.rowProps) {
            rowProps = props?.rowProps
        }

        return (
            <tr key={i} {...rowProps}>
                {props?.checkbox && (
                    <td className={stickyClass} style={stickyTopSyle}>
                        <input
                            type={'checkbox'}
                            className={'checkbox'}
                            onChange={props?.checkboxCallback}
                        />
                    </td>
                )}
                {props?.headers.map(
                    (header) =>
                        header && (
                            <td
                                key={'tableData' + header.name + (props?.id ?? Math.random())}
                                className={
                                    (header.rowClassName ? header.rowClassName : '') + stickyClass
                                }
                                style={stickyTopSyle}
                            >
                                {link !== undefined && to !== undefined ? (
                                    <Link href={to}>
                                        <a href={'#'} style={linkStyle}>
                                            {tableCell(row, header)}
                                        </a>
                                    </Link>
                                ) : (
                                    <>{tableCell(row, header)}</>
                                )}
                            </td>
                        )
                )}
                {props?.rowTotals && (
                    <td>
                        {props?.headers
                            .map((header) => !header.noTotal && row[header.value])
                            .reduce((partial_sum, a) => partial_sum + a)}
                    </td>
                )}
            </tr>
        )
    }

    function searchData() {
        return tableData?.filter((row) => row?.searchValues?.includes(searchValue.toLowerCase()))
    }

    function filteredData() {
        // console.log('filtered data',state?.searchedData.length,state?.searchedData,state?.filter)
        const searched = searchData()
        return props?.filter && !isEmpty(state?.filter) ? filter(searched, state?.filter) : searched
    }

    function exportToCSV(data) {
        //create csv
        let csvContent = 'data:application/vnd.ms-excel;charset=utf-8,'
        csvContent +=
            props?.headers
                .map((h) => {
                    const value = h.name.replace(/"/g, '""')
                    if (value.search(/("|,|\n)/g) >= 0) {
                        return '"' + value + '"'
                    }
                    return value
                })
                .join(',') + '\n'
        csvContent += data
            .map((r) =>
                props?.headers
                    .map((h) => {
                        const value = r[h.value].replace(/"/g, '""')
                        if (value.search(/("|,|\n)/g) >= 0) {
                            return '"' + value + '"'
                        }
                        return value
                    })
                    .join(',')
            )
            .join('\n')

        //create link and download
        let downloadLink = document.createElement('a')
        document.body.appendChild(downloadLink)
        downloadLink.href = encodeURI(csvContent)
        downloadLink.download = 'excelExport.csv'
        downloadLink.click()
    }
    // console.log('table', props)
    if (props?.info?.length === 0) {
        return this?.props?.noDataDisplay ?? props?.skeleton ? (
            <TableSkeleton count={props?.headers?.length} />
        ) : (
            <Card.Body>No Data</Card.Body>
        )
    }
    const filtered = filteredData()
    const orderedData = orderBy(
        filtered,
        Array.isArray(state?.sort) ? state?.sort : [state?.sort],
        [state?.sortBy]
    )
    // if (props?.id && typeof window !== 'undefined') {
    //     window.localStorage.setItem(props?.id, JSON.stringify(orderedData))
    // }

    let grandTotal = 0
    const colTotals =
        props?.colTotals ||
        (props?.actionBtn && (
            <tr style={{ backgroundColor: '#fbfbfb' }}>
                {props?.colTotals &&
                    props?.headers.map((header, key) => {
                        if (header) {
                            return (
                                <td key={key}>
                                    {header.noTotal
                                        ? key === 0
                                            ? 'Totals'
                                            : 'None'
                                        : new Intl.NumberFormat().format(
                                              props?.info
                                                  .map((row) => parseInt(row[header.value]))
                                                  .reduce((partial_sum, a, index) => {
                                                      grandTotal =
                                                          grandTotal +
                                                          (index === 1 ? partial_sum + a : a)
                                                      return partial_sum + a
                                                  })
                                          )}
                                </td>
                            )
                        }
                    })}
                {props?.rowTotals && <td>{grandTotal}</td>}
                {props?.actionBtn && view > 15 && (
                    <td colSpan={props?.headers?.length}>{props?.actionBtn}</td>
                )}
            </tr>
        ))

    let enableTools =
        props?.search ||
        (props?.filter && !isEmpty(state?.filter)) ||
        props?.exportBtn ||
        props?.exportToCSV ||
        (props?.customizeColumn && props?.customizeColumnOptions?.length > 0) ||
        props?.actionBtn

    let tools = (
        <>
            {enableTools && (
                // {props?.filter && !isEmpty(state?.filter) &&
                <div className={'row justify-content-end'}>
                    <div className={'col-12 col-sm-9'}>
                        {props?.exportBtn && (
                            <span className={'float-start'}>{props?.exportBtn}</span>
                        )}
                        {props?.exportToCSV && (
                            <span className={'float-end'}>
                                <button
                                    className={'btn btn-light'}
                                    onClick={() => {
                                        exportToCSV(orderedData)
                                    }}
                                >
                                    <span className={'far fa-file-export'} /> CSV
                                </button>
                            </span>
                        )}
                        {props?.filter && !isEmpty(state?.filter) && (
                            <button
                                className={'btn btn-light float-end'}
                                onClick={() => {
                                    updateState({ filter: {} })
                                }}
                            >
                                <span className={'far fa-times'} /> Clear Filters
                            </button>
                        )}
                        {props?.customizeColumn && props?.customizeColumnOptions?.length > 0 && (
                            <span className={'float-end'}>
                                <CustomizeColumns
                                    customizeColumnOptions={props?.customizeColumnOptions}
                                    saveCustomColumns={props?.saveCustomColumns}
                                />
                            </span>
                        )}
                        {props?.actionBtn && props?.actionBtn}
                    </div>
                    {props?.search && (
                        <div className={'col-12 col-sm-3'}>
                            <div className={'input-group input-group-merge'}>
                                <div class='input-group-prepend input-group-text'>
                                    <i className={props?.searchIcon ?? 'far fa-magnifying-glass'} />
                                </div>
                                <input
                                    className={'form-control'}
                                    placeholder={props?.searchPlaceholder ?? 'Search'}
                                    value={state.search}
                                    onChange={({ target }) => {
                                        updateState({ search: target.value })
                                    }}
                                />
                                {state.search?.length > 0 && (
                                    <div className='input-group-append input-group-button'>
                                        <Button
                                            variant='secondary'
                                            icon='far fa-times'
                                            ghost
                                            onClick={() => {
                                                updateState({
                                                    search: '',
                                                    searchedData: props?.info,
                                                })
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    )

    const table = (
        <div ref={tableRef}>
            <Table
                lightHeader
                borderless={props?.borderless ?? false}
                cardTable={props?.cardTable}
                className={
                    props?.className
                        ? props?.className
                        : 'table table-hover clickable-table' +
                          (props?.responsive ? ' sortableTable-responsive' : '')
                }
                id={state?.tableId}
                // style={props?.style ? {...props?.style}:''}
            >
                <Table.Head
                    className={props?.headerClassName ? props?.headerClassName : ''}
                    id={'sortableTableHeader'}
                >
                    {!props?.card && enableTools && (
                        <tr>
                            <th colSpan={'100%'}>{tools}</th>
                        </tr>
                    )}
                    {props?.filter && (
                        <tr>
                            {props?.headers.map((header, i) => {
                                if (header) {
                                    return header?.name?.length > 0 ? (
                                        header?.filter !== false ? (
                                            <th key={i}>
                                                <select
                                                    className={'form-select'}
                                                    onChange={({ target }) => {
                                                        updateState((prevstate) => {
                                                            if (
                                                                prevstate?.filter?.[header.value] &&
                                                                target.value.length === 0
                                                            ) {
                                                                const newFilter = {
                                                                    ...prevstate?.filter,
                                                                }
                                                                delete newFilter[header.value]
                                                                return {
                                                                    ...prevstate,
                                                                    filter: { ...newFilter },
                                                                }
                                                            } else if (target.value.length > 0) {
                                                                return {
                                                                    ...prevstate,
                                                                    filter: {
                                                                        ...prevstate?.filter,
                                                                        [header.value]:
                                                                            target.value,
                                                                    },
                                                                }
                                                            } else {
                                                                return { ...prevstate }
                                                            }
                                                        })
                                                    }}
                                                    value={state?.filter?.[header.value] ?? ''}
                                                >
                                                    <option value={''}>All {header.name}</option>
                                                    {uniqBy(filtered, header.value)
                                                        .sort((a, b) => {
                                                            const aCompare =
                                                                typeof a[header.value] === 'string'
                                                                    ? a[header.value].toLowerCase()
                                                                    : false
                                                            const bCompare =
                                                                typeof b[header.value] === 'string'
                                                                    ? b[header.value].toLowerCase()
                                                                    : false
                                                            return aCompare > bCompare
                                                                ? 1
                                                                : aCompare < bCompare
                                                                ? -1
                                                                : 0
                                                        })
                                                        .map((option, i) => (
                                                            <option
                                                                value={option[header.value]}
                                                                key={i}
                                                            >
                                                                {option[header.value]}
                                                            </option>
                                                        ))}
                                                </select>
                                            </th>
                                        ) : (
                                            <th key={'filter' + header.name + header.value} />
                                        )
                                    ) : (
                                        <th key={'filter' + header.name + header.value} />
                                    )
                                }
                            })}
                        </tr>
                    )}
                    {!props?.noHeaders && (
                        <tr>
                            {props?.checkbox && (
                                <th>
                                    <input
                                        type={'checkbox'}
                                        className={'checkbox'}
                                        // checked={}
                                        onChange={props?.checkAll}
                                    />
                                </th>
                            )}
                            {props?.headers.map((header, i) => {
                                let style = { whiteSpace: 'nowrap' }
                                if (props?.stickyHeaderOffset) {
                                    style.top = props?.stickyHeaderOffset
                                }
                                const headerName =
                                    typeof header?.name === 'function'
                                        ? header.name(header, i)
                                        : header.name ?? ''
                                if (header) {
                                    return header.name.length > 0 ? (
                                        header.sort !== false ? (
                                            <th
                                                key={i}
                                                onClick={() => {
                                                    changeSort(header?.sortField ?? header.value)
                                                }}
                                                className={
                                                    'pointer ' +
                                                    (header.className ? header.className : '') +
                                                    (props?.stickyHeaders
                                                        ? ' th-sticky-header'
                                                        : '')
                                                }
                                                style={style}
                                            >
                                                <span>
                                                    {headerName}{' '}
                                                    {sortDirection(
                                                        header?.sortField ?? header.value
                                                    )}
                                                </span>
                                            </th>
                                        ) : (
                                            <th
                                                key={i}
                                                style={style}
                                                className={
                                                    header.className
                                                        ? header.className
                                                        : '' +
                                                          (props?.stickyHeaders
                                                              ? ' th-sticky-header'
                                                              : '')
                                                }
                                            >
                                                <span>{headerName}</span>
                                            </th>
                                        )
                                    ) : (
                                        <th
                                            key={i}
                                            className={header.className ? header.className : ''}
                                        >
                                            {headerName}
                                        </th>
                                    )
                                }
                            })}
                            {props?.rowTotals && <th>Total</th>}
                        </tr>
                    )}
                </Table.Head>

                <Table.Body className={props?.bodyClassName ? props?.bodyClassName : ''}>
                    {props?.all ? (
                        <Fragment>
                            {orderedData.map((row, i) =>
                                props?.renderRow ? props?.renderRow(row) : renderRow(row, i)
                            )}
                            {colTotals}
                        </Fragment>
                    ) : (
                        <Pagination
                            info={orderedData}
                            render={props?.renderRow ?? renderRow}
                            renderAfter={colTotals}
                            view={view}
                            start={start}
                            end={end}
                            table={props?.table === false ? false : true}
                            updatePagination={({ view, start, end }) => {
                                setView(view)
                                setStart(start)
                                setEnd(end)
                                if (tableRef.current) {
                                    tableRef.current.scrollIntoView({ behavior: 'smooth' })
                                }
                            }}
                        />
                    )}
                </Table.Body>
            </Table>
        </div>
    )

    if (props?.card) {
        return (
            <Card skeleton={props?.skeleton}>
                {(props?.cardTitle || props?.customizeColumn || enableTools) && (
                    <Card.Header className='d-flex justify-content-between align-items-center'>
                        <Card.Title as='h4' className='d-block'>
                            {props?.cardTitle ?? ''}
                        </Card.Title>
                        <div className='flex-grow-1'>{tools}</div>
                    </Card.Header>
                )}
                <div className='table-responsive'>{table}</div>
            </Card>
        )
    } else {
        return table
    }
}

function TableSkeleton({ count }) {
    return (
        // <div style={{ minHeight: 400 }}>
        <table className={'table'}>
            <tbody>
                <SkeletonRow count={count} />
                <SkeletonRow count={count} />
                <SkeletonRow count={count} />
            </tbody>
        </table>
        // </div>
    )
}

function SkeletonRow({ count }) {
    return (
        <tr style={{ height: 50 }}>
            {times(count, (i) => {
                return (
                    <td key={i}>
                        <Skeleton className='lh-lg w-100' />
                    </td>
                )
            })}
        </tr>
    )
}

// prettier-ignore
SortableTable.propTypes = {
    // HEADERS AND DATA
    headers: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.shape({	// Array of objects for the header info
                name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired, 				// Title of the column
                value: PropTypes.oneOfType([					// Key corresponding to the respective field in the info object
                    PropTypes.string,
                    PropTypes.bool,
                    PropTypes.arrayOf(PropTypes.string)			// Props to be passed to each row.
                ]),											// If multiple fields, pass array of string fieldnames
                sort: PropTypes.bool,						// Determines if the column is sortable
                className: PropTypes.string,				// Class name of the header cell
                rowClassName: PropTypes.string,				// Class name to be used for each cell within that column
                custom: PropTypes.func, 					// Function that returns a custom table cell
                sortField: PropTypes.oneOfType([			// Field used to sort in case of having multiple values or custom function
                    PropTypes.string,
                    PropTypes.arrayOf(PropTypes.string)
                ]), 					// Function that returns a custom table cell
                searchField: PropTypes.oneOfType([			// Field used to sort in case of having multiple values or custom function
                    PropTypes.string,
                    PropTypes.arrayOf(PropTypes.string)
                ])
            })
        ])
    ).isRequired,
    info: PropTypes.arrayOf(PropTypes.object),		// Array of objects containing the data to be used in the table
    id: PropTypes.string,							// Name of field within info object that can be used as a unique ID for each row

    // SORTING
    startSort: PropTypes.string,					// What column to sort on initially
    startSortBy: PropTypes.string,					// Either 'asc' or 'desc'
    filterKey: PropTypes.string,                    // Key to save the sort settings

    // PAGINATION
    all: PropTypes.bool,							// Turns pagination off
    view: PropTypes.number,							// Number of rows per page (if pagination is enabled)

    // SEARCHING / FILTERING
    search: PropTypes.bool,							// Adds a search bar to the top
    filter: PropTypes.bool, 						// Adds column filter dropdowns that all you to filter by any value found in that column
    highlightThis: PropTypes.string,				// Highlight this search term anywhere it appears in the table

    // COUNT TOTALS
    rowTotals: PropTypes.bool,						// Display row totals
    colTotals: PropTypes.bool,						// Display column totals

    // CHECKBOXES
    checkbox: PropTypes.bool, 						// Adds checkboxes to each row
    checkAll: PropTypes.bool,						// Adds a check all button in the header
    checkboxCallback: PropTypes.func,				// Callback for each time a checkbox is checked

    // ATTRIBUTES
    className: PropTypes.string,					// Class name to be used on the table
    bodyClassName: PropTypes.string,				// Class name to be used on body of table
    headerClassName: PropTypes.string,				// Class name to be used on header of table
    rowProps: PropTypes.oneOfType([					// Props to be passed to each row.
        PropTypes.object, 								// If props are static, pass object of props i.e. onClick, style, etc
        PropTypes.func									// If props are dynamic, pass callback that returns an object of props
    ]),

    // STICKY ELEMENTS
    stickyHeaders: PropTypes.bool,					// Headers will remain fixed at the top as you scroll
    stickyHeaderOffset: PropTypes.number,			// How far to offset the sticky header
    stickyRow: PropTypes.shape({					// Allows for a selected row to "sticky" to the top while scrolling
        field: PropTypes.string,						// Field name corresponding to the unique identifier for each row in info object
        value: PropTypes.oneOfType([					// Props to be passed to each row.
            PropTypes.string, 								// If props are static, pass object of props i.e. onClick, style, etc
            PropTypes.number									// If props are dynamic, pass callback that returns an object of props
        ])					// Value of that ID that you want currently stickied
    }),

    // REPORTING
    exportToCSV: PropTypes.bool, 					// Allows you to export the table to CSV
    baseUrl: PropTypes.string,						// TODO: Define this
    metadataOnePageAllTables: PropTypes.any,		// TODO: Define this
    detailKey: PropTypes.any,						// TODO: Define this
    noDataDisplay: PropTypes.string,                // Display separate message if no data

    //ACTION
    actionBtn: PropTypes.oneOfType([                // Action button to be displayed
        PropTypes.func,[PropTypes.func]
    ])						
}

export default SortableTable
