import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { mergeConfigs } from '../../../../utils'
import axios from '../Context/AxiosSetup'
import { Project } from './project.api'

export class Payables {
    static keys = {
        invoices: (invoiceNumber, vendorNumber) => [
            'invoiceNumberUsage',
            invoiceNumber,
            vendorNumber,
        ],
    }
    static updatePayable = (projectId, payableId, data) => {
        return axios
            .patch(`/nml/tracker/2/project/${projectId}/payable/${payableId}/json`, data)
            .then((response) => response.data)
    }

    static createPayable = (projectId, data) => {
        return axios
            .post(`/nml/tracker/2/project/${projectId}/payable/json`, data)
            .then((response) => response.data)
    }

    static deletePayable = (projectId, payableId) => {
        return axios
            .delete(`/nml/tracker/2/project/${projectId}/payable/${payableId}/json`)
            .then((response) => response.data)
    }

    static getInvoiceNumberUsage = (invoiceNumber, vendorNumber) => {
        return axios
            .get(`/nml/tracker/2/vendor/${vendorNumber}/invoice/${invoiceNumber}/json`)
            .then((response) => response.data)
    }
}

export const useUpdatePayable = ({ projectId, payableId }, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        (data) => Payables.updatePayable(projectId, payableId, data),
        mergeConfigs(
            {
                onSuccess: (data) => {
                    queryClient.setQueryData(Project.keys.project(projectId), {
                        ...queryClient.getQueryData(Project.keys.project(projectId)),
                        payables: data,
                    })
                },
            },
            config
        )
    )
}
export const useCreatePayable = ({ projectId }, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        (data) => Payables.createPayable(projectId, data),
        mergeConfigs(
            {
                onSuccess: (data) => {
                    queryClient.setQueryData(Project.keys.project(projectId), {
                        ...queryClient.getQueryData(Project.keys.project(projectId)),
                        payables: data,
                    })
                },
            },
            config
        )
    )
}
export const useDeletePayable = ({ projectId, payableId }, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        () => Payables.deletePayable(projectId, payableId),
        mergeConfigs(
            {
                onSuccess: (data) => {
                    queryClient.setQueryData(Project.keys.project(projectId), {
                        ...queryClient.getQueryData(Project.keys.project(projectId)),
                        payables: data,
                    })
                },
            },
            config
        )
    )
}

export const useGetInvoiceNumberUsage = (invoiceNumber, vendorNumber, config) => {
    return useQuery(
        Payables.keys.invoices(invoiceNumber, vendorNumber),
        () => Payables.getInvoiceNumberUsage(invoiceNumber, vendorNumber),
        mergeConfigs(
            {
                enabled: !!invoiceNumber && !!vendorNumber,
            },
            config
        )
    )
}
