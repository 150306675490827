import { NcsIcon } from 'components/utils'
import { useTrackerDropdowns } from 'tracker/api'

export default function NoteTagIcon({ tag, defaultIcon }) {
    const { data: dropdowns } = useTrackerDropdowns()
    let category
    let value
    // console.log('tag: ', tag)
    if (tag?.tag === 'task') {
        category = 'taskCategory'
        value = dropdowns?.['task.code']?.find(
            (code) => code?.task_code == tag?.task?.task_code
        )?.task_category
    } else if (tag?.tag === 'associate') {
        category = 'associateType'
        value = tag?.associate?.type_code
    } else if (tag?.tag === 'pinned') {
        return <span className='fas fa-thumbtack' />
    }
    // console.log('note icon',category,value)
    if (value && category) {
        return <NcsIcon dept={'nml'} variant='fal' category={category} value={value} />
    } else {
        return <span className={'fal fa-' + (defaultIcon ?? 'tag')} />
    }
}
