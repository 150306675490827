import classnames from 'classnames'
import React from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'

const Button = React.forwardRef(
    (
        {
            className,
            soft,
            outline,
            ghost,
            children,
            pill,
            variant = 'white',
            icon,
            loading,
            circle,
            ...bsProps
        },
        ref
    ) => {
        if (soft) {
            variant = `soft-${variant}`
        } else if (ghost) {
            variant = `ghost-${variant}`
        } else if (outline) {
            variant = `outline-${variant}`
        }

        return (
            <BootstrapButton
                {...bsProps}
                ref={ref}
                type='button'
                variant={variant}
                className={classnames(className, {
                    'rounded-pill': pill,
                    'rounded-circle btn-icon': circle,
                })}
            >
                {icon &&
                    !loading &&
                    (typeof icon === 'string' ? (
                        <i
                            className={classnames(icon, {
                                'me-2': !!children,
                            })}
                        />
                    ) : (
                        icon
                    ))}
                {loading && (
                    <i
                        className={classnames('fal fa-spinner-third fa-spin', {
                            'me-2': !!children,
                        })}
                    />
                )}
                {children}
            </BootstrapButton>
        )
    }
)

export default Button
