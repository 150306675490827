import { OverlayTrigger, Tooltip as BsTooltip } from 'react-bootstrap'

export default function Tooltip({
    children,
    content,
    placement = 'top',
    id = Math.random(),
    ...rest
}) {
    if (content)
        return (
            <OverlayTrigger
                {...rest}
                placement={placement}
                overlay={<BsTooltip id={id}>{content}</BsTooltip>}
            >
                {children}
            </OverlayTrigger>
        )
    else return <>{children}</>
}
