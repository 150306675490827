import React, { Component } from 'react'

export default class Highlighter extends Component {
    constructor(props) {
        super(props)

        // Style that is always applied
        this.baseStyle = {
            transition: '1s',
        }

        // Style for unchanged values that gets faded back to
        this.unchangedStyle = {
            backgroundColor: 'transparent',
        }

        // Highlight style for changed elements
        this.changedStyle = {
            backgroundColor: this.props.color ? this.props.color : '#0072ff61',
            color: this.props.textColor ? this.props.textColor : 'white',
            borderRadius: 2,
            boxShadow: '0px 0px 3px 3px #0072ff61',
        }

        this.state = {
            changeStyle: this.unchangedStyle,
        }
    }

    componentDidUpdate(prevProps) {
        // Check if highlight should trigger
        switch (this.props.compare) {
            case 'greater':
                if (this.props.check > prevProps.check) {
                    this.activateHighlight()
                }
                break
            case 'less':
                if (this.props.check < prevProps.check) {
                    this.activateHighlight()
                }
                break
            default:
                if (this.props.check != prevProps.check) {
                    this.activateHighlight()
                }
        }
    }

    activateHighlight() {
        this.setState({ changeStyle: this.changedStyle }, () => {
            // Fades back to original styling after 1 second
            setTimeout(() => {
                this.setState({ changeStyle: this.unchangedStyle })
            }, 1000)
        })
    }

    // Adds the highlight style changes to the children
    modifyChildren(child) {
        let style = { ...child.props.style, ...this.state.changeStyle, ...this.baseStyle }
        return React.cloneElement(child, { style })
    }

    render() {
        const { children } = this.props

        return (
            <React.Fragment>
                {children.length > 1
                    ? children.map((child) => {
                          return this.modifyChildren(child)
                      })
                    : this.modifyChildren(children)}
            </React.Fragment>
        )
    }
}
