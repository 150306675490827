import { MicroApp, useMicroApp } from '@/components/utils'
import { useModalState } from 'hooks'
import { createContext, useContext } from 'react'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import { usePdrLatestSearch } from '../../pdr/pdr.api'
import { PdrPopover } from '../../pdr/PdrPopover'
import NoteOverlay from 'tracker/Utils/Notes/NoteOverlay'
import { useRouter } from 'next/router'
export const TrackerLinksContext = createContext({})
export default function TrackerLinksContextProvider({ children }) {
    const { fileInfo } = useContext(ProjectContext)
    const urgentNotes = fileInfo?.notes?.filter(
        (note) =>
            note?.need_confirmed == 1 && note?.confirmed == 0 && !note?.tags?.archived_note2client
    )
    const notesCount = fileInfo?.notes?.length
        ? fileInfo?.notes?.filter((note) => !note.tags.archived_note2client)?.length
        : 0
    const url = '/nml/tracker/file'
    const pdrPopoverId = `pdr-${fileInfo?.project_id}`
    const pdrPopover = useMicroApp()
    const notesModal = useModalState(false)
    const { data: pdrResults, ...pdrApi } = usePdrLatestSearch(fileInfo?.project_id)
    const router = useRouter()

    const links = [
        {
            title: 'Project',
            href: `${url}`,
            icon: 'fal fa-folder-open',
            links: [
                {
                    title: 'Overview',
                    href: `${url}/overview`,
                    icon: 'fal fa-folder-closed',
                },
                {
                    title: 'Tasks',
                    href: `${url}/tasks`,
                    icon: 'fal fa-list-check',
                },
                {
                    title: 'Associates',
                    href: `${url}/associates`,
                    icon: 'fal fa-users',
                },
                {
                    title: 'Dates',
                    href: `${url}/dates`,
                    icon: 'fal fa-calendars',
                },
                {
                    title: 'Docuware',
                    href: `${url}/documents/docuware`,
                    icon: 'fab fa-dochub',
                    popout: {
                        windowName: 'docuware',
                        url: `${process.env.PORTAL2}${url}/popout/docuware?project_id=${fileInfo?.project_id}`,
                        title: `Docuware ${fileInfo?.project_id}`,
                    },
                },
                {
                    title: 'Notes',
                    // href: `${url}/research/pdr`,
                    onClick: notesModal.show,
                    icon: 'fal fa-sticky-note',
                    notification: {
                        text: urgentNotes?.length > 0 ? urgentNotes?.length : notesCount,
                        bg: urgentNotes?.length > 0 ? 'danger' : 'secondary',
                        soft: urgentNotes?.length > 0 ? false : true,
                    },
                    popout: {
                        windowName: 'notes',
                        url: `${process.env.PORTAL2}${url}/popout/notes?project_id=${fileInfo?.project_id}`,
                        title: `Notes ${fileInfo?.project_id}`,
                    },
                },
                ,
            ],
        },
        {
            title: 'History',
            href: `${url}/history`,
            icon: 'fal fa-history',
            links: [
                {
                    title: 'Project History',
                    href: `${url}/history/file`,
                    icon: 'fal fa-history',
                    notification: {
                        text: !!fileInfo?.incoming_changes?.version?.id ? 1 : null,
                        bg: 'secondary',
                        soft: true,
                    },
                },
                {
                    title: 'Request History',
                    href: `${url}/history/requests`,
                    icon: 'fal fa-inbox-full',
                    popout: {
                        windowName: 'requests',
                        url: `${process.env.PORTAL2}${url}/popout/requests?project_id=${fileInfo?.project_id}`,
                        title: `Requests ${fileInfo?.project_id}`,
                    },
                },
                {
                    title: 'Mail History',
                    href: `${url}/history/mail`,
                    icon: 'fal fa-envelope',
                },
                {
                    title: 'Packet History',
                    href: `${url}/documents/generated`,
                    icon: 'fal fa-file-contract',
                },
            ],
        },
        {
            title: 'Miscellaneous',
            href: `${url}/research`,
            icon: 'fal fa-binoculars',
            links: [
                {
                    title: 'Documentation',
                    href: `${url}/documentation`,
                    icon: 'fal fa-clipboard-check',
                },
                {
                    title: 'Property Reports',
                    // href: `${url}/research/pdr`,
                    onClick: pdrPopover.show,
                    icon: 'fal fa-building',
                    notification: {
                        text: pdrResults?.list?.length,
                        bg: 'secondary',
                        soft: true,
                    },
                },
                // {
                //     title: 'Dashboard',
                //     href: `${url}/research/dashboard`,
                //     icon: 'fal fa-binoculars',
                // },
                {
                    title: 'Payables',
                    href: `${url}/payables`,
                    icon: 'fal fa-money-check-dollar-pen',
                },
                {
                    title: 'Counties',
                    href: `${url}/counties`,
                    icon: 'fal fa-flag-usa',
                },
                fileInfo?.extra?.length > 0 && {
                    title: 'Extra',
                    href: `${url}/extra`,
                    icon: 'fal fa-asterisk',
                },
            ],
        },
        {
            title: 'NCS',
            icon: 'fal fa-folder-open',
            links: [
                {
                    title: 'Email',
                    icon: 'fal fa-at',
                    href: `${url}/email`,
                    popout: {
                        windowName: 'email',
                        url: `${process.env.PORTAL2}${url}/popout/email?project_id=${fileInfo?.project_id}`,
                        title: `Email Popout ${fileInfo?.project_id}`,
                    },
                },
                {
                    title: 'Open in OLS',
                    href: `${process.env.OLS_HOST}/onlineservices/construction/account_management/?direct_no=${fileInfo?.project_id}`,
                    icon: 'fal fa-screen-users',
                    links: [],
                    newWindow: true,
                },
            ],
        },
    ]

    const filteredTrackerTabs = links.filter((l) => l?.href && router.pathname.startsWith(l.href))
    const trackerTab =
        filteredTrackerTabs?.length > 1 ? filteredTrackerTabs[1] : filteredTrackerTabs[0]

    return (
        <TrackerLinksContext.Provider value={{ links, trackerTab }}>
            {children}
            {fileInfo && (
                <>
                    <MicroApp id={pdrPopover.id} label={'PDR'} as={PdrPopover}>
                        <PdrPopover
                            popover
                            projectId={fileInfo?.project_id}
                            defaultValues={{
                                address: [
                                    fileInfo?.project?.address1,
                                    fileInfo?.project?.address2,
                                    fileInfo?.project?.city,
                                    fileInfo?.project?.state,
                                    fileInfo?.project?.zip,
                                ]
                                    ?.filter(Boolean)
                                    ?.join(', '),
                                owner:
                                    fileInfo?.associates &&
                                    Object.values(fileInfo?.associates)?.find(
                                        ({ _type_code }) => _type_code == 'OW'
                                    )?.name,
                                apn: fileInfo?.project?.parcel_number,
                                zip: fileInfo?.project?.zip,
                            }}
                        />
                    </MicroApp>
                    <NoteOverlay noButton show={notesModal.isOpen} onHide={notesModal.hide} />
                </>
            )}
        </TrackerLinksContext.Provider>
    )
}
