import { Form } from 'front'
import GoToClipboard from './GoToClipboard'

export default function NavSearch({ onClick }) {
    // console.log('onClick: ', onClick)
    // const searchModal = useModalState(false)
    // //open search modal
    // useKeyDown(searchModal.toggle, 'k', ['ctrlKey'])

    return (
        <>
            <Form.Button
                ignoreGlobalDisable
                onClick={onClick}
                variant='white'
                className='d-flex justify-content-between w-100 align-items-center'
            >
                <div className='d-flex align-items-center'>
                    <span className='fal fa-magnifying-glass fa-sm me-2' />
                    Search
                </div>
                <div>
                    <kbd className='opacity-75'>Ctrl+K</kbd>
                </div>
            </Form.Button>
            <GoToClipboard />
            {/* <SearchModal show={searchModal.isOpen} onHide={searchModal.hide} /> */}
        </>
    )
}
