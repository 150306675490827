import classnames from 'classnames'
import GoogleMapsCluster, { MapControl } from 'components/GoogleMapsCluster'
import { Button, Form, Offcanvas } from 'front'
import { useContext, useMemo, useRef, useState } from 'react'
import { ButtonGroup, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import { toTitleCase } from 'utils'
import PdrReport from './PdrReport'
import {
    useGetPropertyReport,
    useMarkPrimaryProperty,
    usePdrLatestSearch,
    usePdrNewSearch,
} from './pdr.api'
import Link from 'next/link'
import { ErrorBoundary } from '@/components/Bugsnag'
import { useUpdateProject } from 'tracker/api'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import { Project } from 'tracker/api/project.api'
import { useQueryClient } from '@tanstack/react-query'

export function PdrPopover({ projectId, defaultValues, popover, show, onHide, onMinimize }) {
    const [searchType, setSearchType] = useState('address')
    const [addressSearch, setAddressSearch] = useState(defaultValues?.address)
    const [ownerFirstNameSearch, setOwnerFirstNameSearch] = useState(defaultValues?.owner)
    const [ownerLastNameSearch, setOwnerLastNameSearch] = useState()
    const [apnSearch, setApnSearch] = useState(defaultValues?.apn)
    const [zipSearch, setZipSearch] = useState(defaultValues?.zip)
    const { data: response, ...latestSearchApi } = usePdrLatestSearch(projectId, {
        onSuccess: (data) => {
            setAddressSearch(data?.latestQuery?.full_address ?? defaultValues?.address)
            setOwnerFirstNameSearch(data?.latestQuery?.first_name ?? defaultValues?.owner)
            setOwnerLastNameSearch(data?.latestQuery?.last_name ?? '')
            setApnSearch(data?.latestQuery?.apn?.[0] ?? defaultValues?.apn)
            setZipSearch(data?.latestQuery?.zip ?? defaultValues?.zip)
            if (!!data?.latestQuery?.full_address) setSearchType('address')
            else if (!!data?.latestQuery?.first_name || !!data?.latestQuery?.last_name)
                setSearchType('owner')
            else if (!!data?.latestQuery?.apn) setSearchType('apn')
        },
    })
    const { mutate: search, ...newSearchApi } = usePdrNewSearch(projectId)

    const executeSearch = () => {
        search({
            searchType,
            full_address: addressSearch,
            first_name: ownerFirstNameSearch,
            last_name: ownerLastNameSearch,
            apn: apnSearch,
            zip: zipSearch,
        })
    }

    const Wrapper = popover ? Offcanvas : 'div'
    const wrapperProps = popover
        ? {
              show,
              onHide: onMinimize,
              placement: 'end',
              style: { width: '75vw' },
          }
        : {}
    const Header = popover ? Offcanvas.Header : DefaultPacketHeader
    const Body = popover ? Offcanvas.Body : 'div'
    const Footer = popover ? Offcanvas.Footer : 'div'

    const liteResultList = response?.list
    const report = response?.reports?.[0]

    const markers = useMemo(() => {
        return liteResultList?.map((item) => ({
            label: `${item?.SequenceId}`,
            onMouseDown: () => {
                setClickedMarker(item?.SequenceId)
            },
            onMouseOver: () => {
                setHoverMarkers([item?.SequenceId])
            },
            onMouseOut: () => {
                setHoverMarkers([])
            },
            address: [item?.Address, item?.ApartmentOrUnit, item?.City, item?.State, item?.Zip]
                ?.filter((part) => part?.length > 0)
                ?.join(', '),
        }))
    }, [response])
    const onClusterMouseOver = useMemo(
        () => (e) => setHoverMarkers(e?.markers?.map(({ label }) => Number(label))),
        []
    )

    const rowRefs = useRef({})

    return (
        <Wrapper {...wrapperProps}>
            <Header>
                <Offcanvas.Title>Property Detail Report</Offcanvas.Title>
                <ButtonGroup>
                    {onMinimize && (
                        <Button
                            variant={'white'}
                            size='sm'
                            icon='fas fa-window-minimize'
                            onClick={onMinimize}
                            // style={{ width: '2rem', height: '2rem' }}
                        />
                    )}
                    {onHide && (
                        <Button
                            variant={'white'}
                            size='sm'
                            icon='fas fa-xmark'
                            onClick={onHide}
                            // style={{ width: '2rem', height: '2rem' }}
                        />
                    )}
                </ButtonGroup>
            </Header>
            <div className='d-flex flex-column' style={{ height: 'calc(100vh - 86px)' }}>
                <div
                    className='d-flex justify-content-start align-items-center py-2 px-4'
                    style={{ height: '8%' }}
                >
                    <ToggleButtonGroup
                        name='showAddressesToggle'
                        value={searchType}
                        type='radio'
                        className='btn-group-segment me-4'
                        size='xs'
                    >
                        <ToggleButton
                            value={'address'}
                            variant=''
                            onClick={() => setSearchType('address')}
                        >
                            Address
                        </ToggleButton>
                        <ToggleButton
                            value={'owner'}
                            variant=''
                            onClick={() => setSearchType('owner')}
                        >
                            Owner
                        </ToggleButton>
                        <ToggleButton value={'apn'} variant='' onClick={() => setSearchType('apn')}>
                            APN
                        </ToggleButton>
                    </ToggleButtonGroup>
                    {searchType == 'address' ? (
                        <>
                            <div className='me-3' style={{ width: 500 }}>
                                <Form.Control
                                    key='1'
                                    className='form-control-light'
                                    placeholder='Address'
                                    value={addressSearch}
                                    onChange={(e) => setAddressSearch(e.target.value)}
                                />
                            </div>
                        </>
                    ) : searchType == 'owner' ? (
                        <>
                            <div className='me-3'>
                                <Form.Control
                                    key='2'
                                    className='form-control-light'
                                    placeholder='First Name'
                                    value={ownerFirstNameSearch}
                                    onChange={(e) => setOwnerFirstNameSearch(e.target.value)}
                                />
                            </div>
                            <div className='me-3'>
                                <Form.Control
                                    key='3'
                                    className='form-control-light'
                                    placeholder='Last Name'
                                    value={ownerLastNameSearch}
                                    onChange={(e) => setOwnerLastNameSearch(e.target.value)}
                                />
                            </div>
                            <div className='me-3'>
                                <Form.Control
                                    key='4'
                                    className='form-control-light'
                                    placeholder='Zip'
                                    value={zipSearch}
                                    onChange={(e) => setZipSearch(e.target.value)}
                                />
                            </div>
                        </>
                    ) : searchType == 'apn' ? (
                        <>
                            <div className='me-3'>
                                <Form.Control
                                    key='5'
                                    className='form-control-light'
                                    placeholder='APN'
                                    value={apnSearch}
                                    onChange={(e) => setApnSearch(e.target.value)}
                                />
                            </div>
                            <div className='me-3'>
                                <Form.Control
                                    key='6'
                                    className='form-control-light'
                                    placeholder='Zip'
                                    value={zipSearch}
                                    onChange={(e) => setZipSearch(e.target.value)}
                                />
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    <button className='btn btn-white ms-auto' onClick={executeSearch}>
                        <i className='fal fa-search me-2' />
                        Search
                    </button>
                </div>
                <div style={{ height: '92%' }}>
                    {liteResultList?.length > 0 ? (
                        <PdrLiteList
                            projectId={projectId}
                            loading={
                                latestSearchApi.isFetching ||
                                newSearchApi.isFetching ||
                                latestSearchApi.isLoading ||
                                newSearchApi.isLoading
                            }
                            list={liteResultList}
                        />
                    ) : report ? (
                        <PdrReport report={report} directNo={projectId} />
                    ) : (
                        <div className='d-flex justify-content-center align-items-center w-100 h-100 text-muted'>
                            <h2 className='d-block'>No Results</h2>
                        </div>
                    )}
                </div>
            </div>
        </Wrapper>
    )
}

function PdrLiteList({ projectId, loading, list }) {
    const [filterListByMapBounds, setFilterListByMapBounds] = useState(true)
    const [visibleMarkers, setVisibleMarkers] = useState(null)
    const [hoverMarkers, setHoverMarkers] = useState([])
    const [clickedMarker, setClickedMarker] = useState()
    const onClusterMouseOut = useMemo(() => (e) => setHoverMarkers([]), [])
    const markers = useMemo(() => {
        return list?.map((item) => ({
            label: `${item?.SequenceId}`,
            onMouseDown: () => {
                setClickedMarker(item?.SequenceId)
            },
            onMouseOver: () => {
                setHoverMarkers([item?.SequenceId])
            },
            onMouseOut: () => {
                setHoverMarkers([])
            },
            address: [item?.Address, item?.ApartmentOrUnit, item?.City, item?.State, item?.Zip]
                ?.filter((part) => part?.length > 0)
                ?.join(', '),
        }))
    }, [list])
    const onClusterMouseOver = useMemo(
        () => (e) => setHoverMarkers(e?.markers?.map(({ label }) => Number(label))),
        []
    )

    if (loading)
        return (
            <div className='w-100 my-8 d-flex justify-content-center'>
                <i className='fal fa-spinner-third fa-spin fa-5x text-muted d-block' />
            </div>
        )

    return (
        <>
            <div style={{ height: '35%' }}>
                <ErrorBoundary FallbackComponent={GoogleMapsErrorFallback}>
                    <GoogleMapsCluster
                        markers={markers}
                        onClusterMouseOver={onClusterMouseOver}
                        onClusterMouseOut={onClusterMouseOut}
                        onVisibleMarkersChange={setVisibleMarkers}
                    >
                        <MapControl position='LEFT_BOTTOM'>
                            <div className='bg-white px-2 py-1 m-2 rounded-1 google-maps-button'>
                                <Form.Check
                                    className='mt-2'
                                    type={'switch'}
                                    checked={filterListByMapBounds}
                                    onClick={() => setFilterListByMapBounds(!filterListByMapBounds)}
                                    label='Filter list by map bounds'
                                />
                            </div>
                        </MapControl>
                    </GoogleMapsCluster>
                </ErrorBoundary>
            </div>
            <div style={{ height: '65%', overflowY: 'auto' }}>
                <table className='table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle'>
                    <thead className='position-sticky top-0 thead-light' style={{ zIndex: 100 }}>
                        <tr>
                            <th>#</th>
                            <th>Owner / Address</th>
                            <th>Apn</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list
                            ?.filter(
                                (item) =>
                                    visibleMarkers === null ||
                                    !filterListByMapBounds ||
                                    !!visibleMarkers?.find(({ label }) => label == item?.SequenceId)
                            )
                            ?.map((item) => {
                                return (
                                    <PdrListItem
                                        key={item?.SequenceId}
                                        isHovered={hoverMarkers?.includes(item?.SequenceId)}
                                        isClicked={clickedMarker == item?.SequenceId}
                                        propertyId={item?.PropertyId}
                                        sequenceId={item?.SequenceId}
                                        directNo={projectId}
                                        address1={item?.Address}
                                        address2={item?.ApartmentOrUnit}
                                        city={item?.City}
                                        state={item?.State}
                                        zip={item?.Zip}
                                        county={item?.County}
                                        owner={item?.Owner}
                                        apn={item?.Apn}
                                    />
                                )
                            })}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default function PdrListItem({
    propertyId,
    isHovered,
    isClicked,
    sequenceId,
    directNo,
    address1,
    address2,
    city,
    state,
    zip,
    county,
    owner,
    apn,
}) {
    const { fileInfo } = useContext(ProjectContext)
    const queryClient = useQueryClient()
    const { mutate: saveInfo, ...useSaveInfo } = useUpdateProject(
        { projectId: directNo },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(Project.keys.project(directNo))
            },
        }
    )
    const { mutate: getPdf, data: reports, ...reportApi } = useGetPropertyReport(directNo)
    const [showReport, setShowReport] = useState(false)

    const isUsingInfo = fileInfo?.project?.parcel_number?.includes(apn)

    const address = [
        toTitleCase(address1),
        toTitleCase(address2),
        toTitleCase(city),
        state,
        zip,
        toTitleCase(county),
    ]
        ?.filter((addressPart) => addressPart != null && addressPart != '')
        ?.join(', ')

    return (
        <>
            <tr
                className={classnames({
                    'bg-soft-success': isClicked,
                    'bg-soft-primary': isHovered && !isClicked,
                })}
            >
                <td>{sequenceId}</td>
                <td>
                    <div className='fw-bold text-dark'>{toTitleCase(owner)}</div>
                    <div className='text-muted'>{address}</div>
                </td>
                <td className=''>
                    <span className='badge bg-soft-primary text-primary'>{apn}</span>
                </td>
                <td>
                    <ButtonGroup size='sm' className='shadow'>
                        <Button
                            variant='white'
                            size='sm'
                            className=''
                            onClick={() => {
                                getPdf(propertyId)
                                setShowReport(true)
                            }}
                            disabled={reportApi?.isLoading}
                        >
                            {reportApi?.isLoading ? (
                                <i className='fal fa-spinner-third fa-spin me-2' />
                            ) : (
                                <i className='fal fa-clipboard-list me-2' />
                            )}
                            Report
                        </Button>
                        <Button
                            variant={
                                !fileInfo?.project?.parcel_number || isUsingInfo
                                    ? 'primary'
                                    : 'white'
                            }
                            size='sm'
                            disabled={isUsingInfo}
                            onClick={() => {
                                const id = Math.round(Math.random() * -1000)

                                saveInfo({
                                    project: {
                                        parcel_number: fileInfo?.project?.parcel_number
                                            ? `${fileInfo?.project?.parcel_number}, ${apn}`
                                            : apn,
                                    },
                                    associates: {
                                        [id]: {
                                            type_id: 1,
                                            _create: true,
                                            is_debtor: false,
                                            source: 'PDR',
                                            name: owner,
                                            address1: address1 ?? '',
                                            address2: address2 ?? '',
                                            city: city ?? '',
                                            state: state ?? '',
                                            zip: zip.toString() ?? '',
                                        },
                                    },
                                })
                            }}
                        >
                            {useSaveInfo.isLoading ? (
                                <i className='fal fa-spinner-third fa-spin me-2' />
                            ) : (
                                <i className='fal fa-check me-2' />
                            )}
                            {isUsingInfo
                                ? 'Saved'
                                : !fileInfo?.project?.parcel_number
                                ? 'Save Info'
                                : 'Save Info'}
                        </Button>
                    </ButtonGroup>
                </td>
            </tr>
            <Offcanvas
                size='xl'
                show={showReport && reports?.length > 0}
                onClose={() => setShowReport(false)}
                backdrop='static'
                style={{ zIndex: 999999, width: '75vw' }}
                placement='end'
            >
                <Offcanvas.Header className='d-flex position-relative'>
                    <Button
                        variant={'white'}
                        icon={'fal fa-arrow-left'}
                        onClick={() => setShowReport(false)}
                        circle
                        className='flex-shrink-0 me-3 position-absolute'
                        style={{ marginLeft: '-52px' }}
                    />
                    <Offcanvas.Title as='h3' className='offcanvas-title d-block'>
                        Report
                    </Offcanvas.Title>
                </Offcanvas.Header>
                {reports?.length > 0 && <PdrReport report={reports[0]} directNo={directNo} />}
            </Offcanvas>
        </>
    )
}

function GoogleMapsErrorFallback({ error }) {
    console.error('GOOGLE MAPS ERROR FALLBACK', error)
    return <div className=''>Google Maps failed to load</div>
}
