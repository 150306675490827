import { useEffect, useState } from 'react'
import { pickerOptions } from '../GlobalState'
import formatDate from './formatDate'

export default function DatePicker({
    date,
    update,
    placeholder,
    name,
    className,
    onBlur = () => {},
}) {
    const id = 'datePicker' + Math.round(Math.random() * 10000)
    const [value, setValue] = useState(undefined)

    useEffect(() => {
        if ($.datetimepicker) {
            $('#' + id).datetimepicker(pickerOptions)
            $('#' + id).on('dp.change', handleChange)
        }
    }, [$])

    function handleChange(e) {
        // console.log('changing',e.target.value)
        setValue(e.target.value)
    }

    useEffect(() => {
        // console.log('sending update',value)
        value && update({ target: { value, name } })
    }, [value])

    useEffect(() => {
        // console.log('date effect',formatDate(date) !== value || !value,'date',formatDate(date),'value',value)
        formatDate(date) !== value && setValue(formatDate(date))
    }, [date])

    // console.log('date picker')

    return (
        <input
            className={'form-control datepicker ' + className ?? ''}
            id={id}
            type={'text'}
            value={value}
            name={name ?? id + 'date_picker'}
            placeholder={placeholder ?? 'Date Picker'}
            onChange={() => {}}
            onBlur={onBlur}
        />
    )
}
