import React, { useState, useEffect } from 'react'
import axios from '../axios'

export const ClientMaintenanceContext = React.createContext({})

function ClientMaintenanceContextProvider(props) {
    const [isLoading, setIsLoading] = useState(false)
    const [searchTxt, setSearchTxt] = useState('')
    const [selectedClientInfo, saveClientInfo] = useState([])

    return (
        <ClientMaintenanceContext.Provider
            value={{
                isLoading,
                setIsLoading,
                searchTxt,
                setSearchTxt,
                selectedClientInfo,
                saveClientInfo,
            }}
        >
            {props.children}
        </ClientMaintenanceContext.Provider>
    )
}

export default ClientMaintenanceContextProvider
