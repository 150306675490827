import classnames from 'classnames'
import { Badge, Button } from 'front'
import { ButtonGroup, Col, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import { Notes, useAssignedNotes, useConfirmNote } from 'tracker/api/note.api'
import Cookies from 'js-cookie'
import { EmployeeAvatar, Tooltip, formatDate } from '@/components/utils'
import { useState } from 'react'
import Link from 'next/link'

const employee = Cookies.getJSON('employee')
const getEmpID = () => employee?.EmpID
const emp_id = getEmpID()

export default function AssignedNotePopover() {
    const [onlyShowUnconfirmed, setOnlyShowUnconfirmed] = useState(true)
    const { data: notes, ...notesApi } = useAssignedNotes(emp_id)

    const unconfirmedNotes = notes?.filter((note) => note?.need_confirmed == 1 && note?.confirmed == 0)
    const filteredNotes = onlyShowUnconfirmed ? unconfirmedNotes : notes

    return (
        <OverlayTrigger
            trigger={'click'}
            placement={'top'}
            rootClose
            overlay={
                <Popover id={'unconfirmed-notes'} style={{ maxWidth: '50%', width: 850 }}>
                    <Popover.Header className='d-flex justify-content-between align-items-center fs-6 fw-bold py-1 px-3 border-bottom border-1'>
                        <div>
                            Unconfirmed Notes{' '}
                            {unconfirmedNotes?.length > 0 && `(${unconfirmedNotes?.length})`}
                        </div>
                        {/* <ToggleButtonGroup
                            name='filterNotes'
                            value={onlyShowUnconfirmed}
                            type='radio'
                            className='btn-group-segment'
                            size='xs'
                        >
                            <ToggleButton
                                value={false}
                                variant=''
                                onClick={() => setOnlyShowUnconfirmed(false)}
                            >
                                All {notes?.length > 0 && `(${notes?.length})`}
                            </ToggleButton>
                            <ToggleButton
                                value
                                variant=''
                                onClick={() => setOnlyShowUnconfirmed(true)}
                            >
                                Unread{' '}
                                {unconfirmedNotes?.length > 0 && `(${unconfirmedNotes?.length})`}
                            </ToggleButton>
                        </ToggleButtonGroup> */}
                    </Popover.Header>
                    {filteredNotes?.length > 0 && (
                        <div className='overflow-auto' style={{ maxHeight: 750 }}>
                            {filteredNotes?.map((note) => {
                                return <Note key={note?.id} {...note} />
                            })}
                        </div>
                    )}
                    {filteredNotes?.length === 0 && (
                        <Popover.Body className=''>
                            You have no notes needing confirmed.
                        </Popover.Body>
                    )}
                </Popover>
            }
        >
            <div>
                <Tooltip content='Assigned Notes' trigger={['hover', 'click']}>
                    <div className='position-relative'>
                        <Button icon='fal fa-sticky-note' circle ghost variant='secondary' />
                        {unconfirmedNotes?.length > 0 && (
                            <Badge
                                bg='danger'
                                pill
                                className='position-absolute translate-middle fs-xs rounded-circle'
                                style={{ top: 11, right: -9 }}
                            >
                                {unconfirmedNotes?.length}
                            </Badge>
                        )}
                    </div>
                </Tooltip>
            </div>
        </OverlayTrigger>
    )
}

const Note = ({ created, direct_no, need_confirmed, confirmed, text, id, emp_id }) => {
    if (typeof need_confirmed === 'number') need_confirmed = need_confirmed === 1 ? true : false
    if (typeof confirmed === 'number') confirmed = confirmed === 1 ? true : false
    const { mutate: confirmNote, ...confirmNoteApi } = useConfirmNote(direct_no)

    return (
        <Popover.Body className='d-flex align-items-center border-top border-1'>
            <div className='w-70'>
                <div className='d-flex align-items-center mb-2'>
                    <EmployeeAvatar empId={emp_id} size='xs' includeLabel />
                    <div className='fst-italic text-muted ms-1 fs-smaller'>
                        - {formatDate(created, 'MMM Do YYYY h:mm a')}
                    </div>
                </div>
                <div style={{ whiteSpace: 'pre-wrap' }}>{text}</div>
            </div>
            <div className='ms-auto'>
                <div>
                    <ButtonGroup>
                        {!!confirmed && (
                            <Badge className='ms-2' bg='info' soft>
                                <span className='far fa-check' /> Confirmed
                            </Badge>
                        )}
                        {need_confirmed && !confirmed && (
                            <Button
                                disabled={confirmNoteApi.isLoading}
                                size='xs'
                                variant='danger'
                                loading={confirmNoteApi.isLoading}
                                icon='far fa-check'
                                onClick={() => {
                                    confirmNote(id)
                                }}
                            >
                                Confirm
                            </Button>
                        )}
                        <Link href={`/nml/tracker/file/overview/?project_id=${direct_no}`}>
                            <Button size='xs' variant='white'>
                                <span className='fw-bold'>D{direct_no}</span>
                                <i className='far fa-arrow-right ms-2' />
                            </Button>
                        </Link>
                    </ButtonGroup>
                </div>
            </div>
        </Popover.Body>
    )
}
