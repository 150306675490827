import { useContext } from 'react'
import { Form } from '../front'
import { EmployeeAvatar } from './EmployeeAvatar'
import { PortalContext } from '../GlobalState'

export default function EmployeeDropdown({
    employees,
    onChange,
    selected = false,
    disabled = false,
    allEmployees = true,
    isClearable = true,
    FirstName = 'Name',
    LastName = 'Not Found',
    currentUserFirst = false,
    clearsToCurrentUser = false,
    clearsToCurrentUserLabel,
}) {
    const { employee } = useContext(PortalContext)

    if (allEmployees) {
        employees = [
            {
                label: 'All Employees',
                hideAvatar: true,
                EmpID: false,
            },
            ...employees,
        ]
    }

    if (clearsToCurrentUser) {
        employees = [
            {
                label: clearsToCurrentUserLabel
                    ? clearsToCurrentUserLabel
                    : `${employee?.FirstName} ${employee?.LastName}`,
                hideAvatar: clearsToCurrentUserLabel ? true : false,
                EmpID: employee.EmpID,
            },
            ...employees,
        ]
    }

    let value = employees?.find((e) => e.EmpID == selected)
    if (!value) {
        value = { FirstName, LastName, hideAvatar: false, EmpID: selected }
    }

    const customFilter = (option, searchText) =>
        option.data.search.toLowerCase().includes(searchText.toLowerCase())

    if (currentUserFirst) {
        employees = employees.sort((a, b) => {
            if (a.EmpID == employee.EmpID) return -1
            if (b.EmpID == employee.EmpID) return 1
            return 0
        })
    }

    const clearedState = clearsToCurrentUser ? employee.EmpID : false

    return (
        <Form.AdvancedSelect
            isClearable={isClearable && selected != clearedState}
            options={employees?.map((e) => {
                return employeeOption(e)
            })}
            value={employeeOption(value)}
            onChange={(e) => {
                if (clearsToCurrentUser && !e) {
                    return onChange(employee.EmpID)
                }
                onChange(e?.value)
            }}
            isDisabled={disabled}
            ignoreGlobalDisable
            filterOption={customFilter}
        />
    )
}

const employeeOption = ({
    label = 'Unknown',
    FirstName,
    LastName,
    EmpID = false,
    hideAvatar = false,
}) => ({
    label: hideAvatar ? (
        label
    ) : (
        <EmployeeAvatar
            includeLabel
            firstName={FirstName}
            lastName={LastName}
            size='xxs'
            labelClassName='fs-sm'
        />
    ),
    value: EmpID,
    search: label,
})
