import { combineReducers } from 'redux'
import file_list from './file_list_reducer'
import ucc_file from './file_details_reducer'
import search_box from './search_box_reducer'
import file_view from './file_view_reducer'
import edit_stage_modal from './edit_stage_modal_reducer'
import rollback_modal from './rollback_modal_reducer'
import skip_ahead from './skip_ahead_reducer'
import log from './log_reducer'
import notification_letter_modal from './notification_letter_modal'
import link_files from './link_files_reducer'
import stage_totals from './stage_totals_reducer'
import snooze_modal from './snooze_modal_reducer'
import alerts from './alerts_reducer'
import notes from './note_reducer'
import next_stage from './next_stage_reducer'
import complete from './complete_reducer'
import build_docs from './build_docs_reducer'

const rootReducer = combineReducers({
    ucc: file_list,
    ucc_file,
    search_box,
    file_view,
    edit_stage_modal,
    rollback_modal,
    skip_ahead,
    log,
    notification_letter_modal,
    link_files,
    stage_totals,
    snooze_modal,
    alerts,
    notes,
    next_stage,
    complete,
    build_docs,
})

export default rootReducer
