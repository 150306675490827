import React, { Component } from 'react'

export default class Alert extends Component {
    render() {
        return (
            <div
                className={'alert alert-' + (this.props.style ? this.props.style : 'danger')}
                style={{
                    position: 'fixed',
                    top: 15,
                    right: 10,
                    zIndex: 1050,
                    minWidth: 300,
                    maxWidth: 500,
                }}
            >
                <span
                    // data-bs-dismiss="alert"
                    style={{ position: 'absolute', top: 15, right: 10 }}
                    className={'far fa-times pointer fa-lg'}
                    onClick={() => {
                        this.props.onClick ? this.props.onClick() : () => {}
                    }}
                />
                <div style={{ marginRight: 15 }}>{this.props.children}</div>
            </div>
        )
    }
}
