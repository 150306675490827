import { useSession } from 'hooks'
import Cookies from 'js-cookie'
import { useProjectSearch } from '../api'
import { useEffect } from 'react'
export default function useDashboardQueue() {
    const employee = Cookies.getJSON('employee')
    const getEmpID = () => employee?.EmpID
    const emp_id = getEmpID()
    const employee_id = emp_id
    const startFilters = {
        is_active: [],
        client_id: [],
        project_type: [],
        project_service_level: [],
        current_stage: [],
        is_priority: [],
        state: [],
        employee_id: emp_id,
        created_date: [],
    }
    const startQueueFilters = {
        employee_id,
    }

    const [activeTab, setActiveTab] = useSession('trackerDashboardActiveTab', 'userQueues')
    const [queueFilters, setQueueFilters] = useSession('navQueueFilters')
    const [advancedFilters, setAdvancedFilters] = useSession('navAdvancedFilters', startFilters)
    const [workflowFilter, setWorkflowFilter] = useSession('workflowFilter', false)

    useEffect(() => {
        if (!queueFilters && !!startQueueFilters?.employee_id) {
            setQueueFilters(startQueueFilters)
        }
        if (!advancedFilters) {
            setAdvancedFilters(startFilters)
        }
    }, [queueFilters, advancedFilters, startQueueFilters])

    const filters = activeTab === 'userQueues' ? queueFilters : advancedFilters

    const projectSearch = useProjectSearch(filters)
    const setFilters = activeTab === 'userQueues' ? setQueueFilters : setAdvancedFilters

    let currentList = projectSearch?.data?.list ?? []
    if (activeTab === 'userQueues' && (workflowFilter?.length > 0 || workflowFilter === null)) {
        currentList = currentList?.filter((file) => file?.workflow == workflowFilter)
    }

    return {
        activeTab,
        setActiveTab,
        queueFilters,
        setQueueFilters,
        advancedFilters,
        setAdvancedFilters,
        filters,
        setFilters,
        workflowFilter,
        setWorkflowFilter,
        startFilters,
        ...projectSearch,
        currentList,
    }
}
