import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useLocal } from 'hooks'
import { mergeConfigs } from '../../../../utils'
import axios from '../../../axios'

export class TrackerDropdowns {
    static keys = {
        allDropdowns: ['dropdowns'],
        trackerDropdowns: () => [...this.keys.allDropdowns, 'tracker'],
    }

    static get = () => axios.get('/nml/tracker/2/dropdowns/json').then(({ data }) => data)
}

export const useTrackerDropdowns = (config) => {
    const queryClient = useQueryClient()

    const cachedDropdowns = queryClient.getQueryData({
        queryKey: TrackerDropdowns.keys.trackerDropdowns(),
    })

    // Stores response in localStorage as well so data is preloaded each session
    const [localStorageDropdowns, setLocalStorageDropdowns] = useLocal('dropdowns')

    const key = TrackerDropdowns.keys.trackerDropdowns()
    const api = () => TrackerDropdowns.get()
    const configs = mergeConfigs(
        {
            enabled: true,
            // initialData: localStorageDropdowns,
            onSuccess: setLocalStorageDropdowns,

            // These ensure api will be fetched just once per session
            refetchOnMount: false,
            cacheTime: Infinity,
        },
        config
    )

    return useQuery(key, api, configs)
}
