import React, { Fragment } from 'react'

export default function LabelAndDisplay({ label, display }) {
    return (
        <div className='labeled-input'>
            <div className='info-label'>{label}</div>
            {Array.isArray(display) ? (
                display.map((row, i) => (
                    <Fragment key={i}>
                        {i !== 0 && <hr style={{ margin: '10px' }} />}
                        {row}
                    </Fragment>
                ))
            ) : (
                <Fragment>
                    {display?.length > 0 || Number.isInteger(display) ? display : 'None'}
                </Fragment>
            )}
        </div>
    )
}
