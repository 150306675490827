import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export default class Overlay extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: 'overlay' + Math.random(),
        }
    }
    static defaultProps = {
        style: {},
    }

    render() {
        return (
            <OverlayTrigger
                overlay={<Tooltip id={this.state.id}>{this.props.text}</Tooltip>}
                placement={this.props.placement ? this.props.placement : 'top'}
            >
                <span>{this.props.children}</span>
            </OverlayTrigger>
        )
    }
}

Overlay.propTypes = {
    text: PropTypes.string.isRequired,
}
