import React, { useContext } from 'react'
import { MessageContext } from '../Context/GlobalState'
import LoadingSpinner from '../../utils/LoadingSpinner'
import { Badge, Button } from 'front'

export default function NavItem({ openModal }) {
    const { unseen, isLoading } = useContext(MessageContext)
    return (
        <li className='nav-item position-relative'>
            <Button
                ghost
                icon='fal fa-comments'
                variant='light'
                circle
                onClick={openModal}
                loading={isLoading}
            />
            {unseen > 0 && (
                <Badge
                    rounded
                    bg='danger'
                    className='position-absolute translate-middle fs-xs rounded-circle'
                    style={{ top: 11, right: -9 }}
                >
                    {unseen}
                </Badge>
            )}
        </li>
    )
}
