import { Button, ListGroup } from 'front'
import { Alert, Modal } from 'react-bootstrap'
import { useUpdatePacket } from 'tracker/api'
import { NcsLoadingSpinner } from 'utils'
// TODO: clean this code up
export default function AddTemplateModal({ packet, show, onHide }) {
    const { mutate: updatePacket, ...updatePacketApi } = useUpdatePacket(packet?.project_id, {
        onSuccess: onHide,
    })

    function onClose() {
        updatePacketApi.reset()
        onHide()
    }

    return (
        <Modal centered show={show} onHide={onClose} scrollable>
            <Modal.Header closeButton>
                <Modal.Title>Add Softening Letter</Modal.Title>
            </Modal.Header>
            {updatePacketApi?.isError && (
                <Modal.Body>
                    <AddSofteningLetterErrorAlert error={updatePacketApi?.error} />
                </Modal.Body>
            )}
            <ListGroup className='mt-4' noBorders>
                {packet?.softeningLetterOptions?.map((letter) => (
                    <ListGroup.Item
                        key={letter?.id}
                        action
                        className='d-flex justify-content-start ps-5'
                        onClick={() => {
                            updatePacket({
                                packetId: packet?.id,
                                data: {
                                    softening_letter_id: letter.id,
                                },
                            })
                        }}
                    >
                        {letter?.DOC_DESC}
                    </ListGroup.Item>
                ))}
            </ListGroup>
            <Modal.Footer className='justify-content-between'>
                <Button onClick={onClose} ghost variant='secondary' className='me-2'>
                    Cancel
                </Button>
                {updatePacketApi?.isLoading && (
                    <div>
                        <i className='far fa-spinner-third fa-spin' />
                    </div>
                )}
            </Modal.Footer>
        </Modal>
    )
}

function AddSofteningLetterErrorAlert({ error }) {
    const errorMessage = error?.response?.data?.errors?.[0]?.message
    return (
        <Alert variant='soft-danger'>
            <div className='d-flex'>
                <div className='flex-shrink-0'>
                    <i className='fas fa-circle-exclamation' />
                </div>
                <div className='flex-grow-1 ms-2'>{errorMessage}</div>
            </div>
        </Alert>
    )
}
