// Checks if callback is valid before calling it
export function verifyFunctionThenCall(callback, ...args) {
    if (callback && typeof callback === 'function') {
        return callback(...args)
    }
}

// Transform object into array of values
export function objToArray(obj) {
    if (typeof obj === 'object') return Object.entries(obj).map(([key, value]) => value)
    else return []
}

// Transform any text into all lowercase except first letter of each word
export function toTitleCase(str, ignoreNonWords = false) {
    return str
        ?.split(' ')
        .map((substr) => {
            if (ignoreNonWords && !containsOnlyLetters(substr)) {
                return substr
            }
            return substr.charAt(0).toUpperCase() + substr.substring(1).toLowerCase()
        })
        .join(' ')
}

export function containsOnlyLetters(str) {
    return /^[a-zA-Z]+$/.test(str)
}

export function jsonCompare(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
}
