import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { mergeConfigs } from '../../../../utils'
import axios from '../Context/AxiosSetup'
import { Project } from './project.api'
import { useLocal } from 'hooks'
import { ProjectSearch } from './projectSearch.api'
import Cookies from 'js-cookie'

const employee = Cookies.getJSON('employee')
const getEmpID = () => employee?.EmpID
const emp_id = getEmpID()

export class Notes {
    static keys = {
        all: ['notes'],
        assignedNotesByEmpId: (empId) => [...this.keys.all, 'assignedNotes', empId],
    }

    static create = (projectId, data) => {
        return axios
            .post(`/nml/tracker/1/note/create/${projectId}/json`, { ...data, _return: true })
            .then((response) => response.data)
    }

    static patchNote = (data) => {
        return axios
            .patch(`/nml/tracker/1/note/${data?.id}/json`, { ...data, _return: true })
            .then((response) => response.data)
    }

    static confirmNote = (noteId) => {
        return axios
            .post(`/nml/tracker/1/note/${noteId}/confirm/json`, { _return: true })
            .then((response) => response.data)
    }

    static deleteNote = (noteId) => {
        return axios.delete(`/nml/tracker/1/note/${noteId}/json`).then((response) => response.data)
    }

    static getAssignedNotes = (empId) => {
        return axios
            .post(`/nml/tracker/2/notes/assigned/json`, {
                employee_id: empId,
                confirmed_only: false,
            })
            .then((response) => response.data)
    }
}

export const useCreateNote = (projectId, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        (data) => Notes.create(projectId, data),
        mergeConfigs(
            {
                onSuccess: (data) => {
                    queryClient.setQueryData(Project.keys.project(projectId), {
                        ...queryClient.getQueryData(Project.keys.project(projectId)),
                        ...data,
                    })
                },
            },
            config
        )
    )
}

export const useUpdateNote = (projectId, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        (data) => Notes.patchNote(data),
        mergeConfigs(
            {
                onSuccess: (data) => {
                    queryClient.setQueryData(Project.keys.project(projectId), {
                        ...queryClient.getQueryData(Project.keys.project(projectId)),
                        ...data,
                    })
                },
            },
            config
        )
    )
}

export const useConfirmNote = (projectId, config) => {
    const queryClient = useQueryClient()
    const [currentSearchCriteria] = useLocal('current-tracker-search')

    return useMutation(
        (noteId) => Notes.confirmNote(noteId),
        mergeConfigs(
            {
                onSuccess: (data, noteId) => {
                    queryClient.setQueryData(Project.keys.project(projectId), {
                        ...queryClient.getQueryData(Project.keys.project(projectId)),
                        ...data,
                    })

                    const currentSearchKey = ProjectSearch.keys.type(currentSearchCriteria)
                    const currentSearchData = queryClient.getQueryData(currentSearchKey)
                    queryClient.setQueryData(ProjectSearch.keys.type(currentSearchCriteria), {
                        ...currentSearchData,
                        list: currentSearchData?.list?.map((row) => {
                            if (projectId == row?.project_id) {
                                return {
                                    ...row,
                                    notes: row?.notes?.filter(({ id }) => id != noteId),
                                }
                            }
                            return row
                        }),
                        ...data,
                    })
                    queryClient.invalidateQueries(Notes.keys.assignedNotesByEmpId(emp_id))
                },
            },
            config
        )
    )
}

export const useDeleteNote = (projectId, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        (noteId) => Notes.deleteNote(noteId),
        mergeConfigs(
            {
                onSuccess: (data) => {
                    queryClient.setQueryData(Project.keys.project(projectId), {
                        ...queryClient.getQueryData(Project.keys.project(projectId)),
                        ...data,
                    })
                },
            },
            config
        )
    )
}

export const useAssignedNotes = (empId, config) => {
    const key = Notes.keys.assignedNotesByEmpId(empId)
    const api = () => Notes.getAssignedNotes(empId)
    const configs = mergeConfigs(
        {
            enabled: !!empId,
            refetchOnWindowFocus: true,
            refetchInterval: 1000 * 60 * 5,
        },
        config
    )

    return useQuery(key, api, configs)
}
