import dayjs from 'lib/dayjs'
// import customParseFormat from 'dayjs/plugin/customParseFormat'
// dayjs.extend(customParseFormat)

export default function formatDate(date, format = 'MM/DD/YYYY', fallback = 'None') {
    if (date) {
        if (date == '0000-00-00') {
            return fallback
        } else if (date == '0000-00-00 00:00:00') {
            return fallback
        } else {
            let return_date = dayjs(date).format(format)
            // let day = dayjs(date).format(format)
            // console.log('day js format',day)
            return return_date == '12/31/1969' ? fallback : return_date
        }
    } else {
        return ''
    }
}
