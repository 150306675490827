import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isToday from 'dayjs/plugin/isToday'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(isToday)
dayjs.extend(isSameOrBefore)
dayjs.tz.setDefault('America/New_York')
export default dayjs

export function toNow(date) {
    const today = dayjs()
    const dueDate = dayjs(date)
    const daysUntilDue = dueDate.diff(today, 'day', true)

    const relativeDeadline = dayjs(dayjs(date).set('hour', 17)).fromNow()

    let displayDeadline
    if (daysUntilDue <= 0 && daysUntilDue > -1) {
        displayDeadline = 'today'
    } else if (daysUntilDue <= 1 && daysUntilDue > 0) {
        displayDeadline = 'tomorrow'
    } else if (daysUntilDue <= -1 && daysUntilDue > -2) {
        displayDeadline = 'yesterday'
    } else {
        displayDeadline = relativeDeadline
    }
    return displayDeadline
}
