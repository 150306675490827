import classnames from 'classnames'
import { formatDate } from 'components/utils'
import { useDebounce } from 'hooks'
import { useContext, useState } from 'react'
import { Alert, Col, Collapse, Modal, Row } from 'react-bootstrap'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import { useGetInvoiceNumberUsage } from 'tracker/api/payables.api'
import { Badge, Button, Form } from '../../../../../front'
import { useFirmAndAttorneySearch, useFirms } from '../../../api'
import LinkTaskForm from './LinkTaskForm'

export default function PayableForm({
    projectId,
    payable,
    onChange,
    saveLabel,
    onSave,
    saveLoading,
    onCancel,
}) {
    const { fileInfo } = useContext(ProjectContext)

    const defaultOnChange = ({ target: { name, value } }) => onChange({ [name]: value })
    const { data: allFirms, ...allFirmsApi } = useFirms()
    const [firmSearch, setFirmSearch] = useState()
    const { data: firmSearchResults, ...searchApi } = useFirmAndAttorneySearch(firmSearch)
    const firms = firmSearchResults?.filter((result) => result?.contact_record !== 1)
    const selectedFirm = allFirms?.find((firm) => firm.firm_no == payable?.vendor_number)
    const [lineItemsEnabled, setLineItemsEnabled] = useState(
        payable?.line_items?.filter(({ is_deleted }) => !is_deleted)?.length > 1
    )
    const debounceInvoiceNo = useDebounce(payable?.invoice_number, 1000)
    const debounceVendorNo = useDebounce(payable?.vendor_number, 1000)

    const { data: usedInvoices, ...usedInvoicesApi } = useGetInvoiceNumberUsage(
        debounceInvoiceNo,
        debounceVendorNo
    )

    let noOptionsMessage = null
    if (!firms) noOptionsMessage = 'Start typing to search'
    else if (firms?.length === 0) noOptionsMessage = 'Search returned no firms'

    let firmOptions = []
    if (firms && firms?.length != 0) {
        firmOptions = [
            {
                label: `Search results for "${firmSearch}"`,
                options: firms?.map(firmOption),
            },
        ]
    }

    const lineItemsTotal = payable?.line_items
        ?.filter(({ is_deleted }) => !is_deleted)
        ?.reduce((sum, { item_fee_amount }) => sum + Number(item_fee_amount), 0)
    const totalsEqual = Number(payable?.fee_amount) == lineItemsTotal
    const invoiceUsedInAnotherProject = usedInvoices?.find(
        ({ project_id }) => !!project_id && project_id != projectId
    )
    const invoiceUsedInThisProject = usedInvoices?.find(
        ({ project_id, id }) => !!project_id && project_id == projectId && id != payable?.id
    )

    const disableSave =
        !totalsEqual ||
        !payable?.invoice_date ||
        !payable?.invoice_number ||
        !payable?.vendor_number ||
        !payable?.fee_amount ||
        payable?.line_items?.filter(({ is_deleted, task_id }) => !is_deleted && !task_id)?.length >
            0 ||
        invoiceUsedInAnotherProject ||
        invoiceUsedInThisProject

    return (
        <>
            <Modal.Body>
                <Collapse in={!lineItemsEnabled}>
                    <Form.Group as={Row} className='mb-3'>
                        <Form.Label column sm={3}>
                            Task
                        </Form.Label>
                        <Col sm={9}>
                            <LinkTaskForm
                                value={payable?.line_items?.[0]?.task_id}
                                name='task_id'
                                onChange={(value) => {
                                    onChange({
                                        line_items: [
                                            {
                                                ...payable?.line_items?.[0],
                                                task_id: value,
                                            },
                                        ],
                                        vendor_number: fileInfo?.tasks?.find(
                                            ({ id }) => id == value
                                        )?.firm_no,
                                    })
                                }}
                            />
                        </Col>
                    </Form.Group>
                </Collapse>
                <Form.Group as={Row} className='mb-3'>
                    <Form.Label column sm={3}>
                        Firm #
                    </Form.Label>
                    <Col sm={9}>
                        <Form.AdvancedSelect
                            debounce
                            isLoading={searchApi?.isFetching || allFirmsApi?.isFetching}
                            onDebounce={(input) => {
                                if (input?.length !== 0) setFirmSearch(input)
                            }}
                            value={firmOption(selectedFirm)}
                            options={firmOptions}
                            onChange={(firm) => {
                                onChange({ vendor_number: firm?.firmNo ?? null })
                            }}
                            noOptionsMessage={() => noOptionsMessage}
                            placeholder='Search firms...'
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3'>
                    <Form.Label column sm={3}>
                        Invoice #
                    </Form.Label>
                    <Col sm={9}>
                        <Form.Control
                            light
                            value={payable?.invoice_number}
                            placeholder='Invoice #'
                            name='invoice_number'
                            onChange={defaultOnChange}
                        />
                    </Col>
                </Form.Group>
                {invoiceUsedInAnotherProject && (
                    <Alert variant='soft-danger'>
                        <i className='fas fa-circle-exclamation me-3' />
                        Invoice already used in project {invoiceUsedInAnotherProject?.project_id}
                    </Alert>
                )}
                {invoiceUsedInThisProject && !invoiceUsedInAnotherProject && (
                    <Alert variant='soft-danger'>
                        <i className='fas fa-circle-exclamation me-3' />
                        Invoice already used in this project.
                    </Alert>
                )}
                <Form.Group as={Row} className='mb-3'>
                    <Form.Label column sm={3}>
                        Invoice Date
                    </Form.Label>
                    <Col sm={9}>
                        <Form.Date
                            light
                            value={formatDate(payable?.invoice_date)}
                            placeholder='Invoice Date'
                            name='invoice_date'
                            onChange={([date]) => {
                                onChange({ invoice_date: formatDate(date, 'YYYY-MM-DD') })
                            }}
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className='mb-3'>
                    <Form.Label column sm={3}>
                        Invoice Total
                    </Form.Label>
                    <Col sm={9}>
                        <Form.Control
                            light
                            value={payable?.fee_amount}
                            placeholder='Fee amount'
                            name='fee_amount'
                            onChange={({ target: { value } }) => {
                                if (!lineItemsEnabled) {
                                    onChange({
                                        fee_amount: value,
                                        line_items: [
                                            {
                                                ...payable?.line_items?.[0],
                                                item_fee_amount: value,
                                            },
                                        ],
                                    })
                                } else onChange({ fee_amount: value })
                            }}
                        />
                    </Col>
                </Form.Group>
                <Collapse in={lineItemsEnabled}>
                    <div>
                        <table className='table w-100 mt-6'>
                            <thead className=''>
                                <tr>
                                    <th></th>
                                    <th>Task</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {payable?.line_items
                                    ?.filter(({ is_deleted }) => !is_deleted)
                                    ?.map(({ id, task_id, item_fee_amount }, index) => (
                                        <tr key={id} className='align-middle'>
                                            <td>{index + 1}</td>
                                            <td className='table-input-group'>
                                                <LinkTaskForm
                                                    value={task_id}
                                                    name='task_id'
                                                    excludeIds={payable?.line_items
                                                        ?.filter(
                                                            (lineItem) =>
                                                                !lineItem?.is_deleted &&
                                                                lineItem?.id != id
                                                        )
                                                        ?.map(({ task_id }) => task_id)}
                                                    onChange={(value) =>
                                                        onChange({
                                                            line_items: payable?.line_items?.map(
                                                                (lineItem) => {
                                                                    if (lineItem?.id == id) {
                                                                        return {
                                                                            ...lineItem,
                                                                            task_id: value,
                                                                        }
                                                                    } else return lineItem
                                                                }
                                                            ),
                                                        })
                                                    }
                                                />
                                            </td>
                                            <td style={{ width: 125 }}>
                                                <Form.Control
                                                    light
                                                    value={item_fee_amount}
                                                    placeholder='Amount'
                                                    name='item_fee_amount'
                                                    className='w-100'
                                                    onChange={({ target: { value } }) =>
                                                        onChange({
                                                            line_items: payable?.line_items?.map(
                                                                (lineItem) => {
                                                                    if (lineItem?.id == id) {
                                                                        return {
                                                                            ...lineItem,
                                                                            item_fee_amount: value,
                                                                        }
                                                                    } else return lineItem
                                                                }
                                                            ),
                                                        })
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <Button
                                                    ghost
                                                    circle
                                                    variant={'danger'}
                                                    onClick={() =>
                                                        onChange({
                                                            line_items:
                                                                id > 0
                                                                    ? payable?.line_items?.map(
                                                                          (lineItem) => {
                                                                              if (
                                                                                  lineItem?.id ===
                                                                                  id
                                                                              ) {
                                                                                  return {
                                                                                      ...lineItem,
                                                                                      is_deleted: 1,
                                                                                  }
                                                                              } else return lineItem
                                                                          }
                                                                      )
                                                                    : payable?.line_items?.filter(
                                                                          (lineItem) =>
                                                                              lineItem?.id != id
                                                                      ),
                                                        })
                                                    }
                                                    icon={'fal fa-xmark'}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                <tr
                                    className={classnames('fw-bold', {
                                        'text-danger': !totalsEqual,
                                    })}
                                >
                                    <td colSpan={2} className='text-end'>
                                        LINE ITEMS TOTAL
                                    </td>
                                    <td>
                                        <span className='ps-3'>${lineItemsTotal}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Collapse>
                {lineItemsEnabled && (
                    <Button
                        className='w-100'
                        variant='primary'
                        ghost
                        onClick={() =>
                            onChange({
                                line_items: [
                                    ...payable?.line_items,
                                    {
                                        id: Math.random() * -1,
                                        item_fee_amount: 0,
                                        task_id: null,
                                    },
                                ],
                            })
                        }
                    >
                        Add line item
                    </Button>
                )}
                {!lineItemsEnabled && (
                    <Button
                        className='w-100 mt-4'
                        variant='primary'
                        ghost
                        onClick={() => {
                            setLineItemsEnabled(true)
                            onChange({
                                line_items: [
                                    ...payable?.line_items,
                                    {
                                        id: Math.random() * -1,
                                        created_by: null,
                                        is_deleted: 0,
                                        payable_id: payable?.id,
                                        item_fee_amount: 0,
                                        task_id: null,
                                    },
                                ],
                            })
                        }}
                    >
                        Split total across multiple tasks
                        <i className='far fa-split ms-2' />
                    </Button>
                )}
                {lineItemsEnabled && !totalsEqual && (
                    <Alert variant='soft-danger' className='mt-4'>
                        <i className='fas fa-circle-exclamation me-3' />
                        Line item amounts must equal invoice total
                    </Alert>
                )}
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <Button ghost variant='secondary' onClick={onCancel}>
                    Cancel
                </Button>
                <Button
                    variant='primary'
                    onClick={onSave}
                    loading={saveLoading}
                    disabled={disableSave}
                >
                    {saveLabel}
                </Button>
            </Modal.Footer>
        </>
    )
}

const firmOption = (firm) => {
    if (!firm?.firm_no) return null

    return {
        firmNo: firm?.firm_no,
        value: `${firm?.firm_no} - ${firm?.firm}`,
        label: (
            <div className='d-flex justify-content-start'>
                <div>
                    <Badge className='me-2' bg='dark' soft style={{ minWidth: '50px' }}>
                        {firm?.firm_no}
                    </Badge>
                </div>
                <small className='d-block' style={{ paddingTop: '0.2em' }}>
                    {firm?.firm}
                </small>
            </div>
        ),
    }
}
