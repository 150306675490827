import classnames from 'classnames'
import React from 'react'
import { Badge as BootstrapBadge } from 'react-bootstrap'
import StatusDot from './StatusDot'

const Badge = React.forwardRef(
    ({ className, soft, statusDot, children, icon, ...bsProps }, ref) => {
        return (
            <BootstrapBadge
                ref={ref}
                {...bsProps}
                text={soft ? bsProps.bg : null}
                className={classnames(className, {
                    [`bg-soft-${bsProps.bg}`]: soft,
                })}
            >
                {statusDot && <StatusDot bg={bsProps.bg} />}
                {icon && <i className={classnames(icon, 'me-1')} />}
                {children}
            </BootstrapBadge>
        )
    }
)
Badge.defaultProps = {
    bg: 'dark',
}

export default Badge
