import { times } from 'lodash'
import filter from 'lodash/filter'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/uniqBy'
import Link from 'next/link'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Card } from '../front'
import Table from '../front/components/Table'
import currencyFormatter from './currencyFormatter'
import CustomizeColumns from './CustomizeColumns'
import formatDate from './formatDate'
import Highlighter from './Highlighter'
import isEmpty from './isEmpty'
import Pagination from './Pagination'
export default class SortableTable extends Component {
    constructor(props) {
        super(props)
        this.paginationRef = React.createRef()
        this.defaultView = 15

        // console.log("Headers", props.headers);
        // console.log("Info", props.info);

        this.state = {
            view: props.view ? props.view : this.defaultView,
            start: 1,
            end: props.all ? props.info.length : props.view ? props.view - 1 : this.defaultView - 1,
            sort: props.startSort ? props.startSort : '',
            sortBy: props.startSortBy ? props.startSortBy : 'desc',
            selected: [],
            selectedAll: false,
            colTotals: null,
            filter: {},
            search: '',
            timeout: 0,
            searchedData: props.info,
            tableId: 'SortableTable' + Math.random() * 10000,
        }
    }

    componentDidUpdate(prevProps) {
        // Update this.state.view and this.state.end if the 'view' prop is being used and has changed
        // console.log('component is updating',prevProps,this.props)
        let props = this.props
        if (props.view !== prevProps.view) {
            this.setState({
                view: props.view,
                end: props.all ? props.info.length : props.view - 1,
            })
        }
    }

    changeSort(variable) {
        // console.log('changing sort',variable)
        const newSortBy = this.state.sortBy === 'desc' ? 'asc' : 'desc'
        if (this.state.sort === variable) {
            this.setState({
                sortBy: newSortBy,
            })
        } else {
            this.setState({
                sort: variable,
                sortBy: 'desc',
            })
        }
    }

    sortDirection(variable) {
        // console.log(
        //     'sort',
        //     JSON.stringify(this.state.sort) === JSON.stringify(variable),
        //     JSON.stringify(this.state.sort),
        //     JSON.stringify(variable),
        //     this.state.sort,
        //     variable
        // )
        if (JSON.stringify(this.state.sort) === JSON.stringify(variable)) {
            return this.state.sortBy === 'desc' ? (
                <span className={'fad fa-sort-down text-primary ms-2 fa-sm'} />
            ) : (
                <span className={'fad fa-sort-up text-primary ms-2 fa-sm'} />
            )
        } else {
            return <span className={'fas fa-sort ms-2 fa-sm'} />
        }
    }

    multiRow(header, row) {
        return header?.value?.map((val, key) => (
            <div key={'multiRow' + key + row[this.props.id ? this.props.id : Math.random()]}>
                {!header?.noHr && key > 0 && <hr className={'my-1'} />}
                <Highlighter check={row[val]}>
                    <span style={key > 0 ? { color: '#8f8f8f' } : {}}>
                        {this.getValue(row, val)}
                    </span>
                </Highlighter>
            </div>
        ))
    }

    getValue(row, value) {
        // console.log('inside obj',value,row)
        let rowValue = row
        let splitValue = []
        if (typeof value === 'boolean' || !value) {
            rowValue = row
            splitValue = ''
        } else if (typeof value === 'string') {
            rowValue = row?.[value]
            splitValue = value
        } else if (typeof value === 'object') {
            rowValue = row?.[value?.value]
            splitValue = value?.value
        }
        const split = splitValue?.split('.')
        let returnValue = ''
        if (split?.length > 1) {
            let returnRow = row
            split?.map((val) => {
                returnRow = returnRow?.[val]
            })
            if (!returnRow) {
                returnRow = 'None'
            }
            returnValue = returnRow
        } else if (!rowValue) {
            returnValue = 'None'
        } else {
            returnValue = rowValue
        }

        if (typeof value === 'object') {
            if (value?.date) {
                returnValue = formatDate(returnValue)
            } else if (value?.currency) {
                returnValue = currencyFormatter(returnValue)
            }

            if (value?.className) {
                returnValue = <div className={value?.className}>{returnValue}</div>
            }
        }
        return returnValue
    }

    getLinkForReportTable(row) {
        let baseURL = this.props.baseURL
        let metadataOnePageAllTables = this.props.metadataOnePageAllTables
        let detailKey = this.props.detailKey
        let detail_id = row[detailKey]
        let to = undefined
        let link = undefined
        if (baseURL && detail_id && metadataOnePageAllTables) {
            link = baseURL + detail_id + '/'
            to = {
                pathname: link,
                state: { definition: metadataOnePageAllTables, fromURL: this.props?.fromURL },
            }
        }
        return { link, to }
    }

    tableCell(row, header) {
        // This function takes in an entire table row and one of the table's headers ('Name' or 'Address' for examples),
        // picks out the row data that corresponds to the header, and returns a div containing that data.
        // Usually, the data is simply equal to row[header.value].  However, if the given header has a 'custom' field,
        // 'custom' is assumed to be a function which can produce the contents of the table cell (e.g.,
        // a checkbox), and that function is called instead of using row[header.value].
        return (
            <div>
                {header.custom ? (
                    header.custom(
                        Array.isArray(header.value)
                            ? header.value.map((val) => {
                                  return this.getValue(row, val)
                              })
                            : this.getValue(row, header.value)
                    )
                ) : header.date ? (
                    formatDate(row[header.value])
                ) : header.currency ? (
                    currencyFormatter(row[header.value])
                ) : Array.isArray(header.value) ? (
                    this.multiRow(header, row)
                ) : typeof row[header.value] === 'string' &&
                  this.props.highlightThis !== '' &&
                  row[header.value].includes(this.props.highlightThis) ? (
                    <Highlighter check={row[header.value]}>
                        <span className='highlighted-value'>
                            {this.getValue(row, header.value)}
                        </span>
                    </Highlighter>
                ) : (
                    <Highlighter check={row[header.value]}>
                        <span>{this.getValue(row, header.value)}</span>
                    </Highlighter>
                )}
            </div>
        )
    }

    renderRow = (row) => {
        let { link, to } = this.getLinkForReportTable(row)
        let linkStyle = {
            textDecoration: 'inherit',
        }
        const sticky = this.props?.stickyRow
            ? row[this.props.stickyRow.field] === this.props.stickyRow.value
            : false
        const stickyClass = sticky ? 'sortableTableStickyRow' : ''
        const stickyHeaderOffset = document.getElementById('sortableTableHeader')?.clientHeight
        const stickyTopSyle = sticky
            ? {
                  top:
                      (stickyHeaderOffset ? stickyHeaderOffset - 2 : 0) +
                      (this.props.stickyHeaderOffset ?? 0),
              }
            : {}

        let rowProps = {}
        if (typeof this.props.rowProps === 'function') {
            rowProps = this.props.rowProps(row)
        } else if (this.props.rowProps) {
            rowProps = this.props.rowProps
        }

        return (
            <tr key={this.props.id ? row[this.props.id] : Math.random()} {...rowProps}>
                {this.props.checkbox && (
                    <td className={stickyClass} style={stickyTopSyle}>
                        <input
                            type={'checkbox'}
                            className={'checkbox'}
                            onChange={this.props.checkboxCallback}
                        />
                    </td>
                )}
                {this.props.headers.map(
                    (header) =>
                        header && (
                            <td
                                key={
                                    'tableData' +
                                    header.name +
                                    (this.props.id ? row[this.props.id] : Math.random())
                                }
                                className={
                                    (header.rowClassName ? header.rowClassName : '') + stickyClass
                                }
                                style={stickyTopSyle}
                            >
                                {link !== undefined && to !== undefined ? (
                                    <Link href={to}>
                                        <a href={to?.pathname} style={linkStyle}>
                                            {this.tableCell(row, header)}
                                        </a>
                                    </Link>
                                ) : (
                                    <div>{this.tableCell(row, header)}</div>
                                )}
                            </td>
                        )
                )}
                {this.props.rowTotals && (
                    <td>
                        {this.props.headers
                            .map((header) => !header.noTotal && row[header.value])
                            .reduce((partial_sum, a) => partial_sum + a)}
                    </td>
                )}
            </tr>
        )
    }

    searchData() {
        const fieldsToSort =
            this.props?.searchColumns ??
            this.props.headers.filter((header) => header.search !== false)?.map((r) => r.value)
        // console.log('searching',this.state.search.length,this.state.search,fieldsToSort)
        return this.state.search.length > 0
            ? filter(this.props.info, (row) => {
                  for (let i = 0; i < fieldsToSort.length; i++) {
                      // console.log('inside for',row[this.props.headers[i].value].toLowerCase().indexOf(this.state.search.toLowerCase()) > -1,row[this.props.headers[i].value].toLowerCase().indexOf(this.state.search.toLowerCase()),row[this.props.headers[i].value].toLowerCase())
                      if (
                          row?.[fieldsToSort[i]]
                              ?.toLowerCase()
                              .indexOf(this.state.search.toLowerCase()) > -1
                      ) {
                          return row
                      }
                  }
              })
            : this.props.info
    }

    filteredData() {
        // console.log('filtered data',this.state.searchedData.length,this.state.searchedData,this.state.filter)
        const searched = this.searchData()
        return this.props.filter && !isEmpty(this.state.filter)
            ? filter(searched, this.state.filter)
            : searched?.length > 0
            ? searched
            : this.props.info
    }

    exportToCSV(data) {
        //create csv
        let csvContent = 'data:application/vnd.ms-excel;charset=utf-8,'
        csvContent +=
            this.props.headers
                .map((h) => {
                    const value = h.name.replace(/"/g, '""')
                    if (value.search(/("|,|\n)/g) >= 0) {
                        return '"' + value + '"'
                    }
                    return value
                })
                .join(',') + '\n'
        csvContent += data
            .map((r) =>
                this.props.headers
                    .map((h) => {
                        const value = r[h.value].replace(/"/g, '""')
                        if (value.search(/("|,|\n)/g) >= 0) {
                            return '"' + value + '"'
                        }
                        return value
                    })
                    .join(',')
            )
            .join('\n')

        //create link and download
        let downloadLink = document.createElement('a')
        document.body.appendChild(downloadLink)
        downloadLink.href = encodeURI(csvContent)
        downloadLink.download = 'excelExport.csv'
        downloadLink.click()
    }

    render() {
        // console.log('table', this.props)
        if (this.props?.info?.length === 0) {
            return this?.props?.noDataDisplay ?? this.props?.skeleton ? (
                <TableSkeleton count={this.props?.headers?.length} />
            ) : (
                <Card.Body>No Data</Card.Body>
            )
        }
        const filtered = this.filteredData()
        const orderedData = orderBy(
            filtered,
            Array.isArray(this.state.sort) ? this.state.sort : [this.state.sort],
            [this.state.sortBy]
        )
        if (this.props.id && typeof window !== 'undefined') {
            window.localStorage.setItem(this.props.id, JSON.stringify(orderedData))
        }

        let grandTotal = 0
        const colTotals = this.props.colTotals && (
            <tr style={{ backgroundColor: '#fbfbfb' }}>
                {this.props.headers.map((header, key) => {
                    if (header) {
                        return (
                            <td key={key}>
                                {header.noTotal
                                    ? key === 0
                                        ? 'Totals'
                                        : 'None'
                                    : new Intl.NumberFormat().format(
                                          this.props.info
                                              .map((row) => parseInt(row[header.value]))
                                              .reduce((partial_sum, a, index) => {
                                                  grandTotal =
                                                      grandTotal +
                                                      (index === 1 ? partial_sum + a : a)
                                                  return partial_sum + a
                                              })
                                      )}
                            </td>
                        )
                    }
                })}
                {this.props.rowTotals && <td>{grandTotal}</td>}
            </tr>
        )

        let enableTools =
            this.props.search ||
            (this.props.filter && !isEmpty(this.state.filter)) ||
            this.props.exportBtn ||
            this.props.exportToCSV ||
            (this.props.customizeColumn && this.props.customizeColumnOptions?.length > 0)
        let tools = (
            <>
                {enableTools && (
                    // {this.props.filter && !isEmpty(this.state.filter) &&
                    <div className={'row justify-content-end'}>
                        <div className={'col-12 col-sm-9'}>
                            {this.props.exportBtn && (
                                <span className={'float-start'}>{this.props.exportBtn}</span>
                            )}
                            {this.props.exportToCSV && (
                                <span className={'float-end'}>
                                    <button
                                        className={'btn btn-light'}
                                        onClick={() => {
                                            this.exportToCSV(orderedData)
                                        }}
                                    >
                                        <span className={'far fa-file-export'} /> CSV
                                    </button>
                                </span>
                            )}
                            {this.props.filter && !isEmpty(this.state.filter) && (
                                <button
                                    className={'btn btn-light float-end'}
                                    onClick={() => {
                                        this.setState({ filter: {} })
                                    }}
                                >
                                    <span className={'far fa-times'} /> Clear Filters
                                </button>
                            )}
                            {this.props.customizeColumn &&
                                this.props.customizeColumnOptions?.length > 0 && (
                                    <span className={'float-end'}>
                                        <CustomizeColumns
                                            customizeColumnOptions={
                                                this.props.customizeColumnOptions
                                            }
                                            saveCustomColumns={this.props.saveCustomColumns}
                                        />
                                    </span>
                                )}
                        </div>
                        {this.props.search && (
                            <div className={'col-12 col-sm-3'}>
                                <div className={'input-group input-group-merge'}>
                                    <input
                                        className={'form-control'}
                                        placeholder={'Search...'}
                                        value={this.state.search}
                                        onChange={({ target }) => {
                                            if (this.state.timeout) {
                                                clearTimeout(this.state.timeout)
                                            }
                                            this.setState({
                                                search: target.value,
                                                timeout: setTimeout(() => this.searchData(), 250),
                                            })
                                        }}
                                    />
                                    <div className='input-group-append input-group-button'>
                                        <button
                                            className={'btn btn-light'}
                                            onClick={() => {
                                                this.setState({
                                                    search: '',
                                                    searchedData: this.props.info,
                                                })
                                            }}
                                        >
                                            <span className={'far fa-times'} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </>
        )

        let table = (
            <Table
                lightHeader
                borderless={this.props.borderless ?? false}
                cardTable={this.props.cardTable}
                className={
                    this.props.className
                        ? this.props.className
                        : 'table table-hover clickable-table' +
                          (this.props.responsive ? ' sortableTable-responsive' : '')
                }
                id={this.state.tableId}
                // style={this.props.style ? {...this.props.style}:''}
            >
                <Table.Head
                    className={this.props.headerClassName ? this.props.headerClassName : ''}
                    id={'sortableTableHeader'}
                >
                    {!this.props.card && enableTools && (
                        <tr>
                            <th colSpan={'100%'}>{tools}</th>
                        </tr>
                    )}
                    {this.props.filter && (
                        <tr>
                            {this.props.headers.map((header, i) => {
                                if (header) {
                                    return header.name.length > 0 ? (
                                        header.filter !== false ? (
                                            <th key={i}>
                                                <select
                                                    className={'form-select'}
                                                    onChange={({ target }) => {
                                                        this.setState((prevState) => {
                                                            if (
                                                                prevState.filter[header.value] &&
                                                                target.value.length === 0
                                                            ) {
                                                                const newFilter = {
                                                                    ...prevState.filter,
                                                                }
                                                                delete newFilter[header.value]
                                                                return {
                                                                    ...prevState,
                                                                    filter: { ...newFilter },
                                                                }
                                                            } else if (target.value.length > 0) {
                                                                return {
                                                                    ...prevState,
                                                                    filter: {
                                                                        ...prevState.filter,
                                                                        [header.value]:
                                                                            target.value,
                                                                    },
                                                                }
                                                            } else {
                                                                return { ...prevState }
                                                            }
                                                        })
                                                    }}
                                                    value={
                                                        this.state.filter[header.value]
                                                            ? this.state.filter[header.value]
                                                            : ''
                                                    }
                                                >
                                                    <option value={''}>All {header.name}</option>
                                                    {uniqBy(filtered, header.value)
                                                        .sort((a, b) => {
                                                            const aCompare =
                                                                typeof a[header.value] === 'string'
                                                                    ? a[header.value].toLowerCase()
                                                                    : false
                                                            const bCompare =
                                                                typeof b[header.value] === 'string'
                                                                    ? b[header.value].toLowerCase()
                                                                    : false
                                                            return aCompare > bCompare
                                                                ? 1
                                                                : aCompare < bCompare
                                                                ? -1
                                                                : 0
                                                        })
                                                        .map((option, i) => (
                                                            <option
                                                                value={option[header.value]}
                                                                key={i}
                                                            >
                                                                {option[header.value]}
                                                            </option>
                                                        ))}
                                                </select>
                                            </th>
                                        ) : (
                                            <th key={'filter' + header.name + header.value} />
                                        )
                                    ) : (
                                        <th key={'filter' + header.name + header.value} />
                                    )
                                }
                            })}
                        </tr>
                    )}
                    {!this.props?.noHeaders && (
                        <tr>
                            {this.props.checkbox && (
                                <th>
                                    <input
                                        type={'checkbox'}
                                        className={'checkbox'}
                                        // checked={}
                                        onChange={this.props.checkAll}
                                    />
                                </th>
                            )}
                            {this.props.headers.map((header, i) => {
                                let style = { whiteSpace: 'nowrap' }
                                if (this.props.stickyHeaderOffset) {
                                    style.top = this.props.stickyHeaderOffset
                                }
                                if (header) {
                                    return header.name.length > 0 ? (
                                        header.sort !== false ? (
                                            <th
                                                key={i}
                                                onClick={() => {
                                                    this.changeSort(
                                                        header?.sortField ?? header.value
                                                    )
                                                }}
                                                className={
                                                    'pointer ' +
                                                    (header.className ? header.className : '') +
                                                    (this.props.stickyHeaders
                                                        ? ' th-sticky-header'
                                                        : '')
                                                }
                                                style={style}
                                            >
                                                <span>
                                                    {header.name}{' '}
                                                    {this.sortDirection(
                                                        header?.sortField ?? header.value
                                                    )}
                                                </span>
                                            </th>
                                        ) : (
                                            <th
                                                key={i}
                                                style={style}
                                                className={
                                                    header.className
                                                        ? header.className
                                                        : '' +
                                                          (this.props.stickyHeaders
                                                              ? ' th-sticky-header'
                                                              : '')
                                                }
                                            >
                                                <span>{header.name}</span>
                                            </th>
                                        )
                                    ) : (
                                        <th
                                            key={i}
                                            className={header.className ? header.className : ''}
                                        >
                                            {header.name}
                                        </th>
                                    )
                                }
                            })}
                            {this.props.rowTotals && <th>Total</th>}
                        </tr>
                    )}
                </Table.Head>

                <tbody className={this.props.bodyClassName ? this.props.bodyClassName : ''}>
                    {this.props.all ? (
                        <Fragment>
                            {orderedData.map((row) =>
                                this.props.renderRow
                                    ? this.props.renderRow(row)
                                    : this.renderRow(row)
                            )}
                            {colTotals}
                        </Fragment>
                    ) : (
                        <Pagination
                            info={orderedData}
                            render={this.props.renderRow ?? this.renderRow}
                            renderAfter={colTotals}
                            view={this.state.view}
                            table={this.props.table === false ? false : true}
                        />
                    )}
                </tbody>
            </Table>
        )

        if (this.props.card) {
            return (
                <Card skeleton={this.props?.skeleton}>
                    {(this.props.cardTitle || this.props.customizeColumn) && (
                        <Card.Header className='d-flex justify-content-between align-items-center'>
                            <Card.Title as='h4' className='d-block'>
                                {this.props.cardTitle}
                            </Card.Title>
                            {tools}
                        </Card.Header>
                    )}
                    <div className='table-responsive'>{table}</div>
                </Card>
            )
        } else {
            return table
        }
    }
}

function TableSkeleton({ count }) {
    return (
        // <div style={{ minHeight: 400 }}>
        <table className={'table'}>
            <tbody>
                <SkeletonRow count={count} />
                <SkeletonRow count={count} />
                <SkeletonRow count={count} />
            </tbody>
        </table>
        // </div>
    )
}

function SkeletonRow({ count }) {
    return (
        <tr style={{ height: 50 }}>
            {times(count, (i) => {
                return (
                    <td key={i}>
                        <Skeleton className='lh-lg w-100' />
                    </td>
                )
            })}
        </tr>
    )
}

// prettier-ignore
SortableTable.propTypes = {
    // HEADERS AND DATA
    headers: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.shape({	// Array of objects for the header info
                name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired, 				// Title of the column
                value: PropTypes.oneOfType([					// Key corresponding to the respective field in the info object
                    PropTypes.string,
                    PropTypes.bool,
                    PropTypes.arrayOf(PropTypes.string)			// Props to be passed to each row.
                ]),											// If multiple fields, pass array of string fieldnames
                sort: PropTypes.bool,						// Determines if the column is sortable
                className: PropTypes.string,				// Class name of the header cell
                rowClassName: PropTypes.string,				// Class name to be used for each cell within that column
                custom: PropTypes.func, 					// Function that returns a custom table cell
                sortField: PropTypes.oneOfType([			// Field used to sort in case of having multiple values or custom function
                    PropTypes.string,
                    PropTypes.arrayOf(PropTypes.string)
                ])
            })
        ])
    ).isRequired,
    info: PropTypes.arrayOf(PropTypes.object),		// Array of objects containing the data to be used in the table
    id: PropTypes.string,							// Name of field within info object that can be used as a unique ID for each row

    // SORTING
    startSort: PropTypes.string,					// What column to sort on initially
    startSortBy: PropTypes.string,					// Either 'asc' or 'desc'

    // PAGINATION
    all: PropTypes.bool,							// Turns pagination off
    view: PropTypes.number,							// Number of rows per page (if pagination is enabled)

    // SEARCHING / FILTERING
    search: PropTypes.bool,							// Adds a search bar to the top
    filter: PropTypes.bool, 						// Adds column filter dropdowns that all you to filter by any value found in that column
    highlightThis: PropTypes.string,				// Highlight this search term anywhere it appears in the table

    // COUNT TOTALS
    rowTotals: PropTypes.bool,						// Display row totals
    colTotals: PropTypes.bool,						// Display column totals

    // CHECKBOXES
    checkbox: PropTypes.bool, 						// Adds checkboxes to each row
    checkAll: PropTypes.bool,						// Adds a check all button in the header
    checkboxCallback: PropTypes.func,				// Callback for each time a checkbox is checked

    // ATTRIBUTES
    className: PropTypes.string,					// Class name to be used on the table
    bodyClassName: PropTypes.string,				// Class name to be used on body of table
    headerClassName: PropTypes.string,				// Class name to be used on header of table
    rowProps: PropTypes.oneOfType([					// Props to be passed to each row.
        PropTypes.object, 								// If props are static, pass object of props i.e. onClick, style, etc
        PropTypes.func									// If props are dynamic, pass callback that returns an object of props
    ]),

    // STICKY ELEMENTS
    stickyHeaders: PropTypes.bool,					// Headers will remain fixed at the top as you scroll
    stickyHeaderOffset: PropTypes.number,			// How far to offset the sticky header
    stickyRow: PropTypes.shape({					// Allows for a selected row to "sticky" to the top while scrolling
        field: PropTypes.string,						// Field name corresponding to the unique identifier for each row in info object
        value: PropTypes.oneOfType([					// Props to be passed to each row.
            PropTypes.string, 								// If props are static, pass object of props i.e. onClick, style, etc
            PropTypes.number									// If props are dynamic, pass callback that returns an object of props
        ])					// Value of that ID that you want currently stickied
    }),

    // REPORTING
    exportToCSV: PropTypes.bool, 					// Allows you to export the table to CSV
    baseUrl: PropTypes.string,						// TODO: Define this
    metadataOnePageAllTables: PropTypes.any,		// TODO: Define this
    detailKey: PropTypes.any,						// TODO: Define this
    noDataDisplay: PropTypes.string                 // Display separate message if no data
}
