import Head from 'next/head'
import { useContext, useEffect, useState } from 'react'
import { PortalContext } from './GlobalState'

const assets = process.env.ASSETS_URL
const ENV = process.env.ENVIRONMENT

export default function PortalHead(props) {
    const [addSupport, setAddSupport] = useState(false)
    const [navbarRight, setNavbarRight] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const { theme } = useContext(PortalContext)

    let port = ''
    if (process.env.ENVIRONMENT != 'PRODUCTION') {
        port = ':8001'
    }

    useEffect(() => {
        setLoaded(true)
    }, [])

    useEffect(() => {
        if (document) {
            const width = document.getElementById('navbar_right')?.offsetWidth
            if (width) {
                setNavbarRight(width)
            }
        }
    }, [loaded])

    useEffect(() => {
        // console.log('nav bar',navbarRight)
        if (!addSupport) {
            // console.log("ZE", window.zESettings);
            // window.zESettings = {
            // 	webWidget: {
            // 		offset: {horizontal: '50px', vertical: '-5px'},
            // 		position: {horizontal: 'right', vertical: 'top'},

            // 	}
            // }
            setAddSupport(true)
        }
    }, [])

    return (
        <Head>
            <title>Welcome to Portal</title>
            <meta charSet='utf-8' />
            <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
            {/* Front Theme */}
            <link
                href={`${assets}/ncs-bootstrap/front-latest/vendor/flatpickr/dist/flatpickr.min.css`}
                rel='stylesheet'
                type='text/css'
            />
            <link
                href={`${assets}/ncs-bootstrap/front-latest/css/theme.min.css`}
                rel='stylesheet'
                type='text/css'
            />
            <link
                href={`${assets}/ncs-bootstrap/front-latest/vendor/hs-mega-menu/dist/hs-mega-menu.min.css`}
                rel='stylesheet'
                type='text/css'
            />
            {theme == 'dark' && (
                <link
                    href={`${assets}/ncs-bootstrap/front-latest/css/theme-dark.min.css`}
                    rel='stylesheet'
                    type='text/css'
                />
            )}
            <script src={`${assets}/ncs-bootstrap/front-latest/vendor/jquery/dist/jquery.min.js`} />
            <script
                src={`${assets}/ncs-bootstrap/front-latest/vendor/jquery-migrate/dist/jquery-migrate.min.js`}
            />
            <link
                href={`${process.env.ASSETS_URL}/bootstrap-datetimepicker/latest/css/bootstrap-datetimepicker.min.css`}
                rel='stylesheet'
                type='text/css'
            />

            <link
                href='https://fonts.googleapis.com/css2?family=Inter:wght@400;600&amp;display=swap'
                rel='stylesheet'
            ></link>
            <script src={`${assets}/moment/latest/min/moment.min.js`} />
            {/*<script src={`${assets}/ncs-bootstrap/latest-5/js/bootstrap.bundle.min.js`}/>*/}
            <script
                src={`https://onlyoffice2.ncscredit.com${port}/web-apps/apps/api/documents/api.js`}
            />
            <link rel='shortcut icon' href='/images/favicon.ico' type='image/vnd.microsoft.icon' />
        </Head>
    )
}
