import { Tooltip } from 'components/utils'
import { Button } from 'front'
import { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ProjectContext } from 'tracker/Context/ProjectContext'

export default function DeleteDateModal({ onClick, row, type }) {
    const [show, setShow] = useState(false)
    const { canEdit } = useContext(ProjectContext)

    return (
        <>
            <Tooltip
                delay={{
                    show: 450,
                    hide: 0,
                }}
                content='Delete Custom Date'
            >
                <Button
                    ghost
                    circle
                    variant={!row?.is_deleted ? 'danger' : 'secondary'}
                    onClick={() => {
                        setShow(true)
                    }}
                    disabled={!canEdit}
                    icon={row?.is_deleted ? 'fal fa-trash-can-undo' : 'fal fa-trash-alt'}
                />
            </Tooltip>
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>Delete Deadline</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {row?.is_deleted
                            ? 'Would you like to restore '
                            : `Are you sure you want to delete `}
                        {row?.custom_label ?? type?.label}?
                    </p>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-between'>
                    <Button
                        ghost
                        variant='secondary'
                        onClick={() => {
                            setShow(false)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={row?.is_deleted ? 'primary' : 'danger'}
                        onClick={() => {
                            setShow(false)
                            onClick(row?._id, !row?.is_deleted)
                        }}
                    >
                        {row?.is_deleted && 'Undo '}Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
