import HighlightText from '@/components/utils/HighlightText'
import { Button, Form, Tabs } from 'front'
import { useEffect, useRef, useState } from 'react'
import LinkifyIt from 'linkify-it'
const linkify = LinkifyIt()

export default function ClientNotes({ show, fileInfo, enableSearch = true }) {
    const [searchNotes, setSearchNotes] = useState('')
    const searchRef = useRef(null)
    const [activeTab, setActiveTab] = useState('nml_note')
    const { client } = fileInfo || {}
    useEffect(() => {
        show && searchRef?.current?.focus()
    }, [show])

    const renderHighlightedText = (text) => {
        if (!text) return null

        const matches = linkify.match(text)
        if (!matches) return <HighlightText text={text} highlight={searchNotes} />

        let lastIndex = 0
        const elements = []

        matches.forEach((match, index) => {
            if (match.index > lastIndex) {
                elements.push(
                    <HighlightText
                        key={`text-${index}`}
                        text={text.slice(lastIndex, match.index)}
                        highlight={searchNotes}
                    />
                )
            }
            elements.push(
                <a key={`link-${index}`} href={match.url} target="_blank" rel="noopener noreferrer">
                    <HighlightText text={match.text} highlight={searchNotes} />
                </a>
            )
            lastIndex = match.lastIndex
        })

        if (lastIndex < text.length) {
            elements.push(
                <HighlightText
                    key="text-last"
                    text={text.slice(lastIndex)}
                    highlight={searchNotes}
                />
            )
        }

        return elements
    }

    return (
        <>
            <div className={'row sticky-top bg-white'} style={{ marginBottom: '1.25rem' }}>
                {enableSearch && (
                    <div className={'col-12 justify-content-between d-flex mb-3'}>
                        <div className='flex-fill pe-3'>
                            <Form.InputGroup>
                                <Form.Control
                                    ref={searchRef}
                                    placeholder={'Search notes'}
                                    value={searchNotes}
                                    onChange={({ target }) => {
                                        setSearchNotes(target.value)
                                    }}
                                    ignoreGlobalDisable
                                />
                                {searchNotes?.length > 0 && (
                                    <Button
                                        className='btn btn-outline-secondary'
                                        onClick={() => {
                                            setSearchNotes('')
                                        }}
                                    >
                                        <span className={'far fa-times'} />
                                    </Button>
                                )}
                            </Form.InputGroup>
                        </div>
                    </div>
                )}
                <Tabs
                    fill
                    activeTab={activeTab}
                    onSelect={(tab) => {
                        setActiveTab(tab)
                    }}
                >
                    {client?.nml_note && (
                        <Tabs.Tab
                            eventKey='nml_note'
                            title={
                                <div className='d-flex justify-content-center align-items-center'>
                                    Special
                                </div>
                            }
                        />
                    )}
                    {client?.client_note && (
                        <Tabs.Tab
                            eventKey='client_note'
                            title={
                                <div className='d-flex justify-content-center align-items-center'>
                                    Client
                                </div>
                            }
                        />
                    )}
                    {client?.master_client_note && (
                        <Tabs.Tab
                            eventKey='master_client_note'
                            title={
                                <div className='d-flex justify-content-center align-items-center'>
                                    Master
                                </div>
                            }
                        />
                    )}
                    {client?.billing_note && (
                        <Tabs.Tab
                            eventKey='billing_note'
                            title={
                                <div className='d-flex justify-content-center align-items-center'>
                                    Billing
                                </div>
                            }
                        />
                    )}
                </Tabs>
            </div>
            <p style={{ whiteSpace: 'pre-line' }}>
                {renderHighlightedText(client?.[activeTab])}
            </p>
        </>
    )
}
