import GlobalState from './Context/GlobalState'
import NavItem from './Components/NavItem'
import Modal from './Components/Modal'
import { useModalState } from 'hooks'

export default function Index(props) {
    const modal = useModalState(false)
    return (
        <GlobalState>
            <NavItem openModal={modal.show} />
            <Modal show={modal.isOpen} hide={modal.hide} />
        </GlobalState>
    )
}
