import {
    FETCH_SETTINGS,
    FETCH_DROPDOWNS,
    ADD_SETTING,
    IS_LOADING,
    DELETE_ROW,
    CHANGE_DROPDOWN,
    COMPANY_SEARCH,
    SELECT_COMPANY,
    CHANGE_CERT_SPOD,
    CHANGE_CERT_SPOD_OPTION,
} from './action'

export default function UccSettingsReducer(state = { isLoading: true }, action) {
    switch (action.type) {
        case FETCH_SETTINGS:
            return { ...state, settings: action.settings }
        case FETCH_DROPDOWNS:
            return { ...state, dropdowns: action.dropdowns }
        case ADD_SETTING:
            let settings = state.settings
            settings[action.reference].push(action.setting_to_add)
            return { ...state, settings }
        case IS_LOADING:
            return { ...state, isLoading: action.set }
        case DELETE_ROW:
            let current_settings = state.settings
            let modified_settings = []
            _.forEach(current_settings[action.reference], (setting) => {
                if (setting.id !== action.id) {
                    modified_settings.push(setting)
                }
            })
            current_settings[action.reference] = modified_settings
            return { ...state, settings: current_settings }
        case CHANGE_DROPDOWN:
            let current_dropdown = state.settings
            let selected_dropdown = current_dropdown[action.reference]
            let key = _.findKey(selected_dropdown, ['id', action.id])
            selected_dropdown[key][action.label] = action.value
            current_dropdown[action.reference] = selected_dropdown

            return { ...state, settings: current_dropdown }
        case COMPANY_SEARCH:
            return { ...state, company_search: action.results }
        case SELECT_COMPANY:
            let current_company = state.settings
            let selected_company = current_company['notification_letter']
            let company_key = _.findKey(selected_company, ['id', action.id])
            selected_company[company_key]['CLI_ID'] = action.cli_id
            selected_company[company_key]['COMPANY'] = action.company
            current_company['notification_letter'] = selected_company

            return { ...state, settings: current_company }
        case CHANGE_CERT_SPOD:
            state.settings.cert_spod.value = action.value
            return { ...state }
        case CHANGE_CERT_SPOD_OPTION:
            state.settings.cert_spod.option = action.value
            return { ...state }
        default:
            return state
    }
}
