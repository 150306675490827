import formatDate from '../../utils/formatDate'
import {
    CHANGE_SNOOZED_CHECKED,
    SNOOZE_FOLLOW_UP_CHANGE,
    SNOOZE_MODAL,
    SNOOZE_PROJECT,
} from './action'

export default function SnoozeModalReducer(
    state = { show: false, info: {}, follow_up: formatDate(new Date()) },
    action
) {
    switch (action.type) {
        case SNOOZE_MODAL:
            return { ...state, show: action.open, info: {} }
        case SNOOZE_PROJECT:
            return { ...state, info: action.open_service }
        case SNOOZE_FOLLOW_UP_CHANGE:
            return { ...state, follow_up: action.follow_up }
        case CHANGE_SNOOZED_CHECKED:
            state.info.open_services[action.key].checked = action.checked
            return { ...state }
        default:
            return state
    }
}
