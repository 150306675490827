import { useQuery } from '@tanstack/react-query'
import { mergeConfigs } from '../../../../utils'
import axios from '../Context/AxiosSetup'

// Used for attorney and firm related APIs
export class Attorney {
    static keys = {
        search: (search) => ['attorneySearch', search],
        allFirms: ['firms'],
        firm: (firmNo) => [...Attorney.keys.allFirms, firmNo],
        attorneysForFirm: (firm) => ['attorneysForFirm', firm],
    }

    // Searches attorneys and firms
    static search = (search) =>
        axios
            .post('/attorney/management/1/search/json', { search })
            .then((response) => response.data)

    static getAllFirms = () => {
        return axios.get('/attorney/management/1/all/json').then((response) => response.data)
    }
    static getFirm = (firmNo) => {
        return axios.get(`/attorney/management/1/${firmNo}/json`).then((response) => response.data)
    }

    static getAttorneysForFirm = (firmNo) => {
        return axios
            .get(`/attorney/management/1/attorneys/${firmNo}/json`)
            .then((response) => response.data)
    }
}

export const useFirmAndAttorneySearch = (search, config) => {
    return useQuery(
        Attorney.keys.search(search),
        () => Attorney.search(search),
        mergeConfigs(
            {
                enabled: !!search,
                staleTime: 60 * 60 * 1000,
                refetchOnMount: false,
            },
            config
        )
    )
}

export const useFirms = (config) => {
    return useQuery(
        Attorney.keys.allFirms,
        Attorney.getAllFirms,
        mergeConfigs(
            {
                staleTime: 60 * 60 * 1000,
                refetchOnMount: false,
            },
            config
        )
    )
}

export const useFirm = (firmNo, config) => {
    return useQuery(
        Attorney.keys.firm(firmNo),
        Attorney.getFirm,
        mergeConfigs(
            {
                enabled: !!firmNo,
                staleTime: 60 * 60 * 1000,
                refetchOnMount: false,
            },
            config
        )
    )
}

export const useAttorneysByFirm = (firmNo, config) => {
    return useQuery(
        Attorney.keys.attorneysForFirm(firmNo),
        () => Attorney.getAttorneysForFirm(firmNo),
        mergeConfigs(
            {
                enabled: !!firmNo,
                staleTime: 24 * 60 * 60 * 1000,
                refetchOnMount: false,
            },
            config
        )
    )
}
