import { Col, Row } from 'react-bootstrap'
import { PrintDate } from '../../components/PrintDate'
import { Recipients } from '../../components/Recipients'
import { usePacketDraftContext } from '../PacketWizard'

export default function OptionsPage({}) {
    const { minMailDate, updateMinMailDate, recipients, updateRecipientPostage, selectRecipient } =
        usePacketDraftContext()

    return (
        <Row className='mt-3'>
            <Col sm={'auto'} className='flex-grow-1'>
                <Recipients
                    recipients={recipients}
                    updateRecipientPostage={updateRecipientPostage}
                    selectRecipient={selectRecipient}
                />
            </Col>
            <Col sm={'auto'}>
                <PrintDate value={minMailDate} onChange={updateMinMailDate} />
            </Col>
        </Row>
    )
}
