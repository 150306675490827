import React, { useState, useEffect } from 'react'

import axios from '../../../axios'
const ROOT_URL = '/attorney/management/1'
import { useRouter } from 'next/router'

export const default_attorney = {
    id: 0,
    attorney_key: '',
    firm_no: '',
    act_flag: 0,
    pref_flag: 0,
    states_handled_1: '',
    states_handled_2: '',
    states_handled_3: '',
    states_handled_4: '',
    states_handled_5: '',
    states_handled_6: '',

    firstName: 'New',
    lastName: 'Attorney',
    address_one: '',
    address_two: '',
    city: '',
    state: '',
    zip: '',

    area_code: '',
    phone_one: '',
    phone_two: '',
    extension: '',
    fax_area: '',
    fax_one: '',
    fax_two: '',
    fax_extension: '',
    toll_free_area_code: '',
    toll_free_one: '',
    toll_free_two: '',
    toll_free_extension: '',
    mobile_area_code: '',
    mobile_one: '',
    mobile_two: '',

    salutation: '',
    title: '',
    e_mail: '',
    cc_email: '',
    cc_email_two: '',
    cc_email_three: '',

    note: '',
    active_attorney: '1',
    con_fee: '',
    con_suit_fee: '',
    avg_cost: 0.0,

    clla_rates: 0,
    ncsf: 0,
    default_hourly: 0.0,
}

export const AttorneyMaintenanceContext = React.createContext({})

function AttorneyMaintenanceContextProvider(props) {
    const router = useRouter()
    const [isLoading, setIsLoading] = useState(false)
    const [attorneyLoading, setAttorneyLoading] = useState(true)
    const [emailGroupLoading, setEmailGroupLoading] = useState(true)

    const [isSaving, setIsSaving] = useState(false)
    const [searchResults, setSearchResults] = useState(null)
    const [selectedFirm, setSelectedFirm] = useState({})
    const [attorneys, setAttorneys] = useState([])
    const [emailGroups, setEmailGroups] = useState([])
    const [searchVal, setSearchVal] = useState('')

    const [attorneyDetailLoading, setAttorneyDetailLoading] = useState(true)
    const [selectedAttorney, setSelectedAttorney] = useState({})
    const [selectedAttorneyDetails, setSelectedAttorneyDetails] = useState({})

    useEffect(() => {
        const { firm_no } = router.query
        if (router.pathname.includes('/firm')) {
            // console.log('check for firm info')
            firm_no && selectedFirm?.firm_no && findFirm(firm_no)
        }
        if (router.pathname.includes('/edit')) {
            // console.log('check for attorney info',firm_no)
            firm_no && attorneys?.length === 0 && findAttorneys(firm_no)
        }
    }, [router])

    function search() {
        setIsLoading(true)
        axios.post(`${ROOT_URL}/search/json`, { search: searchVal }).then(({ data }) => {
            setSearchResults(data)
            setIsLoading(false)
        })
    }

    function findFirm(firm) {
        setIsLoading(true)
        axios.get(`${ROOT_URL}/${firm}/json`).then(({ data }) => {
            // console.log('finding firm',data)
            setSelectedFirm({ ...data })
            setIsLoading(false)
        })
    }

    function findAttorneys(firm) {
        setAttorneyLoading(true)
        axios.get(`${ROOT_URL}/attorneys/${firm}/json`).then(({ data }) => {
            setAttorneys([...data])
            setAttorneyLoading(false)
        })
    }

    function findEmailGroupRecords(firm) {
        setEmailGroupLoading(true)
        axios.get(`${ROOT_URL}/email_groups/${firm}/json`).then(({ data }) => {
            setEmailGroups([...data])
            setEmailGroupLoading(false)
        })
    }

    function saveFirm(firm) {
        setIsSaving(true)
        axios.post(`${ROOT_URL}/firm/json`, firm).then(({ data }) => {
            setSelectedFirm({ ...data })
            setIsSaving(false)
        })
    }

    function saveAttorney(attorney) {
        setIsSaving(true)
        axios.post(`${ROOT_URL}/save/attorney/json`, attorney).then(({ data }) => {
            setAttorneys([...data])
            setIsSaving(false)
        })
    }

    function findAttorneyDetails(attorney_id) {
        setAttorneyDetailLoading(true)
        axios.get(`${ROOT_URL}/attorney/${attorney_id}/details/json`).then(({ data }) => {
            setSelectedAttorneyDetails({
                rate_changes: data.rate_changes,
                cases: data.cases_handled,
            })
            setAttorneyDetailLoading(false)
        })
    }

    function clearSearch() {
        setSearchVal('')
        setSearchResults(null)
    }

    return (
        <AttorneyMaintenanceContext.Provider
            value={{
                isLoading,
                setIsLoading,

                isSaving,
                setIsSaving,

                searchResults,
                search,
                searchVal,
                setSearchVal,
                clearSearch,

                selectedFirm,
                setSelectedFirm,
                findFirm,
                saveFirm,
                emailGroupLoading,
                findEmailGroupRecords,
                emailGroups,
                setEmailGroups,

                attorneyLoading,
                setAttorneys,
                findAttorneys,
                saveAttorney,
                attorneys,
                setAttorneyLoading,
                attorneyDetailLoading,
                selectedAttorney,
                setSelectedAttorney,
                selectedAttorneyDetails,
                setSelectedAttorneyDetails,
                findAttorneyDetails,
            }}
        >
            {props.children}
        </AttorneyMaintenanceContext.Provider>
    )
}

export default AttorneyMaintenanceContextProvider
