import Link from 'next/link'
import React from 'react'

// Decides whether to wrap in a Next.Link component or just traditional <a> tag (or other component if specified with 'as' prop)
export default function Anchor({ as: Component = 'a', href, children, ...props }) {
    // If internal link or object (design for Next href), use Next.Link
    if (typeof href === 'object' || String(href).startsWith('/')) {
        return (
            <Link href={href} passHref {...props}>
                <Component {...props}>{children}</Component>
            </Link>
        )
    }
    // Otherwise use specified component (defaults to <a> tag)
    // Note: external links automatically apply target=_blank to open in new tab
    return (
        <Component {...props} href={href} target={href?.startsWith('http') ? '_blank' : null}>
            {children}
        </Component>
    )
}
