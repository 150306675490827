// THIS IS NOT A REAL COMPONENT
// This is a template to build other generic components

import React, { Component } from 'react'

export default class GenericComponent extends Component {
    static defaultProps = {
        className: '',
        id: null,
        style: {},
    }

    render() {
        const { id, className, style, children } = this.props

        return (
            <div className={`${className}`} id={id} style={{ ...style }}>
                {children}
            </div>
        )
    }
}
