import { useScript } from 'hooks'
import { useEffect } from 'react'

var infoWindow = null
var mapFeatures = []

export default function ParcelMapScript({ map, googleMapLoaded }) {
    const parcelScriptLoaded = useScript(
        'https://reportallusa.com/overlay/js.php?v=5&map=Google&client=5GZ1vfMRoT'
    )

    useEffect(() => {
        if (googleMapLoaded && parcelScriptLoaded)
            REP.Layer.Google.Initialize(map, { Return_Buildings: true })
    }, [googleMapLoaded, parcelScriptLoaded])

    useEffect(() => {
        if (googleMapLoaded) {
            google.maps.event.addListener(map, 'click', function (event) {
                if (map.getZoom() < REP.Layer.Google.MIN_ZOOM) return

                // Close any previous InfoWindow and hide any previous features.
                if (infoWindow !== null) infoWindow.close()
                infoWindow = null
                for (var i = 0; i < mapFeatures.length; i++) mapFeatures[i].setMap(null)
                mapFeatures = []

                var latLng = event.latLng

                REP.Layer.Google.IdentifyByPoint(
                    map,
                    latLng,
                    function (resp) {
                        var wText = ''
                        if (resp.results.length) {
                            var respRow0 = resp.results[0]
                            for (var respKey in respRow0) {
                                var respVal = respRow0[respKey]
                                if (respVal === null) continue
                                if (respKey === 'geom') {
                                    // Add parcel geometry (possibly multiple if multipart) to map.
                                    for (var i = 0; i < respVal.length; i++) {
                                        respVal[i].setOptions({
                                            fillColor: 'rgb(144,238,144)',
                                            strokeColor: 'rgb(200,0,0)',
                                        })
                                        respVal[i].setMap(map)
                                        mapFeatures.push(respVal[i])
                                    }
                                } else if (respKey === 'buildings_poly') {
                                    // Iterate through each building record.
                                    for (
                                        var bldgRecIdx = 0;
                                        bldgRecIdx < respVal.length;
                                        bldgRecIdx++
                                    ) {
                                        var bldgRec = respVal[bldgRecIdx]
                                        if (
                                            typeof bldgRec['geom'] === 'undefined' ||
                                            bldgRec['geom'] === null
                                        )
                                            continue
                                        var bldgRecGeoms = bldgRec['geom']
                                        // Add each building geometry to map.
                                        for (var i = 0; i < bldgRecGeoms.length; i++) {
                                            bldgRecGeoms[i].setOptions({
                                                strokeColor: 'rgb(255,128,255)',
                                                fillOpacity: 0.0,
                                                clickable: false,
                                            })
                                            bldgRecGeoms[i].setMap(map)
                                            mapFeatures.push(bldgRecGeoms[i])
                                        }
                                    }
                                } else {
                                    if (wText !== '') wText += '\n<br>'
                                    wText += respKey + ': ' + respVal
                                }
                            }
                            infoWindow = new google.maps.InfoWindow({
                                position: latLng,
                                content: formatParcelInfo(wText),
                            })
                            infoWindow.open(map)
                        }
                    },
                    function (errObj) {
                        alert('REP Overlays error: ' + errObj.message)
                    }
                )
            })
        }
    }, [map, googleMapLoaded])

    return null
}

function formatParcelInfo(htmlString) {
    // Split the HTML string into lines
    var lines = htmlString.split('<br>')

    // Create the table element
    var table = document.createElement('table')
    table.style.borderCollapse = 'collapse'

    // Iterate over each line
    for (var i = 0; i < lines.length; i++) {
        var line = lines[i].trim()

        // Split the line at the colon
        var parts = line.split(':')

        // Ensure that the line contains a colon
        if (parts.length === 2) {
            var key = parts[0].trim()
            var value = parts[1].trim()

            // Convert underscores to spaces and capitalize each word
            var words = key.split('_')
            for (var j = 0; j < words.length; j++) {
                words[j] = words[j].charAt(0).toUpperCase() + words[j].slice(1)
            }
            var formattedKey = words.join(' ')

            // Create a new table row
            var row = table.insertRow()

            // Insert the key in the first column
            var keyCell = row.insertCell()
            keyCell.innerHTML = formattedKey
            keyCell.style.fontWeight = 'bold'
            keyCell.style.textAlign = 'right'
            keyCell.style.padding = '4px'

            // Insert the value in the second column
            var valueCell = row.insertCell()
            valueCell.innerHTML = value
            valueCell.style.padding = '4px'
        }
    }

    return table.outerHTML
}
