// Calls callback after timeout ends but restarts timeout each time
// function is called
const debounce = (callback, duration = 500) => {
    let timer
    return function (...args) {
        const context = this
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => {
            timer = null
            callback.apply(context, args)
        }, duration)
    }
}

export default debounce
