import { useContext, useState } from 'react'
import Badge from '../../../../front/components/Badge'
import Table from '../../../../front/components/Table'
import { PortalContext } from '../../../../GlobalState'
import { Col, Row } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import Collapse from 'react-bootstrap/Collapse'
import { Button } from 'front'

export default function SideBySide({ changes, changesFirst, sectionFilter = 'all' }) {
    const { theme } = useContext(PortalContext)
    let icons = {
        project: 'folder',
        associates: 'user',
        date: 'calendar',
        service: 'clipboard-list',
    }
    let openAccordion = []
    if (changes?.length > 0) {
        changes = changes?.filter((section) => {
            if (
                (sectionFilter == 'all' || sectionFilter == section.section) &&
                section.change_type != 'none'
            ) {
                if (section.section == 'project') {
                    openAccordion.push(section.label + section.id)
                }
                return true
            }
        })
    }
    // console.log('changes: ', changes)

    return (
        <div className='changes-popup-body'>
            <Row>
                <Accordion defaultActiveKey={openAccordion} alwaysOpen>
                    {changes?.map((section, i) => {
                        const displayChanges =
                            section.change_type != 'none' && filterChanges(section?.fields)

                        return (
                            <>
                                <Accordion.Item eventKey={section.label + section.id}>
                                    {' '}
                                    <Accordion.Header>
                                        <Row key={`section-${i}`}>
                                            <Col className='pt-4 pb-2'>
                                                <span className='popup-section-title text-dark'>
                                                    {String(section.label)?.toUpperCase()}
                                                </span>
                                                {section.change_type == 'delete' && (
                                                    <Badge
                                                        bg='danger'
                                                        soft={theme == 'dark'}
                                                        className='changes-popup-field-value old ms-2'
                                                    >
                                                        REMOVED
                                                    </Badge>
                                                )}
                                                {section.change_type == 'add' && (
                                                    <Badge
                                                        bg='success'
                                                        soft={theme == 'dark'}
                                                        className='changes-popup-field-value new ms-2'
                                                    >
                                                        NEW
                                                    </Badge>
                                                )}
                                                {icons[section?.section] && (
                                                    <span
                                                        className={`fas fa-${
                                                            icons[section?.section]
                                                        } ms-2`}
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                    </Accordion.Header>
                                    <Accordion.Body className='mb-5'>
                                        <div className='d-flex'>
                                            {section.change_type != 'add' && (
                                                <>
                                                    <Changes
                                                        items={displayChanges?.oldItems}
                                                        changesFirst={changesFirst}
                                                        theme={theme}
                                                    />
                                                    <div className='flex-shrink-1 d-flex justify-content-center align-items-center'>
                                                        <span className='fa-solid fa-right fa-xl' />
                                                    </div>
                                                </>
                                            )}
                                            <Changes
                                                items={displayChanges?.newItems}
                                                changesFirst={changesFirst}
                                                add
                                                theme={theme}
                                            />
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </>
                        )
                    })}
                </Accordion>
            </Row>
        </div>
    )
}

function filterChanges(fields) {
    let newItems = []
    let oldItems = []

    fields.map((field, i) => {
        if (field?.change_type !== 'none') {
            newItems.push({ label: field?.label, value: field?.new_display })
            oldItems.push({ label: field?.label, value: field?.old_display })
        }
    })

    return { newItems, oldItems }
}

function OldChanges(props) {
    const [open, setOpen] = useState(false)
    return (
        <>
            <div className='h-100'>
                <Collapse in={open} dimension='width'>
                    <div id='example-collapse-text'>
                        <Changes {...props} />
                    </div>
                </Collapse>
            </div>
            <Button
                onClick={() => setOpen(!open)}
                aria-controls='example-collapse-text'
                aria-expanded={open}
                className={'bg-body me-2 p-1 ' + (open ? 'ms-2' : '')}
            >
                <span className={'far fa-chevron-' + (open ? 'left' : 'right')} />
            </Button>
        </>
    )
}

function Changes({ items, add = false, theme = 'light' }) {
    return (
        <div className={'d-flex flex-wrap gap-3 flex-fill ' + (add ? 'ms-2' : '')}>
            {items?.map(
                (item) =>
                    item?.value?.length > 0 && (
                        <div key={item?.name}>
                            <div>{item?.label}</div>
                            <div>
                                <Badge
                                    soft={theme == 'dark'}
                                    bg={add ? 'success' : 'danger'}
                                    className={(add ? 'new' : 'old') + ' changes-popup-field-value'}
                                >
                                    {item?.value}
                                </Badge>
                            </div>
                        </div>
                    )
            )}
        </div>
    )
}
