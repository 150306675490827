import Head from 'next/head'

export default function MaintenanceLayout({ children }) {
    return (
        <>
            <Head>
                <title>Tracker Maintenance</title>
            </Head>
            <div className={'p-3 extra-content'}>{children}</div>
        </>
    )
}
