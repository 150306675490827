import { PortalContext } from 'components/GlobalState'
import { isEmpty } from 'components/utils'
import { Badge, Button, Form, Tabs } from 'front'
import { orderBy } from 'lodash'
import { useContext, useEffect, useRef, useState } from 'react'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import NoteTagList from './NoteTagList'
import { StepNotes } from './StepsNotes'
import Accordion from 'react-bootstrap/Accordion'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'

export default function NoteDisplay({ show, associate, task, tagsSelected = [], setTagsSelected }) {
    const { fileInfo } = useContext(ProjectContext)
    const { notes, dynamic_tags: tags } = fileInfo || { notes: [], dynamic_tags: [] }
    const { employee } = useContext(PortalContext)
    const [searchNotes, setSearchNotes] = useState('')
    const searchRef = useRef(null)
    const [activeTab, setActiveTab] = useState('all')

    useEffect(() => {
        show && searchRef?.current?.focus()

        setSelectedTags()
    }, [show])

    function setSelectedTags() {
        if (associate) {
            if (associate?._id > 0) {
                setTagsSelected([tags?.find((tag) => tag?.associate?.id == associate?._id)])
            }
        } else if (task) {
            setTagsSelected([tags?.find((tag) => tag?.task?.id == task?.id)])
        }
    }

    function changeTagSelected(tag) {
        const inList = tagsSelected?.find((t) => t.value === tag.value)
        if (inList) {
            setTagsSelected((current) => current?.filter((t) => t.value !== tag.value))
        } else {
            setTagsSelected((current) => [...current, tag])
        }
    }

    const searchedNotes =
        searchNotes?.length === 0 && tagsSelected?.length === 0
            ? notes
            : notes?.filter(
                  (note) =>
                      (note.text.toLowerCase().includes(searchNotes.toLowerCase()) ||
                          note.name.toLowerCase().includes(searchNotes.toLowerCase())) &&
                      (tagsSelected?.length > 0
                          ? tagsSelected?.filter((tag) => {
                                if (tag?.value == 'untagged') {
                                    return isEmpty(note?.tags)
                                } else {
                                    return note?.tags?.[tag?.value]
                                }
                            })?.length > 0
                          : true)
              )

    const urgentNotes = searchedNotes?.filter(
        (note) => note?.need_confirmed == 1 && note?.confirmed == 0
    )
    const pinnedNotes = searchedNotes?.filter((note) => note?.tags?.pinned)
    const assignedNotes = searchedNotes?.filter((note) => note?.assigned_emp_id == employee?.EmpID)

    let displayNotes
    switch (activeTab) {
        case 'urgent':
            displayNotes = urgentNotes
            break
        case 'pinned':
            displayNotes = pinnedNotes
            break
        case 'assigned':
            displayNotes = assignedNotes
            break
        default:
            displayNotes = searchedNotes
            break
    }
    let topNotes = []
    let bottomNotes = []
    // const archivedNoteToClient = displayNotes?.filter((note) => note?.tags?.archived_note2client)
    displayNotes
        ?.filter((note) => !note?.tags?.archived_note2client)
        ?.map((note) => {
            if ((note?.need_confirmed && !note?.confirmed) || note?.is_pinned) {
                topNotes.push(note)
            } else {
                bottomNotes.push(note)
            }
        })
    topNotes = orderBy(topNotes, ['is_pinned', 'need_confirmed'])
    // console.log('displayNotes: ', [...topNotes, ...bottomNotes])

    return (
        <>
            <div className={'row sticky-top bg-white'} style={{ marginBottom: '1.25rem' }}>
                {/* <div className='col-12'>
                    <ToggleButtonGroup
                        name='showAddressesToggle'
                        value={showClientNotes}
                        type='radio'
                        className='btn-group-segment w-100 mb-3'
                    >
                        <ToggleButton
                            value={false}
                            variant=''
                            onClick={() => setShowClientNotes(false)}
                        >
                            Project
                        </ToggleButton>
                        <ToggleButton
                            value={true}
                            variant=''
                            onClick={() => setShowClientNotes(true)}
                        >
                            Client
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div> */}

                <Accordion alwaysOpen>
                    <div className='text-center'>
                        <FilterNotesBtn variant='link' eventKey={'0'}>
                            Filter Notes
                        </FilterNotesBtn>
                    </div>
                    <Accordion.Collapse eventKey='0'>
                        <div
                            className={'col-12 justify-content-between align-items-end d-flex mb-3'}
                        >
                            <div className='flex-fill pe-3'>
                                <Form.Label>Search Notes</Form.Label>
                                <Form.InputGroup>
                                    <Form.Control
                                        ref={searchRef}
                                        placeholder={'Search notes'}
                                        value={searchNotes}
                                        onChange={({ target }) => {
                                            setSearchNotes(target.value)
                                        }}
                                        ignoreGlobalDisable
                                    />
                                    {searchNotes?.length > 0 && (
                                        <Button
                                            className='btn btn-outline-secondary'
                                            onClick={() => {
                                                setSearchNotes('')
                                            }}
                                        >
                                            <span className={'far fa-times'} />
                                        </Button>
                                    )}
                                </Form.InputGroup>
                            </div>
                            <div className='flex-fill'>
                                {tags?.length > 0 && (
                                    <>
                                        <Form.Label>Filter By Tag</Form.Label>
                                        <NoteTagList
                                            search
                                            tags={tags}
                                            tagsSelected={tagsSelected}
                                            changeTagSelected={changeTagSelected}
                                            setTagsSelected={setTagsSelected}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </Accordion.Collapse>
                </Accordion>

                <div className={'col-6 text-end'}></div>
                {/* {tagsSelected?.length > 0 && (
                    <TagsList tags={tagsSelected} setTagsSelected={setTagsSelected} />
                )} */}

                {(urgentNotes?.length > 0 ||
                    pinnedNotes?.length > 0 ||
                    assignedNotes?.length > 0) && (
                    <Tabs
                        fill
                        activeTab={activeTab}
                        onSelect={(tab) => {
                            setActiveTab(tab)
                        }}
                    >
                        <Tabs.Tab
                            eventKey='all'
                            title={
                                <div className='d-flex justify-content-center align-items-center'>
                                    All{' '}
                                    {searchedNotes?.length > 0 && (
                                        <Badge
                                            className='ms-1'
                                            bg={activeTab === 'all' ? 'primary' : 'secondary'}
                                            soft={activeTab !== 'all'}
                                        >
                                            {searchedNotes?.length}
                                        </Badge>
                                    )}
                                </div>
                            }
                        />
                        {urgentNotes?.length > 0 && (
                            <Tabs.Tab
                                eventKey='urgent'
                                title={
                                    <div className='d-flex justify-content-center align-items-center'>
                                        Urgent
                                        <Badge
                                            className='ms-1'
                                            bg={activeTab === 'urgent' ? 'primary' : 'secondary'}
                                            soft={activeTab !== 'urgent'}
                                        >
                                            {urgentNotes?.length}
                                        </Badge>
                                    </div>
                                }
                            />
                        )}
                        {pinnedNotes?.length > 0 && (
                            <Tabs.Tab
                                eventKey='pinned'
                                title={
                                    <div className='d-flex justify-content-center align-items-center'>
                                        Pinned
                                        <Badge
                                            className='ms-1'
                                            bg={activeTab === 'pinned' ? 'primary' : 'secondary'}
                                            soft={activeTab !== 'pinned'}
                                        >
                                            {pinnedNotes?.length}
                                        </Badge>
                                    </div>
                                }
                            />
                        )}
                        {assignedNotes?.length > 0 && (
                            <Tabs.Tab
                                eventKey='assigned'
                                title={
                                    <div className='d-flex justify-content-center align-items-center'>
                                        Assigned
                                        <Badge
                                            className='ms-1'
                                            bg={activeTab === 'assigned' ? 'primary' : 'secondary'}
                                            soft={activeTab !== 'assigned'}
                                        >
                                            {assignedNotes?.length}
                                        </Badge>
                                    </div>
                                }
                            />
                        )}
                    </Tabs>
                )}
            </div>
            <StepNotes notes={[...topNotes, ...bottomNotes]} searchText={searchNotes} />
        </>
    )
}

function FilterNotesBtn({ eventKey, children, ...props }) {
    const onClick = useAccordionButton(eventKey, () => console.log('totally custom!'))

    return (
        <Button {...props} onClick={onClick}>
            {children}
        </Button>
    )
}
