import { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import { useCreatePayable } from 'tracker/api'
import PayableForm from '../PayableForm'

const emptyPayable = {
    fee_amount: 0,
    vendor_number: null,
    project_id: null,
    invoice_number: null,
    invoice_date: null,
    line_items: [
        {
            id: Math.random() * -1,
            item_fee_amount: 0,
            task_id: null,
        },
    ],
}

export default function NewPayableModal({ show, onHide, defaultValues = {} }) {
    const { projectId } = useContext(ProjectContext)
    const { mutate: createPayable, ...createPayableApi } = useCreatePayable(
        { projectId },
        {
            onSuccess: onHide,
        }
    )
    const [payable, setPayable] = useState({
        ...emptyPayable,
        project_id: projectId,
        ...defaultValues,
    })

    const onChange = (update) =>
        setPayable({
            ...payable,
            ...update,
        })

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Add Payable</Modal.Title>
            </Modal.Header>
            <PayableForm
                projectId={projectId}
                payable={payable}
                onChange={onChange}
                saveLabel='Add Payable'
                onSave={() => createPayable(payable)}
                saveLoading={createPayableApi.isLoading}
                onCancel={onHide}
            />
        </Modal>
    )
}
