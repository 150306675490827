import { verifyFunctionThenCall } from './miscUtils'

// Config2 will override any optiosn from config1, but callbacks will both execute
export function mergeConfigs(config1, config2) {
    let onSuccess2 = config2?.onSuccess
    let onSettled2 = config2?.onSettled
    let onError2 = config2?.onError
    let onMutate2 = config2?.onMutate

    delete config2?.onSuccess
    delete config2?.onSettled
    delete config2?.onError
    delete config2?.onMutate

    return {
        ...config1,
        ...config2,
        onSuccess: (...args) => {
            verifyFunctionThenCall(config1?.onSuccess, ...args)
            verifyFunctionThenCall(onSuccess2, ...args)
        },
        onSettled: (...args) => {
            verifyFunctionThenCall(config1?.onSettled, ...args)
            verifyFunctionThenCall(onSettled2, ...args)
        },
        onError: (...args) => {
            verifyFunctionThenCall(config1?.onError, ...args)
            verifyFunctionThenCall(onError2, ...args)
        },
        onMutate: (...args) => {
            verifyFunctionThenCall(config1?.onMutate, ...args)
            verifyFunctionThenCall(onMutate2, ...args)
        },
    }
}
