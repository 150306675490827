import React, { Component } from 'react'

export default class Overlay extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { children, viewWidth, position } = this.props
        const colWidth = 25 / 3

        let width = (12 / viewWidth) * 100
        let pos = position * (width / -12)

        return (
            <div className='row' style={{ overflowX: 'hidden', width: '104%' }}>
                {/* <div className="row" > */}
                <div className={'col'}>
                    <div style={{ width: width + '%', transition: '1s', marginLeft: pos + '%' }}>
                        {children}
                    </div>
                </div>
            </div>
        )
    }
}
