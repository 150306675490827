import axios from '../../../axios'
const CancelToken = axios.CancelToken
let cancel

export const SEARCH = 'search'
export function search(searchState) {
    if (cancel) {
        cancel()
    }
    const params = {
        start_date: searchState.start,
        end_date: searchState.end,
        search: searchState.search,
        csg_hits: searchState.csg,
        ucc_hits: searchState.ucc,
        client_hits: searchState.client,
        user: searchState.user,
        business: searchState.business,
    }

    return (dispatch) => {
        dispatch(loading(true))
        axios
            .get('/portal/bkdata/1/json', {
                params,
                cancelToken: new CancelToken(function executor(c) {
                    cancel = c
                }),
            })
            .then(({ data }) => {
                dispatch({
                    type: SEARCH,
                    data,
                })
                dispatch(loading(false))
            })
            .catch(function (thrown) {
                if (axios.isCancel(thrown)) {
                    //console.log('Request canceled', thrown);
                }
            })
    }
}

export const LOADING = 'loading'
export function loading(loading) {
    return {
        type: LOADING,
        loading,
    }
}

export const CHANGE_SELECTED = 'change_selected'
export function changeSelected(id) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_SELECTED,
            id,
        })
    }
}
