import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { LoadingSpinner, formatDate } from 'components/utils'
import { Badge, Button, Dropdown, DropdownButton, Form, Nav, Offcanvas, Tabs } from 'front'
import { useDebounce } from 'hooks'
import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import ProjectLookupModal from './ProjectLookupModal'
import { ButtonGroup, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import { useSearchFilings } from 'tracker/api/recordedFilings.api'
import Search from '@/components/clientMaintenance/components/search'
import SearchResult from './SearchResult'
import { toTitleCase } from 'utils'
import SortableTable from '@/components/SortableTable'

export default function SearchModal({ show, onHide }) {
    const [tab, setTab] = useState('project')
    const [queueSearchText, setQueueSearchText] = useState('')
    const [projectSearchText, setProjectSearchText] = useState('')
    const [olsSearchText, setOlsSearchText] = useState('')
    const [filingSearchText, setFilingSearchText] = useState('')

    const searchRef = useRef(null)

    useEffect(() => {
        if (show) {
            setTab('project')
            searchRef?.current?.focus()
        }
    }, [show])

    function changeTab(tab) {
        setTab(tab)
        // searchRef?.current?.focus()
        // setSearchText(tab === 'queue' ? queueText : tab === 'project' ? projectText : olsText)
    }

    return (
        <Modal show={show} onHide={onHide} size={'xl'}>
            <Modal.Header closeButton>
                <Modal.Title>Search</Modal.Title>
            </Modal.Header>
            <Modal.Body className='pt-3'>
                <ToggleButtonGroup
                    name='showProjectSearch'
                    value={tab}
                    type='radio'
                    className='btn-group-segment w-100 my-2'
                    size='sm'
                >
                    <ToggleButton
                        value={'project'}
                        variant=''
                        onClick={() => {
                            changeTab('project')
                        }}
                    >
                        Project #
                    </ToggleButton>
                    {/* <ToggleButton
                        value={'queue'}
                        variant=''
                        onClick={() => {
                            changeTab('queue')
                        }}
                    >
                        Queue
                    </ToggleButton> */}
                    <ToggleButton
                        value={'general'}
                        variant=''
                        onClick={() => {
                            changeTab('general')
                        }}
                    >
                        OLS
                    </ToggleButton>
                    <ToggleButton
                        value={'filing'}
                        variant=''
                        onClick={() => {
                            changeTab('filing')
                        }}
                    >
                        Filing #
                    </ToggleButton>
                </ToggleButtonGroup>

                <div>
                    <ProjectLookupModal
                        show={show && tab === 'project'}
                        onHide={onHide}
                        searchText={projectSearchText}
                        setSearchText={setProjectSearchText}
                    />
                    {/* <QueueSearch
                        show={show && tab === 'queue'}
                        onHide={onHide}
                        searchText={queueSearchText}
                        setSearchText={setQueueSearchText}
                    /> */}
                    <OlsSearch
                        show={show && tab === 'general'}
                        onHide={onHide}
                        searchText={olsSearchText}
                        setSearchText={setOlsSearchText}
                    />
                    <FilingSearch
                        show={show && tab === 'filing'}
                        onHide={onHide}
                        searchText={filingSearchText}
                        setSearchText={setFilingSearchText}
                    />
                </div>
            </Modal.Body>
        </Modal>
    )
}

function QueueSearch({ show, onHide }) {
    const [searchText, setSearchText] = useState('')
    const [projectStatusFilter, setProjectStatusFilter] = useState('all')

    const debounceSearch = useDebounce(searchText, 1000)
    const {
        data: searchResults,
        isLoading,
        isFetching,
    } = useQuery({
        queryKey: ['projectSearchModal' + debounceSearch + projectStatusFilter],
        queryFn: () => search(debounceSearch, projectStatusFilter),
        enabled: !!debounceSearch?.length > 0,
    })

    const searchRef = useRef(null)

    useEffect(() => {
        show && searchRef?.current?.focus()
    }, [show])

    function handleSubmit(e) {
        e.preventDefault()
    }
    function search(search, status) {
        return axios
            .post(`/nml/tracker/1/search/json`, { search, open: status })
            .then(({ data }) => data)
    }

    return (
        show && (
            <form onSubmit={handleSubmit}>
                <Form.InputGroup>
                    <Form.Control
                        ignoreGlobalDisable={true}
                        ref={searchRef}
                        type={'text'}
                        value={searchText}
                        name={'searchProject'}
                        onChange={({ target: { value } }) => {
                            setSearchText(value)
                        }}
                        placeholder={'Search Tracker Queue'}
                        className='fs-2'
                    />
                    <DropdownButton title={toTitleCase(projectStatusFilter)}>
                        <Dropdown.Item onClick={() => setProjectStatusFilter('all')}>
                            All
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setProjectStatusFilter('open')}>
                            Open
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setProjectStatusFilter('closed')}>
                            Closed
                        </Dropdown.Item>
                    </DropdownButton>
                </Form.InputGroup>
                {isLoading && isFetching && (
                    <h3 className='my-3'>
                        <LoadingSpinner /> Searching...
                    </h3>
                )}
                {searchResults?.list?.length > 0 && (
                    <div className='mt-5'>
                        <SearchResult
                            results={searchResults?.list ?? []}
                            onHide={onHide}
                            tab='queue'
                        />
                    </div>
                )}
            </form>
        )
    )
}

function OlsSearch({ show, onHide, searchText, setSearchText }) {
    const debounceSearch = useDebounce(searchText, 1000)
    const [projectStatusFilter, setProjectStatusFilter] = useState('all')

    const {
        data: searchResults,
        isLoading,
        isFetching,
    } = useQuery({
        queryKey: ['olsSearchhModal' + debounceSearch + projectStatusFilter],
        queryFn: () => search(debounceSearch, projectStatusFilter),
        enabled: !!debounceSearch?.length > 0,
    })

    const searchRef = useRef(null)

    useEffect(() => {
        show && searchRef?.current?.focus()
    }, [show])

    function handleSubmit(e) {
        e.preventDefault()
    }
    function search(search, status) {
        let payload = { '*': search }
        if (status !== 'all') {
            payload.close_flag = status === 'open' ? 0 : 1
        }
        return axios
            .post(`/nml/tracker/2/search/elastic/ols/json`, payload)
            .then(({ data }) => data?.projects ?? [])
    }

    return (
        show && (
            <form onSubmit={handleSubmit}>
                <Form.InputGroup>
                    <Form.Control
                        ignoreGlobalDisable={true}
                        ref={searchRef}
                        type={'text'}
                        value={searchText}
                        name={'searchProject'}
                        onChange={({ target: { value } }) => {
                            setSearchText(value)
                        }}
                        placeholder={'OLS Admin Search'}
                        className='fs-2'
                    />
                    <DropdownButton title={toTitleCase(projectStatusFilter)}>
                        <Dropdown.Item onClick={() => setProjectStatusFilter('all')}>
                            All
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setProjectStatusFilter('open')}>
                            Open
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setProjectStatusFilter('closed')}>
                            Closed
                        </Dropdown.Item>
                    </DropdownButton>
                </Form.InputGroup>

                {isLoading && isFetching && (
                    <h3 className='my-3'>
                        <LoadingSpinner /> Searching...
                    </h3>
                )}
                {searchResults?.length > 0 && (
                    <div className='mt-5'>
                        <table className='table table-hover'>
                            <thead className='thead-light'>
                                <tr>
                                    <th>Project</th>
                                    <th className='text-center'>Status</th>
                                    <th>Debtor / Ref #</th>
                                    <th className='text-end'>Type / State</th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchResults?.map((result) => {
                                    return (
                                        <Link
                                            href={`/nml/tracker/file/overview?project_id=${result?.project_id}`}
                                        >
                                            <tr className='cursor-pointer' onClick={onHide}>
                                                <td className=''>
                                                    <div className='text-dark fw-bold'>
                                                        {(result?.ncs_lno?.length > 0 ||
                                                            result?.project_id ||
                                                            result?.direct_no) && (
                                                            <Badge bg='info' className='me-2' pill>
                                                                {result?.ncs_lno
                                                                    ? result?.ncs_lno
                                                                    : result?.project_id
                                                                    ? `D${result?.project_id}`
                                                                    : false ??
                                                                      result?.direct_no ??
                                                                      'IMPORT'}
                                                            </Badge>
                                                        )}
                                                        {result?.project_name ??
                                                            `${result?.project?.substring(0, 50)}`}
                                                    </div>
                                                    <div className='ms-1'>
                                                        {result?.natcr_no} - {result?.company}
                                                    </div>
                                                </td>
                                                <td className='text-center align-middle'>
                                                    <Badge
                                                        bg={
                                                            result?.close_flag
                                                                ? 'secondary'
                                                                : 'success'
                                                        }
                                                        soft={result?.close_flag}
                                                    >
                                                        {result?.close_flag ? 'Closed' : 'Open'}
                                                    </Badge>
                                                </td>
                                                <td className=''>
                                                    <div className='fw-bold'>
                                                        {result?.debtor_name}
                                                    </div>
                                                    <div>{result?.cli_ref_no}</div>
                                                </td>
                                                <td className='text-end'>
                                                    {result?.project_type?.length > 0 && (
                                                        <div className='text-capitalize fw-bold'>
                                                            {result?.project_type}
                                                        </div>
                                                    )}
                                                    {result?.proj_state?.length > 0 && (
                                                        <div className='text-capitalize'>
                                                            {result?.proj_state}
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        </Link>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </form>
        )
    )
}

function FilingSearch({ show, onHide, searchText, setSearchText }) {
    const debounceSearch = useDebounce(searchText, 1000)

    const {
        data: searchResults,
        isLoading,
        isFetching,
    } = useSearchFilings(debounceSearch, {
        enabled: !!debounceSearch?.length > 0,
    })
    const searchRef = useRef(null)

    useEffect(() => {
        show && searchRef?.current?.focus()
    }, [show])

    function handleSubmit(e) {
        e.preventDefault()
    }

    return (
        show && (
            <form onSubmit={handleSubmit}>
                <Form.Control
                    ignoreGlobalDisable={true}
                    ref={searchRef}
                    type={'text'}
                    value={searchText}
                    name={'searchProject'}
                    onChange={({ target: { value } }) => {
                        setSearchText(value)
                    }}
                    placeholder={'Recorded filing #'}
                    className='fs-2'
                />
                {isLoading && isFetching && (
                    <h3 className='my-3'>
                        <LoadingSpinner /> Searching...
                    </h3>
                )}
                {searchResults?.length > 0 && (
                    <div className='mt-5'>
                        <h4>Results</h4>

                        <table className='table table-hover'>
                            <thead className='thead-light'>
                                <tr>
                                    <th>Project</th>
                                    <th className='text-center'>Recording #</th>
                                    <th className='text-center'>Recorded Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchResults?.map((result) => {
                                    return (
                                        <Link
                                            href={`/nml/tracker/file/overview?project_id=${result?.project_id}`}
                                        >
                                            <tr className='cursor-pointer' onClick={onHide}>
                                                <td className=''>
                                                    <div className='text-dark fw-bold'>
                                                        {result?.project_id && (
                                                            <Badge bg='info' className='me-2' pill>
                                                                {result?.project_id}
                                                            </Badge>
                                                        )}
                                                        {result?.project_name}
                                                    </div>
                                                    <div className='ms-1'>{result?.COMPANY}</div>
                                                </td>
                                                <td className='text-center align-middle'>
                                                    <Badge bg='dark' soft>
                                                        {result?.file_number}
                                                    </Badge>
                                                </td>
                                                <td className='text-center align-middle'>
                                                    {result?.recorded_date?.length > 0 && (
                                                        <div className='text-capitalize'>
                                                            {formatDate(result?.recorded_date)}
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        </Link>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </form>
        )
    )
}
