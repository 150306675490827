import { Badge } from 'front'
import { toTitleCase } from 'utils'

export function PacketStatusBadge({ status, ...rest }) {
    let background = 'secondary'
    let soft = true
    let label = toTitleCase(status)

    switch (status) {
        case 'DRAFT':
            label = 'Draft'
            background = 'secondary'
            soft = true
            break
        case 'PENDING REVIEW':
            label = 'Pending Review'
            background = 'info'
            soft = true
            break
        case 'PRINT QUEUED':
            label = 'Queued for Printing'
            background = 'primary'
            soft = true
            break
        case 'PRINT PENDING':
            label = 'Being Printed'
            background = 'primary'
            soft = true
            break
        case 'PRINT COMPLETED':
            label = 'Print Complete'
            background = 'success'
            soft = true
            break
        case 'DELETED':
            label = 'Deleted'
            background = 'danger'
            soft = true
            break
        case 'COMPLETED':
            label = 'Completed Electronic'
            background = 'success'
            soft = true
            break
        default:
            label = 'Draft'
    }

    return (
        <Badge {...rest} soft={soft} bg={background}>
            {label}
        </Badge>
    )
}
