import { useRouter } from 'next/router'
import React, { useContext, useState } from 'react'
import axios from '../../../axios'
import { ProjectContext } from './ProjectContext'

const ROOT_URL = '/nml/tracker/2'

export const NoticeContext = React.createContext({})
let cancel

function NoticeState({ children }) {
    const router = useRouter()
    const { fileInfo } = useContext(ProjectContext)
    const [noticeLoading, setNoticeLoading] = useState(false)
    const [packetLoading, setPacketLoading] = useState(false)
    const [templateList, setTemplateList] = useState([])
    const [softeningList, setSofteningList] = useState([])
    const [packetList, setPacketList] = useState([])
    const [noticeProjectId, setNoticeProjectId] = useState('')

    function getTemplateList(project_id, serviceId) {
        setNoticeLoading(true)
        axios
            .get(`${ROOT_URL}/template/list/${project_id}/${serviceId}/json`)
            .then(({ data }) => {
                setNoticeLoading(false)
                setTemplateList([...data?.templates])
                setSofteningList([...data?.softening])
            })
            .catch((error) => {
                setNoticeLoading(false)
                console.error('get template list', error)
            })
    }

    function getPacketListForProject(project_id) {
        if (project_id != noticeProjectId && project_id) {
            setPacketLoading(true)
            axios
                .post(`${ROOT_URL}/packet/list/${project_id}/json`)
                .then(({ data }) => {
                    setPacketLoading(false)
                    if (data?.length > 0) {
                        setPacketList([...data])
                        setNoticeProjectId(project_id)
                    }
                })
                .catch((error) => {
                    setPacketLoading(false)
                    console.error('get packet list', error)
                })
        }
    }

    function updatePacket(id, data) {
        setPacketLoading(true)
        axios.patch(`/nml/tracker/2/packet/${id}/json`, data).then(({ data }) => {
            updatePacketList(id, data)
            setPacketLoading(false)
        })
    }

    function getPacket(id) {
        setPacketLoading(true)
        axios
            .get(`${ROOT_URL}/packet/${id}/json`)
            .then(({ data }) => {
                setPacketLoading(false)
                updatePacketList(id, data)
                data?.softening && setSofteningList([...data?.softening])
                data?.templates && setTemplateList([...data?.templates])
            })
            .catch((error) => {
                setPacketLoading(false)
                console.error('get packet', error)
            })
    }

    function updatePacketList(id, update) {
        // console.log('update packet list',id,update,packetList)
        if (!packetList?.find((p) => p.id == id)) {
            setPacketList([...packetList, { ...update }])
        } else {
            setPacketList([
                ...packetList?.map((p) => (p.id == id ? { ...p, ...update } : { ...p })),
            ])
        }
    }

    function createPacket(project_id, task_id, data) {
        setPacketLoading(true)
        return axios
            .post(`/nml/tracker/2/packet/create/${project_id}/${task_id}/json`, data)
            .then(({ data }) => {
                setPacketLoading(false)
                if (data?.id) {
                    updatePacketList(data.id, data)
                    router.push(
                        `/nml/tracker/file/packet/options/?project_id=${project_id}&task_id=${task_id}&packet_id=${data?.id}`
                    )
                }
            })
            .catch((error) => {
                console.log('create packet error', error)
                setPacketLoading(false)
            })
    }

    function mergePacketDocuments(id) {
        axios.post(`/nml/tracker/2/packet/merge/${id}/json`).then(({ data }) => {
            updatePacketList(id, data)
        })
    }

    function deletePacket(id, project_id) {
        setPacketLoading(true)
        axios.post(`/nml/tracker/2/packet/submit/deleted/${id}/json`).then(({ data }) => {
            if (data) {
                setPacketList([...packetList.filter((p) => p.id !== id)])
                setPacketLoading(false)
                router.push(`/nml/tracker/file/tasks?project_id=${project_id}`)
            }
        })
    }

    function changeStatus(id, status, data = {}) {
        setPacketLoading(true)
        axios
            .post(`/nml/tracker/2/packet/submit/${status}/${id}/json`, data)
            .then(({ data }) => {
                setPacketLoading(false)
                if (data) {
                    updatePacketList(id, { ...data })
                }
            })
            .catch((error) => {
                setPacketLoading(false)
                console.error('packet change status error', error)
            })
    }

    return (
        <NoticeContext.Provider
            value={{
                getTemplateList,
                noticeLoading,
                templateList,
                getPacketListForProject,
                packetList,
                packetLoading,
                updatePacket,
                getPacket,
                updatePacketList,
                softeningList,
                createPacket,
                mergePacketDocuments,
                deletePacket,
                changeStatus,
            }}
        >
            {children}
            {/*<TrackerLinkModal/>*/}
        </NoticeContext.Provider>
    )
}

export default NoticeState
