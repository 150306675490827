import classnames from 'classnames'
import React from 'react'
import { Nav as BootstrapNav, NavDropdown as BootstrapNavDropdown } from 'react-bootstrap'
import Anchor from '../../utils/Anchor'

export default function Nav({
    children,
    vertical,
    light,
    className,
    style,
    noBottomBorder,
    ...bsProps
}) {
    return (
        <BootstrapNav
            {...bsProps}
            className={classnames(className, {
                'flex-column nav-vertical': vertical,
                'nav-segment': bsProps?.variant === 'pillbar',
                'nav-light': light,
            })}
            style={{
                ...style,
                borderBottom: noBottomBorder ? 'initial' : null,
            }}
        >
            {children}
        </BootstrapNav>
    )
}

function NavLink({ children, ...bsProps }) {
    return (
        <Anchor as={BootstrapNav.Link} {...bsProps}>
            {children}
        </Anchor>
    )
}

function NavIcon({ icon, className, style }) {
    return <i className={classnames('nav-icon fa-fw', className, icon)} style={style} />
}

// TODO: Incorporate Nav Scroller library from Front Theme into this

Nav.Item = BootstrapNav.Item
Nav.Link = NavLink
Nav.Icon = NavIcon
Nav.Dropdown = BootstrapNavDropdown
