import { Tooltip } from 'components/utils'
import { Badge, CollapsingHeader, Dropdown } from 'front'
import { useContext } from 'react'
import { ButtonGroup } from 'react-bootstrap'
import { usePublishProject, useTakeoverProject } from 'tracker/api'
import { ProjectContext } from '../../Context/ProjectContext'

export default function SaveEditPublishMenu({ setShowChangesOffcanvas, changesCount }) {
    const {
        fileInfo,
        projectId,
        editStatus,
        manuallySaveProject,
        editingEnabled,
        publishDisabled,
    } = useContext(ProjectContext)
    const { mutate: publishProject, ...publishVersionApi } = usePublishProject({ projectId })

    const currentlyEditing = fileInfo?.lock_status?.can_edit && editingEnabled
    const showChangesButton = changesCount > 0 || editingEnabled
    const lockedByOtherEmployee =
        fileInfo?.lock_status?.is_locked && !fileInfo?.lock_status?.is_mine

    return (
        <Tooltip
            content={
                fileInfo?.incoming_changes?.version?.id
                    ? 'Review OLS changes before publishing'
                    : null
            }
            placement='bottom'
        >
            <div className='float-end'>
                <ErrorMenu />
                {/* {!fileInfo?.warnings?.import_error && ( */}
                <ButtonGroup>
                    {currentlyEditing && (
                        <AutosaveStatus
                            editStatus={editStatus}
                            manuallySaveProject={manuallySaveProject}
                            setShowChangesOffcanvas={setShowChangesOffcanvas}
                            changesCount={changesCount}
                        />
                    )}
                    {/* {showChangesButton && (
                    <ChangesButton
                    setShowChangesOffcanvas={setShowChangesOffcanvas}
                    changesCount={changesCount}
                    />
                )} */}
                    {fileInfo &&
                        !editingEnabled &&
                        fileInfo?.project?._workflow === 'PORTAL' &&
                        !(fileInfo?.version?.is_locked && !fileInfo?.version?.is_mine) && (
                            <EditProjectButton />
                        )}
                    {!(!fileInfo?.version?.changes || changesCount === 0) &&
                        fileInfo?.project?._workflow === 'PORTAL' &&
                        fileInfo?.version?.is_mine && (
                            <PublishButton
                                onClick={publishProject}
                                changesCount={changesCount}
                                fileInfo={fileInfo}
                                editStatus={editStatus}
                                loading={publishVersionApi?.isLoading}
                                disabled={publishDisabled}
                            />
                        )}
                </ButtonGroup>
                {/* )} */}
            </div>
        </Tooltip>
    )
}

function AutosaveStatus({
    editStatus,
    manuallySaveProject,
    setShowChangesOffcanvas,
    changesCount,
}) {
    return (
        <CollapsingHeader.Button
            {...getSaveButtonProps(editStatus, changesCount)}
            collapsedSize='xs'
            expandedSize='sm'
            onClick={
                editStatus == 'Saved' || editStatus == 'Synced'
                    ? () => {
                          setShowChangesOffcanvas(true)
                      }
                    : manuallySaveProject
            }
        />
    )
}

function EditProjectButton({ disabled }) {
    // console.log('disabled: ', disabled)
    const { projectId, setEditingEnabled } = useContext(ProjectContext)
    const { mutate: takeoverProject, isLoading } = useTakeoverProject(
        { projectId },
        {
            onSuccess: (data) => {
                // console.log('data: ', data)
                if (data?.success) setEditingEnabled(true)
            },
        }
    )

    return (
        <Tooltip content={disabled ? 'Being edited by another user' : null} placement='bottom'>
            <CollapsingHeader.Button
                collapsedSize='xs'
                expandedSize='sm'
                icon={isLoading ? 'fal fa-spinner-third fa-spin' : 'fas fa-pencil'}
                variant='info'
                disabled={disabled}
                onClick={takeoverProject}
            >
                Edit Project
            </CollapsingHeader.Button>
        </Tooltip>
    )
}

function PublishButton(props) {
    const { fileInfo, publishDisabled } = useContext(ProjectContext)

    return (
        <Tooltip
            content={
                fileInfo?.warnings?.debtor_missing
                    ? 'Please select a debtor before publishing'
                    : fileInfo?.warnings?.contact_missing || fileInfo?.warnings?.contact_error
                    ? 'Please select a contact before publishing'
                    : fileInfo?.incoming_changes?.version?.id
                    ? 'Review OLS changes before publishing'
                    : null
            }
            placement='top'
        >
            <div>
                <CollapsingHeader.Button
                    onClick={props.onClick}
                    loading={props.loading}
                    disabled={
                        props.loading ||
                        props.editStatus == 'Unsaved Changes' ||
                        props.editStatus == 'Saving' ||
                        props.editStatus == 'Editing' ||
                        publishDisabled
                    }
                    collapsedSize='xs'
                    expandedSize='sm'
                    icon='fas fa-cloud-arrow-up'
                    variant='primary'
                    style={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                    }}
                >
                    Publish to OLS
                </CollapsingHeader.Button>
            </div>
        </Tooltip>
    )
}

function ChangesButton(props) {
    return (
        <CollapsingHeader.Button
            collapsedSize='xs'
            expandedSize='sm'
            variant='white'
            onClick={() => {
                props.setShowChangesOffcanvas(true)
            }}
            disabled={props.changesCount === 0}
        >
            <Badge soft bg='secondary' className='me-2'>
                {props.changesCount}
            </Badge>
            Changes
        </CollapsingHeader.Button>
    )
}

function ErrorMenu() {
    const { errorList, fileInfo } = useContext(ProjectContext)
    // Originally checked for import_error and pushed onto error list but with every render it would keep adding the same import error

    if (errorList.length === 0 && !fileInfo?.warnings?.import_error) return null

    return (
        <Dropdown className='me-2 d-inline-block'>
            <Dropdown.Toggle
                as={CollapsingHeader.Button}
                collapsedSize='xs'
                expandedSize='sm'
                icon='fas fa-circle-exclamation'
                variant='danger'
                soft
                animation
            >
                Errors ({errorList.length + (fileInfo?.warnings?.import_error ? 1 : 0)})
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {errorList.map((error, i) => {
                    return <DropdownErrorItem key={i} error={error} />
                })}
                {fileInfo?.warnings?.import_error && (
                    <DropdownErrorItem
                        error={{
                            label: 'Import Error',
                            details: {
                                message:
                                    'There was an issue when importing this project from OLS, please contact IT',
                            },
                        }}
                    />
                )}
            </Dropdown.Menu>
        </Dropdown>
    )
}

function DropdownErrorItem({ error }) {
    return (
        <Dropdown.Item>
            <h5>{error.label}</h5>
            <small className='text-muted'>{error.details?.message}</small>
            <div className='truncate-lines-3'>
                {error.details?.response?.data?.errors?.[0]?.message}
            </div>
        </Dropdown.Item>
    )
}

function getSaveButtonProps(editStatus, changesCount) {
    const changesDisplay = (
        <>
            Changes
            <Badge soft bg='secondary' className='ms-2'>
                {changesCount}
            </Badge>
        </>
    )
    switch (editStatus) {
        case 'Editing':
            return {
                disabled: true,
                children: (
                    <span className='text-body'>
                        <span className='dot-flashing-wrapper me-2'>
                            <span className='dot-flashing'>●</span>
                        </span>
                        Editing
                    </span>
                ),
                variant: 'white',
            }
        case 'Unsaved Changes':
            return {
                icon: 'fas fa-save',
                children: 'Save',
                variant: 'info',
                soft: true,
            }
        case 'Saving':
            return {
                icon: 'fal fa-spinner-third fa-spin',
                disabled: true,
                children: 'Saving',
                variant: 'white',
            }
        case 'Saved':
            return {
                icon: 'far fa-list-ul',
                disabled: false,
                children: changesDisplay,
                variant: 'white',
            }
        case 'Synced':
            return {
                icon: 'far fa-list-ul',
                disabled: false,
                children: changesDisplay,
                variant: 'white',
            }
        default:
            return {
                icon: 'fas fa-save',
                children: 'Save',
                variant: 'white',
            }
    }
}
