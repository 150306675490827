import { Tooltip, formatDate } from 'components/utils'
import { Button, Form } from 'front'
import { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import { TaskStatusBadge } from '../tasks/Tasks'

export default function LinkTaskModal({ onSave, row, type, notEditable }) {
    const [show, setShow] = useState(false)
    const { fileInfo } = useContext(ProjectContext)
    const [taskSelected, setTaskSelected] = useState(row?.task_id)

    const label = row?.custom_label ?? type?.label
    const options = [
        { id: null, _task_code_label: 'None' },
        ...fileInfo?.tasks?.filter((t) => t.status != 'DELETED'),
    ]
    const value = options?.find((o) => o?.id == taskSelected)

    return (
        <>
            <Tooltip
                delay={{
                    show: 450,
                    hide: 0,
                }}
                content={`Link ${label} to Task`}
            >
                <Button
                    variant='link'
                    className='p-0 m-0 fs-sm'
                    onClick={() => {
                        setShow(true)
                    }}
                    disabled={notEditable}
                >
                    {row?.task_id ? (
                        <small className='text-muted'>
                            {
                                fileInfo?.tasks?.find((task) => task?.id === row?.task_id)
                                    ?._task_code_label
                            }
                        </small>
                    ) : (
                        <span className='text-info'>Link Task</span>
                    )}
                </Button>
            </Tooltip>
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>Link {label} to Task</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.AdvancedSelect
                        onChange={(task) => {
                            setTaskSelected(task?.value ?? null)
                        }}
                        options={options?.map((o) => taskOption(o))}
                        value={taskOption(value, true)}
                        placeholder={`Select a task to link`}
                    />
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-between'>
                    <Button
                        ghost
                        variant='secondary'
                        onClick={() => {
                            setShow(false)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={'primary'}
                        onClick={() => {
                            setShow(false)
                            onSave(row?._id, taskSelected)
                        }}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

function taskOption(task, display = false) {
    return {
        value: task?.id,
        label: display ? (
            <div>
                {task?._task_code_label} <TaskStatusBadge status={task?.status} size='sm' />
            </div>
        ) : (
            <div className='d-flex flex-column'>
                <div>
                    {task?._task_code_label} <TaskStatusBadge status={task?.status} size='sm' />
                </div>
                {task?._created_contact_name && (
                    <div>
                        <small className='text-muted'>
                            created by {task?._created_contact_name} on{' '}
                            {formatDate(task?.created_date)}
                        </small>
                    </div>
                )}
            </div>
        ),
    }
}
