import classnames from 'classnames'
import DocPreviewWrapper from 'components/docuware/components/DocPreviewWrapper'
import dayjs from 'dayjs'
import { Badge, Card, Form, ListGroup } from 'front'
import { Col, Row } from 'react-bootstrap'
import { toTitleCase } from 'utils'
import { DocumentIcon } from '../../components/DocumentIcon'
import { usePacketDraftContext } from '../PacketWizard'

export default function AttachmentsPage({}) {
    const { attachmentOptions, addAttachment, removeAttachment } = usePacketDraftContext()

    return (
        <Row className='mt-3'>
            <Col sm={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>
                            Add Attachment
                            <Badge bg='secondary' soft className='ms-2'>
                                Optional
                            </Badge>
                        </Card.Title>
                    </Card.Header>
                    <AttachmentList
                        attachments={attachmentOptions}
                        addAttachmentId={addAttachment}
                        removeAttachmentId={removeAttachment}
                    ></AttachmentList>
                </Card>
            </Col>
        </Row>
    )
}

export function AttachmentList({ attachments, addAttachmentId, removeAttachmentId }) {
    return (
        <ListGroup noBorders>
            {attachments?.map(({ id, document_type, create_date, content_type, selected }) => (
                <AttachmentListItem
                    key={id}
                    docId={id}
                    type={content_type}
                    selectAttachment={() => addAttachmentId(id)}
                    deselectAttachment={() => removeAttachmentId(id)}
                    selected={selected}
                    name={document_type}
                    dateCreated={create_date}
                />
            ))}
        </ListGroup>
    )
}

function AttachmentListItem({
    docId,
    type,
    selectAttachment,
    deselectAttachment,
    selected,
    name,
    dateCreated,
}) {
    return (
        <ListGroup.Item
            action
            className='d-flex justify-content-start align-items-center cursor-pointer'
            onClick={() => (selected ? deselectAttachment() : selectAttachment())}
        >
            <Form.Check type='checkbox' checked={selected} />
            <DocPreviewWrapper cabinet='nml' docId={docId}>
                <DocumentIcon type='attachment' className='mx-2 my-3 flex-shrink-0 fa-fw' />
            </DocPreviewWrapper>
            <div className='ms-1 w-100'>
                <div className={classnames('fw-bold', { 'text-body': !selected })}>
                    {toTitleCase(name, true)}
                </div>
                <div className='text-muted'>{dayjs(dateCreated).format('M/D/YYYY')}</div>
            </div>
        </ListGroup.Item>
    )
}
