import { useRef } from 'react'
import { Button } from 'front'

export default function CaptureButton(props) {
    const canvasRef = useRef(null)

    function takeScreenshot() {
        navigator.mediaDevices
            .getDisplayMedia({ video: true })
            .then((stream) => {
                const track = stream.getVideoTracks()[0]
                console.log('🚀 ~ .then ~ track:', track)

                // Check if the stream is active
                if (track.readyState === 'live') {
                    const imageCapture = new ImageCapture(track)

                    imageCapture
                        .grabFrame()
                        .then((imageBitMap) => {
                            console.log('🚀 ~ .then ~ imageBitMap:', imageBitMap)
                            canvasRef.current.width = imageBitMap.width
                            canvasRef.current.height = imageBitMap.height
                            canvasRef.current.getContext('2d').drawImage(imageBitMap, 0, 0)
                            track.stop()
                            const image = canvasRef.current.toDataURL()
                            console.log('🚀 ~ .then ~ image:', image)
                            props.capture(image)

                            window.focus()
                        })
                        .catch((err) => {
                            console.error('Image capture error:', err)
                            track.stop()
                            // Provide user feedback about the error
                        })
                } else {
                    console.error('Stream is not ready')
                    // Provide user feedback that the stream is not ready
                    track.stop()
                }
            })
            .catch((error) => {
                console.error('Media error:', error)
                // Provide user feedback, especially if user denies permission
            })
    }

    // console.log('image',image)
    return (
        <>
            <Button
                className={props.className ? props.className : ''}
                onClick={takeScreenshot}
                type={'button'}
            >
                <span className={'far fa-plus'} /> Screenshot
            </Button>
            <canvas ref={canvasRef} style={{ display: 'none' }} />
        </>
    )
}
