import SortableTable from '@/components/SortableTable'
import { formatDate } from '@/components/utils'
import { Badge, Button } from 'front'
import { useState } from 'react'
import { Collapse } from 'react-bootstrap'

export default function WorkflowHistory({ task }) {
    const [collapsed, setCollapsed] = useState(true)
    if (!task?.history?.length) return null

    return (
        <div>
            <div className='d-flex justify-content-center'>
                <Button
                    className='w-100'
                    variant='secondary'
                    icon={collapsed ? 'far fa-history' : 'far fa-chevron-up'}
                    ghost
                    size='sm'
                    onClick={() => {
                        setCollapsed(!collapsed)
                    }}
                >
                    {collapsed ? 'View' : 'Hide'} Stage History
                </Button>
            </div>
            <Collapse in={!collapsed}>
                <div>
                    <SortableTable
                        className='w-100 fs-sm'
                        headers={[
                            {
                                name: 'Date',
                                value: 'date',
                                date: true,
                                custom: (date) => {
                                    return (
                                        <>
                                            <small>{formatDate(date, 'M/D/YYYY')}</small>
                                            <small className='d-block'>
                                                {formatDate(date, 'h:mm A')}
                                            </small>
                                        </>
                                    )
                                },
                            },
                            {
                                name: 'Previous Stage',
                                value: ['previous_stage', 'updated_name'],
                                custom: StageDisplay,
                            },
                            {
                                name: 'Action',
                                value: 'action',
                                custom: ActionBadge,
                                className: 'text-center',
                                rowClassName: 'text-center',
                            },
                            {
                                name: 'New Stage',
                                value: ['new_stage', 'assigned_name'],
                                className: 'text-end',
                                rowClassName: 'text-end',
                                custom: StageDisplay,
                            },
                        ]}
                        info={task?.history}
                        all
                    />
                </div>
            </Collapse>
        </div>
    )
}

function StageDisplay([stage, name]) {
    return (
        <div className='d-flex flex-column'>
            <div className='fw-bold'>{stage}</div>
            {name}
        </div>
    )
}

function ActionBadge(action) {
    action = action.toLowerCase()
    return (
        <Badge
            bg={
                action == 'complete'
                    ? 'primary'
                    : action == 'rollback'
                    ? 'danger'
                    : action == 'skipto'
                    ? 'success'
                    : action == 'import'
                    ? 'info'
                    : 'secondary'
            }
            icon={
                action == 'complete'
                    ? 'fas fa-check'
                    : action == 'rollback'
                    ? 'fas fa-undo'
                    : action == 'skipto'
                    ? 'fas fa-forward'
                    : action == 'import'
                    ? 'fas fa-arrow-down-to-line'
                    : action == 'assign'
                    ? 'fas fa-arrow-right-from-arc'
                    : null
            }
            soft
            className='text-capitalize'
        >
            {action == 'complete'
                ? 'Completed'
                : action == 'rollback'
                ? 'Rolled back to'
                : action == 'skipto'
                ? 'Skipped to'
                : action == 'import'
                ? 'Imported'
                : action}
        </Badge>
    )
}
