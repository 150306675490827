import { useContext, useEffect, useState } from 'react'
import { PortalContext } from '../GlobalState'

export default function DateRange({ start, end, onSubmit }) {
    const { pickerOptions } = useContext(PortalContext)
    const [fromId] = useState('datepicker' + Math.round(Math.random() * 1000).toString())
    const [toId] = useState('datepicker' + Math.round(Math.random() * 1000).toString())
    const [from, setFrom] = useState(start)
    const [to, setTo] = useState(end)

    useEffect(() => {
        onSubmit(from, to)
        $(`#${fromId}`).on('dp.change', onChange)
        $(`#${toId}`).on('dp.change', onChange)
    }, [])

    function onChange({ target }) {
        const { name, value } = target
        name === 'from' ? setFrom(value) : setTo(value)
    }

    const formSubmit = (e) => {
        e.preventDefault()
        onSubmit(from, to)
    }

    useEffect(() => {
        $('#' + fromId).datetimepicker(pickerOptions)
        $('#' + toId).datetimepicker(pickerOptions)
    }, [])

    return (
        <>
            <form onSubmit={formSubmit}>
                <div className={'form-row'}>
                    <div className={'col-auto'}>
                        <div className={'mb-3'}>
                            <label>From</label>
                            <input
                                className={'form-control'}
                                id={fromId}
                                type={'text'}
                                value={from}
                                onChange={({ target }) => {
                                    setFrom(target.value)
                                }}
                                name={'from'}
                            />
                        </div>
                    </div>
                    <div className={'col-auto'}>
                        <div className={'mb-3'}>
                            <label>To</label>
                            <input
                                className={'form-control date_inputer'}
                                id={toId}
                                type={'text'}
                                onChange={({ target }) => {
                                    setTo(target.value)
                                }}
                                value={to}
                                name={'to'}
                            />
                        </div>
                    </div>
                    <div className={'col-auto d-flex align-items-end mb-3'}>
                        <button className={'btn btn-primary'} type={'submit'} onClick={formSubmit}>
                            <span className={'far fa-check'} /> Submit
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}
