export default function HighlightedText({ text, highlight }) {
    if (!highlight || highlight?.length < 2) return <>{text}</>

    const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
    return (
        <>
            {parts.map((part, i) => (
                <span
                    key={i}
                    className={
                        part.toLowerCase() === highlight.toLowerCase()
                            ? 'bg-warning'
                            : undefined
                    }
                >
                    {part}
                </span>
            ))}
        </>
    )
}
