import { verifyFunctionThenCall } from './miscUtils'
// Returns icon for various NCS-specific use-cases
export function getNcsIcon({ dept, category, value, variant = 'fal', fallback, size = 'md' }) {
    dept = dept.toLowerCase()

    const deptIcon = icons?.[dept]?.department
    const iconForValue = icons?.[dept]?.[category]?.[value]
    const categoryDefault = icons?.[dept]?.[category]?._default

    // Fallback order: specific icon -> supplied fallback -> category default
    let iconInfo
    if (!category) {
        if (isIconValid(deptIcon)) iconInfo = deptIcon()
        else iconInfo = { icon: fallback }
    } else if (isIconValid(iconForValue)) iconInfo = iconForValue()
    else if (fallback) iconInfo = { icon: fallback }
    else if (isIconValid(categoryDefault)) iconInfo = categoryDefault()
    else return null

    let iconName = iconInfo.icon
    variant = iconInfo?.variant ?? variant

    return `${variant} fa-${iconName} fa-${size}`
}

const isIconValid = (icon) => {
    const iconInfo = verifyFunctionThenCall(icon)

    return !!iconInfo?.icon
}
// Icon reference hierarchy organized by dept > category > value
// Example: 'nml' > 'accociateType' > 'Prime Contractor'
// PS:  Each item is a callback to make it easier to reference other elements within the hierarchy
//      this is useful when you want to include the ID, code, and label for a category
const icons = {
    nml: {
        department: () => ({ icon: 'helmet-safety' }),
        associateType: {
            1: () => ({ icon: 'user-tie' }),
            2: () => ({ icon: 'house-person-return' }),
            3: () => ({ icon: 'helmet-safety' }),
            4: () => ({ icon: 'person-digging' }),
            5: () => ({ icon: 'person-digging' }),
            6: () => ({ icon: 'person-digging' }),
            7: () => ({ icon: 'building-columns' }),
            8: () => ({ icon: 'user-shield' }),
            11: () => ({ icon: null }),
            13: () => ({ icon: 'building-flag' }),
            14: () => ({ icon: 'user-helmet-safety' }),
            16: () => ({ icon: 'compass-drafting' }),
            17: () => ({ icon: null }),
            18: () => ({ icon: 'user-headset' }),
            19: () => ({ icon: null }),
            23: () => ({ icon: 'asterisk' }),
            OW: () => icons.nml.associateType[1](),
            TE: () => icons.nml.associateType[2](),
            GC: () => icons.nml.associateType[3](),
            S1: () => icons.nml.associateType[4](),
            S2: () => icons.nml.associateType[5](),
            S3: () => icons.nml.associateType[6](),
            LE: () => icons.nml.associateType[7](),
            BC: () => icons.nml.associateType[8](),
            DE: () => icons.nml.associateType[11](),
            CH: () => icons.nml.associateType[13](),
            CM: () => icons.nml.associateType[14](),
            AR: () => icons.nml.associateType[16](),
            SO: () => icons.nml.associateType[17](),
            LA: () => icons.nml.associateType[18](),
            TC: () => icons.nml.associateType[19](),
            OT: () => icons.nml.associateType[23](),
            Owner: () => icons.nml.associateType[1](),
            Tenant: () => icons.nml.associateType[2](),
            'Prime Contractor': () => icons.nml.associateType[3](),
            Subcontractor: () => icons.nml.associateType[4](),
            'Sub-Subcontractor': () => icons.nml.associateType[5](),
            'Sub-Sub-Subcontractor': () => icons.nml.associateType[6](),
            Lender: () => icons.nml.associateType[7](),
            'Bonding Company': () => icons.nml.associateType[8](),
            Designee: () => icons.nml.associateType[11](),
            'Corp Headquarters': () => icons.nml.associateType[13](),
            'Construction Manager': () => icons.nml.associateType[14](),
            Architect: () => icons.nml.associateType[16](),
            'System Owner': () => icons.nml.associateType[17](),
            'Lien Agent': () => icons.nml.associateType[18](),
            'Title Company': () => icons.nml.associateType[19](),
            Other: () => icons.nml.associateType[23](),
            _default: () => ({ icon: 'user' }),
        },
        associateRole: {
            39: () => ({ icon: 'user-group' }),
            40: () => ({ icon: 'user-check' }),
            41: () => ({ icon: 'mailbox' }),
            JOINT: () => icons.nml.associateRole[39](),
            RA: () => icons.nml.associateRole[40](),
            POBOX: () => icons.nml.associateRole[41](),
            Joint: () => icons.nml.associateRole[39](),
            'Registered Agent': () => icons.nml.associateRole[40](),
            'P.O. Box': () => icons.nml.associateRole[41](),
            _default: () => ({ icon: 'user-tag' }),
        },
        materialType: {
            M: () => ({ icon: 'block-brick' }),
            L: () => ({ icon: 'trowel' }),
            SFM: () => ({ icon: 'block-brick' }),
            'M+L': () => ({ icon: 'trowel-bricks' }),
            'SFM+L': () => ({ icon: 'trowel-bricks' }),
            _default: () => ({ icon: 'block-brick' }),
        },
        projectType: {
            1: () => ({ icon: 'building-lock' }),
            2: () => ({ icon: 'school' }),
            3: () => ({ icon: 'landmark-dome' }),
            4: () => ({ icon: 'campground' }),
            PRIVATE: () => icons.nml.projectType[1](),
            PUBLIC: () => icons.nml.projectType[2](),
            FEDERAL: () => icons.nml.projectType[3](),
            TRIBAL: () => icons.nml.projectType[4](),
            Private: () => icons.nml.projectType[1](),
            Public: () => icons.nml.projectType[2](),
            Federal: () => icons.nml.projectType[3](),
            Tribal: () => icons.nml.projectType[4](),
            _default: () => ({ icon: 'house-building' }),
        },
        feeType: {
            76: () => ({ icon: 'list' }),
            77: () => ({ icon: 'envelope' }),
            78: () => ({ icon: 'dollar' }),
            SERVICE: () => icons.nml.feeType[76](),
            POSTAGE: () => icons.nml.feeType[77](),
            CHARGES: () => icons.nml.feeType[78](),
        },
        taskCategory: {
            'Main Contact': () => ({ icon: 'user-check' }),
            Default: () => ({ icon: 'clipboard-check' }),
            Tracking: () => ({ icon: 'binoculars' }),
            LTO: () => ({ icon: 'globe' }),
            'Non-Stat Notice': () => ({ icon: 'envelope' }),
            Notice: () => ({ icon: 'envelope-circle-check' }),
            Bond: () => ({ icon: 'file-shield' }),
            'Release of Bond Claim': () => ({ icon: 'file-slash' }),
            Lien: () => ({ icon: 'file-signature' }),
            'Release of Lien': () => ({ icon: 'file-slash' }),
            Suit: () => ({ icon: 'gavel' }),
            Release: () => ({ icon: 'file-slash' }),
            Other: () => ({ icon: 'asterisk' }),
            'Misc Update': () => ({ icon: 'pencil' }),
            'Client Review': () => ({ icon: 'eye' }),
            'LTO Update': () => ({ icon: 'pencil' }),
            _default: () => ({ icon: 'clipboard-check' }),
        },
        taskStage: {
            'PRE-RESEARCH': () => ({ icon: 'magnifying-glass-arrow-right fa-flip-horizontal' }),
            RESEARCH: () => ({ icon: 'magnifying-glass' }),
            PREPARING: () => ({ icon: 'pen-field' }),
            REVIEW: () => ({ icon: 'user-magnifying-glass' }),
            EXECUTING: () => ({ icon: 'forward' }),
            'POST-REVIEW': () => ({ icon: 'magnifying-glass-arrow-right' }),
            SERVING: () => ({ icon: 'envelope' }),
            FINALIZING: () => ({ icon: 'circle-check' }),
        },
        serviceLevel: {
            ATTORNEY: () => ({ icon: 'scale-balanced' }),
            EXPRESS: () => ({ icon: 'forward' }),
            PREMIUM: () => ({ icon: 'dollar-sign' }),
        },
        postage: {
            StdFedEx: () => ({ icon: 'fedex', variant: 'fab' }),
            FirstClass: () => ({ icon: 'envelope' }),
            CertifiedERR: () => ({ icon: 'envelope-circle-check' }),
            IntlReg: () => ({ icon: 'earth-americas' }),
            CERR: () => ({ icon: 'envelope-circle-check' }),
            CNRR: () => ({ icon: 'envelope-circle-check' }),
            CRD: () => ({ icon: 'envelope-circle-check' }),
            FC: () => ({ icon: 'envelope' }),
            INFC: () => ({ icon: 'earth-americas' }),
            FX1: () => ({ icon: 'fedex', variant: 'fab' }),
            FX2: () => ({ icon: 'fedex', variant: 'fab' }),
            RMD: () => ({ icon: 'flag-usa' }),
            RMI: () => ({ icon: 'earth-europe' }),
        },
        specialHandling: {
            17: () => ({ icon: 'fedex', variant: 'fab' }),
            33: () => ({ icon: 'earth-americas' }),
            45: () => ({ icon: 'earth-americas' }),
            18: () => ({ icon: 'asterisk' }),
            16: () => ({ icon: 'truck-container' }),
            15: () => ({ icon: 'envelope-circle-check' }),
            14: () => ({ icon: 'badge-check' }),
            FX: () => icons.nml.specialHandling[17](),
            IN: () => icons.nml.specialHandling[33](),
            INFC: () => icons.nml.specialHandling[45](),
            OT: () => icons.nml.specialHandling[18](),
            RD: () => icons.nml.specialHandling[16](),
            RE: () => icons.nml.specialHandling[15](),
            RM: () => icons.nml.specialHandling[14](),
        },
        createdDate: {
            1: () => ({ icon: 'sparkles' }),
            2: () => ({ icon: 'calendar-week' }),
            3: () => ({ icon: 'calendar-week' }),
            4: () => ({ icon: 'hourglass-end' }),
        },
    },
    ucc: {
        department: () => ({ icon: 'lock-alt' }),
    },
}
