import { FILE_VIEW, CHANGE_FILE_VIEW_LOADING } from './action'

export default function FileViewReducer(state = {}, action) {
    switch (action.type) {
        case FILE_VIEW:
            return {
                ...state,
                services: action.file_view.services,
                secured_parties: action.file_view.secured_parties,
                debtors: action.file_view.debtors,
                info: action.file_view.info,
                notification_letters: action.file_view.notification_letters,
            }
        case CHANGE_FILE_VIEW_LOADING:
            return { ...state, isLoading: action.set }
        default:
            return state
    }
}
