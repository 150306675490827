import { Button } from 'front'
import { usePrevious } from 'hooks'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { jsonCompare } from 'utils'

const MicroAppContext = React.createContext({})

export const MicroApp = ({ as, label, id, children }) => {
    useMicroApp({
        label,
        id,
        component: as,
        props: children?.props,
    })

    return null
}

// TODO: Fully remove microApps when no longer needed rather than merely hiding them
export const useMicroApp = (microApp) => {
    const [id, setId] = useState(Math.random())
    const microAppContext = useContext(MicroAppContext)
    const prevMicroAppState = usePrevious(microApp)

    const isOpen = microAppContext.isMicroAppShowing(id)
    const isMinimized = microAppContext.isMicroAppClosed(id)
    const isHidden = microAppContext.isMicroAppHidden(id)
    const isClosed = microAppContext.isMicroAppMinimized(id)
    const hide = useCallback(() => {
        microAppContext.hideMicroApp(id)
        setId(Math.random())
    }, [id])
    const close = useCallback(() => {
        microAppContext.closeMicroApp(id)
        setId(Math.random())
    }, [id])
    const minimize = useCallback(() => microAppContext.minimizeMicroApp(id), [id])
    const show = useCallback(() => microAppContext.showMicroApp(id), [id])
    const set = useCallback((microApp) => microAppContext.setMicroApp(microApp), [])

    useEffect(() => {
        if (jsonCompare(microApp, prevMicroAppState)) return

        if (microApp?.component) set(microApp)

        // if (!isOpen) return close
    }, [microApp, prevMicroAppState, isOpen, isMinimized, isClosed])

    // useEffect(() => {
    //     microAppContext.markParentMounted(microApp?.id)
    //     return () => microAppContext.markParentUnmounted(microApp?.id)
    // }, [isHidden])

    return {
        id,
        isOpen,
        isMinimized,
        isHidden,
        isClosed,
        show,
        hide,
        minimize,
        close,
        set,
    }
}

export function MicroAppContextProvider({ children }) {
    const [microApps, setMicroApps] = useState({})

    const setMicroApp = (microApp) => {
        if (microApp?.component)
            setMicroApps((prevMicroApps) => ({
                ...prevMicroApps,
                [microApp?.id]: {
                    ...prevMicroApps?.[microApp?.id],
                    ...microApp,
                    isClosed: false,
                },
            }))
    }

    function updateMicroApp(id, update) {
        setMicroApps((prevMicroApps) => ({
            ...prevMicroApps,
            [id]: {
                ...prevMicroApps[id],
                ...update,
            },
        }))
    }

    // function removeMicroApp(id) {
    //     setMicroApps(({ ...prevMicroApps }) => {
    //         delete prevMicroApps?.[id]
    //         return prevMicroApps
    //     })
    // }

    const isMicroAppShowing = (id) => microApps?.[id]?.isOpen
    const isMicroAppMinimized = (id) => microApps?.[id]?.isMinimized
    const isMicroAppHidden = (id) => !microApps?.[id]?.isOpen
    const isMicroAppParentUnmounted = (id) => microApps?.[id]?.isParentUnmounted
    const isMicroAppClosed = (id) => microApps?.[id]?.isClosed
    const showMicroApp = (id) => updateMicroApp(id, { isOpen: true, isMinimized: false })
    const minimizeMicroApp = (id) => updateMicroApp(id, { isMinimized: true, isOpen: true })
    const hideMicroApp = (id) =>
        updateMicroApp(id, { isOpen: false, isMinimized: false, key: Math.random() })
    const closeMicroApp = (id) =>
        updateMicroApp(id, {
            isOpen: false,
            isMinimized: false,
            isClosed: true,
            key: Math.random(),
        })
    const markParentMounted = (id) =>
        updateMicroApp(id, { isClosed: false, isParentUnmounted: false })
    const markParentUnmounted = (id) => {
        isMicroAppHidden(id) ? closeMicroApp(id) : updateMicroApp(id, { isParentUnmounted: true })
    }

    function removeMicroApp(id) {
        setMicroApps(({ ...prevMicroApps }) => {
            delete prevMicroApps?.[id]
            return prevMicroApps
        })
    }

    return (
        <MicroAppContext.Provider
            value={{
                setMicroApp,
                isMicroAppShowing,
                isMicroAppMinimized,
                isMicroAppHidden,
                isMicroAppClosed,
                showMicroApp,
                hideMicroApp,
                minimizeMicroApp,
                removeMicroApp,
                markParentMounted,
                markParentUnmounted,
                closeMicroApp,
            }}
        >
            {children}
            {Object.values(microApps)?.map(({ component: Component, props, id, key }) => (
                <Component
                    {...props}
                    key={key ?? id}
                    show={isMicroAppShowing(id) && !isMicroAppMinimized(id)}
                    isMinimized={isMicroAppMinimized(id)}
                    onMinimize={() => minimizeMicroApp(id)}
                    onHide={() => hideMicroApp(id)}
                    onClose={() => closeMicroApp(id)}
                />
            ))}
            <div className='microapp-tabs position-fixed me-4 bottom-0 end-0 overflow-visible'>
                {Object.values(microApps)
                    ?.filter(({ isMinimized }) => isMinimized)
                    ?.map(({ id, label }) => (
                        <Button
                            variant='success'
                            className='mx-1 microapp-tab'
                            size='sm'
                            onClick={() => showMicroApp(id)}
                        >
                            <span>{label}</span>
                            <i
                                className='fal fa-xmark ms-3 microapp-close-button'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    if (isMicroAppParentUnmounted(id)) closeMicroApp(id)
                                    else hideMicroApp(id)
                                }}
                            />
                        </Button>
                    ))}
            </div>
        </MicroAppContext.Provider>
    )
}
