import classnames from 'classnames'
import { currencyFormatter } from 'components/utils'
import { Badge, Button, Form } from 'front'
import { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import FurnishingDatesEdit from './FurnishingDatesEdit'
export function BalanceModal({ balance, update, deadlineId, furnishings, project }) {
    const [isHovering, setIsHovering] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [updateFurnishings, setUpdateFurnishings] = useState([])
    // console.log('updateFurnishings: ', updateFurnishings)
    const balanceRef = useRef(null)

    useEffect(() => {
        showModal && balanceRef?.current?.focus()
    }, [showModal])

    function openModal() {
        setShowModal(true)
        mapFurnishings()
    }

    function onHide() {
        setShowModal(false)
    }

    function saveBalance() {
        let updateObj = {}
        updateFurnishings?.map((r) => {
            updateObj[r?._id] = r
        })
        update({ furnishings: updateObj })
        onHide()
    }

    function mapFurnishings() {
        return setUpdateFurnishings(
            furnishings?.map((furnishId) => project?.furnishings?.[furnishId]) ?? []
        )
    }

    return (
        <>
            <Badge
                bg='dark'
                soft
                onMouseEnter={() => {
                    setIsHovering(true)
                }}
                onMouseLeave={() => {
                    setIsHovering(false)
                }}
                onClick={() => {
                    if (isHovering) {
                        openModal()
                    }
                }}
                className={classnames('pointer', {
                    'me-3': !isHovering,
                })}
            >
                <span
                    className={classnames({
                        'text-muted': !(balance > 0),
                        'text-dark': balance > 0,
                    })}
                >
                    {currencyFormatter(balance)}
                    {isHovering && <span className='far fa-pen ms-2' />}
                </span>
            </Badge>
            <Modal show={showModal} onHide={onHide} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title>Update Balance</Modal.Title>
                </Modal.Header>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault()
                        saveBalance()
                    }}
                >
                    <Modal.Body>
                        <FurnishingDatesEdit
                            furnishings={updateFurnishings}
                            updateFurnishings={({ furnishing, name, value }) => {
                                setUpdateFurnishings((current) =>
                                    current?.map((row) =>
                                        furnishing?._id == row?._id
                                            ? { ...furnishing, [name]: value }
                                            : row
                                    )
                                )
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer className='justify-content-between'>
                        <Button ghost variant='secondary' onClick={onHide}>
                            Cancel
                        </Button>
                        <Form.Button type='submit' variant='primary' onClick={saveBalance}>
                            Save
                        </Form.Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}
