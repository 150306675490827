import { OPEN_BUILD_DOCS_MODAL, CLOSE_BUILD_DOCS_MODAL } from './action.js'

export default function BuildDocsReducer(state = { show: false }, action) {
    switch (action.type) {
        case OPEN_BUILD_DOCS_MODAL:
            return { ...state, show: action.open }
        case CLOSE_BUILD_DOCS_MODAL:
            return { ...state, show: action.open }
        default:
            return state
    }
}
