import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { LoadingSpinner } from 'components/utils'
import { Badge, Button, Form } from 'front'
import { useDebounce } from 'hooks'
import Link from 'next/link'
import { useEffect, useRef, useState } from 'react'
import { stripOutLetters } from '../compareProjectIds'
import { useClipboardProject } from '../ClipboardContext'
import { useRouter } from 'next/router'
import SearchResult from './SearchResult'

export default function ProjectLookupModal({ show, onHide }) {
    const clipboardProject = useClipboardProject()
    const [searchText, setSearchText] = useState('')
    const debounceSearch = useDebounce(searchText, 1000)
    const query = useQuery({
        queryKey: ['projectSearchModal', debounceSearch],
        queryFn: () => search(debounceSearch),
        enabled: !!debounceSearch,
    })
    const { data: searchResults, isLoading, isFetching } = query
    function onChange({ target: { value } }) {
        setSearchText(value)
    }

    function search(search) {
        return axios
            .get(`/nml/tracker/2/lookup/project/json`, { params: { term: search } })
            .then(({ data }) => data)
    }
    const projectNumberPattern = /^[A-Za-z]?\d{7}$/
    let goToProject = false
    if (projectNumberPattern.test(searchText)) goToProject = true

    const searchRef = useRef(null)

    useEffect(() => {
        show && searchRef?.current?.focus()
    }, [show])

    const router = useRouter()

    function handleSubmit(e) {
        e.preventDefault()

        if (goToProject) {
            router.push(`/nml/tracker/file/overview/?project_id=${stripOutLetters(searchText)}`)
            onHide()
        }
    }

    return (
        show && (
            <form onSubmit={handleSubmit}>
                <Form.Control
                    ignoreGlobalDisable={true}
                    ref={searchRef}
                    type={'text'}
                    value={searchText}
                    name={'searchProject'}
                    onChange={onChange}
                    placeholder={'Project or Reference #'}
                    className='fs-2'
                />
                {clipboardProject && (
                    <div className='my-1'>
                        <Link
                            href={`/nml/tracker/file/overview/?project_id=${stripOutLetters(
                                clipboardProject
                            )}`}
                        >
                            <Button variant='link' className='px-0' onClick={onHide}>
                                From clipboard: {clipboardProject}{' '}
                                <span className='far fa-arrow-right' />
                            </Button>
                        </Link>
                    </div>
                )}

                {isFetching && (
                    <h3 className='my-3'>
                        <LoadingSpinner /> Searching...
                    </h3>
                )}
                {searchResults?.length > 0 ? (
                    <div className='mt-5'>
                        <h4>Results</h4>

                        <table className='table table-hover'>
                            <thead className='thead-light'>
                                <tr>
                                    <th>Project</th>
                                    <th className='text-center'>Status</th>
                                    <th>Ref #</th>
                                    <th className='text-end'>Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchResults?.map((result) => {
                                    return (
                                        <Link
                                            href={`/nml/tracker/file/overview?project_id=${result?.id}`}
                                            key={result?.id}
                                        >
                                            <tr className='cursor-pointer' onClick={onHide}>
                                                <td className=''>
                                                    <div className='text-dark fw-bold'>
                                                        {(result?.ncs_lno?.length > 0 ||
                                                            result?.id) && (
                                                            <Badge bg='info' className='me-2' pill>
                                                                {result?.ncs_lno
                                                                    ? result?.ncs_lno
                                                                    : result?.id
                                                                    ? `D${result?.id}`
                                                                    : 'IMPORT'}
                                                            </Badge>
                                                        )}
                                                        {result?.name}
                                                    </div>
                                                    <div className='ms-1'>
                                                        {result?.client_client_no} -{' '}
                                                        {result?.client_name}
                                                    </div>
                                                </td>
                                                <td className='text-center align-middle'>
                                                    <Badge
                                                        bg={
                                                            !!result?.closed_date
                                                                ? 'secondary'
                                                                : 'success'
                                                        }
                                                        soft={!!result?.closed_date}
                                                    >
                                                        {!!result?.closed_date ? 'Closed' : 'Open'}
                                                    </Badge>
                                                </td>
                                                <td className='align-middle'>
                                                    {result?.reference_number?.length > 0 && (
                                                        <div>{result?.reference_number}</div>
                                                    )}
                                                </td>
                                                <td className='text-end'>
                                                    {result?.project_type?.length > 0 && (
                                                        <div className='text-capitalize fw-bold'>
                                                            {result?.project_type?.toLowerCase()}
                                                        </div>
                                                    )}
                                                    {result?.state?.length > 0 && (
                                                        <div className='text-capitalize'>
                                                            {result?.state}
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        </Link>
                                    )
                                })}
                            </tbody>
                        </table>

                        {/* <ul className='list-group'>
                            {searchResults?.map((result) => {
                                return (
                                    <li
                                        className='list-group-item list-group-item-action'
                                        key={result?.id}
                                    >
                                        <div className='d-flex justify-content-between align-items-center pb-2 mb-3 border-bottom border-2'>
                                            {(result?.ncs_lno?.length > 0 ||
                                                result?.id?.length > 0 ||
                                                result?.direct_no > 0 ||
                                                result?.project_name) && (
                                                <Link
                                                    key={result?.direct_no}
                                                    href={`/nml/tracker/file/overview?project_id=${result?.id}`}
                                                >
                                                    <Button variant='link p-0' onClick={onHide}>
                                                        {result?.ncs_lno ?? result?.id
                                                            ? `D${result?.id}`
                                                            : false ??
                                                              result?.direct_no ??
                                                              'IMPORT'}
                                                        {result?.name && ` - ${result?.name}`}
                                                    </Button>
                                                </Link>
                                            )}
                                            {result?.reference_number?.length > 0 && (
                                                <div>{result?.reference_number}</div>
                                            )}
                                        </div>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <div className='d-flex flex-column'>
                                                {result?.client_name?.length > 0 && (
                                                    <div className='mb-1 fst-italic'>
                                                        ({result?.client_client_no}){' '}
                                                        {result?.client_name}
                                                    </div>
                                                )}
                                                <div className='d-flex gap-3'>
                                                    {result?.debtor?.name?.length > 0 && (
                                                        <div>
                                                            <small className='text-muted'>
                                                                Debtor
                                                            </small>
                                                            <div>{result?.debtor?.name}</div>
                                                        </div>
                                                    )}
                                                    {result?.service?.length > 0 && (
                                                        <div>
                                                            <small className='text-muted'>
                                                                Service
                                                            </small>
                                                            <div>{result?.service}</div>
                                                        </div>
                                                    )}
                                                    {result?.next_deadline?.length > 0 && (
                                                        <div>
                                                            <small className='text-muted'>
                                                                Next Deadline
                                                            </small>
                                                            <div>
                                                                {formatDate(result?.next_deadline)}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column text-end'>
                                                {result?.project_type?.length > 0 && (
                                                    <div className='text-capitalize'>
                                                        {result?.project_type}
                                                    </div>
                                                )}
                                                {result?.state?.length > 0 && (
                                                    <div className='text-capitalize'>
                                                        {result?.state}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul> */}
                    </div>
                ) : (
                    debounceSearch?.length > 0 &&
                    searchResults?.length === 0 && <h4 className='mt-3'>No Results</h4>
                )}
            </form>
        )
    )
}
