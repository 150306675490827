export default function getObjectDifferences(originalObj, comparisonObj) {
    const differences = {}

    for (let key in comparisonObj) {
        if (!originalObj.hasOwnProperty(key)) {
            differences[key] = comparisonObj[key]
        }
    }

    return differences
}
