import classNames from 'classnames'
import { Badge, Button, Dropdown, Offcanvas } from 'front'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import AddEditNoteModal from './AddEditNoteModal'
import ClientNotes from './ClientNotes'
import NotesDisplay from './NotesDisplay'

export default function NoteOverlay({ noButton, ...props }) {
    const { canEdit, fileInfo } = useContext(ProjectContext)
    const { notes } = fileInfo || { notes: [] }
    const [show, setShow] = useState(props?.show ?? false)
    const router = useRouter()
    const [showClientNotes, setShowClientNotes] = useState(false)
    const [showAddEditModal, setShowAddEditModal] = useState(false)
    const [tagsSelected, setTagsSelected] = useState([])
    useEffect(() => {
        props?.show && setShow(true)
    }, [props?.show])

    const urgentNotes = notes?.filter((note) => note?.need_confirmed == 1 && note?.confirmed == 0)
    // console.log('notes: ', notes)

    return (
        <>
            {!noButton &&
                (props?.dropdownItemBtn ? (
                    <Dropdown.Item
                        onClick={() => {
                            setShow(true)
                        }}
                    >
                        {props.children ?? (
                            <>
                                Notes
                                {urgentNotes?.length > 0 && (
                                    <Badge pill bg='danger' className='ms-1' size='xs'>
                                        {urgentNotes?.length}
                                    </Badge>
                                )}
                            </>
                        )}
                    </Dropdown.Item>
                ) : (
                    <Button
                        circle={!props?.noCircle}
                        ghost={!props?.noGhost}
                        variant={props?.variant ?? 'secondary'}
                        className={classNames(props?.className)}
                        onClick={() => {
                            setShow(true)
                        }}
                    >
                        {props.children ?? (
                            <>
                                {urgentNotes?.length > 0 ? (
                                    <span className={'stack-icons'}>
                                        <span className={'badge badge-pill bg-danger mt-1'}>
                                            {urgentNotes.length}
                                        </span>
                                        <span className={'far fa-sticky-note fa-lg'} />
                                    </span>
                                ) : (
                                    <span className={'far fa-sticky-note'} />
                                )}
                                {!props?.iconOnly && <span className={'ms-2'}>Notes</span>}
                            </>
                        )}
                    </Button>
                ))}
            <Offcanvas
                show={show}
                onHide={() => {
                    setShow(false)
                    props?.onHide && props.onHide()
                }}
                placement='end'
                style={{ width: '60vw' }}
            >
                <Offcanvas.Title>
                    <Offcanvas.Header>
                        {/* <Button
                            variant={'secondary'}
                            icon={'fal fa-arrow-right-from-line'}
                            onClick={() => {
                                setShow(false)
                            }}
                            circle
                            className='flex-shrink-0 me-3 position-absolute'
                            style={{ marginLeft: '-52px' }}
                            // disabled={unsavedChanges}
                        /> */}
                        <div className='d-flex justify-content-between w-100 align-items-center'>
                            <a
                                className='h3 m-0'
                                href={
                                    process.env.DOMAIN +
                                    '/nml/tracker/file/popout/notes?project_id=' +
                                    router?.query?.project_id
                                }
                                target='_blank'
                            >
                                Notes <span className='ms-2 fa-regular fa-external-link' />
                            </a>

                            <ToggleButtonGroup
                                name='showClientNotes'
                                value={showClientNotes}
                                type='radio'
                                className='btn-group-segment'
                                size='sm'
                            >
                                <ToggleButton
                                    value={false}
                                    variant=''
                                    onClick={() => setShowClientNotes(false)}
                                >
                                    Project
                                </ToggleButton>
                                <ToggleButton
                                    value={true}
                                    variant=''
                                    onClick={() => setShowClientNotes(true)}
                                >
                                    Client
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </Offcanvas.Header>
                </Offcanvas.Title>
                <Offcanvas.Body className='pt-0'>
                    {showClientNotes ? (
                        <div className='pt-5'>
                            <ClientNotes fileInfo={fileInfo} />
                        </div>
                    ) : (
                        <NotesDisplay
                            show={show}
                            {...props}
                            tagsSelected={tagsSelected}
                            setTagsSelected={setTagsSelected}
                        />
                    )}
                    <AddEditNoteModal
                        show={showAddEditModal}
                        tags={tagsSelected?.filter((tag) => tag?.value !== 'untagged')}
                        cancel={() => {
                            setShowAddEditModal(false)
                        }}
                    />
                </Offcanvas.Body>
                <Offcanvas.Footer className={'d-flex justify-content-between'}>
                    <Button
                        ghost
                        onClick={() => {
                            setShow(false)
                            props?.onHide && props.onHide()
                        }}
                    >
                        Cancel
                    </Button>
                    {canEdit && (
                        <Button
                            variant='primary'
                            onClick={() => {
                                setShowAddEditModal(true)
                            }}
                        >
                            <span className='far fa-plus' /> Add Note
                        </Button>
                    )}
                </Offcanvas.Footer>
            </Offcanvas>
        </>
    )
}
