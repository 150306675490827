import classnames from 'classnames'
import React from 'react'

export default function Divider({
    className,
    location = 'center', // start, center, end
    children,
    ...rest
}) {
    return (
        <span
            {...rest}
            className={classnames(className, `divider-${location}`, {
                'unlabeled-divider': !children,
            })}
        >
            {children}
        </span>
    )
}
