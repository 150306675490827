import { useContext } from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import { Form } from '../../../../../front'
import { useTrackerDropdowns } from '../../../api'
import { useUpdateServiceWorkflow } from '../../../api/workflow.api'
import { ProjectContext } from '../../../Context/ProjectContext'
import { EmployeeDropdown, NcsIcon, Tooltip } from '@/components/utils'
import sortBy from 'lodash/sortBy'
import classNames from 'classnames'

const NonPendingPacketStatuses = ['PRINT COMPLETED', 'DELETED', 'COMPLETED']

export default function Workflow({ task }) {
    const { canEdit, fileInfo } = useContext(ProjectContext)
    const { data: dropdowns } = useTrackerDropdowns()
    const { mutate: updateWorkflow, isLoading: workflowLoading } = useUpdateServiceWorkflow({
        project_id: task?.project_id,
        task_id: task?.id,
    })
    const isFirstStage = task?.stages?.[0]?.stage === task?.current_stage
    const isLastStage = task?.stages[task.stages.length - 1]?.stage === task?.current_stage
    let currentIndex = task?.stages?.findIndex((s) => s.stage === task?.current_stage) ?? 0
    const reviewStageIndex = task?.stages?.findIndex((s) => s.stage === 'REVIEW')

    const completed = isLastStage && task?.status === 'COMPLETED'
    const completedEmp =
        dropdowns.employee_lookup?.[task?.stages[task.stages.length - 1]?.employee_id]

    function onChange(employee_id, stage, process) {
        updateWorkflow({ employee_id, stage, process })
    }

    const isPacketQueuedForPrinting = task?.packets?.find(
        (packet) => !NonPendingPacketStatuses.includes(packet?.status)
    )
    const isPacketInReview = task?.packets?.find((packet) => packet?.status === 'PENDING REVIEW')
    const disableCompletion = isPacketQueuedForPrinting && isLastStage
    const disableInReview = isPacketInReview && task?.current_stage === 'REVIEW'
    const disabledNextStep = disableCompletion || disableInReview
    const taskIsCompleted = task?.status === 'COMPLETED'

    return (
        <>
            {updateWorkflow.isError && (
                <Alert variant='soft-danger' className='d-flex align-items-center'>
                    <i className='fas fa-exclamation-triangle fa-lg ms-1 me-3 d-block' />
                    <div>
                        <div className='fw-bold'>Error saving task</div>
                        {updateTaskApi?.error?.response?.data?.errors?.[0]?.message && (
                            <div className='fst-italic'>
                                {updateTaskApi?.error?.response?.data?.errors?.[0]?.message}
                            </div>
                        )}
                    </div>
                </Alert>
            )}
            <Row>
                <Col className={'d-flex justify-content-between align-items-center'}>
                    <Form.Label>Current Stage</Form.Label>
                    <div>
                        {canEdit && (
                            <>
                                {workflowLoading ? (
                                    'Loading...'
                                ) : (
                                    <>
                                        {!isFirstStage && !taskIsCompleted && (
                                            <a
                                                className='link p-0 me-3'
                                                disabled={workflowLoading}
                                                onClick={() => {
                                                    onChange(
                                                        task?.employee_id,
                                                        task?.stages[
                                                            completed
                                                                ? currentIndex
                                                                : currentIndex - 1
                                                        ]?.stage,
                                                        'rollback'
                                                    )
                                                }}
                                            >
                                                Previous
                                            </a>
                                        )}
                                        {!completed && (
                                            <Tooltip
                                                content={
                                                    disableCompletion
                                                        ? 'Task will be marked complete once packet finishes printing.'
                                                        : disableInReview
                                                        ? 'A packet is currently in review. Review must be completed before proceeding.'
                                                        : null
                                                }
                                            >
                                                <a
                                                    className={classNames('link p-0', {
                                                        'opacity-50': disabledNextStep,
                                                    })}
                                                    disabled={workflowLoading}
                                                    onClick={() => {
                                                        if (!disabledNextStep) {
                                                            // Change employee_id to the main contact if the stage is REVIEW
                                                            let emp_id = task?.employee_id
                                                            if (
                                                                reviewStageIndex > -1 &&
                                                                currentIndex >= reviewStageIndex
                                                            ) {
                                                                emp_id = dropdowns?.employee?.find(
                                                                    (emp) =>
                                                                        emp.UserName ===
                                                                        fileInfo?.main_contact
                                                                            ?.UserName
                                                                )?.EmpID
                                                            }
                                                            onChange(
                                                                task?.employee_id,
                                                                task?.current_stage,
                                                                'complete'
                                                            )
                                                        }
                                                    }}
                                                >
                                                    {isLastStage ? 'Complete' : 'Next'}
                                                </a>
                                            </Tooltip>
                                        )}
                                        {taskIsCompleted && (
                                            <a
                                                className='link p-0 ms-3'
                                                disabled={workflowLoading}
                                                onClick={() => {
                                                    onChange(
                                                        task?.employee_id,
                                                        task?.stages[
                                                            completed
                                                                ? currentIndex
                                                                : currentIndex - 1
                                                        ]?.stage,
                                                        'rollback'
                                                    )
                                                }}
                                            >
                                                Reopen
                                            </a>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </Col>
            </Row>
            <div>
                {completed ? (
                    <h4 className='text-center'>Completed by {completedEmp?.label}</h4>
                ) : (
                    <div
                        className={classNames('row justify-content-center', {
                            'col-divider': !canEdit,
                        })}
                    >
                        <div className='col mb-1'>
                            {canEdit ? (
                                <EmployeeDropdown
                                    employees={sortBy(dropdowns?.employee, [
                                        'FirstName',
                                        'LastName',
                                    ])}
                                    onChange={(employee_id) => {
                                        onChange(employee_id, task?.current_stage, 'assign')
                                    }}
                                    selected={task?.current_employee_id}
                                    allEmployees={false}
                                    isClearable={false}
                                    currentUserFirst
                                />
                            ) : (
                                <h5 className='text-center'>
                                    <span className={'fas fa-user'} /> {task?.employee_name}
                                </h5>
                            )}
                        </div>
                        <div className='col mb-1'>
                            {canEdit ? (
                                <>
                                    <Form.AdvancedSelect
                                        isSearchable={false}
                                        value={stageOption(
                                            task?.stages?.find(
                                                ({ stage }) => stage == task?.current_stage
                                            ),
                                            false,
                                            dropdowns
                                        )}
                                        options={task?.stages?.map((v) =>
                                            stageOption(
                                                v,
                                                task?.current_stage === v?.stage,
                                                dropdowns
                                            )
                                        )}
                                        onChange={(stage) => {
                                            const selectedIndex = task?.stages?.findIndex(
                                                (stg) => stg?.stage === stage?.value
                                            )

                                            // If skipping or rolling back stages, updateStage should be the selected stage
                                            // If only proceeding one stage, updateStage should be the same as current stage
                                            let updateStage = stage.value

                                            let process = 'skipto'
                                            if (selectedIndex < currentIndex) {
                                                process = 'rollback'
                                            } else if (selectedIndex == currentIndex + 1) {
                                                process = 'complete'
                                                updateStage = task?.current_stage
                                            }
                                            onChange(task?.employee_id, updateStage, process)
                                        }}
                                        isClearable={false}
                                    />
                                </>
                            ) : (
                                <h5 className='text-center'>
                                    <span className={'far fa-folder-open'} />{' '}
                                    {
                                        dropdowns?.['task.stage']?.find(
                                            (stg) => stg?.code == task?.current_stage
                                        )?.label
                                    }
                                </h5>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

const stageOption = (stage, isSelected, dropdowns) => {
    return {
        label: (
            <span
                className={classNames('text-left', {
                    'fw-bold': isSelected,
                })}
            >
                <NcsIcon
                    dept='nml'
                    size='sm'
                    variant={isSelected ? 'fas' : 'far'}
                    category='taskStage'
                    value={stage?.stage}
                    className={classNames('me-3 fa-fw', {
                        'text-primary': isSelected,
                    })}
                />
                {dropdowns?.['task.stage']?.find((stg) => stg?.code == stage?.stage)?.label}
            </span>
        ),
        value: stage?.stage,
    }
}
