import classnames from 'classnames'
import { NcsIcon } from 'components/utils'
import { Button, Card, Dropdown, DropdownButton, Form } from 'front'
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import { useTrackerDropdowns } from 'tracker/api'

export function OptionsCard({ packet, canEdit = true }) {
    const { data: dropdowns } = useTrackerDropdowns()

    const specialHandlingOptions = dropdowns?.['docgen.packet.options.special_handling'] ?? []

    return (
        <Card className='pb-2'>
            <Card.Header className='d-flex justify-content-between align-items-center pe-3'>
                <Card.Title as='h5' className='d-block'>
                    Options
                </Card.Title>
            </Card.Header>
            <Card.Body className=''>
                <div className='d-flex justify-content-between align-items-center mb-3'>
                    <small className='d-block'>Return Address</small>
                    <ToggleButtonGroup
                        name='returnAddressToggle'
                        value={packet?.options?.return_address}
                        type='radio'
                        className='btn-group-segment'
                        size='xs'
                    >
                        {returnAddressOptions.map(({ label, value }, i) => (
                            <ToggleButton
                                value={value}
                                variant=''
                                onClick={() => packet.updateOptions({ return_address: value })}
                                disabled={!canEdit}
                            >
                                {label}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                    <small className='d-block'>Special Handling</small>
                    <DropdownButton
                        variant='white'
                        title={
                            <SpecialHandlingOptionLabel
                                label={
                                    specialHandlingOptions?.find(
                                        ({ code }) => code === packet?.options?.special_handling
                                    )?.label
                                }
                                code={packet?.options?.special_handling}
                                selectedLabel
                            />
                        }
                        disabled={!canEdit}
                        size='sm'
                    >
                        {specialHandlingOptions
                            ?.filter(({ is_active }) => is_active)
                            ?.sort((a, b) => {
                                if (a.code === 'OT') return 1
                                if (b.code === 'OT') return -1
                                return 0
                            })
                            ?.map(({ code, label }, i) => {
                                return (
                                    <Dropdown.Item
                                        key={i}
                                        onClick={() =>
                                            packet.updateOptions({ special_handling: code })
                                        }
                                        className='ps-1'
                                    >
                                        <SpecialHandlingOptionLabel code={code} label={label} />
                                    </Dropdown.Item>
                                )
                            })}
                        <Dropdown.Divider />
                        <Dropdown.Item
                            onClick={() => packet.updateOptions({ special_handling: null })}
                            className='ps-1'
                        >
                            <SpecialHandlingOptionLabel />
                        </Dropdown.Item>
                    </DropdownButton>
                </div>
                {packet?.suggestedSpecialHandling &&
                    packet?.suggestedSpecialHandling != packet?.options?.special_handling && (
                        <Button
                            variant='warning'
                            size='xs'
                            className='w-100 d-flex justify-content-between mt-2'
                            onClick={() =>
                                packet.updateOptions({
                                    special_handling: packet?.suggestedSpecialHandling,
                                })
                            }
                            disabled={!canEdit}
                        >
                            <div className='fst-italic'>
                                <i className='fas fa-circle-exclamation me-2' />
                                Use suggested handling:
                            </div>
                            <div className='fw-bold'>
                                {
                                    specialHandlingOptions?.find(
                                        ({ code }) => code === packet?.suggestedSpecialHandling
                                    )?.label
                                }
                            </div>
                        </Button>
                    )}
                <div className='mt-3'>
                    <small className='d-block'>Special Handling Message</small>
                    <Form.Control
                        as='textarea'
                        light
                        value={packet?.options?.special_handling_message}
                        onChange={(e) =>
                            packet.updateOptions({ special_handling_message: e.target.value }, 2000)
                        }
                        placeholder='Special handling message'
                        className='mt-2 w-100'
                        disabled={!canEdit}
                    />
                </div>
            </Card.Body>
        </Card>
    )
}

const SpecialHandlingOptionLabel = ({ code = null, label = 'None', selectedLabel }) => (
    <>
        {code ? (
            <NcsIcon dept='nml' category='specialHandling' value={code} className='me-2 fa-fw' />
        ) : (
            <i
                className={classnames('far fa-circle me-2 fa-fw opacity-0', {
                    'd-none': selectedLabel,
                })}
            />
        )}
        {label}
    </>
)

const returnAddressOptions = [
    {
        label: 'NCS',
        value: 'ncs',
    },
    {
        label: 'Michelle Gerred',
        value: 'mgerred',
    },
]

function SpecialHandlingMessage({ value, onChange, special_handling }) {
    return (
        <div className='mt-3'>
            <small className='d-block'>Special Handling Message</small>
            <Form.Control
                as='textarea'
                light
                value={value}
                onChange={onChange}
                placeholder='Special handling message'
                className='mt-2 w-100'
                ref={textBoxRef}
            />
        </div>
    )
}
