import { Button } from 'front'
import { Alert, Col, Modal, Row } from 'react-bootstrap'
import { useDeletePacket } from 'tracker/api'

export default function DeletePacketModal({ show, onHide, onSuccess = () => {}, packetId }) {
    const { mutate: deletePacket, ...deletePacketApi } = useDeletePacket({
        onSuccess: () => {
            onHide()
            onSuccess()
        },
    })

    let submit = () => deletePacket(packetId)

    return (
        <Modal centered show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Packet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>Are you sure you want to delete this packet?</Col>
                </Row>
                {deletePacketApi?.isError && <DeletePacketErrorAlert />}
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <Button onClick={onHide} ghost variant='secondary' className='me-2'>
                    Cancel
                </Button>
                <Button
                    onClick={submit}
                    icon={deletePacketApi?.isLoading ? 'fal fa-spinner-third fa-spin' : null}
                    variant='danger'
                >
                    Delete Packet
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const DeletePacketErrorAlert = () => (
    <Alert variant='soft-danger'>
        <div className='d-flex'>
            <div className='flex-shrink-0'>
                <i className='fas fa-circle-exclamation' />
            </div>
            <div className='flex-grow-1 ms-2'>Error cancelling packet</div>
        </div>
    </Alert>
)
