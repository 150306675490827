import { useEffect } from 'react'
import { Button } from 'front'

export default function Modal({ id, title, children, save, footer, showSave, size, onClose }) {
    useEffect(() => {
        $('#' + id).on('hidden.bs.modal', function (event) {
            // console.log('modal hiding')
            if (onClose) {
                onClose()
            }
        })
    }, [])

    return (
        <div className={'modal fade'} id={id}>
            <div className={'modal-dialog ' + size ?? ''} role='document'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h5 className='modal-title'>{title}</h5>
                        <button type='button' className='btn-close' data-bs-dismiss='modal' />
                    </div>
                    {children && <div className='modal-body'>{children}</div>}
                    <div className='modal-footer'>
                        {footer}
                        {save && (showSave ?? true) && (
                            <Button
                                variant={'primary'}
                                outline
                                data-bs-dismiss='modal'
                                onClick={save}
                            >
                                <span className={'far fa-save'} /> Save
                            </Button>
                        )}
                        <Button data-bs-dismiss='modal'>
                            <span className={'far fa-times'} /> Close
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
