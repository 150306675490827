import React, { useState } from 'react'

export const LayoutContext = React.createContext({})

export default function FrontLayout({ children }) {
    const [leftSidebarCollapsed, setLeftSidebarCollapsed] = useState(false)

    return (
        <LayoutContext.Provider value={{ leftSidebarCollapsed, setLeftSidebarCollapsed }}>
            <main id='content' role='main' className='main d-flex flex-grow-1 overflow-hidden'>
                {children}
            </main>
        </LayoutContext.Provider>
    )
}
