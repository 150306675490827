import React from 'react'
import ActionDisplay from './ActionDisplay'

export default function DynamicActions({ actions }) {
    // console.log('actions',actions,footer)
    return (
        <div className={'d-flex mt-3'}>
            {actions.map((action, i) => (
                <div className={'me-1'} key={i}>
                    <ActionDisplay action={action} />
                </div>
            ))}
        </div>
    )
}
