import Cookies from 'js-cookie'
import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'
import { PortalContext } from '../GlobalState'

export default function OnlyOffice({
    documentUrl, // (Required) URL to the document
    callbackUrl, // (Required) Callback that OnlyOffice will send status updates to
    allowEditing = false,
    fileType = 'docx',
    documentType = 'word',
    forceSave = true,
    title = '',
    placeholder = 'onlyoffice',

    // Events
    onAppReady,
    onDocumentReady,
    onDocumentStateChange,
    onDownloadAs,
    onError,
    onRequestEditRights,
    onRequestClose,
}) {
    const employee = Cookies.getJSON('employee')
    const { theme } = useContext(PortalContext)
    let docEditor
    useEffect(() => {
        if (!docEditor) {
            let config = {
                width: '100%',
                height: '100%',
                type: 'embed',
                documentType: documentType,
                document: {
                    fileType: fileType,
                    key: makeKey(),
                    title: title,
                    url: documentUrl,
                    info: {
                        owner: `${employee?.FirstName} ${employee?.LastName}`,
                    },
                    permissions: {
                        comment: true,
                        edit: allowEditing,
                        review: true,
                        print: true,
                        download: true,
                    },
                },
                editorConfig: {
                    callbackUrl: callbackUrl,
                    mode: allowEditing ? 'edit' : 'view',
                    lang: 'en',
                    user: {
                        firstname: employee?.FirstName,
                        id: employee?.EmpID,
                        lastname: employee?.LastName,
                        name: `${employee?.FirstName} ${employee?.LastName}`,
                        group: employee?.Department,
                    },
                    embedded: {
                        toolbarDocked: 'top',
                    },
                    customization: {
                        about: false,
                        chat: false,
                        comments: false,
                        feedback: false,
                        goback: false,
                        forcesave: forceSave,
                        uiTheme: theme === 'light' ? 'theme-light' : 'theme-dark',
                        logo: {
                            image: '',
                            imageEmbedded: '',
                            url: '',
                        },
                        customer: {
                            logo: '',
                            name: 'NCS Credit',
                            address: '729 Miner Rd.',
                            mail: 'support@ncscredit.com',
                            www: 'www.ncscredit.com',
                            info: '',
                        },
                    },
                },
                events: {
                    // the application is loaded into the browser
                    onAppReady: onAppReady ?? '',
                    // the document is loaded into the document editor
                    onDocumentReady: onDocumentReady ?? '',
                    // the document is modified
                    // it is called with the parameter: {"data": true} when the current user is editing the document and with the parameter: {"data" : false} when the current user's changes are sent to the document editing service.
                    onDocumentStateChange: onDocumentStateChange ?? '',
                    // the absolute URL to the edited file when the downloadAs method is being called
                    onDownloadAs: onDownloadAs ?? '',
                    // an error or some other specific event occurs
                    onError: onError ?? '',
                    // the user is trying to switch the document from the viewing into the editing mode by clicking the Edit Document button
                    onRequestEditRights: onRequestEditRights ?? '',
                    // the work with the editor must be ended and the editor must be closed
                    onRequestClose: onRequestClose ?? '',
                },
            }

            if (window.DocsAPI && window.DocsAPI.DocEditor) {
                docEditor = new window.DocsAPI.DocEditor(placeholder, config)
                window.docEditorConfig = config;
            }
        }
        return () => {
            docEditor.destroyEditor();
            window.docEditorConfig = null;
        }
    }, [theme])

    if (!documentUrl || !callbackUrl) return <></>

    return <div id='onlyoffice' style={{ minWidth: '100%', minHeight: '100%' }} />
}

function makeKey() {
    var text = ''
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (var i = 0; i < 20; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length))
    return text
}

OnlyOffice.propTypes = {
    documentUrl: PropTypes.string.isRequired,
    callbackUrl: PropTypes.string.isRequired,
    allowEditing: PropTypes.bool,
    fileType: PropTypes.oneOf([
        '.csv',
        '.djvu',
        '.doc',
        '.docm',
        'docx',
        '.docx',
        '.docxf',
        '.dot',
        '.dotm',
        '.dotx',
        '.epub',
        '.fb2',
        '.fodp',
        '.fods',
        '.fodt',
        '.htm',
        '.html',
        '.mht',
        '.odp',
        '.ods',
        '.odt',
        '.oform',
        '.otp',
        '.ots',
        '.ott',
        '.oxps',
        '.pdf',
        '.pot',
        '.potm',
        '.potx',
        '.pps',
        '.ppsm',
        '.ppsx',
        '.ppt',
        '.pptm',
        '.pptx',
        '.rtf',
        '.txt',
        '.xls',
        '.xlsb',
        '.xlsm',
        '.xlsx',
        '.xlt',
        '.xltm',
        '.xltx',
        '.xml',
        '.xps',
    ]),
    documentType: PropTypes.oneOf(['word', 'cell', 'slide']),
    forceSave: PropTypes.bool,
    onAppReady: PropTypes.func,
    onDocumentReady: PropTypes.func,
    onDocumentStateChange: PropTypes.func,
    onDownloadAs: PropTypes.func,
    onError: PropTypes.func,
    onRequestEditRights: PropTypes.func,
    onRequestClose: PropTypes.func,
}
