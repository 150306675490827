import PriceInput from '@/components/front/components/PriceInput'
import { formatDate } from 'components/utils'
import { Form } from 'front'
import { InputGroup } from 'react-bootstrap'

export default function FurnishingDatesEdit({
    furnishings,
    updateFurnishings,
    updateFurnishingData,
}) {
    const existingFurnishings = furnishings?.filter(({ _create }) => !_create)
    const newFurnishings = furnishings?.filter(({ _create }) => _create)

    return (
        <div>
            {existingFurnishings
                ?.sort((a, b) => new Date(a.date) - new Date(b.date))
                ?.map((furnishing, index) => (
                    <FurnishingDateRow
                        key={furnishing?._id}
                        furnishing={furnishing}
                        updateFurnishings={updateFurnishings}
                        rowIndex={index}
                        updateFurnishingData={updateFurnishingData}
                    />
                ))}
            <br />
            {newFurnishings
                ?.sort((a, b) => new Date(a.date) - new Date(b.date))
                ?.map((furnishing, index) => (
                    <FurnishingDateRow
                        key={furnishing?._id}
                        furnishing={furnishing}
                        updateFurnishings={updateFurnishings}
                        rowIndex={index}
                        updateFurnishingData={updateFurnishingData}
                    />
                ))}
        </div>
    )
}

function FurnishingDateRow({
    furnishing,
    updateFurnishings,
    rowIndex,
    showHeader = true,
    updateFurnishingData,
}) {
    return (
        <div key={furnishing?._id} className={'d-flex align-items-center mb-3'}>
            <div>
                {rowIndex === 0 && <div className='mb-1'>Date</div>}
                <Form.Date
                    light
                    value={formatDate(furnishing?.date)}
                    className={!furnishing?.date ? 'border-danger' : ''}
                    onChange={([date]) => {
                        updateFurnishings({
                            furnishing,
                            name: 'date',
                            value: formatDate(date, 'YYYY-MM-DD'),
                        })
                    }}
                    style={{
                        width: 130,
                    }}
                />
            </div>
            <div className='mx-2'>
                {rowIndex === 0 && <div className='mb-1'>Balance</div>}
                <InputGroup light>
                    <PriceInput
                        light
                        value={furnishing?.balance}
                        name='balance'
                        onChange={({ target: { value, name } }) => {
                            updateFurnishingData(furnishing, {
                                balance: value,
                                is_paid: parseFloat(value, 2) === 0 ? true : furnishing?.is_paid,
                            })
                        }}
                        onBlur={() => {
                            if (
                                parseFloat(furnishing?.balance, 2) >
                                parseFloat(furnishing?.total, 2)
                            ) {
                                updateFurnishings({
                                    furnishing,
                                    name: 'total',
                                    value: furnishing?.balance,
                                })
                            }
                        }}
                    />
                    <InputGroup.Text
                        light
                        className='pointer'
                        onClick={() => {
                            updateFurnishingData(furnishing, {
                                balance: 0,
                                is_paid: !furnishing?.is_paid,
                            })
                        }}
                    >
                        <Form.Check
                            light
                            onChange={() => {}}
                            checked={furnishing?.is_paid}
                            name='balance'
                            className='me-1'
                        />
                        Paid
                    </InputGroup.Text>
                </InputGroup>
            </div>
            {/* <div className='me-2'>
                {rowIndex === 0 && <div className='mb-1'>Total</div>}
                <PriceInput
                    light
                    value={furnishing?.total}
                    name='total'
                    onChange={({ target: { value, name } }) => {
                        updateFurnishings({ furnishing, name, value })
                    }}
                />
            </div> */}
            <div>
                {rowIndex === 0 && <div className='mb-1'>&nbsp;</div>}
                <Form.Button
                    ghost
                    variant='danger'
                    onClick={() => {
                        updateFurnishings({
                            furnishing,
                            name: 'is_deleted',
                            value: true,
                        })
                    }}
                    icon='far fa-times'
                />
            </div>
        </div>
    )
}
