export default function Skeleton({ variant, size, style }) {
    let skeletonStyle = { ...style }
    if (variant == 'block') {
        // skeletonStyle = {height: "20px"}
    }

    switch (size) {
        case 'small':
        case 'sm':
            break
        case 'medium':
        case 'md':
            break
        case 'large':
        case 'lg':
            break
    }
    return (
        <div className={`row skeleton skeleton-${variant}`} style={skeletonStyle}>
            <div className='col' />
        </div>
    )
}
