import { Steps } from 'front'
import NoteStep from './NoteStep'

export function StepNotes({ title, notes, searchText }) {
    // console.log('notes: ', notes)
    return (
        notes?.length > 0 && (
            <>
                {title?.length > 0 && <h4>{title}</h4>}
                <Steps>
                    {notes?.map((note) => (
                        <NoteStep key={note?.id} note={note} searchText={searchText} />
                    ))}
                </Steps>
            </>
        )
    )
}
