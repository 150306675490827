export default function makeKeysLowerCase(obj) {
    // console.log('making object lowercase',obj)
    let key,
        keys = Object.keys(obj)
    let n = keys.length
    let newobj = {}
    while (n--) {
        key = keys[n]
        // console.log('type of',typeof obj[key] === 'object',key,obj[key])
        newobj[key.toLowerCase()] =
            obj[key] !== null && typeof obj[key] === 'object'
                ? makeKeysLowerCase(obj[key])
                : obj[key]
    }
    return newobj
}
