import React, { Fragment, useContext, useState } from 'react'
import Collapse from '../../Collapse'
import { DynamicCardContext } from '../Context/DynamicCardContext'
import DynamicActions from './DynamicActions'
import SectionDisplay from './SectionDisplay'
import { Card } from 'react-bootstrap'
import { COMPONENT_MAP } from './custom'

export default function DynamicCardSection({ section, startCollapsed }) {
    const { data, collapseClass, page } = useContext(DynamicCardContext)
    const [startedCollapsed] = useState(
        (startCollapsed || data?.pages?.[page]?.startCollapsed) ?? false
    )
    const isCustom = section?.type === 'custom'
    const CustomComponent = isCustom ? COMPONENT_MAP?.[section?.component] : null

    return (
        <ul
            className={
                'list-group list-group-flush ' +
                (section?.showWhenCollapsed
                    ? ''
                    : `collapse ${collapseClass} ` + (startedCollapsed ? '' : 'show'))
            }
        >
            <li className={'list-group-item' + (section?.flush ? ' p-0' : '')}>
                {isCustom ? (
                    CustomComponent && <CustomComponent {...section} />
                ) : section?.collapsableSection ? (
                    <Collapse title={section.title} className={section?.title ? 'h5' : ''}>
                        <SectionBody section={section} />
                    </Collapse>
                ) : (
                    <SectionBody section={section} />
                )}
            </li>
        </ul>
    )
}

function SectionBody({ section }) {
    const { title, actions, collapsableSection } = section

    return (
        <Fragment>
            {title?.length > 0 && !collapsableSection && <Card.Title>{title}</Card.Title>}
            <SectionDisplay section={section} />
            {actions && <DynamicActions actions={actions} />}
        </Fragment>
    )
}
