// Renders a text input area that allows a user to create list of tags.
// Users can freely enter text or select from dropdown list provided
// ( Component acts like Send To section of an email form )

import { useEffect, useState } from 'react'

export default function TypeTags(props) {
    const [tagList, setTagList] = useState(props?.tagList ?? [])
    const [input, setInput] = useState('')
    const [inputError, setInputError] = useState(0)
    const [previousSendLength, updatePreviousSendLength] = useState(0)

    useEffect(() => {
        // console.log('🚀 ~ useEffect ~ tagList:', tagList)
        if (props?.tagList) setTagList(props?.tagList)
    }, [props?.tagList])

    function addTag(text) {
        let tag = text.trim()

        if (tagList.indexOf(tag) != -1) {
            setInput({ input: '' })
        } else if (props?.validate(tag)) {
            let a = tagList.concat([tag])

            setTagList([...tagList, tag])
            setInput('')
            setInputError(0)

            props?.handleList(a)
        } else if (tag != '') {
            setInputError(1)
        } else if (tag == '') {
            setInputError(0)
        }
    }

    function renderTags() {
        let renderArray = []

        tagList.map((element, i) => {
            renderArray.push(
                <span
                    style={{ padding: '8px', margin: '4px', transition: '.5s' }}
                    key={i}
                    className='badge bg-soft-secondary text-secondary'
                >
                    {element}
                    <span
                        style={{ marginLeft: '4px' }}
                        className='far fa-times pointer'
                        onClick={() => {
                            handleRemove(i)
                        }}
                    />
                </span>
            )
        })

        // Renders a blank tag as the last element in the list which allows for a transition
        renderArray.push(
            <span
                style={{
                    color: 'grey',
                    backgroundColor: 'white',
                    opacity: 0,
                    transition: '.5s',
                    padding: '0px',
                }}
                key={tagList.length}
                className='badge'
            >
                &nbsp;
            </span>
        )

        return renderArray
    }

    function onInputChange(target) {
        setInput(target.value)
        if (target.value.length - previousSendLength > 1) {
            updatePreviousSendLength(0)
            addTag(target.value)
            return
        } else if (target.value.substr(target.value.length - 1) == ' ') {
            addTag(target.value.slice(0, -1))
        }

        updatePreviousSendLength(target.value.length)
    }

    const handleBlur = (e) => {
        addTag(e.target.value)
    }

    function renderErrorBubble() {
        return (
            <span
                style={{
                    padding: '0px 8px',
                    display: 'inline-block',
                    color: 'red',
                    float: 'right',
                    fontSize: '18px',
                    margin: '4px',
                }}
                className='fas fa-exclamation-circle fa-2x'
            />
        )
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            addTag(e.target.value)
        } else if (e.key === 'Backspace' && e.target.selectionEnd == 0) {
            let updatedArray = tagList

            updatedArray.pop()

            setTagList(updatedArray)

            props?.handleList(updatedArray)
        }
    }

    const handleRemove = (i) => {
        let tempArray = tagList
        tempArray.splice(i, 1)

        setTagList(tempArray)

        // console.log(tempArray);

        props?.handleList(tempArray)
    }

    function renderOptionList(textBoxId) {
        let displayArray = []
        if (props?.optionList) {
            props?.optionList.forEach((item, i) => {
                if (item.emailAddress != '' && !tagList.includes(item.emailAddress)) {
                    displayArray.push(
                        <option key={i} value={item.emailAddress}>
                            {item.title}
                        </option>
                    )
                }
            })
        }

        return (
            <datalist id={textBoxId} onKeyDown={handleKeyPress}>
                {displayArray}
            </datalist>
        )
    }

    const id = props?.id ?? Math.random()

    return (
        <div
            onClick={() => {
                document.getElementById(id).focus()
            }}
            style={{ width: '100%', cursor: 'text' }}
        >
            {/* {renderTags()}
             */}

            {tagList?.length > 0 &&
                tagList.map((element, i) => (
                    <span
                        style={{
                            padding: '8px',
                            margin: '4px',
                            transition: '.5s',
                        }}
                        key={i}
                        className='badge bg-soft-secondary text-secondary'
                    >
                        {element}
                        <span
                            style={{ marginLeft: '4px' }}
                            className='far fa-times pointer'
                            onClick={() => {
                                handleRemove(i)
                            }}
                        />
                    </span>
                ))}
            <span
                style={{
                    color: 'grey',
                    backgroundColor: 'white',
                    opacity: 0,
                    transition: '.5s',
                    padding: '0px',
                }}
                className='badge'
            >
                &nbsp;
            </span>
            <input
                className='type-tags-input form-control'
                type='text'
                style={{
                    border: '0px',
                    boxShadow: 'inset 0px 0px 0px 0px white',
                    width: '250px',
                    display: 'inline-block',
                    paddingLeft: '0px',
                }}
                id={id ?? Math.random()}
                placeholder=''
                value={input}
                onChange={(event) => onInputChange(event.target)}
                // onKeyPress={handleKeyPress}
                onKeyDown={handleKeyPress}
                onBlur={handleBlur}
                list={id + '-list'}
            />
            {renderOptionList(id + '-list')}
            {inputError ? renderErrorBubble() : ''}
        </div>
    )
}
