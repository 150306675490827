import { GoogleMaps } from 'components/utils'
import React, { Component } from 'react'
import axios from '../../axios'
import Dropdown from '../../utils/Dropdown'
import LoadingSpinner from '../../utils/LoadingSpinner'
export default class PdrReport extends Component {
    constructor(props) {
        super(props)

        let numReports = 1
        let colWidth = Math.floor(12 / numReports)

        this.state = {
            showBlankFields: true,
            showSameValues: true,
            colWidth: colWidth,
            docLoading: false,
            docMessage: null,
            numReports: numReports,
            docuwareModal: false,
        }
    }

    orderDocs(files) {
        this.setState({
            docLoading: true,
        })

        var settings = {
            url: '/tracker/nml/pdr/1/report_files/json',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                files: files,
                property_id: this.props.report.SubjectProperty.PropertyId,
                direct_no: this.props.directNo,
            },
        }

        axios(settings).then((response) => {
            let docsAvailable = response.data.Reports.every((report) => {
                return report.ReportStatus == 'Ready'
            })
            this.setState({
                docLoading: false,
                docMessage: docsAvailable ? 'Docs stored in Docuware' : 'No docs available',
            })
        })
    }

    pair(label, data, special = null) {
        if (!this.props.compare) {
            var size1 = 6
            var size2 = 6
        } else {
            var size1 = 2
            var size2 = 10
        }

        let output = []
        if (special == 'address') {
            output.push(
                <React.Fragment>
                    <span className={'pdr-data'}>{data?.StreetAddress}</span>
                    ,<br />
                    <span className={'pdr-data'}>{data?.City}</span>
                    ,<br />
                    <span className={'pdr-data'}>{data?.State}</span>
                    <br />
                    <span className={'pdr-data'}>{data?.Zip9}</span>
                </React.Fragment>
            )
        } else {
            switch (special) {
                case null:
                    output.push(<span className={'pdr-data'}>{data}</span>)
                    break
                case 'date':
                    output.push(<span className={'pdr-data'}>{this.dateFormat(data)}</span>)
                    break
                case 'money':
                    output.push(<span className={'pdr-data'}>{this.formatMoney(data)}</span>)
                    break
                default:
                    ;<span className={'pdr-data'}>{data}</span>
            }
        }

        return (
            <div className='row pdr-row'>
                <div className={'pdr-label text-muted col-' + size1}>
                    <span>{label}:</span>
                </div>
                <div className={'col-' + size2}>
                    {output?.map((e, i) => {
                        return (
                            <div
                                key={i}
                                className={
                                    this.props.compare
                                        ? 'col-' + this.state.colWidth + ' pdr-compare'
                                        : ''
                                }
                                style={{ marginBottom: '3px' }}
                            >
                                {e}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    dateFormat(isoDate) {
        let dateString = isoDate

        if (!dateString?.isValid || dateString == 'Invalid date') {
            return ''
        }

        return dateString
    }

    formatMoney(value) {
        return value ? '$ ' + value : ''
    }

    render() {
        const { showBlankFields, docLoading, docMessage } = this.state
        const { report, compare, compareReports, rerun, toggleView } = this.props
        const {
            LastMarketSaleInformation,
            LocationInformation,
            OwnerInformation,
            OwnerTransferInformation,
            SiteInformation,
            PriorSaleInformation,
            PropertyCharacteristics,
            TaxInformation,
            SubjectProperty,
        } = report

        console.log('compare', compare)

        return (
            <div className='d-flex flex-column' style={{ height: 'calc(100vh - 86px)' }}>
                <div style={{ height: '30%' }}>
                    <GoogleMaps
                        streetAddress1={SubjectProperty?.SitusAddress?.StreetAddress}
                        city={SubjectProperty?.SitusAddress?.City}
                        state={SubjectProperty?.SitusAddress?.State}
                        zip={SubjectProperty?.SitusAddress?.Zip9}
                        height='100%'
                    />
                </div>
                <div className='px-6 pt-6 pb-8' style={{ height: '70%', overflowY: 'auto' }}>
                    <div className='mb-4'>
                        {/*<div className={'btn-group'}>*/}
                        <button
                            className='btn btn-white'
                            style={{ marginRight: '7px' }}
                            onClick={() => {
                                rerun(SubjectProperty.PropertyId)
                            }}
                        >
                            <span className='fal fa-sync' /> &nbsp;Rerun
                        </button>
                        {/* <button className="btn btn-secondary" style={{marginRight: "7px"}} onClick={()=>{rerun(SubjectProperty.PropertyId.values[0])} }>
                                            <span className="fal fa-star" /> &nbsp;Save APN to Tracker
                                        </button> */}
                        {/*<span style={{marginRight:"7px"}}>*/}
                        {/*	<Docuware*/}
                        {/*		modal*/}
                        {/*		cabinet="NML"*/}
                        {/*		file={this.props.project}*/}
                        {/*		service={[]}*/}
                        {/*	/>*/}
                        {/*</span>*/}
                        <Dropdown text='Order Docs'>
                            <a
                                className={'dropdown-item'}
                                onClick={() => {
                                    this.orderDocs(['LastTransferDocument', 'LastFinanceDocument'])
                                }}
                            >
                                All
                            </a>
                            <a
                                className={'dropdown-item'}
                                onClick={() => {
                                    this.orderDocs(['LastTransferDocument'])
                                }}
                            >
                                Last Transfer Document
                            </a>
                            <a
                                className={'dropdown-item'}
                                onClick={() => {
                                    this.orderDocs(['LastFinanceDocument'])
                                }}
                            >
                                Last Finance Document
                            </a>
                        </Dropdown>
                        {/*</div>*/}
                        &nbsp;
                        {docLoading && <LoadingSpinner bsStyle='l' pulse />}
                        {!docLoading && docMessage && (
                            <span className='light-font'>{docMessage}</span>
                        )}
                    </div>
                    <Section id='subject-property'>
                        <Row>
                            <div className='col-6'>
                                <h4 className='block-title'>
                                    <b>Property</b>
                                </h4>
                            </div>
                        </Row>
                        <Row>
                            <Chunk compare={compare} size='4'>
                                {this.pair(
                                    'Property Address',
                                    SubjectProperty?.SitusAddress,
                                    'address'
                                )}
                                {this.pair('County', SubjectProperty?.SitusAddress?.County)}
                            </Chunk>
                            <Chunk compare={compare} size='4'>
                                {this.pair(
                                    'Data as of',
                                    LastMarketSaleInformation?.CurrentThroughDate,
                                    'date'
                                )}
                            </Chunk>
                        </Row>
                    </Section>
                    <Section showBlankFields={showBlankFields} id='owner-information'>
                        <Row>
                            <div className='col-6'>
                                <h4 className='block-title'>
                                    <b>Owner Information</b>
                                </h4>
                            </div>
                        </Row>
                        <Row>
                            <Chunk compare={compare} size='4'>
                                {this.pair('Owner Name', OwnerInformation.OwnerNames)}
                                {this.pair('Occupancy Name', OwnerInformation?.Occupancy)}
                                {this.pair(
                                    'Vesting',
                                    OwnerInformation?.OwnerVestingInfo?.VestingOwner
                                )}
                            </Chunk>
                            <Chunk compare={compare} size='4'>
                                {this.pair(
                                    'Mailing Address',
                                    OwnerInformation?.MailingAddress,
                                    'address'
                                )}
                            </Chunk>
                        </Row>
                    </Section>
                    <Section showBlankFields={showBlankFields} id='location-information'>
                        <h4 className='block-title'>
                            <b>Location Information</b>
                        </h4>
                        <Row>
                            <Chunk compare={compare} size='4'>
                                {this.pair('APN', LocationInformation?.APN)}
                                {this.pair(
                                    'Munic / Twnship',
                                    LocationInformation?.MunicipalityTownship
                                )}
                                {this.pair('Subdivision', LocationInformation?.Subdivision)}
                                {this.pair('Neighborhood', LocationInformation?.NeighborhoodName)}
                                {this.pair(
                                    'Elementary School',
                                    LocationInformation?.ElementarySchool
                                )}
                            </Chunk>
                            <Chunk compare={compare} size='4'>
                                {this.pair('Alternate APN', LocationInformation?.AlternateAPN)}
                                {this.pair(
                                    'Twnship-Rng-Sec',
                                    LocationInformation?.TownshipRangeSection
                                )}
                                {this.pair('Tract #', LocationInformation?.TractNumber)}
                                {this.pair('School District', LocationInformation?.SchoolDistrict)}
                                {this.pair('Middle School', LocationInformation?.MiddleSchool)}
                            </Chunk>
                            <Chunk compare={compare} size='4'>
                                {/* {this.pair("County",                this.item(LocationInformation.County))} */}
                                {this.pair('Census Tract', LocationInformation?.CensusTract)}
                                {this.pair('Census Block', LocationInformation?.CensusBlock)}
                                {this.pair('Legal Lot', LocationInformation?.LegalLot)}
                                {this.pair('Legal Block', LocationInformation?.LegalBlock)}
                                {this.pair('Legal Book | Page', LocationInformation?.LegalBookPage)}
                                {this.pair('High School', LocationInformation?.HighSchool)}
                            </Chunk>
                        </Row>
                        <br />
                        <Row>
                            <Chunk compare={compare}>
                                {this.pair(
                                    'Legal Description',
                                    LocationInformation?.LegalDescription
                                )}
                            </Chunk>
                        </Row>
                    </Section>
                    <Section showBlankFields={showBlankFields} id='last-transfer'>
                        <h4 className='block-title'>
                            <b>Last Transfer / Conveyance - Current Owner</b>
                        </h4>
                        <Row>
                            <Chunk compare={compare} size='4'>
                                {this.pair(
                                    'Transfer Date',
                                    OwnerTransferInformation?.SaleDate,
                                    'date'
                                )}
                                {this.pair(
                                    'Rec Date',
                                    OwnerTransferInformation?.RecordingSaleDate,
                                    'date'
                                )}
                                {this.pair('Buyer Name', OwnerTransferInformation?.BuyerName)}
                            </Chunk>
                            <Chunk compare={compare} size='4'>
                                {this.pair('Price', OwnerTransferInformation?.SalePrice)}
                                {this.pair('Seller Name', OwnerTransferInformation?.SellerName)}
                            </Chunk>
                            <Chunk compare={compare} size='4'>
                                {this.pair(
                                    'Transfer Doc #',
                                    OwnerTransferInformation?.TransferDocumentNumber
                                )}
                                {this.pair('Deed Type', OwnerTransferInformation?.DeedType)}
                            </Chunk>
                        </Row>
                    </Section>
                    <Section showBlankFields={showBlankFields} id='last-market-sale'>
                        <h4 className='block-title'>
                            <b>Last Market Sale</b>
                        </h4>
                        <Row>
                            <Chunk compare={compare} size='4'>
                                {this.pair(
                                    'Sale Date',
                                    LastMarketSaleInformation?.SaleDate,
                                    'date'
                                )}
                                {this.pair(
                                    'Rec Date',
                                    LastMarketSaleInformation?.RecordingDate,
                                    'date'
                                )}
                                {this.pair(
                                    'Multi / Split Sale',
                                    LastMarketSaleInformation?.MultiOrSplitSaleIdentifier
                                )}
                                {this.pair(
                                    '1st Mtg Amt',
                                    LastMarketSaleInformation?.FirstMortgageAmount
                                )}
                                {this.pair(
                                    '1st Mtg Type',
                                    LastMarketSaleInformation?.FirstMortgageType
                                )}
                                {this.pair(
                                    '2nd Mtg Amt',
                                    LastMarketSaleInformation?.SecondMortgageAmount
                                )}
                                {this.pair(
                                    '2nd Mtg ype',
                                    LastMarketSaleInformation?.SecondMortgageType
                                )}
                                {this.pair('Seller Name', LastMarketSaleInformation?.SellerName)}
                                {this.pair('Lender', LastMarketSaleInformation?.Lender)}
                                {this.pair(
                                    'Title Company',
                                    LastMarketSaleInformation?.TitleCompany
                                )}
                            </Chunk>
                            <Chunk compare={compare} size='4'>
                                {this.pair('Sale Price', LastMarketSaleInformation?.SalePrice)}
                                {this.pair('Sale Type', LastMarketSaleInformation?.SaleType)}
                                {this.pair(
                                    'Price / Sq. Ft.',
                                    LastMarketSaleInformation?.PricePerSquareFoot
                                )}
                                {this.pair(
                                    '1st Mtg Rate',
                                    LastMarketSaleInformation?.FirstMortgageInterestRate
                                )}
                                {this.pair(
                                    '2nd Mtg Type',
                                    LastMarketSaleInformation?.SecondMortgageInterestType
                                )}
                            </Chunk>
                            <Chunk compare={compare} size='4'>
                                {this.pair('Deed Type', LastMarketSaleInformation?.DeedType)}
                                {this.pair(
                                    'New Construction',
                                    LastMarketSaleInformation?.NewConstruction
                                )}
                                {this.pair(
                                    '1st Mtg Doc #',
                                    LastMarketSaleInformation?.FirstMortgageDocumentNumber
                                )}
                                {this.pair(
                                    'Sale Doc #',
                                    LastMarketSaleInformation?.TransferDocumentNumber
                                )}
                            </Chunk>
                        </Row>
                    </Section>
                    <Section showBlankFields={showBlankFields} id='prior-sale-information'>
                        <h4 className='block-title'>
                            <b>Prior Sale Information</b>
                        </h4>
                        <Row>
                            <Chunk compare={compare} size='4'>
                                {this.pair(
                                    'Sale Date',
                                    PriorSaleInformation?.PriorSaleDate,
                                    'date'
                                )}
                                {this.pair(
                                    'Rec Date',
                                    PriorSaleInformation?.PriorRecordingDate,
                                    'date'
                                )}
                                {this.pair(
                                    '1st Mtg Amt',
                                    PriorSaleInformation?.PriorFirstMortgageAmount
                                )}
                                {this.pair(
                                    '1st Mtg Type',
                                    PriorSaleInformation?.PriorFirstMortgageType
                                )}
                                {this.pair('Prior Lender', PriorSaleInformation?.PriorLender)}
                            </Chunk>
                            <Chunk compare={compare} size='4'>
                                {this.pair('Sale Price', PriorSaleInformation?.PriorSalePrice)}
                                {this.pair(
                                    'Sale Type',
                                    PriorSaleInformation?.PriorSaleTypeDescription
                                )}
                                {this.pair(
                                    '1st Mtg Rate',
                                    PriorSaleInformation?.PriorFirstMortgageInterestRate
                                )}
                                {this.pair(
                                    '1st Mtg Type',
                                    PriorSaleInformation?.PriorFirstMortgageInterestType
                                )}
                            </Chunk>
                            <Chunk compare={compare} size='4'>
                                {this.pair('Prior Deed Type', PriorSaleInformation?.PriorDeedType)}
                                {this.pair(
                                    'Prior Sale Doc #',
                                    PriorSaleInformation?.PriorDocNumber
                                )}
                            </Chunk>
                        </Row>
                    </Section>
                    <Section showBlankFields={showBlankFields} id='property-characteristics'>
                        <h4 className='block-title'>
                            <b>Property Characteristics</b>
                        </h4>
                        <Row>
                            <Chunk compare={compare} size='4'>
                                {this.pair('Gross Living Area', PropertyCharacteristics?.GrossArea)}
                                {this.pair('Living Area', PropertyCharacteristics?.LivingArea)}
                                {this.pair(
                                    'Total Adj. Area',
                                    PropertyCharacteristics?.TotalAdjustedArea
                                )}
                                {this.pair('Above Grade', PropertyCharacteristics?.AboveGrade)}
                                {this.pair('Basement Area', PropertyCharacteristics?.BasementArea)}
                                {this.pair('Style', PropertyCharacteristics?.Style)}
                                {this.pair('Foundation', PropertyCharacteristics?.Foundation)}
                                {this.pair('Quality', PropertyCharacteristics?.Quality)}
                                {this.pair('Condition', PropertyCharacteristics?.Condition)}
                            </Chunk>
                            <Chunk compare={compare} size='4'>
                                {this.pair('Total Rooms', PropertyCharacteristics?.TotalRooms)}
                                {this.pair('Bedrooms', PropertyCharacteristics?.Bedrooms)}
                                {this.pair('Baths (Full)', PropertyCharacteristics?.FullBath)}
                                {this.pair('Baths (Half)', PropertyCharacteristics?.HalfBath)}
                                {this.pair('Pool', PropertyCharacteristics?.Pool)}
                                {this.pair(
                                    'Fireplace',
                                    PropertyCharacteristics?.FirePlaceIndicator
                                )}
                                {this.pair('Cooling', PropertyCharacteristics?.AirConditioning)}
                                {this.pair('Heating', PropertyCharacteristics?.HeatType)}
                                {this.pair('Exterior Wall', PropertyCharacteristics?.ExteriorWall)}
                                {this.pair(
                                    'Construction Type',
                                    PropertyCharacteristics?.ConstructType
                                )}
                            </Chunk>
                            <Chunk compare={compare} size='4'>
                                {this.pair('Year Build', PropertyCharacteristics?.YearBuilt)}
                                {this.pair('Year Eff', PropertyCharacteristics?.EFFYear)}
                                {this.pair('Stories', PropertyCharacteristics?.NumberOfStories)}
                                {this.pair('Parking Type', PropertyCharacteristics?.ParkingType)}
                                {this.pair('Garage #', PropertyCharacteristics?.GarageCapacity)}
                                {this.pair('Garage Area', PropertyCharacteristics?.GarageArea)}
                                {this.pair('Porch Type', PropertyCharacteristics?.PorchType)}
                                {this.pair('Patio Type', PropertyCharacteristics?.PatioType)}
                                {this.pair('Roof Type', PropertyCharacteristics?.RoofType)}
                                {this.pair('Roof Material', PropertyCharacteristics?.RoofMaterial)}
                            </Chunk>
                        </Row>
                    </Section>
                    <Section showBlankFields={showBlankFields} id='site-information'>
                        <h4 className='block-title'>
                            <b>Site Information</b>
                        </h4>
                        <Row>
                            <Chunk compare={compare} size='4'>
                                {this.pair('Land Use', SiteInformation?.LandUse)}
                                {this.pair('State Use', SiteInformation?.StateUse)}
                                {this.pair('County Use', SiteInformation?.CountyUse)}
                                {this.pair('Site Influence', SiteInformation?.SiteInfluence)}
                                {this.pair('Flood Zone Code', SiteInformation?.FloodZoneCode)}
                                {this.pair('Community Name', SiteInformation?.CommunityName)}
                            </Chunk>
                            <Chunk compare={compare} size='4'>
                                {this.pair('Lot Area', SiteInformation?.LotArea, 'sqft')}
                                {this.pair('Lot Width', SiteInformation?.LotWidth)}
                                {this.pair('Lot Depth', SiteInformation?.LotDepth)}
                                {this.pair('Usable Lot', SiteInformation?.UsableLot)}
                                {this.pair('Acres', SiteInformation?.Acres)}
                                {this.pair('Flood Map #', SiteInformation?.FloodZoneCode)}
                                {this.pair('Flood Panel #', SiteInformation?.FloodPanel)}
                            </Chunk>
                            <Chunk compare={compare} size='4'>
                                {this.pair('Zoning', SiteInformation?.Zoning)}
                                {this.pair('# of Buildings', SiteInformation?.NumberOfBuildings)}
                                {this.pair('Res Units', SiteInformation?.UnitsResidential)}
                                {this.pair('Comm Units', SiteInformation?.UnitsCommercial)}
                                {this.pair('Water Type', SiteInformation?.WaterType)}
                                {this.pair('Sewer Type', SiteInformation?.SewerType)}
                                {this.pair('Flood Map Date', SiteInformation?.FloodMapDate, 'date')}
                                {this.pair('Inside SFHA', SiteInformation?.InsideSFHA)}
                            </Chunk>
                        </Row>
                    </Section>
                    <Section showBlankFields={showBlankFields} id='tax-information'>
                        <h4 className='block-title'>
                            <b>Tax Information</b>
                        </h4>
                        <Row>
                            <Chunk compare={compare} size='4'>
                                {this.pair('Assessed Year', TaxInformation?.AssessedYear)}
                                {this.pair('Tax Area', TaxInformation?.TaxArea)}
                                {this.pair('Tax Year', TaxInformation?.TaxYear)}
                                {this.pair('Property Tax', TaxInformation?.PropertyTax)}
                                {this.pair('Exemption', TaxInformation?.TaxExemption)}
                            </Chunk>
                            <Chunk compare={compare} size='4'>
                                {this.pair(
                                    'Assessed Value',
                                    TaxInformation?.AssessedValue,
                                    'money'
                                )}
                                {this.pair('Land Value', TaxInformation?.LandValue, 'money')}
                                {this.pair(
                                    'Improved Value',
                                    TaxInformation?.ImprovementValue,
                                    'money'
                                )}
                                {this.pair('Improved %', TaxInformation?.ImprovedPercent)}
                                {this.pair('Delinquent Year', TaxInformation?.DelinquentYear)}
                            </Chunk>
                            <Chunk compare={compare} size='4'>
                                {this.pair(
                                    'Market Total Value',
                                    TaxInformation?.MarketValue,
                                    'money'
                                )}
                                {this.pair(
                                    'Market Land Value',
                                    TaxInformation?.MarketLandValue,
                                    'money'
                                )}
                                {this.pair(
                                    'Market Imprv Value',
                                    TaxInformation?.MarketImprovValue,
                                    'money'
                                )}
                                {this.pair(
                                    'Market Imprv %',
                                    TaxInformation?.MarketImprovValuePercent
                                )}
                            </Chunk>
                        </Row>
                    </Section>
                    {/*{   this.state.docuwareModal && */}
                    {/*<DocuwareModal show={this.state.docuwareModal} onClose={()=>{this.setState({docuwareModal: false})}} cabinet="lien" fileNo={this.props.directNo} />   */}
                    {/*}*/}
                </div>
            </div>
        )
    }
}

function Row(props) {
    return (
        <div className={'row' + (props.classNames ? ' ' + classNames : '')} style={props.style}>
            {props.children}
        </div>
    )
}

function Chunk(props) {
    return (
        <React.Fragment>
            <div
                className={props.compare ? 'row' : 'col-' + (props.size ? props.size : '12')}
                style={props.style}
            >
                {props.children}
            </div>
            {/* {
                props.compare &&
                <br/>
            } */}
        </React.Fragment>
    )
}

function Section(props) {
    return (
        <div className={'pdr-block mb-6'} id={props.id}>
            {props.children}
        </div>
    )
}
