export default function copyToClipboard(value) {
    let textArea = document.createElement('textarea')
    textArea.setAttribute('style', 'width:1px;border:0;opacity:0;')
    document.body.appendChild(textArea)
    textArea.value = value
    textArea.select()
    textArea.focus()
    document.execCommand('copy')
    document.body.removeChild(textArea)
}
