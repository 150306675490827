import { Button } from 'front'
import { useRouter } from 'next/router'
import { Alert, Modal } from 'react-bootstrap'
import { useUpdatePacket } from 'tracker/api'
import { usePacketDocuments } from '../hooks/usePacketDocuments'
import { AttachmentList } from '../wizard/attachments/AttachmentsPage'

export default function AddAttachmentModal({ packet, projectId, taskId, show, onHide }) {
    const router = useRouter()
    const { query } = router

    const { mutate: updatePacket, ...updatePacketApi } = useUpdatePacket({
        onSuccess: onHide,
    })
    const {
        documents: selectedAttachments,
        attachmentOptions,
        addAttachment,
        removeAttachment,
    } = usePacketDocuments(projectId, taskId)

    let submit = () => {
        if (packet?.id)
            updatePacket({ packetId: packet?.id, data: { documents: selectedAttachments } })
        else {
            packet.addAttachments(selectedAttachments?.map(({ id }) => id))
            onHide()
        }
    }

    const noAttachments = !attachmentOptions?.length > 0

    return (
        <Modal centered show={show} onHide={onHide} scrollable>
            <Modal.Header closeButton>
                <Modal.Title>Add Attachment(s)</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-0'>
                {!noAttachments ? (
                    <AttachmentList
                        attachments={attachmentOptions}
                        addAttachmentId={addAttachment}
                        removeAttachmentId={removeAttachment}
                    />
                ) : (
                    <div className='px-5'>No docuware files available for this project.</div>
                )}
            </Modal.Body>

            {updatePacketApi?.isError && (
                <Modal.Body>
                    <AddAttachmentErrorAlert />
                </Modal.Body>
            )}

            <Modal.Footer className='justify-content-between'>
                <Button onClick={onHide} ghost variant='secondary' className='me-2'>
                    Cancel
                </Button>
                {!noAttachments && (
                    <Button
                        onClick={submit}
                        icon={updatePacketApi?.isLoading ? 'fal fa-spinner-third fa-spin' : null}
                        variant='primary'
                        disable={updatePacketApi?.isLoading}
                    >
                        Add Attachment
                        {selectedAttachments?.length > 1 && 's'}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    )
}

const AddAttachmentErrorAlert = () => (
    <Alert variant='soft-danger'>
        <div className='d-flex'>
            <div className='flex-shrink-0'>
                <i className='fas fa-circle-exclamation' />
            </div>
            <div className='flex-grow-1 ms-2'>Error adding attachment</div>
        </div>
    </Alert>
)
