import React, { Component } from 'react'
import axios from '../../axios'
import EmailMergeFieldDropdown from './EmailMergeFieldDropdown'

export default class MergeFieldInput extends Component {
    constructor(props) {
        super(props)

        this.ref = React.createRef()

        this.state = {
            isLoading: true,
            text: props.value ? props.value : '',
            cursorPosition: 0,
            templates: [],
            dropdown: [],
        }
    }
    componentDidMount() {
        this.getTemplates()
        this.getDropdown()
    }
    componentDidUpdate() {
        if (this.state.text !== this.props.value) {
            this.mergeFields(this.props.value)
        }
    }

    getTemplates = () => {
        this.setState({ isLoading: true })
        axios.get('/portal/ucc/1/email_templates/json').then(({ data }) => {
            this.setState({
                isLoading: false,
                templates: data,
            })
        })
    }

    getDropdown() {
        axios.get('/portal/ucc/1/email_template_dropdown/json').then(({ data }) => {
            this.setState({
                mergeFieldDropdown: data,
            })
        })
    }

    mergeFields = (text) => {
        let index,
            startIndex = 0
        let endTag = 0
        while ((index = text.indexOf('<<', startIndex)) > -1) {
            startIndex = index + 2
            endTag = text.indexOf('>>', startIndex)
            const tag = text.slice(index + 2, endTag)
            const sourceIndex = tag.indexOf('_')
            const source = tag.slice(0, sourceIndex).toLowerCase()
            const field = tag.slice(sourceIndex + 1)
            let propsSource = this.props[source]
            if (propsSource) {
                const sourceField = propsSource[field]
                if (sourceField) {
                    text = text.replace('<<' + tag + '>>', sourceField)
                    this.setState({ text })
                    this.props.update(text)
                }
            }
        }

        this.ref.current.focus()
    }

    inputChange = (text) => {
        // console.log('input change',name,string)
        this.cursorPosition()
        this.setState({ text }, () => {
            this.mergeFields(text)
        })
    }

    addMergeField = (name, mergeField) => {
        const { cursorPosition } = this.state
        const firstSlice = this.state.text.slice(0, cursorPosition)
        const lastSlice = this.state.text.slice(cursorPosition)
        const merged = firstSlice + mergeField + lastSlice
        this.setState(
            {
                text: merged,
            },
            () => {
                this.mergeFields(merged)
            }
        )
        this.ref.current.focus()
    }

    cursorPosition = () => {
        this.setState({
            cursorPosition: this.ref.current.selectionStart,
        })
    }

    selectTemplate = (template) => {
        if (this.props.merge) {
            // this.
        }
    }

    render() {
        return (
            <div>
                {this.props.type === 'input' ? (
                    <input
                        className='form-control unstyled-input'
                        ref={this.subject}
                        type='text'
                        value={subject}
                        onKeyDown={() => {
                            this.cursorPosition('subject')
                        }}
                        onClick={() => {
                            this.cursorPosition('subject')
                        }}
                        name='subject'
                        onChange={({ target }) => {
                            this.inputChange(target.name, target.value)
                        }}
                    />
                ) : (
                    <React.Fragment>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <div className={'float-end'}>
                                    <EmailMergeFieldDropdown
                                        show={this.props.show}
                                        select={(mergeField) => {
                                            this.addMergeField('messageBody', mergeField)
                                        }}
                                        dropdown={this.state.mergeFieldDropdown}
                                    />
                                    <div className={'dropdown'}>
                                        <button
                                            className={'btn '}
                                            data-bs-toggle={'dropdown'}
                                            disabled={this.state.isLoading}
                                        >
                                            {this.state.isLoading && (
                                                <span className={'far fa-spin fa-spinner'} />
                                            )}{' '}
                                            Template <span className={'fas fa-caret-down'} />
                                        </button>
                                        <ul className={'dropdown-menu'}>
                                            <li className={'dropdown-header'}>General</li>
                                            {this.state.templates.map((template) => (
                                                <li
                                                    key={template.id}
                                                    className={'pointer'}
                                                    onClick={() => {
                                                        this.selectTemplate(template)
                                                    }}
                                                >
                                                    <a>{template.title}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <textarea
                            ref={this.ref}
                            style={{
                                border: '0px solid white',
                                boxShadow: 'none',
                                resize: 'none',
                                borderRadius: '10px',
                                backgroundColor: '#f7f7f7',
                                height: '300px',
                            }}
                            placeholder='Body'
                            className='form-control'
                            rows='5'
                            value={this.state.text}
                            onKeyDown={() => {
                                this.cursorPosition('messageBody')
                            }}
                            onClick={() => {
                                this.cursorPosition('messageBody')
                            }}
                            name={'messageBody'}
                            onChange={({ target }) => {
                                this.inputChange(target.name, target.value)
                            }}
                        ></textarea>
                    </React.Fragment>
                )}
            </div>
        )
    }
}
