import { useEffect, useRef } from 'react'
import useDebounce from './useDebounce'

function useAutosave({
    data,
    onSave,
    interval = 2000,
    triggerIfOnSaveChanges,
    saveOnUnmount = true,
}) {
    const valueOnCleanup = useRef(data)
    const initialRender = useRef(true)
    const debouncedValueToSave = useDebounce(data, interval)

    const dependencyArray = [debouncedValueToSave]
    if (triggerIfOnSaveChanges) dependencyArray.push(onSave)

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false
        } else {
            onSave(debouncedValueToSave)
        }
    }, dependencyArray)

    useEffect(() => {
        valueOnCleanup.current = data
    }, [data])

    const unmountDependencyArray = [debouncedValueToSave]
    if (triggerIfOnSaveChanges) unmountDependencyArray.push(onSave)

    useEffect(
        () => () => {
            if (saveOnUnmount) {
                onSave(valueOnCleanup.current)
            }
        },
        unmountDependencyArray
    )
}

export default useAutosave
