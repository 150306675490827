import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { mergeConfigs } from '../../../../utils'
import axios from '../Context/AxiosSetup'
import { Project } from './project.api'

export class Task {
    static updateTask = (taskId, data) => {
        return axios
            .patch(`/nml/tracker/2/task/${taskId}/json`, data)
            .then((response) => response.data)
    }
    static createTask = (projectId, data) => {
        return axios
            .post(`/nml/tracker/2/task/create/${projectId}/json`, data)
            .then((response) => response.data)
    }
    static reassignTask = (taskId, data) => {
        return axios
            .post(
                `/nml/tracker/2/task/${taskId}/assign/manual/${data.assigned}/json`,
                data?.text?.length > 0 ? data : {}
            )
            .then((response) => response.data)
    }
}

export const useUpdateTask = (config) => {
    const queryClient = useQueryClient()
    const router = useRouter()

    return useMutation(
        ({ taskId, data }) => Task.updateTask(taskId, data),
        mergeConfigs(
            {
                onSuccess: (response) => {
                    if (response?.project_id) {
                        queryClient.setQueryData(Project.keys.project(response?.project_id), {
                            ...queryClient.getQueryData(Project.keys.project(response?.project_id)),
                            ...response?.refresh,
                        })
                    }
                    if (response?.create_task) {
                        router.push(
                            `/nml/tracker/file/tasks/?project_id=${response?.project_id}&task_id=${response?.create_task.task_id}`
                        )
                    }
                },
            },
            config
        )
    )
}

export const useCreateTask = ({ projectId }, config) => {
    const queryClient = useQueryClient()
    projectId = parseInt(useRouter().query?.project_id)

    return useMutation(
        (data) => Task.createTask(projectId, data),
        mergeConfigs(
            {
                onSuccess: (response) => {
                    if (response?.refresh?.tasks) {
                        queryClient.setQueryData(Project.keys.project(projectId), {
                            ...queryClient.getQueryData(Project.keys.project(projectId)),
                            ...response?.refresh,
                        })
                    }
                },
            },
            config
        )
    )
}

export const useReassignTask = ({ projectId, taskId }, config) => {
    const queryClient = useQueryClient()

    return useMutation(
        (data) => Task.reassignTask(taskId, data),
        mergeConfigs(
            {
                onSuccess: (response) => {
                    if (response?.refresh?.tasks) {
                        queryClient.setQueryData(Project.keys.project(projectId), {
                            ...queryClient.getQueryData(Project.keys.project(projectId)),
                            ...response?.refresh,
                        })
                    }
                },
            },
            config
        )
    )
}
