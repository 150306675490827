import { Button } from 'front'
import { Alert, Col, Modal, Row } from 'react-bootstrap'
import {
    useRevertPacketToDraft,
    useSubmitPacketForPrint,
    useSubmitPacketForReview,
} from 'tracker/api'

export default function CancelPrintModal({
    show,
    onHide,
    packetId,
    projectId,
    taskId,
    currentEmployeeId,
    task,
}) {
    const { mutate: printPacket, ...printPacketApi } = useSubmitPacketForPrint({
        config: { onSuccess: onHide },
        project_id: projectId,
        task_id: taskId,
        employee_id: currentEmployeeId,
    })
    const { mutate: submitPacketForReview, ...submitPacketForReviewApi } = useSubmitPacketForReview(
        {
            config: { onSuccess: onHide },
            project_id: projectId,
            task_id: taskId,
            employee_id: currentEmployeeId,
        }
    )
    const { mutate: revertPacketToDraft, ...revertPacketToDraftApi } = useRevertPacketToDraft({
        config: { onSuccess: onHide },
        project_id: projectId,
        task_id: taskId,
        employee_id: currentEmployeeId,
    })

    let submit = () => {
        const hasReview = !!task?.stages?.find((stage) => stage?.stage === 'REVIEW')
        if (hasReview) {
            submitPacketForReview(packetId)
        } else {
            revertPacketToDraft(packetId)
        }
    }

    const hideModal = () => {
        onHide()
        revertPacketToDraftApi.reset()
        submitPacketForReviewApi.reset()
        printPacketApi.reset()
    }

    return (
        <Modal centered show={show} onHide={hideModal}>
            <Modal.Header closeButton>
                <Modal.Title>Cancel Printing</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>Are you sure you want to cancel printing this packet?</Col>
                </Row>
                {submitPacketForReviewApi?.isError && <CancelPrintingPacketError />}
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <Button onClick={hideModal} ghost variant='secondary' className='me-2'>
                    Keep in Print Queue
                </Button>
                <Button
                    onClick={submit}
                    icon={
                        printPacketApi?.isLoading || submitPacketForReviewApi?.isLoading
                            ? 'fal fa-spinner-third fa-spin'
                            : null
                    }
                    variant='danger'
                >
                    Cancel Print
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const CancelPrintingPacketError = () => (
    <Alert variant='soft-danger'>
        <div className='d-flex'>
            <div className='flex-shrink-0'>
                <i className='fas fa-circle-exclamation' />
            </div>
            <div className='flex-grow-1 ms-2'>Error cancelling print</div>
        </div>
    </Alert>
)
