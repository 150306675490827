import { useEffect, useState } from 'react'

const useScript = (src, onLoad) => {
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        const script = document.createElement('script')
        script.src = src
        script.async = true

        const handleScriptLoad = () => {
            setIsLoaded(true)
            if (typeof onLoad === 'function') {
                onLoad()
            }
        }

        script.addEventListener('load', handleScriptLoad)

        document.body.appendChild(script)

        return () => {
            script.removeEventListener('load', handleScriptLoad)
            document.body.removeChild(script)
        }
    }, [src, onLoad])

    return isLoaded
}

export default useScript
