import classnames from 'classnames'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { Button } from '../../../front'
import { useContext } from 'react'
import { ProjectContext } from 'tracker/Context/ProjectContext'
import SortableTable from '@/components/SortableTable'

export default function ResearchLinksModal({ show, onHide }) {
    // Client state
    const { fileInfo } = useContext(ProjectContext)

    return (
        <Modal show={show} onHide={onHide} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>Research Links </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SortableTable
                    info={fileInfo?.research_links?.filter(({ link }) => !!link)}
                    headers={[
                        {
                            name: 'Type',
                            value: 'description',
                            rowClassName: 'fw-bold text-black',
                        },
                        {
                            name: 'State',
                            value: 'state_code',
                            custom: (state_code) => (state_code != 'None' ? state_code : ''),
                        },
                        {
                            name: 'County',
                            value: 'county',
                            custom: (county) => (county != 'None' ? county : ''),
                        },
                        {
                            name: 'City',
                            value: 'city',
                            custom: (city) => (city != 'None' ? city : ''),
                        },
                        {
                            name: 'Link',
                            value: 'link',
                            custom: (link) => (
                                <span className='text-primary'>
                                    <a href={link} target='_blank'>
                                        {link}
                                    </a>
                                    <i className='ms-2 fal fa-arrow-up-right-from-square fa-sm' />
                                </span>
                            ),
                        },
                    ]}
                />
            </Modal.Body>
            <Modal.Footer className='justify-content-between'>
                <div />
                <Button onClick={onHide} variant='secondary' soft>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
