import { InteractionType } from '@azure/msal-browser'
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useIsAuthenticated,
    useMsal,
} from '@azure/msal-react'
import { QueryClient, QueryClientProvider, QueryCache, MutationCache } from '@tanstack/react-query'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useContext, useEffect, useState } from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import { useLocal } from '../hooks'
import { broadcastQueryClient, NcsLoadingSpinner } from '../utils'
import AdministratorLayout from './administrator/AdministratorLayout'
import ClientMaintenanceLayout from './clientMaintenance/ClientMaintenanceLayout'
import { PORTAL_ERRORS, PortalContext } from './GlobalState'
import Header from './Header'
import NmlLayout from './nml/tracker/NmlLayout'
import ToolsLayout from './tools/ToolsLayout'
import UCCLayout from './ucc/UCCLayout'
import useSession from './utils/SessionStorage'
import { CookiesProvider } from 'react-cookie'
import { LoadScript } from '@react-google-maps/api'
import { LoadingSpinner } from './utils'
const googleMapsApiKey = 'AIzaSyA7Wi6FxwOiK9TOOcy3qAyfa2LcrEUwbRs'

const mutationCache = new MutationCache({
    onError: (error) => {
        addError(error)
    },
})

const queryCache = new QueryCache({
    onError: (error) => {
        addError(error)
    },
})

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
    queryCache,
    mutationCache,
})

broadcastQueryClient({
    queryClient,
    broadcastChannel: 'react-query',
})

function addError({ message, response, ...rest }) {
    const [getErrors, setErrors] = useSession(PORTAL_ERRORS, [])
    const errors = getErrors()
    const id = Math.round(Math.random() * 1000)

    const label = [response?.status && response?.status.toString(), response?.statusText, message]
        .filter(Boolean)
        .join(' - ')

    setErrors([
        {
            label,
            id,
            data: response?.data,
            created: new Date(),
        },
        ...errors,
    ])
}

export default function AppLayout(props) {
    const { auth, employee, addError } = useContext(PortalContext)
    const [attempted, setAttempted] = useState(false)
    const isAuthenticated = useIsAuthenticated()
    const msal = useMsal()
    const { inProgress } = msal

    useEffect(() => {
        if (!auth.loading && !employee?.api_key && auth?.user?.email) {
            auth.getEmployee(auth?.user?.email)
        }
    }, [auth, employee])

    useEffect(() => {
        const activeAccount = auth.instance.getActiveAccount()

        if (inProgress === InteractionType.None && !isAuthenticated && !activeAccount) {
            // setAttempted(true)
            // auth.signIn()
        }
    }, [inProgress, isAuthenticated, attempted])

    function clearLocalAndReload() {
        if (window) {
            window.localStorage.clear()
            window.location.reload()
        }
    }

    return (
        <QueryClientProvider client={queryClient}>
            <CookiesProvider defaultSetOptions={{ path: '/' }}>
                {/* <ReactQueryDevtools /> */}
                <div className='main-content d-flex flex-column' style={{ height: '100vh' }}>
                    <Header />
                    <UnauthenticatedTemplate>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <div className={'align-center mt-3 text-center'}>
                                    {auth?.error?.message?.length > 0 && (
                                        <h4 className={'text-danger'}>*{auth?.error?.message}*</h4>
                                    )}
                                    Not signed in <br />
                                    <button className={'btn btn-primary'} onClick={auth.signIn}>
                                        <span className={'far fa-sign-in'} /> Sign In
                                    </button>
                                    <div>
                                        <button
                                            className={'btn btn-secondary mt-2'}
                                            onClick={clearLocalAndReload}
                                        >
                                            <span className={'far fa-times'} /> Clear and Refresh
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </UnauthenticatedTemplate>
                    <AuthenticatedTemplate>
                        {employee?.api_key ? (
                            <AppDisplay {...props} />
                        ) : (
                            <div className={'row'}>
                                <div className={'col-12 text-center'}>
                                    <h4>No Employee Info</h4>
                                    {auth.loading && (
                                        <h4>
                                            <span className={'far fa-spinner fa-spin'} /> Loading...
                                        </h4>
                                    )}
                                    {auth?.user?.email && !employee?.api_key && (
                                        <button
                                            className={'btn btn-primary'}
                                            onClick={() => {
                                                auth.getEmployee(auth?.user?.email)
                                            }}
                                            disabled={auth.loading}
                                        >
                                            <span
                                                className={
                                                    'far fa-' +
                                                    (auth?.loading ? 'spinner fa-spin' : 'plus')
                                                }
                                            />{' '}
                                            Get Info
                                        </button>
                                    )}
                                    <div>
                                        <button
                                            className={'btn btn-secondary mt-2'}
                                            onClick={clearLocalAndReload}
                                        >
                                            <span className={'far fa-times'} /> Clear and Refresh
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </AuthenticatedTemplate>
                </div>
            </CookiesProvider>
        </QueryClientProvider>
    )
}

function AppDisplay({ router, pageProps, Component }) {
    const [theme] = useLocal('app-theme', null)
    const [googleLoaded, setGoogleLoaded] = useState(false)

    useEffect(() => {
        console.log('GOOGLE', window?.google)
        if (window?.google) {
            setGoogleLoaded(true)
        }
    }, [googleLoaded, window?.google])

    const display = router.pathname.startsWith('/nml') ? (
        <NmlLayout>
            <Component {...pageProps} />
            {/* <FrontScripts/> */}
        </NmlLayout>
    ) : router.pathname.startsWith('/ucc') ? (
        <UCCLayout>
            <Component {...pageProps} />
        </UCCLayout>
    ) : router.pathname.startsWith('/client') ? (
        <ClientMaintenanceLayout>
            <Component {...pageProps} />
        </ClientMaintenanceLayout>
    ) : router.pathname.startsWith('/tools') ? (
        <ToolsLayout>
            <Component {...pageProps} />
        </ToolsLayout>
    ) : router.pathname.startsWith('/administrator') ? (
        <AdministratorLayout>
            <Component {...pageProps} />
        </AdministratorLayout>
    ) : (
        <Component {...pageProps} />
    )

    return (
        <SkeletonTheme
            baseColor={theme == 'light' ? '#f2f4f6' : '#2f323582'}
            highlightColor={theme == 'light' ? '#fbfbfb' : '#2f32354d'}
        >
            <LoadScript
                loadingElement={display}
                googleMapsApiKey={googleMapsApiKey}
                libraries={['places']}
            >
                {display}
            </LoadScript>
        </SkeletonTheme>
    )
}
