import { EventType, PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import '@fortawesome/fontawesome-pro/css/all.css'
import { useEffect } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import AppLayout from '../components/AppLayout'
import { ErrorBoundary } from '../components/Bugsnag'
import GlobalState, { config } from '../components/GlobalState'
import PortalHead from '../components/PortalHead'
import { useLocal } from '../hooks'
import '../styles/portal.scss'
import { Button } from 'front'
import { LoadScript } from '@react-google-maps/api'

const env = process.env.APPLICATION_ENV
const googleMapsApiKey = 'AIzaSyA7Wi6FxwOiK9TOOcy3qAyfa2LcrEUwbRs'

function MyApp(props) {
    const [theme] = useLocal('app-theme', null)

    useEffect(() => {
        if (theme) {
            document.body.classList.remove('app-theme-dark')
            document.body.classList.remove('app-theme-light')
            document.body.classList.add(`app-theme-${theme}`)
        }
    }, [theme])

    // const redirectUri = 'https://portal.loc.ncscredit.com'
    const msalInstance = new PublicClientApplication({
        auth: {
            clientId: config?.appId,
            redirectUri: process.env.DOMAIN,
        },
        cache: {
            cacheLocation: 'localStorage',
            storeAuthStateInCookie: false,
        },
    })

    // Check if there are already accounts in the browser session
    // If so, set the first account as the active account
    const accounts = msalInstance.getAllAccounts()

    if (accounts?.length > 0) {
        msalInstance.setActiveAccount(accounts[0])
    }

    msalInstance.addEventCallback((event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            // Set the active account - this simplifies token acquisition
            const authResult = event.payload
            msalInstance.setActiveAccount(authResult.account)
        }
    })

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <MsalProvider instance={msalInstance}>
                <GlobalState>
                    <PortalHead />
                    <AppLayout {...props} />
                </GlobalState>
            </MsalProvider>
        </ErrorBoundary>
    )
}

function ErrorFallback({ error }) {
    console.error('ERROR FALLBACK', error)
    return (
        <>
            <PortalHead />
            <div className='position-fixed top-0 start-0 end-0 bottom-0 d-flex flex-column gap-3 align-items-center justify-content-center'>
                <i className='far fa-circle-exclamation fa-4x text-black' />
                <div className='text-center'>
                    <h1>Uh oh. Something went wrong.</h1>
                </div>
                {error?.message && (
                    <div className='text-center'>
                        <h5>Error Message:</h5>
                        <div>{error?.message}</div>
                    </div>
                )}
                <Button
                    variant='link'
                    onClick={() => {
                        if (window) window.location.reload()
                    }}
                >
                    Reload Page
                </Button>
            </div>
        </>
    )
}

export default MyApp
