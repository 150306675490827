import { Badge, Card, Form, ListGroup } from 'front'
import { usePacketDraftContext } from '../PacketWizard'

export function SofteningLetterList({}) {
    const { softeningLetterOptions, setSofteningLetter } = usePacketDraftContext()

    return (
        <Card>
            <Card.Header>
                <Card.Title>
                    Select Softening Letter{' '}
                    <Badge bg='secondary' soft className='ms-2'>
                        Optional
                    </Badge>
                </Card.Title>
            </Card.Header>
            <ListGroup noBorders>
                {softeningLetterOptions?.map(({ id, selected, DOC_DESC }) => (
                    <ListGroup.Item
                        key={id}
                        action
                        onClick={() => {
                            setSofteningLetter(!selected ? id : null)
                        }}
                    >
                        <Form.Check type='radio' label={DOC_DESC} checked={selected} />
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </Card>
    )
}
