import classnames from 'classnames'
import _ from 'lodash'
import React, { useContext, useRef, useState } from 'react'
import ReactSelect, { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import { verifyFunctionThenCall } from '../../../utils'
import { PortalContext } from '../../GlobalState'
import { GlobalFormContext } from './Form'

// Uses React-Select library
// Docs: https://react-select.com/props
export function AdvancedSelect({
    className,
    value,
    isClearable = true,
    onChange,
    light,
    hoverLight,
    borderless,
    plaintext,
    ignoreGlobalDisable,
    isDisabled,
    options,
    onInputChange,
    styleOverrides = {},
    async,
    debounce,
    loading = false,
    ...rest
}) {
    const { disableAll } = useContext(GlobalFormContext)
    const { theme } = useContext(PortalContext)

    // Choose correct component based on props
    let SelectComponent = ReactSelect // Basic React-Select
    if (debounce) SelectComponent = DebounceSelect // NCS debounce wrapper around our AdvancedSelect
    else if (async) SelectComponent = AsyncSelect // Async React-Select

    return (
        <SelectComponent
            {...rest}
            components={{
                DropdownIndicator,
                ClearIndicator,
                MultiValueRemove,
                LoadingIndicator,
            }}
            defaultOptions
            isLoading={loading}
            value={value}
            options={options}
            onChange={onChange}
            onInputChange={onInputChange}
            styles={advancedSelectStyles(theme, styleOverrides)}
            isClearable={isClearable}
            isDisabled={isDisabled || (disableAll && !ignoreGlobalDisable) || loading}
            className={classnames('react-select-container', className)}
            classNamePrefix='react-select'
        />
    )
}

function DebounceSelect({
    debounce,
    onDebounce,
    debounceTimeout = 500,
    inputValue,
    onInputChange,
    ...rest
}) {
    const [input, setInput] = useState('')

    const updateDebounceValue = useRef(
        _.debounce((value) => onDebounce(value), debounceTimeout)
    ).current

    const handleInputChange = (inputText, event) => {
        setInput(inputText)
        updateDebounceValue(inputText)
        if (onInputChange && typeof onInputChange === 'function') {
            onInputChange(inputText, event)
        }
    }

    return (
        <AdvancedSelect
            {...rest}
            inputValue={inputValue ?? input}
            onInputChange={handleInputChange}
        />
    )
}

const LoadingIndicator = (props) => (
    <span className='text-muted fw-semibold me-2'>
        <i className='far fa-spinner-third fa-spin me-1' /> Loading...
    </span>
)

// Custom React-Select component props
const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
        <i className='fal fa-angle-down mx-1' />
    </components.DropdownIndicator>
)

const ClearIndicator = (props) => (
    <components.ClearIndicator {...props}>
        <i className='fal fa-xmark mx-1' />
    </components.ClearIndicator>
)

const MultiValueRemove = (props) => (
    <components.MultiValueRemove {...props}>
        <i className='fal fa-xmark mx-1' />
    </components.MultiValueRemove>
)

// React-Select custom CSS props
const advancedSelectStyles = (theme, override) => ({
    singleValue: (provided, state) => ({
        ...provided,
        color: theme == 'light' ? '#677788' : '#c5c8cc',
        ...verifyFunctionThenCall(override?.singleValue, state),
    }),
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled
            ? 'transparent'
            : theme == 'light'
            ? '#f6f7fa'
            : '#2a2d30',
        color: 'var(--bs-dark)',
        borderWidth: '.0625rem',
        borderStyle: 'solid',
        borderColor: theme == 'light' ? 'rgba(231,234,243,.7)' : '#2f3235',
        paddingTop: '4px',
        paddingBottom: '4px',
        '&:hover': {
            borderColor: null,
        },
        ...verifyFunctionThenCall(override?.control, state),
    }),
    input: (provided, state) => ({
        ...provided,
        color: 'var(--bs-dark)',
        ...verifyFunctionThenCall(override?.input, state),
    }),
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: theme == 'light' ? 'white' : 'var(--bs-light)',
        color: 'var(--bs-dark)',
        borderColor: 'var(--bs-gray-200)',
        zIndex: 50,
        overflow: 'hidden',
        ...verifyFunctionThenCall(override?.menu, state),
    }),
    clearIndicator: (provided, state) => ({
        ...provided,
        display: state.isDisabled ? 'none' : 'flex',
        color: theme == 'light' ? 'var(--bs-gray-500)' : '#838990',
        '&:hover': {
            color: 'var(--bs-dark)',
        },
        ...verifyFunctionThenCall(override?.clearIndicator, state),
    }),
    loadingIndicator: (provided, state) => ({
        ...provided,
        color: theme == 'light' ? 'var(--bs-gray-500)' : '#838990',
        ...verifyFunctionThenCall(override?.loadingIndicator, state),
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        backgroundColor: theme == 'light' ? 'var(--bs-gray-300)' : 'var(--bs-gray-500)',
        ...verifyFunctionThenCall(override?.indicatorSeparator, state),
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: theme == 'light' ? 'var(--bs-gray-500)' : '#838990',
        '&:hover': {
            color: 'var(--bs-dark)',
        },
        ...verifyFunctionThenCall(override?.dropdownIndicator, state),
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? 'var(--bs-gray-300)' : 'inherit',
        color: 'var(--bs-dark)',
        ...verifyFunctionThenCall(override?.option, state),
    }),
    multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: theme == 'light' ? 'var(--bs-gray-300)' : '#3c4045',
        padding: '0px 10px',
        borderRadius: '.3125rem',
        ...verifyFunctionThenCall(override?.multiValue, state),
    }),
    multiValueLabel: (provided, state) => ({
        ...provided,
        color: 'var(--bs-dark)',
        paddingLeft: null,
        paddingRight: null,
        padding: '3px 0px',
        ...verifyFunctionThenCall(override?.multiValueLabel, state),
    }),
    container: (provided, state) => ({
        ...provided,
        ...verifyFunctionThenCall(override?.container, state),
    }),
    group: (provided, state) => ({
        ...provided,
        paddingTop: '0px',
        ...verifyFunctionThenCall(override?.group, state),
    }),
    groupHeading: (provided, state) => ({
        ...provided,
        position: 'sticky',
        color: '#91989e',
        top: '0px',
        paddingBottom: '4px',
        paddingTop: '4px',
        backgroundColor: theme == 'light' ? '#f6f7fa' : '#232629',
        ...verifyFunctionThenCall(override?.groupHeading, state),
    }),
    indicatorsContainer: (provided, state) => ({
        ...provided,
        ...verifyFunctionThenCall(override?.indicatorsContainer, state),
    }),
    loadingMessage: (provided, state) => ({
        ...provided,
        ...verifyFunctionThenCall(override?.loadingMessage, state),
    }),
    menuList: (provided, state) => ({
        ...provided,
        paddingTop: '0px',
        paddingBottom: '0px',
        ...verifyFunctionThenCall(override?.menuList, state),
    }),
    menuPortal: (provided, state) => ({
        ...provided,
        ...verifyFunctionThenCall(override?.menuPortal, state),
    }),
    multiValueRemove: (provided, state) => ({
        ...provided,
        color: '#91989e',
        borderRadius: '.3125rem',
        padding: '0px',
        marginLeft: '6px',
        display: state.isDisabled ? 'none' : 'flex',
        '&:hover': {
            // backgroundColor: theme == 'light' ? 'var(--bs-gray-400)' : 'var(--bs-gray-500)',
            backgroundColor: 'inherit',
            color: 'var(--bs-dark)',
        },
        ...verifyFunctionThenCall(override?.multiValueRemove, state),
    }),
    noOptionsMessage: (provided, state) => ({
        ...provided,
        ...verifyFunctionThenCall(override?.noOptionsMessage, state),
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: '#91989e',
        ...verifyFunctionThenCall(override?.placeholder, state),
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        ...verifyFunctionThenCall(override?.valueContainer, state),
    }),
})
