import { useEffect, useState } from 'react'
import copy from './copyToClipboard'
import { Button } from 'front'

export default function CopyButton(props) {
    const [copied, setCopied] = useState(0)

    useEffect(() => {
        setCopied(0)
    }, [props.copy])

    const copyToClipboard = () => {
        copy(props.copy)

        setCopied(1)

        setTimeout(() => {
            setCopied(0)
        }, 2000)
    }

    const copiedDisplay = () => {
        return <span className={'far ' + (copied === 1 ? 'check-icon fa-check' : 'fa-clipboard')} />
    }

    if (props.inline) {
        return (
            <div
                className={props.className ?? 'inline-copy'}
                style={{ display: 'inline-block' }}
                onClick={copyToClipboard}
            >
                <table>
                    <tbody>
                        <tr>
                            <td>{copiedDisplay()}</td>
                            <td
                                className={props.limit ? 'limit-size' : ''}
                                style={{ paddingLeft: '10px' }}
                                title={props.children}
                            >
                                {props.children}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
    return (
        <Button className={props.className ? props.className : ''} onClick={copyToClipboard}>
            {copiedDisplay()} {props.children}
        </Button>
    )
}
